import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Button, Menu, Icon, Row, Col, Popover, Select, List } from 'antd';
import ScrollBar from 'react-perfect-scrollbar';
import { getSourceFilter } from '../tool/layerDataSource';
import Detail from './detail';
import Styles from './styles';
import { updateMap } from '../../redux/actions/index';


import './index.scss';
// import filter from './../../redux/reducers/filter';

class Lists extends Component {
  constructor(props) {
    super(props);
    this.state = {
      resultStyle: []
    }
  }

  initState = (props) => {
    const { activeStyle, zoom, selectLayerInfor, resultStyle, id } = props;
    const filter = getSourceFilter(activeStyle.filter);

    this.setState({
      ...props,
      filter,
      canAddFilter: Object.keys(filter).length > 1,
      filterStandarFlag: false,
      selectedKeys: this.getDefaultSelectKeys(filter, zoom, selectLayerInfor, resultStyle, id),
      detail: false
    })
  }

  componentDidMount() {
    this.initState(this.props);
  }

  getDefaultSelectKeys = (filter, zoom, selectLayerInfor, resultStyle, layerId) => {

    const { selectedKeys, id } = this.state;

    let selectedKeys_ = ['0'];
    let currentZoomFilter = resultStyle.find((item) => {
      return item.zoom.includes(`${zoom}`);
    });

    if (Object.keys(filter).length > 1 && currentZoomFilter && currentZoomFilter.values.length > 1 && selectLayerInfor) {
      currentZoomFilter.values.forEach((item, index) => {
        item.filter.forEach((fiterItem) => {
          fiterItem.forEach((sonFilterItem) => {
            if (selectLayerInfor.layerInfor[sonFilterItem.type] !== undefined) {
              if (sonFilterItem.type !== 'population' && (sonFilterItem.equalArr.includes(String(selectLayerInfor.layerInfor[sonFilterItem.type])) || sonFilterItem.equalArr.includes(String(Number(selectLayerInfor.layerInfor[sonFilterItem.type]))))) {
                selectedKeys_[0] = String(index)
              } else if (sonFilterItem.type === 'population' && (selectLayerInfor.layerInfor[sonFilterItem.type] > Number(sonFilterItem.minRange)) && (selectLayerInfor.layerInfor[sonFilterItem.type] < Number(sonFilterItem.maxRange))) {
                selectedKeys_[0] = String(index)
              }
            }
          })
        })
      })
    } else if (id === layerId && currentZoomFilter.values[Number(selectedKeys[0])]) {

      selectedKeys_ = [...selectedKeys];
    } else {
      selectedKeys_ = ['0'];
    }


    return selectedKeys_
  }

  componentWillReceiveProps(nextProps) {
    this.initState(nextProps);
  }

  handleMenuClick = (e) => {
    const key = e.key;
    this.setState({
      selectedKeys: [`${key}`]
    })
  }

  showDetail = (filter, index) => {
    this.setState({
      detail: filter,
      detailIndex: index,
      filterStandarFlag: false
    })
  }

  closeDetail = () => {
    this.setState({
      detail: false
    })
  }

  addFilter = (e) => {
    e.persist()
    let type = e.target.innerText
    if (e.target.nodeName === 'A') {
      this.setState({
        filterStandarFlag: false
      })
      const addObj = {
        state: 'add'
      }

      if (type !== 'population') {
        addObj.filter = [[{
          equalArr: [],
          source: null,
          type: type
        }]]
      } else {
        addObj.filter = [[{
          equalArr: [],
          source: null,
          type: type,
          minRange: '',
          maxRange: ''
        }]]
      };
      addObj.style = {}

      this.setState({
        detail: addObj
      })
    }

  }

  deleteDetial = (supId, index) => {
    if (this.state.detail.filter[supId].length > 1) {
      this.state.detail.filter[supId].splice(index, 1);
      this.setState({})
    } else if (this.state.detail.filter[supId].length === 1) {
      if (this.state.detail.filter.length > 1) {
        this.state.detail.filter.splice(supId, 1);
      } else if (this.state.detail.filter.length === 1) {
        this.setState({
          detail: false
        })
        const { resultStyle, currentKey } = this.state;
        const { zoom } = this.props;
        let currentZoomFilterIndex = resultStyle.findIndex((item) => {
          return item.zoom.includes(`${zoom}`);
        });
        if (resultStyle[currentZoomFilterIndex].values.length === 1) {
          resultStyle[currentZoomFilterIndex].values[0].filter = [];
          this.setState({
            selectedKeys: ['0']
          })
        } else {
          resultStyle[currentZoomFilterIndex].values.splice(this.state.detailIndex, 1);
          this.setState({
            selectedKeys: ['0']
          })
        }
      }
    }
  }

  updatedDetail = ({ supId, index, value, operation }) => {
    const { resultStyle } = this.state;
    switch (operation) {
      case 'delete':
        this.deleteDetial(supId, index);
        break
      case 'addDataGroup':
        const { zoom } = this.props;
        let currentZoomFilterIndex = resultStyle.findIndex((item) => {
          return item.zoom.includes(`${zoom}`);
        });
        if (resultStyle[currentZoomFilterIndex].values.length === 1 && resultStyle[currentZoomFilterIndex].values[0].filter[0].length === 0) {
          resultStyle[currentZoomFilterIndex].values[0].filter = this.state.detail.filter;
        } else {
          resultStyle[currentZoomFilterIndex].values.push(this.state.detail);
          //console.log(resultStyle[currentZoomFilterIndex].values)
          this.setState({
            selectedKeys: ['0']
          })
        }
        break
      case 'addOrCondition':
        this.state.detail.filter.push(value)
        break
      default: break;
    }
    this.updateStyleJson();
  }

  renderfilterListsItem = (currentZoomFilterArr) => currentZoomFilterArr.map((item, index) => {
    let str = '';

    if (item.filter && item.filter.length > 0) {
      item.filter.forEach((filterItem) => {
        if (filterItem.length > 0) {
          let sonStr = filterItem[0].source;
          str += `${sonStr}|`
        }
      });
    }

    if (str.length > 0) {
      str = str.substring(0, str.length - 1);
      return (
        <Menu.Item key={index} title={str} >
          <Row type="flex" justify="start">
            <Col span={22}>
              <p>
                {
                  str
                }
              </p>
            </Col>
            <Col span={1} offset={1}>
              <Icon type="info-circle" onClick={() => this.showDetail(item, index)} />
            </Col>
          </Row>
        </Menu.Item>
      )
    }
  })

  renderLists = () => {
    const { selectedKeys, resultStyle } = this.state;

    //console.log(resultStyle)
    const { zoom } = this.props;
    const currentZoomFilter = resultStyle.find((item) => {
      return item.zoom.includes(`${zoom}`)
    });

    return (
      <Menu
        className='filter-list'
        onClick={this.handleMenuClick}
        selectedKeys={selectedKeys}
      >
        {currentZoomFilter && currentZoomFilter.values ? this.renderfilterListsItem(currentZoomFilter.values) : null}}
      </Menu>
    )
  }

  updateFilterStandarFlag = (event) => {
    this.setState({ filterStandarFlag: !this.state.filterStandarFlag });
  }

  closeFilterStander = (event) => {
    this.setState({ filterStandarFlag: false });
  }

  //popover about add filter
  renderFilterAttributeItem = (filterStandard) => Object.keys(filterStandard).map((item, index) => {
    if (item !== 'zoom') {
      return (
        <Menu.Item key={item}>
          <span>{item}</span>
        </Menu.Item>
      )
    }
  });

  renderFilterAttributeList = (filterStandard) => {
    const columnArr = Object.keys(filterStandard).filter((columnItem) => {
      return columnItem !== 'zoom';
    })
    return (
      <List onClick={this.addFilter}
        dataSource={columnArr}
        header={<div>Choose a data column</div>}
        bordered
        renderItem={item => <List.Item key={item} id={item} ><a href="#">{item}</a></List.Item>} >
      </List>
    );
  };

  updateStyleJson = () => {
    this.props.updateMap(true);
    this.props.updateStyleJson();
  }

  render() {
    const { canAddFilter, detail, selectedKeys, resultStyle, type, id, pointStyle, detailIndex } = this.state;
    const { zoom } = this.props;
    const filterStandard = this.state.filter
    const lists = this.renderLists();
    let filterChildren = null;


    if (lists && Array.isArray(lists.props.children[0]) && lists.props.children[0][0]) {
      filterChildren = lists.props.children[0].length;
    };
    let filterAttributeList;
    if (filterStandard) {
      filterAttributeList = this.renderFilterAttributeList(filterStandard);
    }
    const currentZoomFilter = resultStyle.find((item) => {
      return item.zoom.includes(`${zoom}`)
    });

    return (
      <React.Fragment>
        {
          canAddFilter && !filterChildren &&
          <Popover placement="right" content={filterAttributeList} overlayStyle={{ padding: '7px' }} overlayClassName='filterStandardList' trigger="click"  >
            <Button block size={'small'} type="primary" icon="plus" onClick={this.updateFilterStandarFlag} className="add-style-btn">
              Add Style by Data Group
            </Button>
          </Popover>
        }
        {filterChildren && filterStandard &&
          <div className='filter-list-scrollbar'>
            {filterChildren > 3 ? <div className='filter-list-wrap'>
              <ScrollBar>
                {lists}
              </ScrollBar>
            </div> : <div>
                {lists}
              </div>}

            <p className='add-another-data'>
              <Popover placement="right" content={filterAttributeList} overlayStyle={{ padding: '7px' }}  overlayClassName='filterStandardList' trigger="click" >
                <a onClick={this.updateFilterStandarFlag}><Icon type="plus" /> Add Style by Data Group </a>
              </Popover>
            </p>
          </div>
        }
        {detail && <Detail detailIndex={detailIndex} detail={detail} filterStandard={filterStandard} closeDetail={this.closeDetail} updatedDetail={this.updatedDetail} filterAttributeList={filterAttributeList} currentZoomFilter={currentZoomFilter} />}
        <Styles
          selectedKeys={selectedKeys}
          resultStyle={resultStyle}
          activeStyle={this.props.activeStyle}
          zoom={zoom}
          type={type}
          id={id}
          pointStyle={pointStyle}
          autoRefresh={this.props.autoRefresh}
          updateStyleJson={this.updateStyleJson}
          filterStandard={this.props.filterStandard}
          handleJsonViewerChange={this.props.handleJsonViewerChange}
        />
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => {
  const { rootReducer } = state;

  return ({
    zoom: Math.floor(rootReducer.zoom)
  })
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    updateMap
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Lists);

