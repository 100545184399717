import React, { Component } from 'react';

import './index.scss'
import '../index.scss'
import { Tabs, Icon } from 'antd';
import Font from './font';
import FontShield from './font/FontShield';
import PointIcon from './pointIcon';
import ShieldIcon from './pointIcon/ShieldIcon';
import Mask from './mask';
import IconMask from './mask/IconMask';
import TextPlacement from './textPlacement';
import IconFont from '../../tool/iconFont';
import filter from './../../../redux/reducers/filter';

const TabPane = Tabs.TabPane;

class Point extends Component {

    constructor(props) {
        super(props);
        this.state = {
            // activeStyle: {...this.props.activeStyle}
        }

        // Update Json code viewer.
        const activeStyle = JSON.stringify(this.props.activeStyle) === "{}" ? {} : this.props.activeStyle;
        const currentStyle = JSON.parse(JSON.stringify(activeStyle));
        delete currentStyle['id'];
        this.props.handleJsonViewerChange(currentStyle);
    }

    componentWillReceiveProps(nextProps) {
        // this.setState({ ...nextProps.activeStyle })
    }

    componentWillUpdate(nextProps, nextState) {
        // Update Json code viewer.
        const activeStyle = JSON.stringify(nextProps.activeStyle) === "{}" ? {} : nextProps.activeStyle;
        const currentStyle = JSON.parse(JSON.stringify(activeStyle));
        delete currentStyle['id'];
        this.props.handleJsonViewerChange(currentStyle);
    }

    updatedStyle = (style) => {
        const afterUpdateStyle = JSON.parse(JSON.stringify(style));
        delete afterUpdateStyle.iconData
        delete afterUpdateStyle.selectItemContent
        if (afterUpdateStyle['text-font-size']) {
            let fontVariant = afterUpdateStyle['text-font-variant'] ? afterUpdateStyle['text-font-variant'] + ' ' : ''
            let variant = afterUpdateStyle['text-letter-case'] === 'small-caps' ? 'small-caps' + ' ' : ''
            let fontStyle = afterUpdateStyle['text-font-style'] ? afterUpdateStyle['text-font-style'] + ' ' : ''
            let fontWeight = afterUpdateStyle['text-font-weight'] ? afterUpdateStyle['text-font-weight'] + ' ' : ''
            let fontSize = afterUpdateStyle['text-font-size'] ? afterUpdateStyle['text-font-size'] + 'px' + ' ' : ''
            let fontFamily = afterUpdateStyle['text-font-family'] ? afterUpdateStyle['text-font-family'].join(', ') : ''

            let str = fontVariant + fontStyle + fontWeight + fontSize + fontFamily
            afterUpdateStyle['text-font'] = str
            delete afterUpdateStyle['text-font-style']
            delete afterUpdateStyle['text-font-weight']
            delete afterUpdateStyle['text-font-size']
            delete afterUpdateStyle['text-font-family']
            delete afterUpdateStyle['text-font-variant']
        } else if (afterUpdateStyle['shield-font-size']) {

            let fontStyle = afterUpdateStyle['shield-font-style'] ? afterUpdateStyle['shield-font-style'] + ' ' : ''
            let fontVariant = afterUpdateStyle['shield-font-variant'] ? afterUpdateStyle['shield-font-variant'] + ' ' : ''
            let fontWeight = afterUpdateStyle['shield-font-weight'] ? afterUpdateStyle['shield-font-weight'] + ' ' : ''
            let fontSize = afterUpdateStyle['shield-font-size'] ? afterUpdateStyle['shield-font-size'] + 'px' + ' ' : ''
            let fontFamily = afterUpdateStyle['shield-font-family'] ? afterUpdateStyle['shield-font-family'].join(', ') : ''
            let str = fontVariant + fontStyle + fontWeight + fontSize + fontFamily
            afterUpdateStyle['shield-font'] = str
            delete afterUpdateStyle['shield-font-style']
            delete afterUpdateStyle['shield-font-weight']
            delete afterUpdateStyle['shield-font-size']
            delete afterUpdateStyle['shield-font-family']
            delete afterUpdateStyle['shield-font-variant']
        } else if (afterUpdateStyle['shield-icon-type'] === 'none') {
            delete afterUpdateStyle['shield-icon-type']
        } else if (afterUpdateStyle['point-type'] === 'none') {
            delete afterUpdateStyle['point-type']
        } else if (afterUpdateStyle['text-mask-type'] === 'none') {
            delete afterUpdateStyle['text-mask-type'];
            // delete afterUpdateStyle['text-mask-outline-width'];
            // delete afterUpdateStyle['text-mask-color'];
            // delete afterUpdateStyle['text-mask-margin'];
            // delete afterUpdateStyle['text-mask-outline-color']
        } else if (afterUpdateStyle['point-glyph-mask-type'] === 'none') {
            delete afterUpdateStyle['point-glyph-mask-type'];
            // delete afterUpdateStyle['point-glyph-mask-outline-width'];
            // delete afterUpdateStyle['point-glyph-mask-color'];
            // delete afterUpdateStyle['point-glyph-mask-margin'];
            // delete afterUpdateStyle['point-glyph-mask-outline-color']

        }
        Object.keys(afterUpdateStyle).map((afterUpdateStyleKey) => {
            if (afterUpdateStyle[afterUpdateStyleKey] == undefined) {
                delete afterUpdateStyle[afterUpdateStyleKey]
            }
        })
        this.props.updatedStyle(afterUpdateStyle)
    }

    render() {
        const { activeStyle, activeStyleJson, pointStyle } = this.props;
        let flag = activeStyleJson && activeStyleJson.filter && activeStyleJson.filter.includes('road') ? true : false
        // const activeStyle = JSON.stringify(this.props.activeStyle) === "{}" ? {} : this.state;

        return (
            <div>
                {pointStyle === 'text' ? <Tabs type="card" defaultActiveKey="1" className="edit-list-tab">

                    <TabPane tab="Label" key="1" >
                        <Font activeStyle={activeStyle} updatedStyle={this.updatedStyle}></Font>
                    </TabPane>

                    <TabPane tab="Mask" key="2">
                        <Mask activeStyle={activeStyle} updatedStyle={this.updatedStyle}></Mask>
                    </TabPane>
                    {
                        flag ? <TabPane tab="Placement" key="3">
                            <TextPlacement activeStyle={activeStyle} updatedStyle={this.updatedStyle}></TextPlacement>
                        </TabPane> : null
                    }
                </Tabs> : null}
                {pointStyle === 'point' ? <div> <PointIcon activeStyle={activeStyle} updatedStyle={this.updatedStyle}></PointIcon>
                    <IconMask activeStyle={activeStyle} updatedStyle={this.updatedStyle}  ></IconMask></div> : null}


                {pointStyle === 'shield' ? <div>
                    <FontShield activeStyle={activeStyle} updatedStyle={this.updatedStyle}></FontShield>
                    <ShieldIcon activeStyle={activeStyle} updatedStyle={this.updatedStyle}></ShieldIcon>
                </div> : null}
            </div>



        );
    }
}

export default Point;