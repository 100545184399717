import React, { Component } from 'react';
import '../index.scss'
import './index.scss'

import LineItem from './item';
import { Tabs, Button } from 'antd';
import _ from 'lodash';
const TabPane = Tabs.TabPane;

// const props = {
//     action: '//jsonplaceholder.typicode.com/posts/',
//     listType: 'picture',
// }

class Line extends Component {
    constructor(props) {
        super(props);
        this.state = {
            lineData: this.handleChidren(this.props),
            activeKey: '0'
        }
        // Update Json code viewer.
        const activeStyle = JSON.stringify(this.props.activeStyle) === "{}" ? {} : this.state;
        const currentStyle = JSON.parse(JSON.stringify(activeStyle));
        delete currentStyle['id'];
        this.props.handleJsonViewerChange(currentStyle);
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            lineData: this.handleChidren(nextProps), 
            activeKey: this.props.id === nextProps.id && this.props.selectedKeys[0] === nextProps.selectedKeys[0] ? this.state.activeKey: '0'
        })
    }

    componentWillUpdate = (nextProps, nextState) => {
        // Update Json code viewer.
        const activeStyle = JSON.stringify(nextProps.activeStyle) === "{}" ? {} : nextState;
        const currentStyle = JSON.parse(JSON.stringify(activeStyle));
        delete currentStyle['id'];
        this.props.handleJsonViewerChange(currentStyle);
    }

    handleChidren = (props) => {
        const activeStyle = props.activeStyle
        const afterHandleStyle = [];
        afterHandleStyle[0] = activeStyle;

        if (activeStyle && activeStyle['children']) {
            activeStyle['children'].forEach(item => {
                afterHandleStyle.push(item)
            });
        }
        delete afterHandleStyle[0]['children'];

        return afterHandleStyle;
    }

    updatedStyle = (style, index) => {
        
        const afterUpdateStyle = {
            ...style
        }
        if (afterUpdateStyle.dasharray) {
            delete afterUpdateStyle.dasharray
        }
        const { lineData } = this.state;

        lineData[index] = afterUpdateStyle
        const childrenStyleArr = JSON.parse(JSON.stringify(lineData));
        this.setState({ lineData })

        if (lineData.length > 1) {
            const arr = JSON.parse(JSON.stringify(lineData));
            childrenStyleArr[0].children = [...arr.splice((0, 1))]
        }

        this.props.updatedStyle(childrenStyleArr[0])
    }

    onChange = activeKey => {
        this.setState({ activeKey });
    }

    add = () => {
        const { lineData } = this.state;
        const obj = {
            "line-width": 1,
            "line-color": "#000000"
        }
        lineData.push(obj)
        let activeKey = `${lineData.length - 1}`;
        this.setState({ lineData, activeKey: activeKey },()=>{
            this.updatedStyle(obj, activeKey)
        });
     
    }

    onEdit = (targetKey, action) => {
        if (action === 'remove') {
            const { lineData } = this.state;
            let activeKey;
            lineData.splice(Number(targetKey), 1)
            if (Number(targetKey) > 0) {
                activeKey = `${Number(targetKey) - 1}`
            } else {
                activeKey = '0'
            }
            const childrenStyleArr = JSON.parse(JSON.stringify(lineData));
            if (lineData.length > 1) {
                const arr = JSON.parse(JSON.stringify(lineData));
                childrenStyleArr[0].children = [...arr.splice((0, 1))]
            }
           
            this.setState({ lineData, activeKey: String(activeKey) },()=>{
                this.props.updatedStyle(childrenStyleArr[0])
            });        
        }
    }

    render() {
        const operations = <Button icon="plus" size='small' shape="circle" onClick={this.add} className='add-line'></Button>;
        const renderLinItem = (lineData) => lineData.map((item, index) => {
            return (
                <TabPane tab={index === 0 ? 'Line' : 'Subline' + index} key={`${index}`} >
                    <LineItem activeStyle={item} lineItemIndex={index} updatedStyle={this.updatedStyle} ></LineItem>
                </TabPane>
            )
        });

        return (
            <Tabs type={this.state.lineData.length > 1 ? 'editable-card' : 'card'} hideAdd tabBarExtraContent={this.state.lineData.length > 2 ? null : operations} activeKey={this.state.activeKey} onEdit={this.onEdit} onChange={this.onChange}  >
                {renderLinItem(this.state.lineData)}
            </Tabs>
        );
    }
}

export default Line;