import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import { Tree, Tooltip } from "antd";
import ScrollBar from 'react-perfect-scrollbar';
import { saveLayerData } from '../../../redux/actions/redoUndo';
import { updateJson, updateMap } from '../../../redux/actions/index';
const { TreeNode } = Tree;

class OrderedLayer extends Component {
  constructor(props){
    super(props);
    this.state = {
      layerDatas: [],
      shouldLayerOrderUpdate: true
    }
  }

  componentDidMount(){
    this.getInitLayerDatas();
  }

  componentWillReceiveProps(nextProps){
    this.updateLayerDatas(nextProps);
  }

  updateLayerDatas(nextProps){
    const {layerDatas, index, newSidebarData} = nextProps;
    let layerDataArr = layerDatas[index];
    
    if(layerDataArr && layerDataArr.type === 'layerOrder'){
      const localLayerDatas = this.state.layerDatas;
      if(!_.isEqual(layerDataArr.data, localLayerDatas)){
        
        this.setState({
          layerDatas: layerDataArr.data,
        })
      }
    }

    this.setState({
      isLayersStyled: this.getIsLayersStyled(newSidebarData)
    })    
  }

  getInitLayerDatas = () => {
    const {json, newSidebarData} = this.props;
    const layers = json.layers;
    let layerDatas = [];

    if(layers && layers[0]){
      layerDatas = layers[0].styles;
    }

    this.setState({
      initialLayerDatas: [...layerDatas],
      layerDatas,
      isLayersStyled: this.getIsLayersStyled(newSidebarData)
    })
  }  

  getIsLayersStyled = (newSidebarData) => {
    let obj = {};

    newSidebarData.forEach(item => {
      obj[item.id] = item.isHaveStyle;
    })

    return obj;
  }
  
  onDrop = (info) => {
    const {layerDatas} = this.state;
    const dropKey = info.node.props.eventKey;
    const dragKey = info.dragNode.props.eventKey;
    const dropPos = info.node.props.pos.split('-');
    const dropPosition = info.dropPosition - Number(dropPos[dropPos.length - 1]);
    
    const loop = (data, key, callback) => {
      data.forEach((item, index, arr) => {
        if (item === key) {
          return callback(item, index, arr);
        }        
      });
    };
    const data = [...layerDatas];

    // Find dragObject
    let dragObj;
    loop(data, dragKey, (item, index, arr) => {
      arr.splice(index, 1);
      dragObj = item;
    });

    if (!info.dropToGap) {
      // // Drop on the content
      // loop(data, dropKey, item => {
      //   item.children = item.children || [];
      //   // where to insert 示例添加到尾部，可以是随意位置
      //   item.children.push(dragObj);
      // });
    } else if (
      (info.node.props.children || []).length > 0 && // Has children
      info.node.props.expanded && // Is expanded
      dropPosition === 1 // On the bottom gap
    ) {
      // loop(data, dropKey, item => {
      //   item.children = item.children || [];
      //   // where to insert 示例添加到尾部，可以是随意位置
      //   item.children.unshift(dragObj);
      // });
    } else {
      let ar;
      let i;
      loop(data, dropKey, (item, index, arr) => {
        ar = arr;
        i = index;
      });
      if (dropPosition === -1) {
        ar.splice(i, 0, dragObj);
      } else {
        ar.splice(i + 1, 0, dragObj);
      }
    }

    this.saveOrderedLayerData(data);
    this.setState({
      layerDatas: data,
    });
  }

  saveOrderedLayerData = (OrderedLayerData) => {
    const {index, maxLength, layerDatas, json, zoom, center} = this.props;
    const {initialLayerDatas, shouldLayerOrderUpdate} = this.state;
    const initData = {
			type: 'layerOrder',
      data: initialLayerDatas,
      mapState: {
        zoom,
        center
      }
		};

    const data = {
			type: 'layerOrder',
      data: OrderedLayerData,
      mapState: {
        zoom,
        center
      }
    };
    
    const dataArr = shouldLayerOrderUpdate ? [initData, data] : [data];

    const json_ = JSON.parse(JSON.stringify(json));
    json_.layers[0].styles = [...OrderedLayerData];
    // this.props.updateMap(true);
    this.props.updateJson(json_);
    this.props.saveLayerData(dataArr, index, maxLength, layerDatas);
    
    this.setState({
      shouldLayerOrderUpdate: false
    })
  }
  
  loop = () => {
    const {layerDatas, isLayersStyled} = this.state;
    let treeNodes = [];
    
    for(let i = layerDatas.length - 1; i >= 0; i--){
      const item = layerDatas[i];
      treeNodes.push(
        <TreeNode 
          key={item} 
          title={<Tooltip title={isLayersStyled[item] ? 'Layer available at current zoom level, and shows on' :
            isLayersStyled[item] === undefined ? 'Layer unavailable at current zoom level' : 'Layer available at current zoom level, but disabled'}>{item}</Tooltip>} 
          className={(isLayersStyled[item] ? 'styled' : 
            (isLayersStyled[item] === undefined ? 'without-style' : 'without-style canStyled'))}
        />
      )
    }

    return treeNodes;
  }
  
  render(){

    return (
      <ScrollBar>
        <Tree
          draggable
          onDrop={this.onDrop}
          className='orderLayersTree'
        >
          {this.loop()}
        </Tree>
      </ScrollBar>
    )
  }
}

const mapStateToProps = (state) => {
  const {rootReducer, filter} = state;

	return {
		index: filter.index,
		layerDatas: filter.layerDatas, 
		maxLength: filter.maxLength,
    json: rootReducer.json,
    zoom: rootReducer.zoom,
    center: rootReducer.centerPoint
	}
}

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators({
    saveLayerData,
    updateJson,
    updateMap
	}, dispatch)
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderedLayer);