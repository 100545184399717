import React, { Component } from 'react';
import WebFont from 'vectormap-icons/webfontloader';
import MapContent from '../../component/map/index';
import { connect } from 'react-redux';
import { updateZoom, updateJson, updateWebfontLoaded, updateCenterPoint, changeTitleState } from '../actions';
import { saveLayerData } from '../actions/redoUndo';
import { bindActionCreators } from 'redux';
import _ from 'lodash';

class MapContentContainer extends Component {
	componentWillMount = () => {
		const { handleJsonChange } = this.props;
		const style = 'light';
		const originalJson = localStorage.styleJson ? JSON.parse(localStorage.styleJson) : require(`../../data/thinkgeo-world-streets-${style}.json`);
		handleJsonChange(originalJson);
		this.loadWebFont();
	};
 
	loadWebFont = () => {

		WebFont.load({
			custom: {
				families: ['vectormap-icons'],
				urls: ['https://cdn.thinkgeo.com/vectormap-icons/3.0.0/vectormap-icons.css']
			},
			active: this.update()
		});
		
	};

	update = () => {
		const { handleWebfontLoaded } = this.props;
		const webfontLoaded = true;
		handleWebfontLoaded(webfontLoaded);
	};

	loadStyleJson = (filePath) => {
		let data = new Promise((resolve, reject) => {
			let xhr = new XMLHttpRequest();
			xhr.overrideMimeType('application/json');
			xhr.open('GET', filePath, true);
			xhr.onreadystatechange = (ERR) => {
				if (xhr.readyState === 4) {
					if (xhr.status === 200) {
						resolve(xhr.responseText);
					} else {
						reject(new Error(ERR));
					}
				}
			};
			xhr.send(null);
		});
		return data;
	};

	render() {
		const { json, webfontLoaded, currentGeocodingPlace, refreshView } = this.props;
		return webfontLoaded && json.styles.length > 0 ? (
			<React.Fragment>
				<MapContent
					{...this.props}
					currentZoomFlag={this.props.currentZoomFlag}
					currentGeocodingPlace={currentGeocodingPlace}
					refreshView={refreshView}
					drawerVisible={this.props.drawerVisible}
				/>
			</React.Fragment>
		) : null;
	}
}

const mapStateToProps = (state) => {
	const { rootReducer, filter } = state;
	return {
		json: rootReducer.json,
		zoom: Math.floor(rootReducer.zoom),
		initialZoom: rootReducer.zoom,
		webfontLoaded: rootReducer.webfontLoaded,
		centerPoint: rootReducer.centerPoint,
		showImagery: rootReducer.showImagery,
		index: filter.index,
		layerDatas: filter.layerDatas,
		maxLength: filter.maxLength,
		titleChange: rootReducer.titleChange,
		shouldMapUpdate: rootReducer.shouldMapUpdate
	};
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators({
		handleZoomChange: updateZoom,
		handleJsonChange: updateJson,
		handleWebfontLoaded: updateWebfontLoaded,
		updateCenterPoint: updateCenterPoint,
		saveInitLayerData: saveLayerData,
		changeTitleState: changeTitleState
	}, dispatch)
};

export default connect(mapStateToProps, mapDispatchToProps)(MapContentContainer);
