import React, { Component } from 'react';
import { Layout, Checkbox, Row, Col, } from 'antd';
import AvatarTitle from './avatar';
import SearchBox from './search';
// import BtnList from './btnList';
import LayerList from './layerList';
import { connect } from 'react-redux';
import { updateJson } from '../../redux/actions';
import _ from 'lodash';
import './index.scss';
import { bindActionCreators } from 'redux';
import OrderedLayer from './orderedLayer';
import ColorPicker from '../tool/colorPicker';


const { Sider } = Layout;

class Sidebar extends Component {
	constructor(props) {
		super(props);
		this.state = {
			selectedNodes: [],
			selected: false,
			expandedKeys: [],
			newSidebarData: [],
			colors: [],
			searchValue: undefined,
			styleJsonCode: {},
			checked: false,
			background: '#000000'
		};
	}

	componentWillReceiveProps(nextProps) {
		const { searchValue } = this.state;
		const { colors, shouldFilterSearchValue, newSidebarData } = nextProps;

		if (shouldFilterSearchValue) {
			this.searchValueFilter(searchValue, newSidebarData);
			this.setState({
				colors
			})
		}
	}

	componentDidMount() {
		this.props.onRef(this)
	}

	handleLayerSelectedOnMap = (a) => {
		this.sidebar.handleLayerSelectedOnMap(a)
	}

	onRef = (ref) => {
		this.sidebar = ref
	}


	// deleteLayer = () => {
	// 	const { handleJsonChange } = this.props;
	// 	const { selectedNodes } = this.state;
	// 	const this_ = this;
	// 	if (selectedNodes.length > 0) {
	// 		const { json } = this.props;
	// 		let json_ = _.cloneDeep(json);
	// 		let styles = json_.styles;
	// 		let layerIds = json_.layers[0].styles;
	// 		let deleteItems = [];
	// 		selectedNodes.forEach((node) => {
	// 			const props = node.props;
	// 			let parentId;
	// 			let childId;
	// 			let classname;
	// 			if (props.subid) {
	// 				// Child node
	// 				parentId = props.subid;
	// 				childId = props.value; // The class value in stylejson, like marine_name: 0,1,2,3,4
	// 				classname = props.classname;
	// 				deleteItems.push({
	// 					parentId: parentId,
	// 					childId: childId,
	// 					classname: classname
	// 				});
	// 				for (let i = 0, l = styles.length; i < l; i++) {
	// 					if (styles[i].id === parentId) {
	// 						(function () {
	// 							this_.deleteFeature(styles[i], childId, classname, styles, i, parentId, layerIds);
	// 						})(i);
	// 						break;
	// 					}
	// 				}
	// 			} else {
	// 				// Parent node
	// 				// Delete the layer which's id is parentId.
	// 				parentId = props.id;
	// 				deleteItems.push({
	// 					parentId: parentId
	// 				});
	// 				_.remove(styles, (obj) => {
	// 					return obj.id === parentId;
	// 				});
	// 				_.pullAll(layerIds, [parentId]);
	// 			}
	// 		});
	// 		handleJsonChange(json_);
	// 	} else {
	// 		message.info('Please choose a layer first!', 3);
	// 	}
	// 	// Clear selectedNodes array.
	// 	this.updateSelectedNodes([]);
	// };

	// deleteFeature = (obj, subclassid, subclassname, stylesArr, index, parentId, layerIds, parentObj) => {
	// 	if (stylesArr.length > 0 && stylesArr[0]['id'] !== undefined) {
	// 		this.totalStyles = stylesArr;
	// 	}

	// 	const classNameRegexp = new RegExp(subclassname, 'g');
	// 	let filter = obj['filter'];
	// 	// Remove the space between letters.
	// 	filter = filter.replace(/\s/g, '');
	// 	if (filter) {
	// 		if (filter.match(classNameRegexp)) {
	// 			// There are classes in the filter

	// 			const filterItems = filter.split(';'); // "zoom=10;class=0" ==> ["zoom=10", "class=0"]  or  "zoom=10;class='0,1,2'" ==> ["zoom=10", "class='0,1,2'"]
	// 			let classFilter;
	// 			filterItems.some((item) => {
	// 				if (item.match(classNameRegexp)) {
	// 					classFilter = item;
	// 					return true;
	// 				}
	// 			}); // classFilter= "class=0"   or   classFilter= "class='0,1,2'"

	// 			const equalRegExp = new RegExp(`${subclassname}=`);
	// 			const unequalRegExp = new RegExp(`${subclassname}!=`);
	// 			if (filter.match(equalRegExp)) {
	// 				// class='0,1,2'
	// 				let classItems = classFilter.split('='); // '0' or "'0,1,2'"
	// 				let classStr = classItems[1].replace(/'/g, ''); // "0,1,2"
	// 				let classItemsArr = classStr.split(','); // ['0']  or  ['0','1','2']
	// 				let classItemLength = classItemsArr.length;
	// 				if (classItemsArr.indexOf(subclassid) > -1 || classItemsArr.indexOf(subclassid.toString()) > -1) {
	// 					// id in class
	// 					if (classItemLength > 1) {
	// 						// ['0','1','2']

	// 						// Move the subclassid away from the ['0','1','2'] former filter.
	// 						let replaceAnotherRegExp;
	// 						let filterAnother;
	// 						if (classItemsArr.indexOf(subclassid) === 0 || classItemsArr.indexOf(subclassid.toString()) === 0) {
	// 							// The classid is the first one: '0',
	// 							replaceAnotherRegExp = new RegExp(`${subclassid},`);
	// 							filterAnother = filter.replace(replaceAnotherRegExp, '');
	// 						}else if (classItemsArr.indexOf(subclassid) === (classItemLength-1) || classItemsArr.indexOf(subclassid.toString()) === (classItemLength-1)) {
	// 							// The classid is the last one: ,'2'
	// 							replaceAnotherRegExp = new RegExp(`,${subclassid}`);
	// 							filterAnother = filter.replace(replaceAnotherRegExp, '');
	// 						} else {
	// 							// The classid is not the first and last one: ,'1',
	// 							replaceAnotherRegExp = new RegExp(`,${subclassid},`);
	// 							filterAnother = filter.replace(replaceAnotherRegExp, ',');
	// 						}

	// 						obj['filter'] = filterAnother;
	// 					} else {
	// 						// ['0']
	// 						if (stylesArr.length === 1 && parentObj.id !== undefined) {
	// 							_.remove(this.totalStyles, (obj) => {
	// 								return obj.id === parentId;
	// 							});
	// 							_.pullAll(layerIds, [ parentId ]);
	// 						} else {
	// 							_.pullAt(stylesArr, [ index ]);
	// 						}
	// 					}
	// 				}
	// 			} else {
	// 				// class!='0,1,2'
	// 				const classItems = classFilter.split('!=');
	// 				let classStr = classItems[1].replace(/'/g, ''); // "0,1,2"
	// 				let classItemsArr = classStr.split(','); // ['0']  or  ['0','1','2']
	// 				if (classItemsArr.indexOf(subclassid.toString()) === -1) {
	// 					// '3' is not in '0,1,2', so '3' will in this style obj.
	// 					if (classItemsArr.length === 1) {
	// 						// There are only on classid in the class filter.
	// 						let filter_ = filter.replace(unequalRegExp, `${subclassname}=`);
	// 						let newObj = {};
	// 						_.pullAt(stylesArr, [ index ]);
	// 						newObj['filter'] = filter_;
	// 						stylesArr.unshift(newObj);
	// 					} else {
	// 						// There are more than one class ids in the class filter.

	// 						// Add a new obj to stylesArr, this obj only has subclassid.
	// 						const replaceRegExp = new RegExp(`${subclassname}!='(\\w*,*)*'`);
	// 						let obj_ = Object.assign({}, obj);
	// 						let filter_ = filter.replace(replaceRegExp, `${subclassname}='${subclassid}'`); // The original filter will not change.
	// 						obj_['filter'] = filter_;
	// 						stylesArr.unshift(obj_);

	// 						// Add subclassid to the != filter.
	// 						const replaceRegExp_ = new RegExp(`${subclassname}!='(\\w*,*)*`);
	// 						let str = filter.match(replaceRegExp_)[0]; // "class!='0,1,2"
	// 						// The original filter will not change.
	// 						let filterAnother = filter.replace(replaceRegExp_, `${str},${subclassid}'`); // "class!='0,1,2,3"
	// 						obj['filter'] = filterAnother;
	// 					}
	// 				}
	// 			}
	// 		} else {
	// 			// There is no class in the filter
	// 			let stylesArr = obj['style'];
	// 			if (stylesArr && stylesArr.length > 0) {
	// 				// There are some inner style objs. Find the one who has class filter.
	// 				let count = 0;
	// 				let parentObj = obj;
	// 				stylesArr.some((obj) => {
	// 					this.deleteFeature(
	// 						obj,
	// 						subclassid,
	// 						subclassname,
	// 						stylesArr,
	// 						count,
	// 						parentId,
	// 						layerIds,
	// 						parentObj
	// 					);
	// 					count++;
	// 				});
	// 			}
	// 		}
	// 	}
	// };

	updateSelectedNodes = (selectedNodes) => {
		this.setState({
			selectedNodes: selectedNodes
		});
	};

	searchValueFilter = (value, newSidebarData = this.state.newSidebarData) => {
		let expandedKeys = [];
		const newSidebarData_ = _.concat(newSidebarData);
		const regexp = new RegExp(value, 'gi');

		newSidebarData_.forEach((layer, index) => {
			if (layer.id.match(regexp)) {
				layer["searched"] = true;
				expandedKeys.push(layer.id);
				if (layer.class && layer.class.length > 0) {
					layer.class.forEach((classItem, index) => {
						classItem["searched"] = true;
					})
				}
			} else {
				if (layer.class && layer.class.length > 0) {
					let flag = false;
					layer.class.forEach((classItem, index) => {
						if (classItem.id.match(regexp)) {
							layer["searched"] = true;
							classItem["searched"] = true;
							expandedKeys.push(layer.id);
							flag = true;
						} else {
							classItem["searched"] = false;
							if ((index === layer.class.length - 1) && !flag) {
								layer["searched"] = false;
							}
						}
					})
				} else {
					layer["searched"] = false;
				}
			}
		});

		this.setState({
			newSidebarData: newSidebarData_,
			expandedKeys
		});
	}

	search = (searchValue) => {
		this.setState({ searchValue })
		this.searchValueFilter(searchValue)
	}

	handleSelected = (selected) => {
		this.setState({
			selected
		});
	}

	handleJsonViewerChange = (styleJsonCode) => {
		if (styleJsonCode.resultArr) {
			const showStyle = {};
			const styles = styleJsonCode.resultArr[0].style;
			styles.forEach(style => {
				Object.assign(showStyle, style)
			});

			this.setState({
				styleJsonCode: showStyle
			})
		} else {
			this.setState({
				styleJsonCode: styleJsonCode
			})
		}
	}

	handleCurrentZoom = (isCurrentZoomFlag) => {
		this.props.handleCurrentZoom(isCurrentZoomFlag)
	}

	onChange = (e) => {
		const checked = e.target.checked;

		this.setState({
			checked
		})
	}



	render() {
		const { handleSidebarDataChange } = this.props;
		const { colors, expandedKeys, searchValue, newSidebarData, styleJsonCode, selectedNodes, checked } = this.state;
		return (
			<Sider>
				<Layout>
					<AvatarTitle />
					<div className='orderLayers'>
						<Checkbox onChange={this.onChange}>View/Reorder All Layers</Checkbox>


					</div>

					{/* <BtnList selected={selected} deleteLayer={this.deleteLayer} selectedNodes={selectedNodes} newSidebarData={newSidebarData}/> */}
					{
						checked ?
							<OrderedLayer newSidebarData={newSidebarData} /> :
							<LayerList
								newSidebarData={newSidebarData}
								colors={colors}
								handleSidebarDataChange={handleSidebarDataChange}
								handleSelected={this.handleSelected}
								updateSelectedNodes={this.updateSelectedNodes}
								selectedNodes={selectedNodes}
								expandedKeys={expandedKeys}
								selectLayerInfor={this.props.selectLayerInfor}
								styleJsonCode={styleJsonCode}
								handleJsonViewerChange={this.handleJsonViewerChange}
								handleCurrentZoom={this.handleCurrentZoom}
								afterVisibleChange={this.props.afterVisibleChange}
								onRef={this.onRef}
								autoRefresh={this.props.autoRefresh}
							/>
					}
					{!checked && <div className="searchbox-wrap">
						<SearchBox search={this.search} value={searchValue} />
					</div>}
				</Layout>
			</Sider>
		);
	}
}

const mapStateToProps = (state) => ({
	json: state.rootReducer.json
});

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators({
		handleJsonChange: updateJson
	}, dispatch)
};

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
