import React, { Component } from 'react';
import './index.scss'
import { Form, Icon, Tooltip, Row, Col, InputNumber, Radio, Popover, Button, Upload } from 'antd';
import ColorPicker from '../../../tool/colorPicker';
import IconFont from '../../../tool/iconFont';
import _ from 'lodash'
// const props = {
//     action: '//jsonplaceholder.typicode.com/posts/',
//     listType: 'picture',
// }
const RadioButton = Radio.Button;
const RadioGroup = Radio.Group;
class LineItem extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...this.props.activeStyle
        }
    }

    componentWillReceiveProps(nextProps) {
        const state = _.cloneDeep(this.state)
        Object.keys(state).map((stateKey) => {
            state[stateKey] = undefined
        })

        this.setState({ ...state, ...nextProps.activeStyle })
    }
    // Delate dasharray item
    deletArr = (index) => {
        let dasharrayArr = this.state['line-dasharray'].replace(/\"/g, "");
        let dasharrayArrAfter = dasharrayArr.split(',')
        if (dasharrayArrAfter.length > 2) {
            dasharrayArrAfter.splice(index, 2)
            this.setState({ 'line-dasharray': dasharrayArrAfter.join(",") }, () => {
                this.props.updatedStyle(this.state, this.props.lineItemIndex)
            });
        }
    }

    // Updated dasharray item
    updatedArr = (value, index) => {
        let dasharrayArr = this.state['line-dasharray'].replace(/\"/g, "");
        let dasharrayArrAfter = dasharrayArr.split(',')
        dasharrayArrAfter[index] = value

        this.setState({ 'line-dasharray': dasharrayArrAfter.join(",") }, () => {
            this.props.updatedStyle(this.state, this.props.lineItemIndex)
        });
    }

    // Render dasharray item
    renderItem = (dasharrayArr) => dasharrayArr.map((item, index) => {
        if (index % 2 == 0) {
            return (
                <React.Fragment key={index}>
                    <Row className='border-dash' type="flex" align="middle">
                        <Col span={3}>
                            Dash
                        </Col>
                        <Col span={dasharrayArr.length > 2 ? 6 : 8} offset={1}  >
                            <InputNumber className="style-input"  size="small" min={0} max={100} value={Number(dasharrayArr[index])} onChange={(value) => { this.updatedArr(value, index) }}></InputNumber>
                        </Col>
                        <Col span={3} offset={1}>
                            Gap
                        </Col>
                        <Col span={dasharrayArr.length > 2 ? 6 : 8} offset={dasharrayArr.length > 2 ? 1 : 0}  >
                            <InputNumber className="style-input" size="small" min={0} max={100} value={dasharrayArr[index + 1]} onChange={(value) => { this.updatedArr(value, index + 1) }}></InputNumber>
                        </Col>
                        <Col span={2} offset={1} className={dasharrayArr.length > 2 ? "" : "delate-unuse"}  >
                            <Icon type="delete" onClick={() => { this.deletArr(index) }} />
                        </Col>
                    </Row>
                    {/* <Row className='border-dash' type="flex" align="middle">
                        <Col span={4}>
                            {index % 2 == 0 ? 'Dash' : 'Gap'}
                        </Col>
                        <Col span={dasharrayArr.length > 2 ? 14 : 18} offset={2} >
                            <InputNumber size="small" min={0} max={100} value={0} value={Number(item)} onChange={(value) => { this.updatedArr(value, index) }}></InputNumber>
                        </Col>
                        <Col span={2} offset={2} className={dasharrayArr.length > 2 ? "" : "delate-unuse"}  >
                            <Icon type="delete" onClick={() => { this.deletArr(index) }} />
                        </Col>
                    </Row> */}
                </React.Fragment>
            )
        }
    })

    // Add dasharray item
    addDash = () => {
        let dasharrayArr = this.state['line-dasharray'].replace(/\"/g, "");
        let dasharrayArrAfter = dasharrayArr.split(',')
        dasharrayArrAfter = dasharrayArrAfter.concat([0, 0])
        this.setState({ 'line-dasharray': dasharrayArrAfter.join(",") }, () => {
            this.props.updatedStyle(this.state, this.props.lineItemIndex);
        });
    }

    // Render dasharray wraplist
    renderLineDasharray = () => {
        let str, dasharrayArrAfter, dasharray;

        if (this.state['line-dasharray']) {
            dasharray = this.state['line-dasharray']
        } else {
            dasharray = "0,0"
        }
        dasharrayArrAfter = dasharray.split(',')

        str = (
            <React.Fragment>
                {
                    this.renderItem(dasharrayArrAfter)
                }
                <div>
                    <Row className='border-dash' type="flex" align="middle">
                        <Col span={12} offset={12}>
                            <Button type="primary" size='small' icon="plus" onClick={this.addDash} block={true}>
                                Add dash
                            </Button>
                        </Col>

                    </Row>
                </div>
            </React.Fragment>
        )
        return str
    }

    handleLineType = (e) => {
        if (e.target.value === 'dash') {
            if (this.state.dasharray) {
                this.setState({ 'line-dasharray': this.state.dasharray }, () => {
                    this.props.updatedStyle(this.state, this.props.lineItemIndex)
                });
            } else {
                this.setState({ 'line-dasharray': '0,0' }, () => {
                    this.props.updatedStyle(this.state, this.props.lineItemIndex)
                });
            }

        } else {
            if (this.state['line-dasharray']) {
                delete this.state['line-dasharray']
                this.setState({
                    dasharray: this.state['line-dasharray']
                }, () => {
                    this.props.updatedStyle(this.state, this.props.lineItemIndex)
                })
            }
        }
    }

    handleLineCap = (e) => {
        this.setState({ "line-cap": e.target.value }, () => {
            this.props.updatedStyle(this.state, this.props.lineItemIndex)
        });

    }

    handleLineJoin = (e) => {
        this.setState({ "line-join": e.target.value }, () => {
            this.props.updatedStyle(this.state, this.props.lineItemIndex)
        });

    }
    handlelineWidth = (value) => {

        if (this.state['line-color'] === undefined) {
            this.setState({ "line-width": value, "line-color": '#bbbbbb'  }, () => {
                this.props.updatedStyle(this.state, this.props.lineItemIndex)
            });
        }else{
            this.setState({ "line-width": value}, () => {
                this.props.updatedStyle(this.state, this.props.lineItemIndex)
            });
        }


    }
    handleLineColor = (color) => {

        this.setState({ "line-color": color }, () => {
            this.props.updatedStyle(this.state, this.props.lineItemIndex)
        });

    }
    handleLineOpacity = (value) => {

        this.setState({ "line-opacity": value }, () => {
            this.props.updatedStyle(this.state, this.props.lineItemIndex)
        });

    }

    render() {
        const content = (
            <div className='dash-array-content'>

                {this.renderLineDasharray()}
            </div>
        );
        return (

            <div>
                <Form>
                    {/* Line Width */}
                    <Form.Item>
                        <Row type="flex" justify="space-around" align="middle">
                            <Col span={3} className={this.state["line-width"] ? "" : 'opacity06'} >
                                <Tooltip title=" Line Width">  <IconFont className="style-icon" type="icon-line-butt" />
                                </Tooltip>
                            </Col>
                            <Col span={8} className={this.state["line-width"] ? "" : 'opacity06'}  >
                                <InputNumber className="style-input" min={0} max={100} onChange={this.handlelineWidth} step={0.1} value={this.state["line-width"] ? this.state["line-width"] : 0} size="small" />
                            </Col>
                            <Col span={3} offset={2} className={this.state["line-width"] ? "" : 'opacity06'}>
                                <Tooltip title="Line Type"><IconFont className="style-icon" type="icon-type" /></Tooltip>
                            </Col>
                            <Col span={8} className={this.state["line-width"] ? "" : 'opacity06'}>
                                <RadioGroup className='inputRadio dash-radio' onChange={this.handleLineType} value={this.state['line-dasharray'] ? 'dash' : 'soild'} size="small">
                                    <Tooltip title="Soild">
                                        <RadioButton value="soild">
                                            <IconFont type="icon-line1" />
                                        </RadioButton>
                                    </Tooltip>
                                    <Popover content={content} title="" trigger="click" placement="bottomLeft" overlayClassName='dasharr-pop'>
                                        <RadioButton value="dash">
                                            <Tooltip title="Dasharray">
                                                <IconFont type="icon--xuxian" />
                                            </Tooltip>
                                        </RadioButton>
                                    </Popover>
                                </RadioGroup>
                            </Col>

                        </Row>
                    </Form.Item>
                    <Form.Item>
                        <Row type="flex" justify="space-around" align="middle">
                            <Col span={3} className={this.state["line-color"] ? "" : 'opacity06'}>
                                <Tooltip title="Line Color">
                                    <IconFont className="style-icon" type="icon-bordercolor" />
                                </Tooltip>
                            </Col>
                            <Col span={8} >
                                <ColorPicker color={this.state["line-color"] ? this.state["line-color"]:'#bbbbbb'} updateColor={this.handleLineColor}>
                                </ColorPicker>
                            </Col>

                            <Col span={3} offset={2} className={this.state["line-opacity"] ? "" : 'opacity06'}><Tooltip title="Opacity"><IconFont className="style-icon" type="icon-opacity" /></Tooltip> </Col>
                            <Col span={8} className={this.state["line-opacity"] ? "" : 'opacity06'} >
                                <InputNumber size="small" className="style-input" min={0} max={1} value={this.state["line-opacity"] ? this.state["line-opacity"] : 1} step={0.1} onChange={this.handleLineOpacity} />
                            </Col>

                        </Row>

                    </Form.Item>
                    {/* Line Color */}

                    {/* Line Cap */}
                    <Form.Item>
                        <Row type="flex" justify="space-around" align="middle">
                            <Col span={7} className={'style-label' + " " + (this.state["line-cap"] ? "" : 'opacity06')} >Line Cap</Col>
                            <Col span={17} className={this.state["line-cap"] ? "" : 'opacity06'} >
                                <RadioGroup className='inputRadio linecap-radio' onChange={this.handleLineCap} value={this.state["line-cap"] ? this.state["line-cap"] : "round"} size="small">
                                    <Tooltip title="Round">
                                        <RadioButton value="round">
                                            <IconFont type="icon-line-cap-round" />
                                        </RadioButton>

                                    </Tooltip>
                                    <Tooltip title="Butt">
                                        <RadioButton value="butt">
                                            <IconFont type="icon-line3-butt" />
                                        </RadioButton>
                                    </Tooltip>
                                    <Tooltip title="Square">
                                        <RadioButton value="square">
                                            <IconFont type="icon-line-butt" />
                                        </RadioButton>

                                    </Tooltip>
                                </RadioGroup>
                            </Col>
                        </Row>
                    </Form.Item>

                    {/* Line Join */}
                    <Form.Item>
                        <Row type="flex" justify="space-around" align="middle">
                            <Col span={7} className={'style-label' + " " + (this.state["line-join"] ? "" : 'opacity06')}>Line Join</Col>
                            <Col span={17} className={this.state["line-join"] ? "" : 'opacity06'}>
                                <RadioGroup className='inputRadio linecap-radio' onChange={this.handleLineJoin} value={this.state["line-join"] ? this.state["line-join"] : "miter"} size="small">
                                    <Tooltip title="Miter">
                                        <RadioButton value="miter">
                                            <IconFont type="icon-Join-miter" />
                                        </RadioButton>
                                    </Tooltip>
                                    <Tooltip title="Bevel">
                                        <RadioButton value="bevel">
                                            <IconFont type="icon-Join-bevel" />
                                        </RadioButton>
                                    </Tooltip>
                                    <Tooltip title="Round">
                                        <RadioButton value="round">
                                            <IconFont type="icon-Join-round" />
                                        </RadioButton>
                                    </Tooltip>
                                </RadioGroup>
                            </Col>
                        </Row>
                    </Form.Item>

                    {/* <Form.Item>
                        <Row type="flex" justify="space-around" align="middle">
                            <Col span={12} className={'style-label' + " " + (this.state["line-oneway-symbol"] ? "" : 'opacity06')}>Oneway Symbol</Col>
                            <Col span={12} className={this.state["line-oneway-symbol"] ? "" : 'opacity06'}>
                                <Upload {...props} className="upload-symbol">
                                    <Button className="style-input " size="small">
                                        <Icon type="upload" /> Upload
                                </Button>
                                </Upload>
                            </Col>
                        </Row>
                    </Form.Item> */}
                </Form>

            </div>
        );
    }
}

export default LineItem;