
export const saveLayerData = (layerData, index, maxLength, layerDatas) => dispatch => {
  if(index > -1 && index < maxLength - 1)  {
    // layerDatas.splice(0, index + 1);
    while(index < layerDatas.length - 1){
      layerDatas.pop();
    }
  }

  if(layerDatas.length === maxLength){
    layerDatas.shift();
    index = index === 0 ? 0 : (index - 1);
  }

  layerDatas.push(...layerData);
  index = index + layerData.length;
  
  dispatch({
    type: 'SAVE_LAYERDATA',
    data: {
      index,
      layerDatas
    }
  })
}

export const handleRedoUndo = (index, json) => dispatch => {
  dispatch({
    type: 'UPDATE_INDEX',
    data: {
      index
    }
  })

  dispatch({
    type: 'UPDATE_JSON',
    json
  })
}

