import './index.scss';
import 'react-perfect-scrollbar/dist/css/styles.css';
import React from 'react';
import { Modal, Button, Row, Col, Upload, message, Card, Tooltip } from 'antd';
import ScrollBar from 'react-perfect-scrollbar';
import IconFont from '../../tool/iconFont';
import UploadImage from './UploadImage';
import { updateJson, updateImageryLayer } from '../../../redux/actions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import getAnalogousColor from './utils/getAnalogousColor'

import lightJson from '../../../data/thinkgeo-world-streets-light.json';
const styles = [
	{
		title: 'Ice Blue',
		imgSrc: require('../../../images/iceblue.png'),
		jsonPath: '../../../data/thinkgeo-world-streets-mutedblue.json',
		fileName: 'mutedblue',
		description: 'An unobtrusive blue design style for map.'
	},
	{
		title: 'Cobalt',
		imgSrc: require('../../../images/cobalt.png'),
		jsonPath: '../../../data/thinkgeo-world-streets-cobalt.json',
		fileName: 'cobalt',
		description: 'Cobalt blue makes the map be more high-end.'
	},
	{
		title: 'Hybrid',
		imgSrc: require('../../../images/hybrid.png'),
		jsonPath: '../../../data/thinkgeo-world-streets-hybrid.json',
		fileName: 'hybrid',
		description: 'Vector data layer on the top of the aerial imagery layer.'
	},
	{
		title: 'Grayscale',
		imgSrc: require('../../../images/grayscale.png'),
		jsonPath: '../../../data/thinkgeo-world-streets-grayscale.json',
		fileName: 'grayscale',
		description: 'A simple grayscale version of map will not be too hash to your eyes.'
	},
	{
		title: 'Light',
		imgSrc: require('../../../images/light.png'),
		jsonPath: '../../../data/thinkgeo-world-streets-light.json',
		fileName: 'light',
		description: 'Light style with detailed map data covering the entire world.'
	},
	{
		title: 'Sepia',
		imgSrc: require('../../../images/Sepia.png'),
		jsonPath: '../../../data/thinkgeo-world-streets-oldancient.json',
		fileName: 'oldancient',
		description: 'This is an orange monochrome style map.'
	},
	{
		title: 'Dark',
		imgSrc: require('../../../images/dark.png'),
		jsonPath: '../../../data/thinkgeo-world-streets-dark.json',
		fileName: 'dark',
		description: 'This is a map great for a website with a really dark theme.'
	}
];

class ImportStyle extends React.Component {
	state = {
		visible: localStorage.getItem('styleJson') ? false : true,
		fileList: [],
		imageUrl: '',
		colorPalette: [],
		ids: []
	};

	componentDidMount() {
		this.setState({ ids: this.getNeedIds() })
	}

	handleFilelistChange = (fileList) => {
		this.setState({
			fileList
		});
	};

	handleImageUrlChange = (imageUrl) => {
		this.setState({
			imageUrl
		});
	};

	getNeedIds = () => {
		let ids = lightJson.layers[0].styles.filter((id) => {
			return id !== 'road_oneway' && id !== 'poi_icon' && id !== 'poi_text';
		});
		ids.push('poi');
		return ids;
	}

	createStyleByPictureColor = () => {
		const { colorPalette, ids } = this.state;
		const jsonFromPicture = JSON.parse(JSON.stringify(lightJson));
		let variables = jsonFromPicture.variables;
		let colors = [];
		let layers = {};
		Object.keys(variables).forEach((property) => {
			const layerName = property.split('-')[0].slice(1);
			if (!layers[layerName]) {
				layers[layerName] = [];
			}
			layers[layerName].push(property);
		});

		Object.keys(layers).forEach(layerName => {
			const currentVariable = layers[layerName];
			const index = ids.indexOf(layerName);
			const mainColor = colorPalette[index];
			const rgb = mainColor.match(/([0-9]+,[0-9]+,[0-9]+)/)[0].split(',');
			let r = Number(rgb[0]);
			let g = Number(rgb[1]);
			let b = Number(rgb[2]);
			let opacity = 1;
			colors = getAnalogousColor({
				r: r,
				g: g,
				b: b,
				a: opacity
			}, layers[layerName].length + 1);

			currentVariable.forEach((property, index) => {
				if (property.indexOf('opacity') > -1) {
					opacity = Number(property.split('opacity')[1]) / 10;
				}
				let rgbObj = colors[index];
				variables[property] = `rgba(${rgbObj.r},${rgbObj.g},${rgbObj.b},${opacity})`;
			})
		})

		jsonFromPicture.id = 'world-streets-customized';
		this.props.handleJsonChange(jsonFromPicture);
		localStorage.removeItem('initializationStyle');
		this.setState({
			visible: false
		})
	};

	showModal = () => {
		this.setState({
			visible: true,
			loading: true
		});
	};

	onChange = (checked) => {
		this.setState({
			loading: !checked
		});
	};

	handleOk = () => {
		this.handleUploadImageClose();
		this.createStyleByPictureColor();
		this.setState({
			uploadVisible: false
		});
	};

	handleCancel = () => {
		this.setState({
			visible: false
		});
	};

	importNewStyle = (fileName) => {
		const { handleJsonChange, updateImageryLayer } = this.props;
		const json = require(`../../../data/thinkgeo-world-streets-${fileName}.json`);
		if (fileName === 'hybrid') {
			updateImageryLayer(true);
		} else {
			updateImageryLayer(false);
		}
		handleJsonChange(JSON.parse(JSON.stringify(json)));
		localStorage.removeItem('initializationStyle');
		this.setState({
			visible: false
		});
	};

	createStyleByCustomJson = (file, fileList) => {
		// Make sure there is only one file in fileList. So we need to get the last file and update to state.
		const fileList_ = [fileList[fileList.length - 1]].concat();
		this.setState({
			fileList: fileList_
		});
		const { handleJsonChange } = this.props;
		const reader = new FileReader();
		reader.readAsText(file);
		reader.onload = (e) => {
			const json = JSON.parse(e.target.result);
			handleJsonChange(json);
			localStorage.removeItem('initializationStyle');
			this.setState({
				visible: false
			});
		};
	};

	handleClick = (e) => {
		const targetName = e.target.name;
		if (targetName === 'create-from-image') {
			this.setState({
				uploadVisible: true
			});
		} else if (targetName === 'start-blank-map') {
			this.importNewStyle('blank');
		}
	};

	handleUploadImageClose = () => {
		this.setState({
			uploadVisible: false,
			fileList: [],
			imageUrl: '',
			colorPalette: []
		});
	};

	changeColorPalette = (colorPalette) => {
		this.setState({
			colorPalette: colorPalette
		});
	};

	render() {
		const { colorPalette, fileList, imageUrl, ids } = this.state;
		const createStyleByCustomJson = this.createStyleByCustomJson;
		const props = {
			name: 'file',
			accept: 'application/json',
			fileList: this.state.fileList,
			showUploadList: false,
			onChange(info) {
				if (info.file.status !== 'uploading') {
					createStyleByCustomJson(info.file, info.fileList);
				}
				if (info.file.status === 'done') {
					message.success(`${info.file.name} file uploaded successfully`);
				} else if (info.file.status === 'error') {
					message.error(`${info.file.name} file upload failed.`);
				}
			},
			beforeUpload() {
				return false;
			}
		};

		return (
			<React.Fragment>
				<div className="top-menu-item" onClick={this.showModal}>
					<Tooltip placement="bottom">
						<IconFont type="icon-create" /> <span>New Style</span>
					</Tooltip>
				</div>
				<Modal
					visible={this.state.visible}
					footer={false}
					onCancel={this.handleCancel}
					className="import-modal"
				>
					<Row>
						<Col className="sider" span={5} lg={5} xs={10} sm={7}>
							<h4> Style Templates </h4> <p> Pick a template or upload an existing style. </p>
							<Upload {...props}>
								<Button type="default"> Import StyleJSON </Button>
							</Upload>
							<Button type="default" name="start-blank-map" onClick={this.handleClick}>
								Start Blank Map
							</Button>
							<Button type="default" name="create-from-image" onClick={this.handleClick}>
								Create From Image
							</Button>
							<Modal
								title={
									<React.Fragment>
										<h4> Create From Image </h4>
										<p>Click colorful rectangle before each layer name(ocean,road etc.) to select what color you want on the image you want.</p>
									</React.Fragment>
								}
								className="upload-modal"
								visible={this.state.uploadVisible}
								onOk={this.handleOk}
								onCancel={this.handleUploadImageClose}
							>
								<UploadImage
									changeColorPalette={this.changeColorPalette}
									colorPalette={colorPalette}
									fileList={fileList}
									imageUrl={imageUrl}
									ids={ids}
									handleImageUrlChange={this.handleImageUrlChange}
									handleFilelistChange={this.handleFilelistChange}
								/>
							</Modal>
						</Col>
						<Col className="card-box" span={19} lg={19} xs={14} sm={17}>
							<ScrollBar>
								{styles.map((item) => {
									return (
										<Card
											bordered={false}
											cover={
												<img
													alt={item.title}
													src={item.imgSrc}
													style={{
														height: 90
													}}
												/>
											}
											key={item.title}
										>
											<h3> {item.title} </h3>
											<Button
												type="default"
												size="small"
												onClick={() => {
													this.importNewStyle(item.fileName);
												}}
											>
												Create
											</Button>
											<p> {item.description} </p>
										</Card>
									);
								})}
							</ScrollBar>
						</Col>
					</Row>
				</Modal>
			</React.Fragment>
		);
	}
}

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			handleJsonChange: updateJson,
			updateImageryLayer: updateImageryLayer
		},
		dispatch
	);
};

export default connect(null, mapDispatchToProps)(ImportStyle);
