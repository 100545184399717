const iconData = [
    {
        code: '\e001',
        content: 'information'
    },
    {
        content: 'swimming',
        code: '\e002'
    },
    {
        content: 'wind_power',
        code: '\e003'
    },
    {
        content: 'gas_power',
        code: '\e004'
    },
    {
        content: 'solar_power',
        code: '\e005'
    },
    {
        content: 'hydro',
        code: '\e006'
    },
    {
        content: 'mineshaft',
        code: '\e007'
    },

    {
        content: 'clockwise',
        code: '\e008'
    },
    {
        content: 'anticlockwise',
        code: '\e009'
    },
    {
        content: 'pizza',
        code: '\e00a'
    },
    {
        content: 'private',
        code: '\e00b'
    },
    {
        content: 'jewish',
        code: '\e00c'
    },
    {
        content: 'buddhist',
        code: '\e00d'
    },
    {
        content: 'christian',
        code: '\e00e'
    },
    {
        content: 'hindu',
        code: '\e00f'
    },
    {
        content: 'muslim',
        code: '\e010'
    },
    {
        content: 'jain',
        code: '\e011'
    },
    {
        content: 'shinto',
        code: '\e012'
    },
    {
        content: 'sikh',
        code: '\e013'
    },
    {
        content: 'men',
        code: '\e014'
    },
    {
        content: 'women',
        code: '\e015'
    },
    {
        content: 'weir',
        code: '\e016'
    },
    {
        content: 'caravan_site',
        code: '\e017'
    },
    {
        content: 'chalet',
        code: '\e018'
    },
    {
        content: 'skiing',
        code: '\e019'
    },
    {
        content: 'archery',
        code: '\e01a'
    },
    {
        content: 'diving',
        code: '\e01b'
    },
    {
        content: 'gymnastics',
        code: '\e01c'
    },
    {
        content: 'horse_racing',
        code: '\e01d'
    },
    {
        content: 'ice_stock',
        code: '\e01e'
    },
    {
        content: 'motor',
        code: '\e01f'
    },
    {
        content: 'shooting',
        code: '\e020'
    },
    {
        content: 'soccer',
        code: '\e021'
    },
    {
        content: 'tennis',
        code: '\e022'
    },
    {
        content: 'alcohol',
        code: '\e023'
    },
    {
        content: 'bakery',
        code: '\e024'
    },
    {
        content: 'bicycle',
        code: '\e025'
    },
    {
        content: 'books',
        code: '\e026'
    },
    {
        content: 'butcher',
        code: '\e027'
    },
    {
        content: 'car',
        code: '\e028'
    },
    {
        content: 'car_repair',
        code: '\e029'
    },
    {
        content: 'clothes',
        code: '\e02a'
    },
    {
        content: 'computer',
        code: '\e02b'
    },
    {
        content: 'confectionery',
        code: '\e02c'
    },
    {
        content: 'convenience',
        code: '\e02d'
    },
    {
        content: 'copyshop',
        code: '\e02e'
    },
    {
        content: 'department_store',
        code: '\e02f'
    },
    {
        content: 'doityourself',
        code: '\e030'
    },
    {
        content: 'florist',
        code: '\e031'
    },
    {
        content: 'greengrocer',
        code: '\e032'
    },
    {
        content: 'gift',
        code: '\e033'
    },
    {
        content: 'hairdresser',
        code: '\e034'
    },
    {
        content: 'hearing_aids',
        code: '\e035'
    },
    {
        content: 'hifi',
        code: '\e036'
    },
    {
        content: 'jewelry',
        code: '\e037'
    },
    {
        content: 'kiosk',
        code: '\e038'
    },
    {
        content: 'laundry',
        code: '\e039'
    },
    {
        content: 'mobile_phone',
        code: '\e03a'
    },
    {
        content: 'musical_instrument',
        code: '\e03b'
    },
    {
        content: 'newsagent',
        code: '\e03c'
    },
    {
        content: 'optician',
        code: '\e03d'
    },
    {
        content: 'pet',
        code: '\e03e'
    },
    {
        content: 'supermarket',
        code: '\e03f'
    },
    {
        content: 'tobacco',
        code: '\e040'
    },
    {
        content: 'toys',
        code: '\e041'
    },
    {
        content: 'video',
        code: '\e042'
    },
    {
        content: 'estate_agent',
        code: '\e043'
    },
    {
        content: 'crane',
        code: '\e044'
    },
    {
        content: 'windmill',
        code: '\e045'
    },
    {
        content: 'traffic_signals',
        code: '\e046'
    },
    {
        content: 'wreck',
        code: '\e047'
    },
    {
        content: 'barrier_block',
        code: '\e048'
    },
    {
        content: 'bollard',
        code: '\e049'
    },
    {
        content: 'cattle_grid',
        code: '\e04a'
    },
    {
        content: 'entrance',
        code: '\e04b'
    },
    {
        content: 'gate',
        code: '\e04c'
    },
    {
        content: 'stile',
        code: '\e04d'
    },
    {
        content: 'lift_gate',
        code: '\e04e'
    },
    {
        content: 'toll_booth',
        code: '\e04f'
    },
    {
        content: 'bench',
        code: '\e050'
    },
    {
        content: 'clock',
        code: '\e051'
    },
    {
        content: 'drinking_water',
        code: '\e052'
    },
    {
        content: 'ice_cream',
        code: '\e053'
    },
    {
        content: 'nursing_home',
        code: '\e054'
    },
    {
        content: 'post_box',
        code: '\e055'
    },
    {
        content: 'post_office',
        code: '\e056'
    },
    {
        content: 'recycling',
        code: '\e057'
    },
    {
        content: 'telephone',
        code: '\e058'
    },
    {
        content: 'vending_machine',
        code: '\e059'
    },
    {
        content: 'plant',
        code: '\e05a'
    },
    {
        content: 'deciduous',
        code: '\e05b'
    },
    {
        content: 'mixed',
        code: '\e05c'
    },
    {
        content: 'coniferous',
        code: '\e05d'
    },
    {
        content: 'artwork',
        code: '\e05e'
    },
    {
        content: 'picnic_site',
        code: '\e05f'
    },
    {
        content: 'baseball',
        code: '\e060'
    },
    {
        content: 'canoe',
        code: '\e061'
    },
    {
        content: 'climbing',
        code: '\e062'
    },
    {
        content: 'cricket',
        code: '\e063'
    },
    {
        content: 'golf',
        code: '\e064'
    },
    {
        content: 'garden_centre',
        code: '\e065'
    },
    {
        content: 'motorcycle',
        code: '\e066'
    },
    {
        content: 'sub_station',
        code: '\e067'
    },
    {
        content: 'cave_entrance',
        code: '\e068'
    },
    {
        content: 'lighthouse',
        code: '\e069'
    },
    {
        content: 'water_tower',
        code: '\e06a'
    },
    {
        content: 'marina',
        code: '\e06b'
    },
    {
        content: 'slipway',
        code: '\e06c'
    },
    {
        content: 'bus_stop',
        code: '\e06d'
    },
    {
        content: 'battlefield',
        code: '\e06e'
    },
    {
        content: 'castle',
        code: '\e06f'
    },
    {
        content: 'memorial',
        code: '\e070'
    },
    {
        content: 'ruins',
        code: '\e071'
    },
    {
        content: 'bicycle_parking',
        code: '\e072'
    },
    {
        content: 'car_rental',
        code: '\e073'
    },
    {
        content: 'bicycle_rental',
        code: '\e074'
    },
    {
        content: 'casino',
        code: '\e075'
    },
    {
        content: 'fountain',
        code: '\e076'
    },
    {
        content: 'pub',
        code: '\e077'
    },
    {
        content: 'shelter',
        code: '\e078'
    },
    {
        content: 'waste_disposal',
        code: '\e079'
    },
    {
        content: 'viewpoint',
        code: '\e07a'
    },
    {
        content: 'airport',
        code: '\e07b'
    },
    {
        content: 'zoo',
        code: '\e07c'
    },
    {
        content: 'theatre',
        code: '\e07d'
    },
    {
        content: 'theme_park',
        code: '\e07e'
    },
    {
        content: 'motel',
        code: '\e07f'
    },
    {
        content: 'hostel',
        code: '\e080'
    },
    {
        content: 'camp_site',
        code: '\e081'
    },
    {
        content: 'hotel',
        code: '\e082'
    },
    {
        content: 'museum',
        code: '\e083'
    },
    {
        content: 'attraction',
        code: '\e084'
    },
    {
        content: 'fitness_centre',
        code: '\e085'
    },
    {
        content: 'swimming_pool',
        code: '\e086'
    },
    {
        content: 'water_park',
        code: '\e087'
    },
    {
        content: 'sports_centre',
        code: '\e088'
    },
    {
        content: 'playground',
        code: '\e089'
    },
    {
        content: 'golf_course',
        code: '\e08a'
    },
    {
        content: 'miniature_golf',
        code: '\e08b'
    },
    {
        content: 'garden',
        code: '\e08c'
    },
    {
        content: 'park',
        code: '\e08d'
    },
    {
        content: 'toilets',
        code: '\e08e'
    },
    {
        content: 'townhall',
        code: '\e08f'
    },
    {
        content: 'prison',
        code: '\e090'
    },
    {
        content: 'police',
        code: '\e091'
    },
    {
        content: 'place_of_worship',
        code: '\e092'
    },
    {
        content: 'market',
        code: '\e093'
    },
    {
        content: 'grave_yard',
        code: '\e094'
    },
    {
        content: 'embassy',
        code: '\e095'
    },
    {
        content: 'fire_station',
        code: '\e096'
    },
    {
        content: 'courthouse',
        code: '\e097'
    },
    {
        content: 'community_centre',
        code: '\e098'
    },
    {
        content: 'cinema',
        code: '\e099'
    },
    {
        content: 'art_centre',
        code: '\e09a'
    },
    {
        content: 'veterinary',
        code: '\e09b'
    },
    {
        content: 'social_facility',
        code: '\e09c'
    },
    {
        content: 'pharmacy',
        code: '\e09d'
    },
    {
        content: 'dentist',
        code: '\e09e'
    },
    {
        content: 'clinic',
        code: '\e09f'
    },
    {
        content: 'hospital',
        code: '\e0a0'
    },
    {
        content: 'bank',
        code: '\e0a1'
    },
    {
        content: 'taxi',
        code: '\e0a2'
    },
    {
        content: 'atm',
        code: '\e0a3'
    },
    {
        content: 'parking',
        code: '\e0a4'
    },
    {
        content: 'fuel',
        code: '\e0a5'
    },
    {
        content: 'bus_station',
        code: '\e0a6'
    },
    {
        content: 'national_park',
        code: '\e0a7'
    },
    {
        content: 'school',
        code: '\e0a8'
    },
    {
        content: 'library',
        code: '\e0a9'
    },
    {
        content: 'kindergarden',
        code: '\e0aa'
    },
    {
        content: 'college',
        code: '\e0ab'
    },
    {
        content: 'fast_food',
        code: '\e0ac'
    },
    {
        content: 'food',
        code: '\e0ad'
    },
    {
        content: 'cafe',
        code: '\e0ae'
    },
    {
        content: 'biergarden',
        code: '\e0af'
    },
    {
        content: 'bbq',
        code: '\e0b0'
    },
    {
        content: 'bar',
        code: '\e0b1'
    },
    {
        content: 'subway_entrance',
        code: '\e0b2'
    },
    {
        content: 'station',
        code: '\e0b3'
    },
    {
        content: 'peak',
        code: '\e0b4'
    },
    {
        content: 'map',
        code: '\e0b5'
    },
    {
        content: 'guidepost',
        code: '\e0b6'
    },
    {
        content: 'bunker',
        code: '\e0b7'
    },
    {
        content: 'tower',
        code: '\e0b8'
    },

]
export default iconData;