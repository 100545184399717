export const toggleThemeAction = (event) => dispatch => {
    dispatch({
        type: 'TOGGLE_THEME',
        themestyle: event.key
    })
}

export const updateZoom = (zoom) => dispatch => {
    dispatch({
        type: 'UPDATE_ZOOM',
        zoom: zoom
    })
}

export const updateMap = (shouldMapUpdate) => dispatch => {
    // dispatch({
    //     type: 'UPDATE_MAP',
    //     shouldMapUpdate
    // })
}

export const updateCenterPoint = (centerPoint) => dispatch => {
    dispatch({
        type: 'UPDATE_CENTER_POINT',
        centerPoint
    })
}

export const updateJson = (json) => dispatch => {
    localStorage.setItem('styleJson', JSON.stringify(json));
    dispatch({
        type: 'UPDATE_JSON',
        json
    })
}

export const updateWebfontLoaded = (webfontLoaded) => dispatch => {
    dispatch({
        type: 'UPDATE_WEBFONTLOADED',
        webfontLoaded
    })
}

export const updateImageryLayer = (showImagery) => dispatch => {
    localStorage.setItem('showImagery', showImagery);
    dispatch({
        type: 'UPDATE_IMAGERYLALER',
        showImagery
    })
}

export const changeTitleState = (titleChange) => dispatch => {
    dispatch({
        type: 'CHANGE_TITLE_STATE',
        titleChange
    })
}