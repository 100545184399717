import React, { Component } from 'react';

import TopMenuItems from './topMenu';
import MapContentContainer from '../redux/container/MapContentContainer'
import Sidebar from './sidebar';
import LonLatContainer from '../redux/container/LonLatContainer';

import 'antd/lib/layout/style/css';
import './LayoutWrap.scss';

import { Layout } from 'antd';
// import FindPlace from './findPlace';
const { Header, Content } = Layout;

class LayoutWrap extends Component {
	constructor(props) {
		super(props);
		this.state = {
			newSidebarData: [],
			colors: [],
			shouldFilterSearchValue: true,
			currentGeocodingPlace: {},
			centerPoint: '',
			refreshView: false,
			autoRefresh: false
		};
	}

	handleSidebarDataChange = (newSidebarData, colors) => {
		if (colors) {
			this.setState({
				colors,
				newSidebarData,
				shouldFilterSearchValue: true
			});
		} else {
			this.setState({
				newSidebarData,
				shouldFilterSearchValue: true
			});
		}
	};

	selectFeature = (value) => {
		this.setState({
			selectLayerInfor: value
		})
		this.sidebar.handleLayerSelectedOnMap(value)
	}

	onRef = (ref) => {
		this.sidebar = ref
	}

	handleCurrentZoom = (isCurrentZoomFlag) => {
		this.setState({
			currentZoomFlag: isCurrentZoomFlag
		})
	}

	handleGeocodingPlaceChange = (currentGeocodingPlace) => {
		this.setState({
			currentGeocodingPlace
		})
	}

	handleRefreshViewChange = (boolean) => {
		this.setState({
			refreshView: boolean
		})
	}



	afterVisibleChange = (visible) => {
		this.setState({
			drawerVisible: visible
		})
	}

	updatedAutoRefresh = (e) => {
		this.setState({
			autoRefresh: e
		})
	}

	render() {
		const { themestyle } = this.props;
		const { colors, newSidebarData, selectLayerInfor, shouldFilterSearchValue, currentGeocodingPlace, refreshView, autoRefresh } = this.state;
		return (
			
			<Layout className={themestyle} themetype={themestyle}>
				<Sidebar
					newSidebarData={newSidebarData}
					colors={colors}
					shouldFilterSearchValue={shouldFilterSearchValue}
					handleSidebarDataChange={this.handleSidebarDataChange}
					selectLayerInfor={selectLayerInfor}
					handleCurrentZoom={this.handleCurrentZoom}
					afterVisibleChange={this.afterVisibleChange}
					onRef={this.onRef}
					autoRefresh={autoRefresh}
				/>
				<Layout>
					<Header>
						<TopMenuItems updatedAutoRefresh={this.updatedAutoRefresh} autoRefresh={autoRefresh} handleGeocodingPlaceChange={this.handleGeocodingPlaceChange} handleRefreshViewChange={this.handleRefreshViewChange}></TopMenuItems>
					</Header>
					<Content>
						<MapContentContainer
							handleSidebarDataChange={this.handleSidebarDataChange}
							selectFeature={this.selectFeature}
							currentZoomFlag={this.state.currentZoomFlag}
							currentGeocodingPlace={currentGeocodingPlace}
							refreshView={refreshView}
							handleRefreshViewChange={this.handleRefreshViewChange}
							drawerVisible={this.state.drawerVisible}
						/>
						<LonLatContainer></LonLatContainer>
					</Content>
				</Layout>
			</Layout>
		);
	}
}

export default LayoutWrap;
