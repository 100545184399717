import React from 'react';
import { Upload, Icon, Modal, message, Tooltip } from 'antd';
import './UploadImage.scss';
import ColorThief from 'color-thief/js/color-thief.js';
import ScrollBar from 'react-perfect-scrollbar';

function getBase64(file) {
	return new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => resolve(reader.result);
		reader.onerror = (error) => reject(error);
	});
}

class UploadImage extends React.Component {
	state = {
		previewVisible: false,
		previewImage: '',
		updateColorPalette: false,
		currentIndex: -1,
		selectedId: ''
	};

	componentDidUpdate = () => {
		const { updateColorPalette } = this.state;
		const { imageUrl, ids, changeColorPalette } = this.props;
		if (imageUrl && updateColorPalette) {
			const image = new Image();
			image.src = imageUrl;
			image.alt = 'loaded image';
			image.className = 'source-image';
			image.onload = () => {
				this.drawImage_(image);
				const colorThief = new ColorThief();
				const palette = colorThief.getPalette(image, ids.length + 1);
				const colors = palette.map((color) => `rgb(${color.toString()})`);
				changeColorPalette(JSON.parse(JSON.stringify(colors)));
				this.setState({
					updateColorPalette: false
				});
			};
		}
	};

	handleCancel = () => {
		this.setState({ previewVisible: false });
	};

	handleRemove = () => {
		this.props.handleImageUrlChange('');
	};

	handlePreview = async (file) => {
		if (!file.url && !file.preview) {
			file.preview = await getBase64(file.originFileObj);
		}

		this.setState({
			previewImage: file.url || file.preview,
			previewVisible: true
		});
	};

	handleChange = ({ fileList }) => {
		if (fileList.length === 1) {
			this.getBase64(fileList[0].originFileObj);
		}
		this.props.handleFilelistChange(fileList);
		this.setState({ updateColorPalette: true });
	};

	getBase64 = (file) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => {
			this.props.handleImageUrlChange(reader.result);
		};
		reader.onerror = (error) => {
			message.error(error);
		};
	};

	getImageData = (image) => {
		const canvas = document.createElement('canvas');
		const width = image.width;
		const height = image.height;
		canvas.width = width;
		canvas.height = height;

		const context = canvas.getContext('2d');
		context.drawImage(image, 0, 0);
		document.body.appendChild(canvas);
		return context.getImageData(0, 0, width, height);
	};

	// getPixel = (imagedata, x, y) => {
	// 	const position = (x + imagedata.width * y) * 4;
	// 	const data = imagedata.data;
	// 	const color = {
	// 		r: data[position],
	// 		g: data[position + 1],
	// 		b: data[position + 2],
	// 		a: data[position + 3]
	// 	};
	// 	return color;
	// };

	getColor = (e, newImage) => {
		const rect = newImage.getBoundingClientRect();
		const x = e.clientX - (rect.left + document.body.scrollLeft) - 3;
		const y = e.clientY - (rect.top + document.body.scrollTop) - 3;
		const color = newImage.getContext('2d').getImageData(x, y, 1, 1).data;
		const transformedColor = `rgba(${color[0]},${color[1]},${color[2]},${color[3] / 255})`;

		return transformedColor;
	}

	drawImage_ = (image) => {
		const newImage = this.refs.imageCanvas;
		newImage.width = image.width;
		newImage.height = image.height;
		newImage.getContext('2d').drawImage(image, 0, 0);
		newImage.onmousemove = (e) => {
			const transformedColor = this.getColor(e, newImage);
			this.makeCursor(transformedColor);
		}
		newImage.onclick = (e) => {
			const {changeColorPalette, colorPalette} = this.props;
			const {currentIndex} = this.state;
			if(currentIndex !== -1){
				const transformedColor = this.getColor(e, newImage);
				colorPalette[currentIndex] = transformedColor;
				changeColorPalette(colorPalette);
			}
		}
	}

	handleClick = (e) => {	
		const target = e.target;
		if (target.getAttribute('type') === 'picker') {
			this.setState({
				selectedId: target.getAttribute('id'),
				currentIndex: Number(target.getAttribute('index'))
			});
		}
		 else if (target.tagName === 'IMG') {
			// if (currentIndex !== -1) {
			// 	const x = e.clientX - e.target.x;
			// 	const y = e.clientY - e.target.y;
			// 	const imagedata = this.getImageData(image);
			// 	const color = this.getPixel(imagedata, x, y);
			// 	colorPalette[currentIndex] = `rgba(${color.r},${color.g},${color.b},${color.a})`;
			// 	changeColorPalette(colorPalette);
			// }
		} else if(target.className !== 'ps__thumb-x' && target.className !== 'ps__rail-x' && target.className !== 'ps__thumb-y' && target.className !== 'ps__rail-y') {
			this.setState({
				selectedId: '',
				currentIndex: -1
			});
		}
	};

	makeCursor(color) {
		var cursor = document.createElement('canvas'),
		ctx = cursor.getContext('2d');
		cursor.width = 41;
		cursor.height = 41;

		ctx.beginPath();
		ctx.lineWidth = 2;
		ctx.strokeStyle = '#000000';
		ctx.moveTo(0, 6);
		ctx.lineTo(12, 6);
		ctx.moveTo(6, 0);
		ctx.lineTo(6, 12);
		ctx.stroke();

		ctx.beginPath();
		ctx.arc(25, 25, 14, 0, 2 * Math.PI, false);
		ctx.lineWidth = 2;
		ctx.strokeStyle = '#000000';
		ctx.stroke();
		ctx.beginPath();
		ctx.arc(25, 25, 13.4, 0, 2 * Math.PI, false);
		ctx.fillStyle = color;
		ctx.fill();

		this.refs.imageCanvas.style.cursor = 'url(' + cursor.toDataURL() + ') 6 6, crosshair';
	}

	render() {
		const { previewVisible, previewImage, selectedId } = this.state;
		const { colorPalette, fileList, imageUrl, ids } = this.props;
		const uploadButton = (
			<div>
				<Icon type="plus" />
				<div className="ant-upload-text">Upload</div>
			</div>
		);

		return (
			<div className="clearfix" onClick={this.handleClick}>
				<Upload
					listType="picture-card"
					fileList={fileList}
					onPreview={this.handlePreview}
					onChange={({ fileList }) => {
						this.handleChange({ fileList });
					}}
					onRemove={this.handleRemove}
					beforeUpload={() => false}
					showUploadList={{
						showPreviewIcon: false,
						showRemoveIcon: false
					}}
					className={imageUrl ? 'uploaded' : ''}
				>
					{fileList.length >= 1 ? null : uploadButton}
				</Upload>
				{/* <Modal visible={previewVisible} footer={null} onCancel={this.handleCancel} className="preview-modal">
					<img
						alt="example"
						style={{ width: '100%' }}
						src={previewImage}
						onClick={this.handleImageClick}
						ref="test"
					/>
				</Modal> */}
				{
					imageUrl ? 
						<div className="color-pick">
							<ul className="color-palette">
								{ids.map((id, index) => (
									<li key={id}>
										<Tooltip title="Click to modify">
											<i
												style={{ backgroundColor: `${colorPalette[index]}` }}
												className={selectedId === id ? 'active' : ''}
												type="picker"
												id={id}
												index={index}
											/>
										</Tooltip>
										{id}
									</li>
								))}
							</ul>
							<ScrollBar>
								<canvas ref='imageCanvas'></canvas>							
							</ScrollBar>
						</div>
					: null
				}
			</div>
		);
	}
}
export default UploadImage;
