import React, { Component } from 'react';
import { Input, Icon, List } from 'antd';
import apiKey from '../../../data/apiKey';
import tg from 'thinkgeocloudclient-js/thinkgeocloudclient';
import { connect } from 'react-redux';
import { updateCenterPoint } from '../../../redux/actions';
import { bindActionCreators } from 'redux';
import './index.scss';

const geocodingClient = new tg.GeocodingClient(apiKey);
const options = {
	Autocomplete: true,
	MaxResults: '5',
	VerboseResults: true,
	apikey: apiKey
};

class FindPlace extends Component {
	constructor(props) {
		super(props);
		this.state = {
			searchValue: '',
			placesList: [],
			loading: false,
			focusIndex: -1,
			emptyText: 'No data.',
			showResultBox: false
		};
	}

	componentDidMount() {
		if (this.input) {
			this.input.focus();
		}
	}

	handleInputChange = (e) => {
		let searchValue = e.target.value;
		if (searchValue !== '') {
			const { updateCenterPoint, handleRefreshViewChange } = this.props;
			const result = this.getValidCoord(searchValue);
			if (result.coord) {
				this.setState({
					placesList: [],
					showResultBox: true
				});
				updateCenterPoint([ result.coord[0], result.coord[1] ]);
				handleRefreshViewChange(true);
			} else if (result.error) {
				this.setState({
					emptyText: result.error,
					placesList: [],
					showResultBox: false
				});
			} else {
				this.getGeocoderResult(searchValue);
				this.setState({
					loading: true,
					showResultBox: false
				});
			}
		}
		this.setState({
			searchValue
		});
	};

	getValidCoord = (searchValue) => {
		let coord = searchValue.split(',');
		if (coord.length === 2 && !!coord[1] && !!coord[0] && !isNaN(coord[0]) && !isNaN(coord[1])) {
			let x = Number(coord[1]);
			let y = Number(coord[0]);
			if (x >= -180 && x <= 180 && y >= -90 && y <= 90) {
				return { coord: [ x, y ] };
			} else {
				return { error: 'Please input correct coordinate: [y, x].' };
			}
		}
		return false;
	};

	getGeocoderResult = (value) => {
		const callback = (status, res) => {
			if (status === 200) {
				const locations = res.data.locations;
				this.setState({
					placesList: [ ...locations ],
					loading: false
				});
			} else if (status === 500) {
				this.setState({
					placesList: [],
					loading: false,
					emptyText: 'There was an error when connecting with the server.'
				});
			} else {
				this.setState({
					placesList: [],
					loading: false,
					emptyText: 'Failed.'
				});
			}
		};
		if (this.xhr) {
			this.xhr.abort();
			delete this.xhr;
		}
		geocodingClient.on('sendingrequest', (e) => {
			this.xhr = e.xhr;
		});
		geocodingClient.searchByPoint(value, callback, options);
		geocodingClient.un('sendingrequest');
	};

	handlePlaceClick = (e) => {
		const target = e.target;
		const index = Number(target.getAttribute('id'));
		this.setState({
			focusIndex: index
		});
		this.changeGeocodingPlace(index);
	};

	changeGeocodingPlace = (index) => {
		if(index === -1){
			return
		}
		const { placesList } = this.state;
		const { handleGeocodingPlaceChange } = this.props;
		const place = placesList[index];

		// Get parameters which will be used to draw matched place.
		handleGeocodingPlaceChange({
			coordinate: [ place.locationPoint.pointX, place.locationPoint.pointY ],
			geometry: place.geometry,
			boundingBox: place.boundingBox,
		});
	};

	handleKeyDown = (e) => {
		const { focusIndex, placesList } = this.state;
		const length = placesList.length;
		let index;
		const pressKey = e.key;
		if (pressKey === 'ArrowDown') {
			index = focusIndex === length - 1 ? 0 : focusIndex + 1;
			this.setState({
				focusIndex: index
			});
		} else if (pressKey === 'ArrowUp') {
			index = focusIndex === 0 ? length - 1 : focusIndex - 1;
			this.setState({
				focusIndex: index
			});
		} else if(pressKey === 'Enter'){
			this.changeGeocodingPlace(focusIndex);
		}
	};

	handleInputBlur = () => {
		const { searchValue } = this.state;
		const { toggleSearch } = this.props;
		if (searchValue === '') {
			toggleSearch(false);
		}
	};

	clearInput = () => {
		this.input.focus();
		this.setState({
			searchValue: '',
			placesList: []
		});
	};

	handleInputRef = (input) => {
		const { inputRef } = this.props;
		inputRef(input);
		this.input = input;
	};

	render() {
		const { hide } = this.props;
		const { searchValue, placesList, loading, focusIndex, emptyText, showResultBox } = this.state;
		let index = -1;
		const suffix = searchValue === '' ? <span /> : <Icon type="close" onClick={this.clearInput} />;
		return (
			<div className={hide ? 'find-place hide' : 'find-place'} tabIndex={0} onKeyDown={this.handleKeyDown}>
				<Input
					placeholder="Search Places"
					prefix={<Icon type="search" />}
					onChange={this.handleInputChange}
					value={searchValue}
					suffix={suffix}
					onBlur={this.handleInputBlur}
					ref={this.handleInputRef}
					
				/>
				{searchValue === '' || showResultBox ? null : (
					<div className={'result-box'}>
						<List
							size="small"
							bordered
							dataSource={placesList}
							loading={loading}
							locale={{
								emptyText: emptyText
							}}
							renderItem={(item) => {
								index++;
								return (
									<List.Item
										key={item.address}
										id={index}
										onClick={this.handlePlaceClick}
										className={index === focusIndex ? 'focus' : ''}
									>
										{item.address}
									</List.Item>
								);
							}}
						/>
					</div>
				)}
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	const { rootReducer } = state;

	return {
		centerPoint: rootReducer.centerPoint
	};
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			updateCenterPoint: updateCenterPoint
		},
		dispatch
	);
};

export default connect(mapStateToProps, mapDispatchToProps)(FindPlace);
