const sidebarLayerData = [
    {
        title: 'Marine_name',
        layerName: 'marine_name',
        content: "Points for labeling major marine features.",
        className: 'class',
        url: 'https://wiki.thinkgeo.com/wiki/marine_name',
        type: 'point',
        filter: {
            zoom: 'zoom>=1;zoom<=19',
            labelrank: 'labelrank>=0;labelrank<=4',

        },
        class: [{
            title: 'ocean',
            value: 0,
            content: 'There are seven continents',
            filter: {
                zoom: 'zoom>=1;zoom<=19',
                labelrank: 'labelrank>=0;labelrank<=4',
            },
            url: 'https://wiki.thinkgeo.com/wiki/marine_name',
            type: 'point',
        }, {
            title: 'sea',
            value: 1,
            content: 'A large body of salt water which is surrounded by land.',
            filter: {
                zoom: 'zoom>=1;zoom<=19',
                labelrank: 'labelrank>=0;labelrank<= 4',
            },
            url: 'https://wiki.thinkgeo.com/wiki/marine_name',
            type: 'point'
        }, {
            title: 'bay',
            value: 2,
            content: 'A part of large water area,partly surrounded by a wide curve of the land',
            filter: {
                zoom: 'zoom>=1;zoom<=19',
                labelrank: 'labelrank>=0;labelrank<=4',
            },
            url: 'https://wiki.thinkgeo.com/wiki/marine_name',
            type: 'point'
        }, {
            title: 'gulf',
            value: 3,
            filter: {
                zoom: 'zoom>=1;zoom<=19',
                labelrank: 'labelrank>=0;labelrank<= 4',
            },
            content: 'A gulf is a large bay that is an arm of the ocean or the sea.',
            url: 'https://wiki.thinkgeo.com/wiki/marine_name',
            type: 'point'
        }, {
            title: 'channel',
            value: 4,
            filter: {
                zoom: 'zoom>=1;zoom<=19',
                labelrank: 'labelrank>=0;labelrank<= 4',
            },
            content: 'A channel consists the outline of the path of a narrow body of water. ',
            url: 'https://wiki.thinkgeo.com/wiki/marine_name',
            type: 'point'
        }]
    }, {
        title: 'Water',
        layerName: 'water',
        content: 'Water polygons representing the lake and river. ',
        filter: {
            zoom: 'zoom>=0;zoom<=19',
        },
        url: 'https://wiki.thinkgeo.com/wiki/water',
        type: 'polygon'
    }, {
        title: 'Water_name',
        layerName: 'water_name',
        content: 'The name representing the waters',
        className: 'class',
        url: 'https://wiki.thinkgeo.com/wiki/water_name',
        type: 'point',
        filter: {
            zoom: 'zoom>=10;zoom<=19',
        },
        class: [{
            title: 'water',
            value: 0,
            url: 'https://wiki.thinkgeo.com/wiki/water_name',
            type: 'point',
            content: 'The area of water, such as lakes',
            filter: {
                zoom: 'zoom>=10;zoom<=19',
            },
        }, {
            title: 'bay',
            value: 1,
            url: 'https://wiki.thinkgeo.com/wiki/water_name',
            type: 'point',
            content: 'A part of large water area,partly surrounded by a wide curve of the land',
            filter: {
                zoom: 'zoom>=10;zoom<=19',
            },
        }, {
            title: 'riverbank',
            value: 2,
            url: 'https://wiki.thinkgeo.com/wiki/water_name',
            type: 'point',
            content: 'The ground at the side of a river',
            filter: {
                zoom: 'zoom>=10;zoom<=19',
            },
        }]
    }, {
        title: 'Waterway',
        layerName: 'waterway',
        content: 'Waterway contains classes for rivers, stream, canal, drain and ditch as line type. ',
        className: 'class',
        url: 'https://wiki.thinkgeo.com/wiki/waterway',
        type: 'line',
        filter: {
            zoom: 'zoom>=2;zoom<=19',
        },
        class: [{
            title: 'river',
            value: 0,
            content: 'The linear flow of a river, in flow direction.',
            url: 'https://wiki.thinkgeo.com/wiki/waterway',
            type: 'line',
            filter: {
                zoom: 'zoom>=2;zoom<=19',
            },
        }, {
            title: 'stream',
            value: 1,
            content: 'Very small waterway ',
            url: 'https://wiki.thinkgeo.com/wiki/waterway',
            type: 'line',
            filter: {
                zoom: 'zoom>=11;zoom<=19',
            },
        }, {
            title: 'canal',
            value: 2,
            content: 'Artificial waterway',
            url: 'https://wiki.thinkgeo.com/wiki/waterway',
            type: 'line',
            filter: {
                zoom: 'zoom>=14;zoom<=19',
            },
        }, {
            title: 'drain',
            value: 3,
            content: 'Artificial channel for rainwater drainage, often concrete lined',
            url: 'https://wiki.thinkgeo.com/wiki/waterway',
            type: 'line',
            filter: {
                zoom: 'zoom>=14;zoom<=19',
            },
        }, {
            title: 'ditch',
            value: 8,
            content: 'Small artificial channel dug in the ground for rainwater drainage',
            url: 'https://wiki.thinkgeo.com/wiki/waterway',
            type: 'line',
            filter: {
                zoom: 'zoom>=14;zoom<=19',
            },
        }]
    }, {
        title: 'Landcover',
        layerName: 'landcover',
        content: 'Land cover is the physical material at the surface of the earth. ',
        url: 'https://wiki.thinkgeo.com/wiki/landcover',
        type: 'polygon',
        className: 'class',
        filter: {
            zoom: 'zoom>=0;zoom<=19',
        },
        class: [{
            title: 'agriculture',
            value: 'agriculture',
            content: 'The practice of cultivating the land or raising stock',
            url: 'https://wiki.thinkgeo.com/wiki/landcover',
            type: 'polygon',
            filter: {
                zoom: 'zoom>=10;zoom<=19',
            }
        }, {
            title: 'cemetery',
            value: 'cemetery',
            content: 'A tract of land used for burials',
            url: 'https://wiki.thinkgeo.com/wiki/landcover',
            type: 'polygon',
            filter: {
                zoom: 'zoom>=13;zoom<=19',
            }
        }, {
            title: 'ice',
            value: 'ice',
            content: 'A large mass of ice floating at sea; usually broken off of a polar glacier',
            url: 'https://wiki.thinkgeo.com/wiki/landcover',
            type: 'polygon',
            filter: {
                zoom: 'zoom>=0;zoom<=19',
            }
        }, {
            title: 'grassland',
            value: 'grassland',
            content: 'Land where grass or grasslike vegetation grows.',
            url: 'https://wiki.thinkgeo.com/wiki/landcover',
            type: 'polygon',
            filter: {
                zoom: 'zoom>=4;zoom<=19',
            }
        }, {
            title: 'wood',
            value: 'wood',
            content: 'Managed forest or woodland plantation.',
            url: 'https://wiki.thinkgeo.com/wiki/landcover',
            type: 'polygon',
            filter: {
                zoom: 'zoom>=4;zoom<=19',
            }
        }, {
            title: 'scrub',
            value: 'scrub',
            content: 'Uncultivated land covered with bushes or stunted trees.',
            url: 'https://wiki.thinkgeo.com/wiki/landcover',
            type: 'polygon',
            filter: {
                zoom: 'zoom>=6;zoom<=19',
            }
        }, {
            title: 'sand',
            value: 'sand',
            content: 'An area covered by sand with no or very little vegetation.',
            url: 'https://wiki.thinkgeo.com/wiki/landcover',
            type: 'polygon',
            filter: {
                zoom: 'zoom>=0;zoom<=19',
            }
        }, {
            title: 'rock',
            value: 'rock',
            content: 'An area with sparse or no vegetation, and the bedrock becomes visible. ',
            url: 'https://wiki.thinkgeo.com/wiki/landcover',
            type: 'polygon',
            filter: {
                zoom: 'zoom>=6;zoom<=19',
            }
        }, {
            title: 'wetland',
            value: 'wetland',
            content: 'A low area where the land is saturated with water',
            url: 'https://wiki.thinkgeo.com/wiki/landcover',
            type: 'polygon',
            filter: {
                zoom: 'zoom>=6;zoom<=19',
            }
        }, {
            title: 'pitch',
            value: 'pitch',
            content: 'A place with exercise machines and/or fitness/dance classes.',
            url: 'https://wiki.thinkgeo.com/wiki/landcover',
            type: 'polygon',
            filter: {
                zoom: 'zoom>=13;zoom<=19',
            }
        }, {
            title: 'park',
            value: 'park',
            content: 'A large area of land preserved in its natural state as public property',
            url: 'https://wiki.thinkgeo.com/wiki/landcover',
            type: 'polygon',
            filter: {
                zoom: 'zoom>=4;zoom<=19',
            }
        }, {
            title: 'grass',
            value: 'grass',
            content: 'Cover with grass',
            url: 'https://wiki.thinkgeo.com/wiki/landcover',
            type: 'polygon',
            filter: {
                zoom: 'zoom>=0;zoom<=19',
            }
        }, {
            title: 'swamps',
            value: 'swamps',
            content: '	Swampsof vegetation.',
            url: 'https://wiki.thinkgeo.com/wiki/landcover',
            type: 'polygon',
            filter: {
                zoom: 'zoom>=0;zoom<=7',
            }
        }, {
            title: 'trees',
            value: 'trees',
            content: 'Trees of vegetation.',
            url: 'https://wiki.thinkgeo.com/wiki/landcover',
            type: 'polygon',
            filter: {
                zoom: 'zoom>=0;zoom<=7',
            }
        }, {
            title: 'tundra',
            value: 'tundra',
            content: 'Tundra of vegetation.',
            url: 'https://wiki.thinkgeo.com/wiki/landcover',
            type: 'polygon',
            filter: {
                zoom: 'zoom>=0;zoom<=7',
            }
        }, {
            title: 'crops',
            value: 'crops',
            content: 'This tag is used for areas that crops related to humans.',
            url: 'https://wiki.thinkgeo.com/wiki/landcover',
            type: 'polygon',
            filter: {
                zoom: 'zoom>=0;zoom<=7',
            }
        }]
    }, {
        title: 'Country',
        layerName: 'country',
        content: 'The polygon represents the 247 countries in the world.',
        url: 'https://wiki.thinkgeo.com/wiki/country',
        type: 'polygon',
        filter: {
            zoom: 'zoom>=0;zoom<=19',
        }
    }, {
        title: 'Admin_boundary',
        layerName: 'admin_boundary',
        content: 'Administrative border region',
        className: 'admin_level',
        url: 'https://wiki.thinkgeo.com/wiki/admin_boundary',
        type: 'line',
        filter: {
            zoom: 'zoom>=1;zoom<=19',
        },
        class: [{
            title: 'country',
            value: 2,
            content: 'country boundary',
            url: 'https://wiki.thinkgeo.com/wiki/admin_boundary',
            type: 'line',
            filter: {
                zoom: 'zoom>=1;zoom<=19',
                disputed: 'disputed>=0;disputed<=2',
                scalerank: 'scalerank>=0;scalerank<=7'
            }
        }, {
            title: 'state',
            value: 4,
            content: 'province or state boundary',
            url: 'https://wiki.thinkgeo.com/wiki/admin_boundary',
            type: 'line',
            filter: {
                zoom: 'zoom>=3;zoom<=19',
                scalerank: 'scalerank>=0;scalerank<=7'
            }
        }, {
            title: 'city',
            value: 6,
            content: 'city boundary',
            url: 'https://wiki.thinkgeo.com/wiki/admin_boundary',
            type: 'line',
            filter: {
                zoom: 'zoom>=11;zoom<=19',
            }
        }, {
            title: 'township',
            value: 8,
            content: 'town,township, subdistrict, villages and hamlets ',
            url: 'https://wiki.thinkgeo.com/wiki/admin_boundary',
            type: 'line',
            filter: {
                zoom: 'zoom>=13;zoom<=19',
            }
        }]
    }, {
        title: 'Admin_name',
        layerName: 'admin_name',
        content: 'The name representing country .',
        className: 'class',
        url: 'https://wiki.thinkgeo.com/wiki/admin_name',
        type: 'point',
        filter: {
            zoom: 'zoom>=1;zoom<=7',
            labelrank: 'labelrank>=2;labelrank<=7'
        },
        class: [{
            title: 'country',
            value: 'country',
            content: 'the country name ',
            url: 'https://wiki.thinkgeo.com/wiki/admin_name',
            type: 'point',
            filter: {
                zoom: 'zoom>=1;zoom<=5',
                labelrank: 'labelrank>=0;labelrank<=7'
            },
        }, {
            title: 'state',
            value: 'state',
            content: 'the state name',
            url: 'https://wiki.thinkgeo.com/wiki/admin_name',
            type: 'point',
            filter: {
                zoom: 'zoom>=3;zoom<=7',
                labelrank: 'labelrank>=0;labelrank<=10'
            },
        }]
    }, {
        title: 'Place',
        layerName: 'place',
        content: 'The point representing the palce. ',
        url: 'https://wiki.thinkgeo.com/wiki/place',
        type: 'point',
        className: 'class',
        filter: {
            zoom: 'zoom>=3;zoom<=19',
            population: 'population>=0;population<=1000000000'
        },
        class: [{
            title: 'city',
            value: 'city',
            url: 'https://wiki.thinkgeo.com/wiki/place',
            type: 'point',
            filter: {
                zoom: 'zoom>=3;zoom<=19',
                population: 'population>=0;population<=1000000000'
            },
            content: 'A large and densely populated urban area'
        }, {
            title: 'town',
            value: 'town',
            url: 'https://wiki.thinkgeo.com/wiki/place',
            type: 'point',
            content: 'an urban area with a fixed boundary that is smaller than a city',
            filter: {
                zoom: 'zoom>=5;zoom<=19',
                population: 'population>=0;population<=10000000'
            },
        }, {
            title: 'county',
            value: 'county',
            url: 'https://wiki.thinkgeo.com/wiki/place',
            type: 'point',
            filter: {
                zoom: 'zoom>=6;zoom<=19',
                population: 'population>=0;population<=10000000000'
            },
            content: 'a region created by territorial division for the purpose of local government'
        }, {
            title: 'suburb',
            value: 'suburb',
            url: 'https://wiki.thinkgeo.com/wiki/place',
            type: 'point',
            filter: {
                zoom: 'zoom>=10;zoom<=19',
                population: 'population>=0;population<=1000000'
            },
            content: 'a residential district located on the outskirts of a city '
        }, {
            title: 'village',
            value: 'village',
            url: 'https://wiki.thinkgeo.com/wiki/place',
            type: 'point',
            filter: {
                zoom: 'zoom>=10;zoom<=19',
                population: 'population>=0;population<=1000000'
            },
            content: 'A smaller distinct settlement, often is is smaller than a town. '
        }, {
            title: 'farm',
            url: 'https://wiki.thinkgeo.com/wiki/place',
            type: 'point',
            value: 'farm',
            filter: {
                zoom: 'zoom>=14;zoom<=19',
                population: 'population>=0;population<=10000'
            },
            content: 'an area of land used for growing crops or keeping animals'
        }, {
            title: 'neighbourhood',
            url: 'https://wiki.thinkgeo.com/wiki/place',
            type: 'point',
            value: 'neighbourhood',
            filter: {
                zoom: 'zoom>=14;zoom<=19',
                population: 'population>=0;population<=100000'
            },
            content: 'a group of buildings with streets on all sides '
        }]
    }, {
        title: 'Aeroway',
        layerName: 'aeroway',
        content: 'The Aeroway polygons based on OpenStreetMap aeroways',
        className: 'class',
        url: 'https://wiki.thinkgeo.com/wiki/aeroway',
        type: 'line',
        filter: {
            zoom: 'zoom>=10;zoom<=19',
        },
        class: [{
            title: 'aerodrome',
            value: 'aerodrome',
            content: 'The main area details of the airport.',
            url: 'https://wiki.thinkgeo.com/wiki/aeroway',
            type: 'line',
            filter: {
                zoom: 'zoom>=10;zoom<=19',
            },
        }, {
            title: 'helipad',
            value: 'helipad',
            content: 'The area for helicopters.',
            url: 'https://wiki.thinkgeo.com/wiki/aeroway',
            type: 'line',
            filter: {
                zoom: 'zoom>=11;zoom<=19',
            },
        }, {
            title: 'runway',
            value: 'runway',
            content: 'A rectangular area for airplane to land and takeoff.',
            url: 'https://wiki.thinkgeo.com/wiki/aeroway',
            type: 'line',
            filter: {
                zoom: 'zoom>=11;zoom<=19',
            },
        }, {
            title: 'taxiway',
            value: 'taxiway',
            content: 'the surface which an aircraft drives on to get to and from the runway',
            url: 'https://wiki.thinkgeo.com/wiki/aeroway',
            type: 'line',
            filter: {
                zoom: 'zoom>=11;zoom<=19',
            }
        }, {
            title: 'apron',
            value: 'apron',
            content: 'An area for plane to park, loaded, unloaded, refueled or boarded. ',
            url: 'https://wiki.thinkgeo.com/wiki/aeroway',
            type: 'line',
            filter: {
                zoom: 'zoom>=11;zoom<=19',
            },
        }]
    }, {
        title: 'Building',
        layerName: 'building',
        content: 'All buildings are from OSM. ',
        className: 'class',
        url: "https://wiki.thinkgeo.com/wiki/building",
        type: 'polygon',
        filter: {
            zoom: 'zoom>=13;zoom<=19',
        },
        class: [{
            "title": "house",
            "value": "house",
            "type": "polygon",
            "content": "A single dwelling usually inhabited by a single household",
            url: "https://wiki.thinkgeo.com/wiki/building",
            "filter": {
                "zoom": "zoom>=14;zoom<=19"
            }
        }, {
            "title": "apartments",
            "value": "apartments",
            "type": "polygon",
            "content": "a suite of rooms usually on one floor of an apartment house",
            url: "https://wiki.thinkgeo.com/wiki/building",
            "filter": {
                "zoom": "zoom>=13;zoom<=19"
            }
        }, {
            "title": "farm",
            "value": "farm",
            "type": "polygon",
            "content": "an area the buildings on it, used for growing crops or keeping animals",
            url: "https://wiki.thinkgeo.com/wiki/building",
            "filter": {
                "zoom": "zoom>=13;zoom<=19"
            }
        }, {
            "title": "hotel",
            "value": "hotel",
            "type": "polygon",
            "content": "A building designed with separate rooms available for overnight accommodation.",
            url: "https://wiki.thinkgeo.com/wiki/building",
            "filter": {
                "zoom": "zoom>=13;zoom<=19"
            }
        }, {
            "title": "detached",
            "value": "detached",
            "type": "polygon",
            "content": "A free-standing residential building usually housing a single family",
            url: "https://wiki.thinkgeo.com/wiki/building",
            "filter": {
                "zoom": "zoom>=13;zoom<=19"
            }
        }, {
            "title": "residential",
            "value": "residential",
            "type": "polygon",
            "content": "A general tag for a building used primarily for residential purposes.",
            url: "https://wiki.thinkgeo.com/wiki/building",
            "filter": {
                "zoom": "zoom>=13;zoom<=19"
            }
        }, {
            "title": "dormitory",
            "value": "dormitory",
            "type": "polygon",
            "content": "For a shared building, as used by college/university students",
            url: "https://wiki.thinkgeo.com/wiki/building",
            "filter": {
                "zoom": "zoom>=13;zoom<=19"
            }
        }, {
            "title": "terrace",
            "value": "terrace",
            "type": "polygon",
            "content": "usually paved outdoor area adjoining a residence",
            url: "https://wiki.thinkgeo.com/wiki/building",
            "filter": {
                "zoom": "zoom>=13;zoom<=19"
            }
        }, {
            "title": "houseboat",
            "value": "houseboat",
            "type": "polygon",
            "content": "A boat used primarily as a home",
            url: "https://wiki.thinkgeo.com/wiki/building",
            "filter": {
                "zoom": "zoom>=13;zoom<=19"
            }
        }, {
            "title": "bungalow",
            "value": "bungalow",
            "type": "polygon",
            "content": "A single-storey detached small house, Dacha.",
            url: "https://wiki.thinkgeo.com/wiki/building",
            "filter": {
                "zoom": "zoom>=13;zoom<=19"
            }
        }, {
            "title": "static_caravan",
            "value": "static_caravan",
            "type": "polygon",
            "content": "A mobile home (semi)permanently left on a single site",
            url: "https://wiki.thinkgeo.com/wiki/building",
            "filter": {
                "zoom": "zoom>=13;zoom<=19"
            }
        }, {
            "title": "cabin",
            "value": "cabin",
            "type": "polygon",
            "content": "A cabin is a small, roughly built house usually with a wood exterior",
            url: "https://wiki.thinkgeo.com/wiki/building",
            "filter": {
                "zoom": "zoom>=13;zoom<=19"
            }
        }, {
            "title": "commercial",
            "value": "commercial",
            "type": "polygon",
            "content": "A building where non-specific commercial activities take place, not necessarily an office building",
            url: "https://wiki.thinkgeo.com/wiki/building",
            "filter": {
                "zoom": "zoom>=13;zoom<=19"
            }
        }, {
            "title": "office",
            "value": "office",
            "type": "polygon",
            "content": "An office building.",
            url: "https://wiki.thinkgeo.com/wiki/building",
            "filter": {
                "zoom": "zoom>=13;zoom<=19"
            }
        }, {
            "title": "industrial",
            "value": "industrial",
            "type": "polygon",
            "content": "A building where some industrial process takes place.",
            url: "https://wiki.thinkgeo.com/wiki/building",
            "filter": {
                "zoom": "zoom>=13;zoom<=19"
            }
        }, {
            "title": "retail",
            "value": "retail",
            "type": "polygon",
            "content": "A building primarily used for selling goods that are sold to the public",
            url: "https://wiki.thinkgeo.com/wiki/building",
            "filter": {
                "zoom": "zoom>=13;zoom<=19"
            }
        }, {
            "title": "supermarket",
            "value": "supermarket",
            "type": "polygon",
            "content": "Introduced by Mapnik. Self-service large-area store.",
            url: "https://wiki.thinkgeo.com/wiki/building",
            "filter": {
                "zoom": "zoom>=13;zoom<=19"
            }
        }, {
            "title": "warehouse",
            "value": "warehouse",
            "type": "polygon",
            "content": "A building primarily used for the storage or goods or as part of a distribution system.",
            url: "https://wiki.thinkgeo.com/wiki/building",
            "filter": {
                "zoom": "zoom>=13;zoom<=19"
            }
        }, {
            "title": "kiosk",
            "value": "kiosk",
            "type": "polygon",
            "content": "Small retail building.",
            url: "https://wiki.thinkgeo.com/wiki/building",
            "filter": {
                "zoom": "zoom>=13;zoom<=19"
            }
        }, {
            "title": "religious",
            "value": "religious",
            "type": "polygon",
            "content": "	Unspecific religious building. Prefer more specific values if possible.",
            url: "https://wiki.thinkgeo.com/wiki/building",
            "filter": {
                "zoom": "zoom>=13;zoom<=19"
            }
        }, {
            "title": "cathedral",
            "value": "cathedral",
            "type": "polygon",
            "content": "A building that was built as a cathedral.",
            url: "https://wiki.thinkgeo.com/wiki/building",
            "filter": {
                "zoom": "zoom>=13;zoom<=19",
                "title": "chapel",
                "value": "chapel",
                "type": "polygon",
                "content": "A building that was built as a chapel.",
                url: "https://wiki.thinkgeo.com/wiki/building",
                "filter": {
                    "zoom": "zoom>=13;zoom<=19"
                }
            }
        }, {
            "title": "church",
            "value": "church",
            "type": "polygon",
            "content": "A building that was built as a church.",
            url: "https://wiki.thinkgeo.com/wiki/building",
            "filter": {
                "zoom": "zoom>=13;zoom<=19"
            }
        }, {
            "title": "mosque",
            "value": "mosque",
            "type": "polygon",
            "content": "(Islam) a Muslim place of worship that usually has a minaret",
            url: "https://wiki.thinkgeo.com/wiki/building",
            "filter": {
                "zoom": "zoom>=13;zoom<=19"
            }
        }, {
            "title": "temple",
            "value": "temple",
            "type": "polygon",
            "content": "place of worship consisting of an edifice for the worship of a deity",
            url: "https://wiki.thinkgeo.com/wiki/building",
            "filter": {
                "zoom": "zoom>=13;zoom<=19"
            }
        }, {
            "title": "synagogue",
            "value": "synagogue",
            "type": "polygon",
            "content": "(Judaism) the place of worship for a Jewish congregation",
            url: "https://wiki.thinkgeo.com/wiki/building",
            "filter": {
                "zoom": "zoom>=13;zoom<=19"
            }
        }, {
            "title": "shrine",
            "value": "shrine",
            "type": "polygon",
            "content": "a place of worship hallowed by association with some sacred thing or person",
            url: "https://wiki.thinkgeo.com/wiki/building",
            "filter": {
                "zoom": "zoom>=13;zoom<=19"
            }
        }, {
            "title": "bakehouse",
            "value": "bakehouse",
            "type": "polygon",
            "content": "a workplace where baked goods are produced or sold",
            url: "https://wiki.thinkgeo.com/wiki/building",
            "filter": {
                "zoom": "zoom>=13;zoom<=19"
            }
        }, {
            "title": "kindergarten",
            "value": "kindergarten",
            "type": "polygon",
            "content": "a preschool for children age 4 to 6 to prepare them for primary school",
            url: "https://wiki.thinkgeo.com/wiki/building",
            "filter": {
                "zoom": "zoom>=13;zoom<=19"
            }
        }, {
            "title": "civic",
            "value": "civic",
            "type": "polygon",
            "content": "officially connected with a town or city",
            url: "https://wiki.thinkgeo.com/wiki/building",
            "filter": {
                "zoom": "zoom>=13;zoom<=19"
            }
        }, {
            "title": "government",
            "value": "government",
            "type": "polygon",
            "content": "For government buildings in general, including municipal, provincial...",
            url: "https://wiki.thinkgeo.com/wiki/building",
            "filter": {
                "zoom": "zoom>=13;zoom<=19"
            }
        }, {
            "title": "hospital",
            "value": "hospital",
            "type": "polygon",
            "content": "a health facility where patients receive treatment",
            url: "https://wiki.thinkgeo.com/wiki/building",
            "filter": {
                "zoom": "zoom>=13;zoom<=19"
            }
        }, {
            "title": "school",
            "value": "school",
            "type": "polygon",
            "content": "For any generic school buildings.",
            url: "https://wiki.thinkgeo.com/wiki/building",
            "filter": {
                "zoom": "zoom>=13;zoom<=19"
            }
        }, {
            "title": "stadium",
            "value": "stadium",
            "type": "polygon",
            "content": "a large structure for open-air sports or entertainments",
            url: "https://wiki.thinkgeo.com/wiki/building",
            "filter": {
                "zoom": "zoom>=13;zoom<=19"
            }
        }, {
            "title": "train_station",
            "value": "train_station",
            "type": "polygon",
            "content": "terminal where trains load or unload passengers or goods",
            url: "https://wiki.thinkgeo.com/wiki/building",
            "filter": {
                "zoom": "zoom>=13;zoom<=19"
            }
        }, {
            "title": "transportation",
            "value": "transportation",
            "type": "polygon",
            "content": "A building related to public transport.",
            url: "https://wiki.thinkgeo.com/wiki/building",
            "filter": {
                "zoom": "zoom>=13;zoom<=19"
            }
        }, {
            "title": "university",
            "value": "university",
            "type": "polygon",
            "content": "A university building.",
            url: "https://wiki.thinkgeo.com/wiki/building",
            "filter": {
                "zoom": "zoom>=13;zoom<=19"
            }
        }, {
            "title": "grandstand",
            "value": "grandstand",
            "type": "polygon",
            "content": "The main stand, usually roofed, commanding the best view sports grounds.",
            url: "https://wiki.thinkgeo.com/wiki/building",
            "filter": {
                "zoom": "zoom>=13;zoom<=19"
            }
        }, {
            "title": "public",
            "value": "public",
            "type": "polygon",
            "content": "The main stand, usually roofed, commanding the best view for spectators at racecourses or sports grounds.",
            url: "https://wiki.thinkgeo.com/wiki/building",
            "filter": {
                "zoom": "zoom>=13;zoom<=19"
            }
        }, {
            "title": "toilets",
            "value": "toilets",
            "type": "polygon",
            "content": "	A toilet block.",
            url: "https://wiki.thinkgeo.com/wiki/building",
            "filter": {
                "zoom": "zoom>=13;zoom<=19"
            }
        }, {
            "title": "barn",
            "value": "barn",
            "type": "polygon",
            "content": "An agricultural building used for storage and as a covered workplace.",
            url: "https://wiki.thinkgeo.com/wiki/building",
            "filter": {
                "zoom": "zoom>=13;zoom<=19"
            }
        }, {
            "title": "bridge",
            "value": "bridge",
            "type": "polygon",
            "content": "A building used as a bridge.",
            url: "https://wiki.thinkgeo.com/wiki/building",
            "filter": {
                "zoom": "zoom>=13;zoom<=19"
            }
        }, {
            "title": "bunker",
            "value": "bunker",
            "type": "polygon",
            "content": "A hardened military building",
            url: "https://wiki.thinkgeo.com/wiki/building",
            "filter": {
                "zoom": "zoom>=13;zoom<=19"
            }
        }, {
            "title": "carport",
            "value": "carport",
            "type": "polygon",
            "content": "garage for one or two cars consisting of a flat roof supported on poles",
            url: "https://wiki.thinkgeo.com/wiki/building",
            "filter": {
                "zoom": "zoom>=13;zoom<=19"
            }
        }, {
            "title": "conservatory",
            "value": "conservatory",
            "type": "polygon",
            "content": "a greenhouse in which plants are arranged in a pleasing manner",
            url: "https://wiki.thinkgeo.com/wiki/building",
            "filter": {
                "zoom": "zoom>=13;zoom<=19"
            }
        }, {
            "title": "construction",
            "value": "construction",
            "type": "polygon",
            "content": "Used for buildings under construction.",
            url: "https://wiki.thinkgeo.com/wiki/building",
            "filter": {
                "zoom": "zoom>=13;zoom<=19"
            }
        }, {
            "title": "cowshed",
            "value": "cowshed",
            "type": "polygon",
            "content": "cowshed is a building for housing cows, usually found on farms.",
            url: "https://wiki.thinkgeo.com/wiki/building",
            "filter": {
                "zoom": "zoom>=13;zoom<=19"
            }
        }, {
            "title": "digester",
            "value": "digester",
            "type": "polygon",
            "content": "A digester is a bioreactor for the production of inflatable biogas from biomass.",
            url: "https://wiki.thinkgeo.com/wiki/building",
            "filter": {
                "zoom": "zoom>=13;zoom<=19"
            }
        }, {
            "title": "farm_auxiliary",
            "value": "farm_auxiliary",
            "type": "polygon",
            "content": "A building on a farm that is not a dwelling",
            url: "https://wiki.thinkgeo.com/wiki/building",
            "filter": {
                "zoom": "zoom>=13;zoom<=19"
            }
        }, {
            "title": "garage",
            "value": "garage",
            "type": "polygon",
            "content": "A garage is a building suitable for the storage of one",
            url: "https://wiki.thinkgeo.com/wiki/building",
            "filter": {
                "zoom": "zoom>=13;zoom<=19"
            }
        }, {
            "title": "garages",
            "value": "garages",
            "type": "polygon",
            "content": "an outbuilding(or part of a building) for housing automobiles",
            url: "https://wiki.thinkgeo.com/wiki/building",
            "filter": {
                "zoom": "zoom>=13;zoom<=19"
            }
        }, {
            "title": "garbage_shed",
            "value": "garbage_shed",
            "type": "polygon",
            "content": "A garbage shed is a building used to store waste bins.",
            url: "https://wiki.thinkgeo.com/wiki/building",
            "filter": {
                "zoom": "zoom>=13;zoom<=19"
            }
        }, {
            "title": "greenhouse",
            "value": "greenhouse",
            "type": "polygon",
            "content": "A greenhouse is a glass or plastic covered building used to grow plants.",
            url: "https://wiki.thinkgeo.com/wiki/building",
            "filter": {
                "zoom": "zoom>=13;zoom<=19"
            }
        }, {
            "title": "hangar",
            "value": "hangar",
            "type": "polygon",
            "content": "hangar is a building used for the storage of airplanes, helicopters or space-craft.",
            url: "https://wiki.thinkgeo.com/wiki/building",
            "filter": {
                "zoom": "zoom>=13;zoom<=19"
            }
        }, {
            "title": "hut",
            "value": "hut",
            "type": "polygon",
            "content": "A hut is a small and crude shelter.",
            url: "https://wiki.thinkgeo.com/wiki/building",
            "filter": {
                "zoom": "zoom>=13;zoom<=19"
            }
        }, {
            "title": "pavilion",
            "value": "pavilion",
            "type": "polygon",
            "content": "",
            url: "https://wiki.thinkgeo.com/wiki/building",
            "filter": {
                "zoom": "zoom>=13;zoom<=19"
            }
        }, {
            "title": "parking",
            "value": "parking",
            "type": "polygon",
            "content": "",
            url: "https://wiki.thinkgeo.com/wiki/building",
            "filter": {
                "zoom": "zoom>=13;zoom<=19"
            }
        }, {
            "title": "riding_hall",
            "value": "riding_hall",
            "type": "polygon",
            "content": "A sports pavilion usually with changing rooms, storage areas",
            url: "https://wiki.thinkgeo.com/wiki/building",
            "filter": {
                "zoom": "zoom>=13;zoom<=19"
            }
        }, {
            "title": "roof",
            "value": "roof",
            "type": "polygon",
            "content": "A structure that consists of a roof with open sides",
            url: "https://wiki.thinkgeo.com/wiki/building",
            "filter": {
                "zoom": "zoom>=13;zoom<=19"
            }
        }, {
            "title": "shed",
            "value": "shed",
            "type": "polygon",
            "content": "A shed is a simple, single-storey structure in a back garden",
            url: "https://wiki.thinkgeo.com/wiki/building",
            "filter": {
                "zoom": "zoom>=13;zoom<=19"
            }
        }, {
            "title": "sports_hall",
            "value": "sports_hall",
            "type": "polygon",
            "content": "A building that was built as a sports hall.",
            url: "https://wiki.thinkgeo.com/wiki/building",
            "filter": {
                "zoom": "zoom>=13;zoom<=19"
            }
        }, {
            "title": "stable",
            "value": "stable",
            "type": "polygon",
            "content": "A stable is a building where horses are kep",
            url: "https://wiki.thinkgeo.com/wiki/building",
            "filter": {
                "zoom": "zoom>=13;zoom<=19"
            }
        }, {
            "title": "sty",
            "value": "sty",
            "type": "polygon",
            "content": "A sty is a building for raising domestic pigs, usually found on farms",
            url: "https://wiki.thinkgeo.com/wiki/building",
            "filter": {
                "zoom": "zoom>=13;zoom<=19"
            }
        }, {
            "title": "transformer_tower",
            "value": "transformer_tower",
            "type": "polygon",
            "content": "A characteristic tall building comprising a distribution transformer",
            url: "https://wiki.thinkgeo.com/wiki/building",
            "filter": {
                "zoom": "zoom>=13;zoom<=19"
            }
        }, {
            "title": "service",
            "value": "service",
            "type": "polygon",
            "content": "a small unmanned building with certain machinery",
            url: "https://wiki.thinkgeo.com/wiki/building",
            "filter": {
                "zoom": "zoom>=13;zoom<=19"
            }
        }, {
            "title": "ruins",
            "value": "ruins",
            "type": "polygon",
            "content": "a ruined building",
            url: "https://wiki.thinkgeo.com/wiki/building",
            "filter": {
                "zoom": "zoom>=13;zoom<=19"
            }
        }, {
            "title": "water_tower",
            "value": "water_tower",
            "type": "polygon",
            "content": "A water tower",
            url: "https://wiki.thinkgeo.com/wiki/building",
            "filter": {
                "zoom": "zoom>=13;zoom<=19"
            }
        }, {
            "title": "yes",
            "value": "yes",
            "type": "polygon",
            "content": "",
            url: "https://wiki.thinkgeo.com/wiki/building",
            "filter": {
                "zoom": "zoom>=13;zoom<=19"
            }
        }]
    }, {
        title: 'POI',
        layerName: 'poi',
        content: 'It represents the points of interests(POI).',
        className: 'class',
        filter: {
            zoom: 'zoom>=10;zoom<=19',
            labelrank: 'labelrank>=0;labelrank<=9'
        },
        url: 'https://wiki.thinkgeo.com/wiki/poi',
        type: 'point',
        class: [{
            title: 'airport',
            value: 'airport',
            url: 'https://wiki.thinkgeo.com/wiki/poi',
            type: 'point',
            content: 'Airports data are from Natural Earth.',
            filter: {
                zoom: 'zoom>=10;zoom<=19',
                labelrank: 'labelrank>=0;labelrank<=9'
            },
        }, {
            title: 'peak',
            value: 'peak',
            url: 'https://wiki.thinkgeo.com/wiki/poi',
            type: 'point',
            content: 'The top (summit) of a hill or mountain.',
            filter: {
                zoom: 'zoom>=10;zoom<=19',
                labelrank: 'labelrank>=0;labelrank<=9'
            },
        }, {
            title: 'national_park',
            value: 'national_park',
            url: 'https://wiki.thinkgeo.com/wiki/poi',
            type: 'point',
            content: 'Area of outstanding natural beauty',
            filter: {
                zoom: 'zoom>=10;zoom<=19',
                labelrank: 'labelrank>=0;labelrank<=9'
            },
        }, {
            title: 'university',
            value: 'university',
            url: 'https://wiki.thinkgeo.com/wiki/poi',
            type: 'point',
            content: 'An university campus',
            filter: {
                zoom: 'zoom>=10;zoom<=19',
                labelrank: 'labelrank>=0;labelrank<=9'
            },
        }, {
            title: 'park',
            value: 'park',
            url: 'https://wiki.thinkgeo.com/wiki/poi',
            type: 'point',
            content: 'Open, green area for recreation, usually municipal.',
            filter: {
                zoom: 'zoom>=10;zoom<=19',
                labelrank: 'labelrank>=0;labelrank<=9'
            },
        }, {
            title: 'arts_centre',
            value: 'arts_centre',
            url: 'https://wiki.thinkgeo.com/wiki/poi',
            type: 'point',
            content: 'A venue where a variety of arts are performed or conducted',
            filter: {
                zoom: 'zoom>=13;zoom<=19',
                labelrank: 'labelrank>=0;labelrank<=9'
            },
        }, {
            title: 'grave_yard',
            value: 'grave_yard',
            url: 'https://wiki.thinkgeo.com/wiki/poi',
            type: 'point',
            content: "A (smaller) place of burial, often you'll find a church nearby",
            filter: {
                zoom: 'zoom>=13;zoom<=19',
                labelrank: 'labelrank>=0;labelrank<=9'
            },
        }, {
            title: 'theatre',
            value: 'theatre',
            url: 'https://wiki.thinkgeo.com/wiki/poi',
            type: 'point',
            content: 'A theatre or opera house.',
            filter: {
                zoom: 'zoom>=13;zoom<=19',
                labelrank: 'labelrank>=0;labelrank<=9'
            },
        }, {
            title: 'college',
            value: 'college',
            url: 'https://wiki.thinkgeo.com/wiki/poi',
            type: 'point',
            content: 'A college campus or buildings',
            filter: {
                zoom: 'zoom>=13;zoom<=19',
                labelrank: 'labelrank>=0;labelrank<=9'
            },
        }, {
            title: 'golf_course',
            value: 'golf_course',
            url: 'https://wiki.thinkgeo.com/wiki/poi',
            type: 'point',
            content: 'The outline of a golf course.',
            filter: {
                zoom: 'zoom>=13;zoom<=19',
                labelrank: 'labelrank>=0;labelrank<=9'
            },
        }, {
            title: 'sports_centre',
            value: 'sports_centre',
            url: 'https://wiki.thinkgeo.com/wiki/poi',
            type: 'point',
            content: 'A distinct facility where a range of sports take place within an enclosed area.',
            filter: {
                zoom: 'zoom>=13;zoom<=19',
                labelrank: 'labelrank>=0;labelrank<=9'
            },
        }, {
            title: 'private',
            value: 'private',
            url: 'https://wiki.thinkgeo.com/wiki/poi',
            type: 'point',
            content: 'Only with permission of the owner on an individual basis.',
            filter: {
                zoom: 'zoom>=14;zoom<=19',
                labelrank: 'labelrank>=0;labelrank<=9'
            },
        }, {
            title: 'bar',
            value: 'bar',
            url: 'https://wiki.thinkgeo.com/wiki/poi',
            type: 'point',
            content: 'a room or establishment where alcoholic drinks are served over a counter',
            filter: {
                zoom: 'zoom>=14;zoom<=19',
                labelrank: 'labelrank>=0;labelrank<=9'
            },
        }, {
            title: 'biergarten',
            value: 'biergarten',
            url: 'https://wiki.thinkgeo.com/wiki/poi',
            type: 'point',
            content: 'tavern with an outdoor area where beer are served',
            filter: {
                zoom: 'zoom>=14;zoom<=19',
                labelrank: 'labelrank>=0;labelrank<=9'
            },
        }, {
            title: 'cafe',
            value: 'cafe',
            url: 'https://wiki.thinkgeo.com/wiki/poi',
            type: 'point',
            content: 'a small restaurant where drinks and snacks are sold',
            filter: {
                zoom: 'zoom>=14;zoom<=19',
                labelrank: 'labelrank>=0;labelrank<=9'
            },
        }, {
            title: 'fast_food',
            value: 'fast_food',
            url: 'https://wiki.thinkgeo.com/wiki/poi',
            type: 'point',
            content: 'Fast food restaurant',
            filter: {
                zoom: 'zoom>=14;zoom<=19',
                labelrank: 'labelrank>=0;labelrank<=9'
            },
        }, {
            title: 'food_court',
            value: 'food_court',
            url: 'https://wiki.thinkgeo.com/wiki/poi',
            type: 'point',
            content: 'an area where fast food is sold',
            filter: {
                zoom: 'zoom>=14;zoom<=19',
                labelrank: 'labelrank>=0;labelrank<=9'
            },
        }, {
            title: 'restaurant',
            value: 'restaurant',
            url: 'https://wiki.thinkgeo.com/wiki/poi',
            type: 'point',
            content: 'a building where people go to eat',
            filter: {
                zoom: 'zoom>=14;zoom<=19',
                labelrank: 'labelrank>=0;labelrank<=9'
            },
        }, {
            title: 'kindergarten',
            value: 'kindergarten',
            url: 'https://wiki.thinkgeo.com/wiki/poi',
            type: 'point',
            content: 'For children too young for a regular school ',
            filter: {
                zoom: 'zoom>=14;zoom<=19',
                labelrank: 'labelrank>=0;labelrank<=9'
            },
        }, {
            "title": "library",
            "value": "library",
            "type": "point",
            "content": "A public library to borrow books from.",
            url: 'https://wiki.thinkgeo.com/wiki/poi',
            "filter": {
                "zoom": "zoom>=14;zoom<=19",
                labelrank: 'labelrank>=0;labelrank<=9'
            }
        }, {
            "title": "school",
            "value": "school",
            "type": "point",
            "content": "School and grounds",
            url: 'https://wiki.thinkgeo.com/wiki/poi',
            "filter": {
                "zoom": "zoom>=14;zoom<=19",
                labelrank: 'labelrank>=0;labelrank<=9'
            }
        }, {
            "title": "bus_station",
            "value": "bus_station",
            "type": "point",
            "content": "The station of public transportation.",
            url: 'https://wiki.thinkgeo.com/wiki/poi',
            "filter": {
                "zoom": "zoom>=14;zoom<=19",
                labelrank: 'labelrank>=0;labelrank<=9'
            }
        }, {
            "title": "fuel",
            "value": "fuel",
            "type": "point",
            "content": "Petrol station; gas station; marine fuel",
            url: 'https://wiki.thinkgeo.com/wiki/poi',
            "filter": {
                "zoom": "zoom>=14;zoom<=19",
                labelrank: 'labelrank>=0;labelrank<=9'
            }
        }, {
            "title": "parking",
            "value": "parking",
            "type": "point",
            "content": "Car park.",
            url: 'https://wiki.thinkgeo.com/wiki/poi',
            "filter": {
                "zoom": "zoom>=14;zoom<=19",
                labelrank: 'labelrank>=0;labelrank<=9'
            }
        }, {
            "title": "taxi",
            "value": "taxi",
            "type": "point",
            "content": "A place where taxis wait for passengers.",
            url: 'https://wiki.thinkgeo.com/wiki/poi',
            "filter": {
                "zoom": "zoom>=14;zoom<=19",
                labelrank: 'labelrank>=0;labelrank<=9'
            }
        }, {
            "title": "bank",
            "value": "bank",
            "type": "point",
            "content": "a financial institution that accepts deposits and channels the money",
            url: 'https://wiki.thinkgeo.com/wiki/poi',
            "filter": {
                "zoom": "zoom>=14;zoom<=19",
                labelrank: 'labelrank>=0;labelrank<=9'
            }
        }, {
            "title": "hospital",
            "value": "hospital",
            "type": "point",
            "content": "a health facility where patients receive treatment",
            url: 'https://wiki.thinkgeo.com/wiki/poi',
            "filter": {
                "zoom": "zoom>=14;zoom<=19",
                labelrank: 'labelrank>=0;labelrank<=9'
            }
        }, {
            "title": "clinic",
            "value": "clinic",
            "type": "point",
            "content": "A medium-sized medical facility or health centre.",
            url: 'https://wiki.thinkgeo.com/wiki/poi',
            "filter": {
                "zoom": "zoom>=14;zoom<=19",
                labelrank: 'labelrank>=0;labelrank<=9'
            }
        }, {
            "title": "dentist",
            "value": "dentist",
            "type": "point",
            "content": "A dentist practice / surgery.",
            url: 'https://wiki.thinkgeo.com/wiki/poi',
            "filter": {
                "zoom": "zoom>=14;zoom<=19",
                labelrank: 'labelrank>=0;labelrank<=9'
            }
        }, {
            "title": "pharmacy",
            "value": "pharmacy",
            "type": "point",
            "content": "Pharmacy",
            url: 'https://wiki.thinkgeo.com/wiki/poi',
            "filter": {
                "zoom": "zoom>=14;zoom<=19",
                labelrank: 'labelrank>=0;labelrank<=9'
            }
        }, {
            "title": "social_facility",
            "value": "social_facility",
            "type": "point",
            "content": "A facility that provides social services.",
            url: 'https://wiki.thinkgeo.com/wiki/poi',
            "filter": {
                "zoom": "zoom>=14;zoom<=19",
                labelrank: 'labelrank>=0;labelrank<=9'
            }
        }, {
            "title": "veterinary",
            "value": "veterinary",
            "type": "point",
            "content": "A place where a veterinary surgeon (vet) practices.",
            url: 'https://wiki.thinkgeo.com/wiki/poi',
            "filter": {
                "zoom": "zoom>=14;zoom<=19",
                labelrank: 'labelrank>=0;labelrank<=9'
            }
        }, {
            "title": "cinema",
            "value": "cinema",
            "type": "point",
            "content": "A place where films are shown (US: movie theater)",
            url: 'https://wiki.thinkgeo.com/wiki/poi',
            "filter": {
                "zoom": "zoom>=14;zoom<=19",
                labelrank: 'labelrank>=0;labelrank<=9'
            }
        }, {
            "title": "community_centre",
            "value": "community_centre",
            "type": "point",
            "content": "A place mostly used for local events, festivities and group activities",
            url: 'https://wiki.thinkgeo.com/wiki/poi',
            "filter": {
                "zoom": "zoom>=14;zoom<=19",
                labelrank: 'labelrank>=0;labelrank<=9'
            }
        }, {
            "title": "courthouse",
            "value": "courthouse",
            "type": "point",
            "content": "A place where justice is dispensed",
            url: 'https://wiki.thinkgeo.com/wiki/poi',
            "filter": {
                "zoom": "zoom>=14;zoom<=19",
                labelrank: 'labelrank>=0;labelrank<=9'
            }
        }, {
            "title": "fire_station",
            "value": "fire_station",
            "type": "point",
            "content": "A fire station",
            url: 'https://wiki.thinkgeo.com/wiki/poi',
            "filter": {
                "zoom": "zoom>=14;zoom<=19",
                labelrank: 'labelrank>=0;labelrank<=9'
            }
        }, {
            "title": "embassy",
            "value": "embassy",
            "type": "point",
            "content": "An embassy",
            url: 'https://wiki.thinkgeo.com/wiki/poi',
            "filter": {
                "zoom": "zoom>=14;zoom<=19",
                labelrank: 'labelrank>=0;labelrank<=9'
            }
        }, {
            "title": "marketplace",
            "value": "marketplace",
            "type": "point",
            "content": "A (smaller) place of burial, often you'll find a church nearby.",
            url: 'https://wiki.thinkgeo.com/wiki/poi',
            "filter": {
                "zoom": "zoom>=14;zoom<=19",
                labelrank: 'labelrank>=0;labelrank<=9'
            }
        }, {
            "title": "place_of_worship",
            "value": "place_of_worship",
            "type": "point",
            "content": "A church, mosque, or temple, etc.",
            url: 'https://wiki.thinkgeo.com/wiki/poi',
            "filter": {
                "zoom": "zoom>=14;zoom<=19",
                labelrank: 'labelrank>=0;labelrank<=9'
            }
        }, {
            "title": "police",
            "value": "police",
            "type": "point",
            "content": "A police station",
            url: 'https://wiki.thinkgeo.com/wiki/poi',
            "filter": {
                "zoom": "zoom>=14;zoom<=19",
                labelrank: 'labelrank>=0;labelrank<=9'
            }
        } , {
            "title": "townhall",
            "value": "townhall",
            "type": "point",
            "content": "a government building that houses administrative offices of a town government",
            url: 'https://wiki.thinkgeo.com/wiki/poi',
            "filter": {
                "zoom": "zoom>=14;zoom<=19",
                labelrank: 'labelrank>=0;labelrank<=9'
            }
        }, {
            "title": "toilets",
            "value": "toilets",
            "type": "point",
            "content": "Public toilets (might require a fee)",
            url: 'https://wiki.thinkgeo.com/wiki/poi',
            "filter": {
                "zoom": "zoom>=14;zoom<=19",
                labelrank: 'labelrank>=0;labelrank<=9'
            }
        }, {
            "title": "shelter",
            "value": "shelter",
            "type": "point",
            "content": "A small shelter against bad weather conditions.",
            url: 'https://wiki.thinkgeo.com/wiki/poi',
            "filter": {
                "zoom": "zoom>=14;zoom<=19",
                labelrank: 'labelrank>=0;labelrank<=9'
            }
        }, {
            "title": "recycling",
            "value": "recycling",
            "type": "point",
            "content": "Recycling facilities (bottle banks, etc.).",
            url: 'https://wiki.thinkgeo.com/wiki/poi',
            "filter": {
                "zoom": "zoom>=14;zoom<=19",
                labelrank: 'labelrank>=0;labelrank<=9'
            }
        }, {
            "title": "pub",
            "value": "pub",
            "type": "point",
            "content": "A place selling beer and other alcoholic drinks;",
            url: 'https://wiki.thinkgeo.com/wiki/poi',
            "filter": {
                "zoom": "zoom>=14;zoom<=19",
                labelrank: 'labelrank>=0;labelrank<=9'
            }
        }, {
            "title": "post_office",
            "value": "post_office",
            "type": "point",
            "content": "Post office building with postal services",
            url: 'https://wiki.thinkgeo.com/wiki/poi',
            "filter": {
                "zoom": "zoom>=14;zoom<=19",
                labelrank: 'labelrank>=0;labelrank<=9'
            }
        }, {
            "title": "nursing_home",
            "value": "nursing_home",
            "type": "point",
            "content": "an institution where people are cared for",
            url: 'https://wiki.thinkgeo.com/wiki/poi',
            "filter": {
                "zoom": "zoom>=14;zoom<=19",
                labelrank: 'labelrank>=0;labelrank<=9'
            }
        }, {
            "title": "ice_cream",
            "value": "ice_cream",
            "type": "point",
            "content": "Ice cream shop or ice cream parlour.",
            url: 'https://wiki.thinkgeo.com/wiki/poi',
            "filter": {
                "zoom": "zoom>=14;zoom<=19",
                labelrank: 'labelrank>=0;labelrank<=9'
            }
        }, {
            "title": "fountain",
            "value": "fountain",
            "type": "point",
            "content": "A fountain for cultural / decorational / recreational purposes.",
            url: 'https://wiki.thinkgeo.com/wiki/poi',
            "filter": {
                "zoom": "zoom>=14;zoom<=19",
                labelrank: 'labelrank>=0;labelrank<=9'
            }
        }, {
            "title": "car_rental",
            "value": "car_rental",
            "type": "point",
            "content": "Rent a car",
            url: 'https://wiki.thinkgeo.com/wiki/poi',
            "filter": {
                "zoom": "zoom>=14;zoom<=19",
                labelrank: 'labelrank>=0;labelrank<=9'
            }
        }, {
            "title": "casino",
            "value": "casino",
            "type": "point",
            "content": "a public building for gambling and entertainment",
            url: 'https://wiki.thinkgeo.com/wiki/poi',
            "filter": {
                "zoom": "zoom>=14;zoom<=19",
                labelrank: 'labelrank>=0;labelrank<=9'
            }
        }, {
            "title": "bicycle_rental",
            "value": "bicycle_rental",
            "type": "point",
            "content": "Rent a bicycle",
            url: 'https://wiki.thinkgeo.com/wiki/poi',
            "filter": {
                "zoom": "zoom>=14;zoom<=19",
                labelrank: 'labelrank>=0;labelrank<=9'
            }
        }, {
            "title": "bicycle_parking",
            "value": "bicycle_parking",
            "type": "point",
            "content": "Parking for bicycles",
            url: 'https://wiki.thinkgeo.com/wiki/poi',
            "filter": {
                "zoom": "zoom>=14;zoom<=19",
                labelrank: 'labelrank>=0;labelrank<=9'
            }
        }, {
            "title": "toll_booth",
            "value": "toll_booth",
            "type": "point",
            "content": "A place where a road usage toll or fee is collected.",
            url: 'https://wiki.thinkgeo.com/wiki/poi',
            "filter": {
                "zoom": "zoom>=14;zoom<=19",
                labelrank: 'labelrank>=0;labelrank<=9'
            }
        }, {
            "title": "wreck",
            "value": "wreck",
            "type": "point",
            "content": "A nautical craft that has unintentionally been sunk or destroyed.",
            url: 'https://wiki.thinkgeo.com/wiki/poi',
            "filter": {
                "zoom": "zoom>=14;zoom<=19",
                labelrank: 'labelrank>=0;labelrank<=9'
            }
        }, {
            "title": "wayside_shrine",
            "value": "wayside_shrine",
            "type": "point",
            "content": "A historical shrine often showing a religious depiction.",
            url: 'https://wiki.thinkgeo.com/wiki/poi',
            "filter": {
                "zoom": "zoom>=14;zoom<=19",
                labelrank: 'labelrank>=0;labelrank<=9'
            }
        }, {
            "title": "ruins",
            "value": "ruins",
            "type": "point",
            "content": "Remains of structures that were once complete, but have fallen into partial",
            url: 'https://wiki.thinkgeo.com/wiki/poi',
            "filter": {
                "zoom": "zoom>=14;zoom<=19",
                labelrank: 'labelrank>=0;labelrank<=9'
            }
        }, {
            "title": "monument",
            "value": "monument",
            "type": "point",
            "content": "a structure erected to commemorate persons or events",
            url: 'https://wiki.thinkgeo.com/wiki/poi',
            "filter": {
                "zoom": "zoom>=14;zoom<=19",
                labelrank: 'labelrank>=0;labelrank<=9'
            }
        }, {
            "title": "memorial",
            "value": "memorial",
            "type": "point",
            "content": "a structure erected to commemorate persons or events",
            url: 'https://wiki.thinkgeo.com/wiki/poi',
            "filter": {
                "zoom": "zoom>=14;zoom<=19",
                labelrank: 'labelrank>=0;labelrank<=9'
            }
        }, {
            "title": "castle",
            "value": "castle",
            "type": "point",
            "content": "a large and stately mansion",
            url: 'https://wiki.thinkgeo.com/wiki/poi',
            "filter": {
                "zoom": "zoom>=14;zoom<=19",
                labelrank: 'labelrank>=0;labelrank<=9'
            }
        }, {
            "title": "battlefield",
            "value": "battlefield",
            "type": "point",
            "content": "a region where a battle is being (or has been) fought",
            url: 'https://wiki.thinkgeo.com/wiki/poi',
            "filter": {
                "zoom": "zoom>=14;zoom<=19",
                labelrank: 'labelrank>=0;labelrank<=9'
            }
        }, {
            "title": "playground",
            "value": "playground",
            "type": "point",
            "content": "an area where many people go for recreation",
            url: 'https://wiki.thinkgeo.com/wiki/poi',
            "filter": {
                "zoom": "zoom>=14;zoom<=19",
                labelrank: 'labelrank>=0;labelrank<=9'
            }
        }, {
            "title": "water_park",
            "value": "water_park",
            "type": "point",
            "content": "Fun at a water park in a wave pool",
            url: 'https://wiki.thinkgeo.com/wiki/poi',
            "filter": {
                "zoom": "zoom>=14;zoom<=19",
                labelrank: 'labelrank>=0;labelrank<=9'
            }
        }, {
            "title": "fitness_centre",
            "value": "fitness_centre",
            "type": "point",
            "content": "A place with exercise machines and/or fitness/dance classes.",
            url: 'https://wiki.thinkgeo.com/wiki/poi',
            "filter": {
                "zoom": "zoom>=14;zoom<=19",
                labelrank: 'labelrank>=0;labelrank<=9'
            }
        }, {
            "title": "garden",
            "value": "garden",
            "type": "point",
            "content": "a plot of ground where plants are cultivated",
            url: 'https://wiki.thinkgeo.com/wiki/poi',
            "filter": {
                "zoom": "zoom>=14;zoom<=19",
                labelrank: 'labelrank>=0;labelrank<=9'
            }
        }, {
            "title": "marina",
            "value": "marina",
            "type": "point",
            "content": "a fancy dock for small yachts and cabin cruisers",
            url: 'https://wiki.thinkgeo.com/wiki/poi',
            "filter": {
                "zoom": "zoom>=14;zoom<=19",
                labelrank: 'labelrank>=0;labelrank<=9'
            }
        }, {
            "title": "miniature_golf",
            "value": "miniature_golf",
            "type": "point",
            "content": "A place or area that you can play miniature golf.",
            url: 'https://wiki.thinkgeo.com/wiki/poi',
            "filter": {
                "zoom": "zoom>=14;zoom<=19",
                labelrank: 'labelrank>=0;labelrank<=9'
            }
        }, {
            "title": "swimming_pool",
            "value": "swimming_pool",
            "type": "point",
            "content": "	A swimming pool (water area only).",
            url: 'https://wiki.thinkgeo.com/wiki/poi',
            "filter": {
                "zoom": "zoom>=14;zoom<=19",
                labelrank: 'labelrank>=0;labelrank<=9'
            }
        }, {
            "title": "windmill",
            "value": "windmill",
            "type": "point",
            "content": "A traditional windmill",
            url: 'https://wiki.thinkgeo.com/wiki/poi',
            "filter": {
                "zoom": "zoom>=14;zoom<=19",
                labelrank: 'labelrank>=0;labelrank<=9'
            }
        }, {
            "title": "water_tower",
            "value": "water_tower",
            "type": "point",
            "content": "a large reservoir for water",
            url: 'https://wiki.thinkgeo.com/wiki/poi',
            "filter": {
                "zoom": "zoom>=14;zoom<=19",
                labelrank: 'labelrank>=0;labelrank<=9'
            }
        }, {
            "title": "lighthouse",
            "value": "lighthouse",
            "type": "point",
            "content": "a tower with a light that gives warning of shoals to passing ships",
            url: 'https://wiki.thinkgeo.com/wiki/poi',
            "filter": {
                "zoom": "zoom>=14;zoom<=19",
                labelrank: 'labelrank>=0;labelrank<=9'
            }
        }, {
            "title": "crane",
            "value": "crane",
            "type": "point",
            "content": "A stationary, permanent crane.",
            url: 'https://wiki.thinkgeo.com/wiki/poi',
            "filter": {
                "zoom": "zoom>=14;zoom<=19",
                labelrank: 'labelrank>=0;labelrank<=9'
            }
        }, {
            "title": "mineshaft",
            "value": "mineshaft",
            "type": "point",
            "content": "Mineshafts are vertical tunnels.",
            url: 'https://wiki.thinkgeo.com/wiki/poi',
            "filter": {
                "zoom": "zoom>=14;zoom<=19",
                labelrank: 'labelrank>=0;labelrank<=9'
            }
        }, {
            "title": "bunker",
            "value": "bunker",
            "type": "point",
            "content": "	Buildings, often build from concrete, to stand even heavier fire.",
            url: 'https://wiki.thinkgeo.com/wiki/poi',
            "filter": {
                "zoom": "zoom>=14;zoom<=19",
                labelrank: 'labelrank>=0;labelrank<=9'
            }
        }, {
            "title": "cave_entrance",
            "value": "cave_entrance",
            "type": "point",
            "content": "An entrance to a cave",
            url: 'https://wiki.thinkgeo.com/wiki/poi',
            "filter": {
                "zoom": "zoom>=14;zoom<=19",
                labelrank: 'labelrank>=0;labelrank<=9'
            }
        }, {
            "title": "estate_agent",
            "value": "estate_agent",
            "type": "point",
            "content": "A place where you can rent or buy a house.",
            url: 'https://wiki.thinkgeo.com/wiki/poi',
            "filter": {
                "zoom": "zoom>=14;zoom<=19",
                labelrank: 'labelrank>=0;labelrank<=9'
            }
        }, {
            "title": "hydro",
            "value": "hydro",
            "type": "point",
            "content": "The fuel of hydro used by individual generators within the plant.",
            url: 'https://wiki.thinkgeo.com/wiki/poi',
            "filter": {
                "zoom": "zoom>=14;zoom<=19",
                labelrank: 'labelrank>=0;labelrank<=9'
            }
        }, {
            "title": "gas",
            "value": "gas",
            "type": "point",
            "content": "The fuel of gas used by individual generators within the plant.",
            url: 'https://wiki.thinkgeo.com/wiki/poi',
            "filter": {
                "zoom": "zoom>=14;zoom<=19",
                labelrank: 'labelrank>=0;labelrank<=9'
            }
        }, {
            "title": "solar",
            "value": "solar",
            "type": "point",
            "content": "	The fuel of solar used by individual generators within the plant.",
            url: 'https://wiki.thinkgeo.com/wiki/poi',
            "filter": {
                "zoom": "zoom>=14;zoom<=19",
                labelrank: 'labelrank>=0;labelrank<=9'
            }
        }, {
            "title": "wind",
            "value": "wind",
            "type": "point",
            "content": "The fuel of wind used by individual generators within the plant.",
            url: 'https://wiki.thinkgeo.com/wiki/poi',
            "filter": {
                "zoom": "zoom>=14;zoom<=19",
                labelrank: 'labelrank>=0;labelrank<=9'
            }
        }, {
            "title": "plant",
            "value": "plant",
            "type": "point",
            "content": "A place/industrial facility where power is generated.",
            url: 'https://wiki.thinkgeo.com/wiki/poi',
            "filter": {
                "zoom": "zoom>=14;zoom<=19",
                labelrank: 'labelrank>=0;labelrank<=9'
            }
        }, {
            "title": "station",
            "value": "station",
            "type": "point",
            "content": "A stationary, permanent crane.",
            url: 'https://wiki.thinkgeo.com/wiki/poi',
            "filter": {
                "zoom": "zoom>=14;zoom<=19",
                labelrank: 'labelrank>=0;labelrank<=9'
            }
        }, {
            "title": "sikh",
            "value": "sikh",
            "type": "point",
            "content": "an adherent of Sikhism",
            url: 'https://wiki.thinkgeo.com/wiki/poi',
            "filter": {
                "zoom": "zoom>=14;zoom<=19",
                labelrank: 'labelrank>=0;labelrank<=9'
            }
        }, {
            "title": "jain",
            "value": "jain",
            "type": "point",
            "content": "relating to or characteristic of Jainism",
            url: 'https://wiki.thinkgeo.com/wiki/poi',
            "filter": {
                "zoom": "zoom>=14;zoom<=19",
                labelrank: 'labelrank>=0;labelrank<=9'
            }
        }, {
            "title": "shinto",
            "value": "shinto",
            "type": "point",
            "content": "the native religion and former ethnic cult of Japan",
            url: 'https://wiki.thinkgeo.com/wiki/poi',
            "filter": {
                "zoom": "zoom>=14;zoom<=19",
                labelrank: 'labelrank>=0;labelrank<=9'
            }
        }, {
            "title": "muslim",
            "value": "muslim",
            "type": "point",
            "content": "a believer in or follower of Islam",
            url: 'https://wiki.thinkgeo.com/wiki/poi',
            "filter": {
                "zoom": "zoom>=14;zoom<=19",
                labelrank: 'labelrank>=0;labelrank<=9'
            }
        }, {
            "title": "hindu",
            "value": "hindu",
            "type": "point",
            "content": "a native or inhabitant of Hindustan or India",
            url: 'https://wiki.thinkgeo.com/wiki/poi',
            "filter": {
                "zoom": "zoom>=14;zoom<=19",
                labelrank: 'labelrank>=0;labelrank<=9'
            }
        }, {
            "title": "buddhist",
            "value": "buddhist",
            "type": "point",
            "content": "one who follows the teachings of Buddha",
            url: 'https://wiki.thinkgeo.com/wiki/poi',
            "filter": {
                "zoom": "zoom>=14;zoom<=19",
                labelrank: 'labelrank>=0;labelrank<=9'
            }
        }, {
            "title": "jewish",
            "value": "jewish",
            "type": "point",
            "content": "of or relating to Jews or their culture or religion",
            url: 'https://wiki.thinkgeo.com/wiki/poi',
            "filter": {
                "zoom": "zoom>=14;zoom<=19",
                labelrank: 'labelrank>=0;labelrank<=9'
            }
        }, {
            "title": "video",
            "value": "video",
            "type": "point",
            "content": "Shop focused on selling or renting out videos/DVDs.",
            url: 'https://wiki.thinkgeo.com/wiki/poi',
            "filter": {
                "zoom": "zoom>=14;zoom<=19",
                labelrank: 'labelrank>=0;labelrank<=9'
            }
        }, {
            "title": "toys",
            "value": "toys",
            "type": "point",
            "content": "Shop focused on selling toys.",
            url: 'https://wiki.thinkgeo.com/wiki/poi',
            "filter": {
                "zoom": "zoom>=14;zoom<=19",
                labelrank: 'labelrank>=0;labelrank<=9'
            }
        }, {
            "title": "tobacco",
            "value": "tobacco",
            "type": "point",
            "content": "Shop focused on selling tobacco, cigarettes and their related accessories.",
            url: 'https://wiki.thinkgeo.com/wiki/poi',
            "filter": {
                "zoom": "zoom>=14;zoom<=19",
                labelrank: 'labelrank>=0;labelrank<=9'
            }
        }, {
            "title": "supermarket",
            "value": "supermarket",
            "type": "point",
            "content": "Supermarket – a large store with groceries and other items",
            url: 'https://wiki.thinkgeo.com/wiki/poi',
            "filter": {
                "zoom": "zoom>=14;zoom<=19",
                labelrank: 'labelrank>=0;labelrank<=9'
            }
        }, {
            "title": "pet",
            "value": "pet",
            "type": "point",
            "content": "A shop for pets, animals and toys or food for them.",
            url: 'https://wiki.thinkgeo.com/wiki/poi',
            "filter": {
                "zoom": "zoom>=14;zoom<=19",
                labelrank: 'labelrank>=0;labelrank<=9'
            }
        }, {
            "title": "optician",
            "value": "optician",
            "type": "point",
            "content": "Shop focused on selling eyeglasses, contact lenses",
            url: 'https://wiki.thinkgeo.com/wiki/poi',
            "filter": {
                "zoom": "zoom>=14;zoom<=19",
                labelrank: 'labelrank>=0;labelrank<=9'
            }
        }, {
            "title": "newsagent",
            "value": "newsagent",
            "type": "point",
            "content": "	Shop focused on selling newspapers, cigarettes, other goods",
            url: 'https://wiki.thinkgeo.com/wiki/poi',
            "filter": {
                "zoom": "zoom>=14;zoom<=19",
                labelrank: 'labelrank>=0;labelrank<=9'
            }
        }, {
            "title": "musical_instrument",
            "value": "musical_instrument",
            "type": "point",
            "content": "	Shop focused on selling musical instruments, lyrics, scores.",
            url: 'https://wiki.thinkgeo.com/wiki/poi',
            "filter": {
                "zoom": "zoom>=14;zoom<=19",
                labelrank: 'labelrank>=0;labelrank<=9'
            }
        }, {
            "title": "motorcycle",
            "value": "motorcycle",
            "type": "point",
            "content": "a motor vehicle with two wheels and a strong frame",
            url: 'https://wiki.thinkgeo.com/wiki/poi',
            "filter": {
                "zoom": "zoom>=14;zoom<=19",
                labelrank: 'labelrank>=0;labelrank<=9'
            }
        }, {
            "title": "mobile_phone",
            "value": "mobile_phone",
            "type": "point",
            "content": "Shop focused on selling mobile phones and accessories",
            url: 'https://wiki.thinkgeo.com/wiki/poi',
            "filter": {
                "zoom": "zoom>=14;zoom<=19",
                labelrank: 'labelrank>=0;labelrank<=9'
            }
        }, {
            "title": "laundry",
            "value": "laundry",
            "type": "point",
            "content": "A shop to get your normal clothes washed.",
            url: 'https://wiki.thinkgeo.com/wiki/poi',
            "filter": {
                "zoom": "zoom>=14;zoom<=19",
                labelrank: 'labelrank>=0;labelrank<=9'
            }
        }, {
            "title": "kiosk",
            "value": "kiosk",
            "type": "point",
            "content": "small area set off by walls for special use",
            url: 'https://wiki.thinkgeo.com/wiki/poi',
            "filter": {
                "zoom": "zoom>=14;zoom<=19",
                labelrank: 'labelrank>=0;labelrank<=9'
            }
        }, {
            "title": "jewelry",
            "value": "jewelry",
            "type": "point",
            "content": "Jewellers shops",
            "filter": {
                "zoom": "zoom>=14;zoom<=19",
                labelrank: 'labelrank>=0;labelrank<=9'
            }
        }, {
            "title": "hifi",
            "value": "hifi",
            "type": "point",
            "content": "Shop focused on selling High Fidelity",
            url: 'https://wiki.thinkgeo.com/wiki/poi',
            "filter": {
                "zoom": "zoom>=14;zoom<=19",
                labelrank: 'labelrank>=0;labelrank<=9'
            }
        }, {
            "title": "hearing_aids",
            "value": "hearing_aids",
            "type": "point",
            "content": "	Shop focused on selling hearing aids devices",
            url: 'https://wiki.thinkgeo.com/wiki/poi',
            "filter": {
                "zoom": "zoom>=14;zoom<=19",
                labelrank: 'labelrank>=0;labelrank<=9'
            }
        }, {
            "title": "hairdresser",
            "value": "hairdresser",
            "type": "point",
            "content": "Here you can get your hair cut, coloured, …",
            url: 'https://wiki.thinkgeo.com/wiki/poi',
            "filter": {
                "zoom": "zoom>=14;zoom<=19",
                labelrank: 'labelrank>=0;labelrank<=9'
            }
        }, {
            "title": "greengrocer",
            "value": "greengrocer",
            "type": "point",
            "content": "Shop focused on selling vegetables and fruits.",
            url: 'https://wiki.thinkgeo.com/wiki/poi',
            "filter": {
                "zoom": "zoom>=14;zoom<=19",
                labelrank: 'labelrank>=0;labelrank<=9'
            }
        }, {
            "title": "gift",
            "value": "gift",
            "type": "point",
            "content": "hop focused on selling gifts, greeting cards, or tourist gifts (souvenirs)",
            url: 'https://wiki.thinkgeo.com/wiki/poi',
            "filter": {
                "zoom": "zoom>=14;zoom<=19",
                labelrank: 'labelrank>=0;labelrank<=9'
            }
        }, {
            "title": "garden_centre",
            "value": "garden_centre",
            "type": "point",
            "content": "Shop focused on selling potted flowers, maybe even trees",
            url: 'https://wiki.thinkgeo.com/wiki/poi',
            "filter": {
                "zoom": "zoom>=14;zoom<=19",
                labelrank: 'labelrank>=0;labelrank<=9'
            }
        }, {
            "title": "florist",
            "value": "florist",
            "type": "point",
            "content": "Shop focused on selling bouquets of flowers",
            url: 'https://wiki.thinkgeo.com/wiki/poi',
            "filter": {
                "zoom": "zoom>=14;zoom<=19",
                labelrank: 'labelrank>=0;labelrank<=9'
            }
        }, {
            "title": "doityourself",
            "value": "doityourself",
            "type": "point",
            "content": "Shop focused on selling tools and supplies to do-it-yourself householders",
            url: 'https://wiki.thinkgeo.com/wiki/poi',
            "filter": {
                "zoom": "zoom>=14;zoom<=19",
                labelrank: 'labelrank>=0;labelrank<=9'
            }
        }, {
            "title": "department_store",
            "value": "department_store",
            "type": "point",
            "content": "A single large store",
            url: 'https://wiki.thinkgeo.com/wiki/poi',
            "filter": {
                "zoom": "zoom>=14;zoom<=19",
                labelrank: 'labelrank>=0;labelrank<=9'
            }
        }, {
            "title": "copyshop",
            "value": "copyshop",
            "type": "point",
            "content": "Shop focused on selling photocopying and printing services.",
            url: 'https://wiki.thinkgeo.com/wiki/poi',
            "filter": {
                "zoom": "zoom>=14;zoom<=19",
                labelrank: 'labelrank>=0;labelrank<=9'
            }
        }, {
            "title": "convenience",
            "value": "convenience",
            "type": "point",
            "content": "a store selling a limited variety of food and pharmaceutical items;",
            url: 'https://wiki.thinkgeo.com/wiki/poi',
            "filter": {
                "zoom": "zoom>=14;zoom<=19",
                labelrank: 'labelrank>=0;labelrank<=9'
            }
        }, {
            "title": "confectionery",
            "value": "confectionery",
            "type": "point",
            "content": "Shop focused on selling sweets or candy",
            url: 'https://wiki.thinkgeo.com/wiki/poi',
            "filter": {
                "zoom": "zoom>=14;zoom<=19",
                labelrank: 'labelrank>=0;labelrank<=9'
            }
        }, {
            "title": "computer",
            "value": "computer",
            "type": "point",
            "content": "Shop focused on selling computers, peripherals, software, …",
            url: 'https://wiki.thinkgeo.com/wiki/poi',
            "filter": {
                "zoom": "zoom>=14;zoom<=19",
                labelrank: 'labelrank>=0;labelrank<=9'
            }
        }, {
            "title": "clothes",
            "value": "clothes",
            "type": "point",
            "content": "Shop focused on selling clothes",
            url: 'https://wiki.thinkgeo.com/wiki/poi',
            "filter": {
                "zoom": "zoom>=14;zoom<=19",
                labelrank: 'labelrank>=0;labelrank<=9'
            }
        }, {
            "title": "car_repair",
            "value": "car_repair",
            "type": "point",
            "content": "Shop focused on car repair",
            url: 'https://wiki.thinkgeo.com/wiki/poi',
            "filter": {
                "zoom": "zoom>=14;zoom<=19",
                labelrank: 'labelrank>=0;labelrank<=9'
            }
        }, {
            "title": "car_parts",
            "value": "car_parts",
            "type": "point",
            "content": "Shop  focused on selling auto parts",
            url: 'https://wiki.thinkgeo.com/wiki/poi',
            "filter": {
                "zoom": "zoom>=14;zoom<=19",
                labelrank: 'labelrank>=0;labelrank<=9'
            }
        }, {
            "title": "car",
            "value": "car",
            "type": "point",
            "content": "Car store – a place to buy cars or to get your car repaired",
            url: 'https://wiki.thinkgeo.com/wiki/poi',
            "filter": {
                "zoom": "zoom>=14;zoom<=19",
                labelrank: 'labelrank>=0;labelrank<=9'
            }
        }, {
            "title": "butcher",
            "value": "butcher",
            "type": "point",
            "content": "Shop focused on selling meat",
            url: 'https://wiki.thinkgeo.com/wiki/poi',
            "filter": {
                "zoom": "zoom>=14;zoom<=19",
                labelrank: 'labelrank>=0;labelrank<=9'
            }
        }, {
            "title": "books",
            "value": "books",
            "type": "point",
            "content": "Shop focused on selling books",
            url: 'https://wiki.thinkgeo.com/wiki/poi',
            "filter": {
                "zoom": "zoom>=14;zoom<=19",
                labelrank: 'labelrank>=0;labelrank<=9'
            }
        }, {
            "title": "bicycle",
            "value": "bicycle",
            "type": "point",
            "content": "Shop focused on selling bicycles and all its relate",
            url: 'https://wiki.thinkgeo.com/wiki/poi',
            "filter": {
                "zoom": "zoom>=14;zoom<=19",
                labelrank: 'labelrank>=0;labelrank<=9'
            }
        }, {
            "title": "bakery",
            "value": "bakery",
            "type": "point",
            "content": "Shop focused on selling bread",
            url: 'https://wiki.thinkgeo.com/wiki/poi',
            "filter": {
                "zoom": "zoom>=14;zoom<=19",
                labelrank: 'labelrank>=0;labelrank<=9'
            }
        }, {
            "title": "alcohol",
            "value": "alcohol",
            "type": "point",
            "content": "Shop selling alcohol to take away",
            url: 'https://wiki.thinkgeo.com/wiki/poi',
            "filter": {
                "zoom": "zoom>=14;zoom<=19",
                labelrank: 'labelrank>=0;labelrank<=9'
            }
        }, {
            "title": "tennis",
            "value": "tennis",
            "type": "point",
            "content": "Tennis",
            url: 'https://wiki.thinkgeo.com/wiki/poi',
            "filter": {
                "zoom": "zoom>=14;zoom<=19",
                labelrank: 'labelrank>=0;labelrank<=9'
            }
        }, {
            "title": "soccer",
            "value": "soccer",
            "type": "point",
            "content": "Association football, more commonly known as football or soccer",
            url: 'https://wiki.thinkgeo.com/wiki/poi',
            "filter": {
                "zoom": "zoom>=14;zoom<=19",
                labelrank: 'labelrank>=0;labelrank<=9'
            }
        }, {
            "title": "shooting",
            "value": "shooting",
            "type": "point",
            "content": "Indentifies given object as used for practicing shooting sports",
            url: 'https://wiki.thinkgeo.com/wiki/poi',
            "filter": {
                "zoom": "zoom>=14;zoom<=19",
                labelrank: 'labelrank>=0;labelrank<=9'
            }
        }, {
            "title": "motor",
            "value": "motor",
            "type": "point",
            "content": "Motorsport",
            url: 'https://wiki.thinkgeo.com/wiki/poi',
            "filter": {
                "zoom": "zoom>=14;zoom<=19",
                labelrank: 'labelrank>=0;labelrank<=9'
            }
        }, {
            "title": "ice_stock",
            "value": "ice_stock",
            "type": "point",
            "content": "ice stock sport, also known as Bavarian Curling is a winter sport",
            url: 'https://wiki.thinkgeo.com/wiki/poi',
            "filter": {
                "zoom": "zoom>=14;zoom<=19",
                labelrank: 'labelrank>=0;labelrank<=9'
            }
        }, {
            "title": "horse_racing",
            "value": "horse_racing",
            "type": "point",
            "content": "Horse racing",
            url: 'https://wiki.thinkgeo.com/wiki/poi',
            "filter": {
                "zoom": "zoom>=14;zoom<=19",
                labelrank: 'labelrank>=0;labelrank<=9'
            }
        }, {
            "title": "gymnastics",
            "value": "gymnastics",
            "type": "point",
            "content": "Gymnastics",
            url: 'https://wiki.thinkgeo.com/wiki/poi',
            "filter": {
                "zoom": "zoom>=14;zoom<=19",
                labelrank: 'labelrank>=0;labelrank<=9'
            }
        }, {
            "title": "golf",
            "value": "golf",
            "type": "point",
            "content": "course consisting of a large landscaped area for playing golf",
            url: 'https://wiki.thinkgeo.com/wiki/poi',
            "filter": {
                "zoom": "zoom>=14;zoom<=19",
                labelrank: 'labelrank>=0;labelrank<=9'
            }
        }, {
            "title": "cricket",
            "value": "cricket",
            "type": "point",
            "content": "",
            url: 'https://wiki.thinkgeo.com/wiki/poi',
            "filter": {
                "zoom": "zoom>=14;zoom<=19",
                labelrank: 'labelrank>=0;labelrank<=9'
            }
        }, {
            "title": "climbing",
            "value": "climbing",
            "type": "point",
            "content": "an event that involves rising to a higher point",
            url: 'https://wiki.thinkgeo.com/wiki/poi',
            "filter": {
                "zoom": "zoom>=14;zoom<=19",
                labelrank: 'labelrank>=0;labelrank<=9'
            }
        }, {
            "title": "canoe",
            "value": "canoe",
            "type": "point",
            "content": "small and light boat; pointed at both ends; propelled with a paddle",
            url: 'https://wiki.thinkgeo.com/wiki/poi',
            "filter": {
                "zoom": "zoom>=14;zoom<=19",
                labelrank: 'labelrank>=0;labelrank<=9'
            }
        }, {
            "title": "baseball",
            "value": "baseball",
            "type": "point",
                "content": "A bat-and-ball game played between two teams of nine players on a field",
            url: 'https://wiki.thinkgeo.com/wiki/poi',
            "filter": {
                "zoom": "zoom>=14;zoom<=19",
                labelrank: 'labelrank>=0;labelrank<=9'
            }
        }, {
            "title": "archery",
            "value": "archery",
            "type": "point",
                "content": "The art, practice, or skill of propelling arrows with the use of a bow.",
            url: 'https://wiki.thinkgeo.com/wiki/poi',
            "filter": {
                "zoom": "zoom>=14;zoom<=19",
                labelrank: 'labelrank>=0;labelrank<=9'
            }
        }, {
            "title": "swimming",
            "value": "swimming",
            "type": "point",
            "content": "A place where people do swimming",
            url: 'https://wiki.thinkgeo.com/wiki/poi',
            "filter": {
                "zoom": "zoom>=14;zoom<=19",
                labelrank: 'labelrank>=0;labelrank<=9'
            }
        }, {
            "title": "attraction",
            "value": "attraction",
            "type": "point",
                "content": "Object of interest for a tourist.",
            url: 'https://wiki.thinkgeo.com/wiki/poi',
            "filter": {
                "zoom": "zoom>=14;zoom<=19",
                labelrank: 'labelrank>=0;labelrank<=9'
            }
        }, {
            "title": "museum",
            "value": "museum",
            "type": "point",
            "content": "a depository for collecting and displaying objects having scientific or historical or artistic value",
            url: 'https://wiki.thinkgeo.com/wiki/poi',
            "filter": {
                "zoom": "zoom>=14;zoom<=19",
                labelrank: 'labelrank>=0;labelrank<=9'
            }
        }, {
            "title": "hotel",
            "value": "hotel",
            "type": "point",
                "content": "a building where travelers can pay for lodging and meals and other services",
            url: 'https://wiki.thinkgeo.com/wiki/poi',
            "filter": {
                "zoom": "zoom>=14;zoom<=19",
                labelrank: 'labelrank>=0;labelrank<=9'
            }
        }, {
            "title": "camp_site",
            "value": "camp_site",
            "type": "point",
                "content": "a site where people on holiday can pitch a tent",
            url: 'https://wiki.thinkgeo.com/wiki/poi',
            "filter": {
                "zoom": "zoom>=14;zoom<=19",
                labelrank: 'labelrank>=0;labelrank<=9'
            }
        }, {
            "title": "hostel",
            "value": "hostel",
            "type": "point",
                "content": "a hotel providing overnight lodging for travelers",
            url: 'https://wiki.thinkgeo.com/wiki/poi',
            "filter": {
                "zoom": "zoom>=14;zoom<=19",
                labelrank: 'labelrank>=0;labelrank<=9'
            }
        }, {
            "title": "motel",
            "value": "motel",
            "type": "point",
                "content": "a motor hotel",
            url: 'https://wiki.thinkgeo.com/wiki/poi',
            "filter": {
                "zoom": "zoom>=14;zoom<=19",
                labelrank: 'labelrank>=0;labelrank<=9'
            }
        }, {
            "title": "theme_park",
            "value": "theme_park",
            "type": "point",
                "content": "an amusement park that is organized around some theme",
            url: 'https://wiki.thinkgeo.com/wiki/poi',
            "filter": {
                "zoom": "zoom>=14;zoom<=19",
                labelrank: 'labelrank>=0;labelrank<=9'
            }
        }, {
            "title": "viewpoint",
            "value": "viewpoint",
            "type": "point",
            "content": "",
            url: 'https://wiki.thinkgeo.com/wiki/poi',
            "filter": {
                "zoom": "zoom>=14;zoom<=19",
                labelrank: 'labelrank>=0;labelrank<=9'
            }
        }, {
            "title": "zoo",
            "value": "zoo",
            "type": "point",
                "content": "a mental position from which things are viewed",
            url: 'https://wiki.thinkgeo.com/wiki/poi',
            "filter": {
                "zoom": "zoom>=14;zoom<=19",
                labelrank: 'labelrank>=0;labelrank<=9'
            }
        }, {
            "title": "picnic_site",
            "value": "picnic_site",
            "type": "point",
                "content": "A locality that is pleasant and suitable for outdoors eating",
            url: 'https://wiki.thinkgeo.com/wiki/poi',
            "filter": {
                "zoom": "zoom>=14;zoom<=19",
                labelrank: 'labelrank>=0;labelrank<=9'
            }
        }, {
            "title": "chalet",
            "value": "chalet",
            "type": "point",
                "content": "a Swiss house with a sloping roof and wide eaves or a house built in this style",
            url: 'https://wiki.thinkgeo.com/wiki/poi',
            "filter": {
                "zoom": "zoom>=14;zoom<=19",
                labelrank: 'labelrank>=0;labelrank<=9'
            }
        }, {
            "title": "caravan_site",
            "value": "caravan_site",
            "type": "point",
                "content": "place where you can stay in a caravan overnight or for longer periods.",
            url: 'https://wiki.thinkgeo.com/wiki/poi',
            "filter": {
                "zoom": "zoom>=14;zoom<=19",
                labelrank: 'labelrank>=0;labelrank<=9'
            }
        }, {
            "title": "artwork",
            "value": "artwork",
            "type": "point",
                "content": "A public piece of art.",
            url: 'https://wiki.thinkgeo.com/wiki/poi',
            "filter": {
                "zoom": "zoom>=14;zoom<=19",
                labelrank: 'labelrank>=0;labelrank<=9'
            }
        }, {
            "title": "alpine_hut",
            "value": "alpine_hut",
            "type": "point",
                "content": "A remote building located in the mountains intended to provide board and lodging.",
            url: 'https://wiki.thinkgeo.com/wiki/poi',
            "filter": {
                "zoom": "zoom>=14;zoom<=19",
                labelrank: 'labelrank>=0;labelrank<=9'
            }
        }, {
            "title": "information",
            "value": "information",
            "type": "point",
                "content": "Information for tourists and visitors, including information offices",
            url: 'https://wiki.thinkgeo.com/wiki/poi',
            "filter": {
                "zoom": "zoom>=14;zoom<=19",
                labelrank: 'labelrank>=0;labelrank<=9'
            }
        }, {
            "title": "coniferous",
            "value": "coniferous",
            "type": "point",
                "content": "coniferous trees are those that have cones, for example pine cone",
            url: 'https://wiki.thinkgeo.com/wiki/poi',
            "filter": {
                "zoom": "zoom>=14;zoom<=19",
                labelrank: 'labelrank>=0;labelrank<=9'
            }
        }, {
            "title": "deciduous",
            "value": "deciduous",
            "type": "point",
                "content": "deciduous trees are those who have leaves and which fall off during winter.",
            url: 'https://wiki.thinkgeo.com/wiki/poi',
            "filter": {
                "zoom": "zoom>=14;zoom<=19",
                labelrank: 'labelrank>=0;labelrank<=9'
            }
        }, {
            "title": "mixed",
            "value": "mixed",
            "type": "point",
                "content": "if the wood has both types of trees select mixed.",
            url: 'https://wiki.thinkgeo.com/wiki/poi',
            "filter": {
                "zoom": "zoom>=14;zoom<=19",
                labelrank: 'labelrank>=0;labelrank<=9'
            }
        }, {
            "title": "tram_stop",
            "value": "tram_stop",
            "type": "point",
                "content": "A tram stop is a place where a passenger can embark / disembark a tram.",
            url: 'https://wiki.thinkgeo.com/wiki/poi',
            "filter": {
                "zoom": "zoom>=14;zoom<=19",
                labelrank: 'labelrank>=0;labelrank<=9'
            }
        }, {
            "title": "subway_entrance",
            "value": "subway_entrance",
            "type": "point",
                "content": "The entrance to a subway station, usually going from surface to underground.",
            url: 'https://wiki.thinkgeo.com/wiki/poi',
            "filter": {
                "zoom": "zoom>=14;zoom<=19",
                labelrank: 'labelrank>=0;labelrank<=9'
            }
        }, {
            "title": "bbq",
            "value": "bbq",
            "type": "point",
                "content": "Barbecue is a permanently built grill for cooking food",
            url: 'https://wiki.thinkgeo.com/wiki/poi',
            "filter": {
                "zoom": "zoom>=14;zoom<=19",
                labelrank: 'labelrank>=0;labelrank<=9'
            }
        }, {
            "title": "atm",
            "value": "atm",
            "type": "point",
                "content": "an ATM or cash point",
            url: 'https://wiki.thinkgeo.com/wiki/poi',
            "filter": {
                "zoom": "zoom>=14;zoom<=19",
                labelrank: 'labelrank>=0;labelrank<=9'
            }
        }, {
            "title": "telephone",
            "value": "telephone",
            "type": "point",
                "content": "Public telephone",
            url: 'https://wiki.thinkgeo.com/wiki/poi',
            "filter": {
                "zoom": "zoom>=14;zoom<=19",
                labelrank: 'labelrank>=0;labelrank<=9'
            }
        }, {
            "title": "post_box",
            "value": "post_box",
            "type": "point",
                "content": "A box for the reception of mail.",
            url: 'https://wiki.thinkgeo.com/wiki/poi',
            "filter": {
                "zoom": "zoom>=14;zoom<=19",
                labelrank: 'labelrank>=0;labelrank<=9'
            }
        }, {
            "title": "drinking_water",
            "value": "drinking_water",
            "type": "point",
                "content": "water suitable for drinking",
            url: 'https://wiki.thinkgeo.com/wiki/poi',
            "filter": {
                "zoom": "zoom>=14;zoom<=19",
                labelrank: 'labelrank>=0;labelrank<=9'
            }
        }, {
            "title": "clock",
            "value": "clock",
            "type": "point",
                "content": "a timepiece that shows the time of day",
            url: 'https://wiki.thinkgeo.com/wiki/poi',
            "filter": {
                "zoom": "zoom>=14;zoom<=19",
                labelrank: 'labelrank>=0;labelrank<=9'
            }
        }, {
            "title": "bench",
            "value": "bench",
            "type": "point",
                "content": "A bench to sit down and relax a bit",
            url: 'https://wiki.thinkgeo.com/wiki/poi',
            "filter": {
                "zoom": "zoom>=14;zoom<=19",
                labelrank: 'labelrank>=0;labelrank<=9'
            }
        }, {
            "title": "vending_machine",
            "value": "vending_machine",
            "type": "point",
                "content": "A machine selling goods – food, tickets, newspapers, etc.",
            url: 'https://wiki.thinkgeo.com/wiki/poi',
            "filter": {
                "zoom": "zoom>=14;zoom<=19",
                labelrank: 'labelrank>=0;labelrank<=9'
            }
        }, {
            "title": "waste_disposal",
            "value": "waste_disposal",
            "type": "point",
                "content": "A place where canal boaters, caravaners,",
            url: 'https://wiki.thinkgeo.com/wiki/poi',
            "filter": {
                "zoom": "zoom>=14;zoom<=19",
                labelrank: 'labelrank>=0;labelrank<=9'
            }
        }, {
            "title": "slipway",
            "value": "slipway",
            "type": "point",
                "content": "	Boats can be launched here.",
            url: 'https://wiki.thinkgeo.com/wiki/poi',
            "filter": {
                "zoom": "zoom>=14;zoom<=19",
                labelrank: 'labelrank>=0;labelrank<=9'
            }
        }, {
            "title": "stile",
            "value": "stile",
            "type": "point",
                "content": "A structure which provides people a passage through",
            url: 'https://wiki.thinkgeo.com/wiki/poi',
            "filter": {
                "zoom": "zoom>=14;zoom<=19",
                labelrank: 'labelrank>=0;labelrank<=9'
            }
        }, {
            "title": "lift_gate",
            "value": "lift_gate",
            "type": "point",
                "content": "A bar or pole pivoted in such a way as to allow the boom",
            url: 'https://wiki.thinkgeo.com/wiki/poi',
            "filter": {
                "zoom": "zoom>=14;zoom<=19",
                labelrank: 'labelrank>=0;labelrank<=9'
            }
        }, {
            "title": "kissing_gate",
            "value": "kissing_gate",
            "type": "point",
                "content": "	A type of gate where you have to go into an enclosure and open a gate to get through.",
            url: 'https://wiki.thinkgeo.com/wiki/poi',
            "filter": {
                "zoom": "zoom>=14;zoom<=19",
                labelrank: 'labelrank>=0;labelrank<=9'
            }
        }, {
            "title": "gate",
            "value": "gate",
            "type": "point",
                "content": "A section in a wall or fence which can be opened to allow access.",
            url: 'https://wiki.thinkgeo.com/wiki/poi',
            "filter": {
                "zoom": "zoom>=14;zoom<=19",
                labelrank: 'labelrank>=0;labelrank<=9'
            }
        }, {
            "title": "bollard",
            "value": "bollard",
            "type": "point",
                "content": "One ore more solid pillar in the middle of the road to prevent passage by some traffic.",
            url: 'https://wiki.thinkgeo.com/wiki/poi',
            "filter": {
                "zoom": "zoom>=14;zoom<=19",
                labelrank: 'labelrank>=0;labelrank<=9'
            }
        }, {
            "title": "cattle_grid",
            "value": "cattle_grid",
            "type": "point",
                "content": "A short section of the roadway where there is a deep dip in the middle to prevent passage by some traffic.",
            url: 'https://wiki.thinkgeo.com/wiki/poi',
            "filter": {
                "zoom": "zoom>=14;zoom<=19",
                labelrank: 'labelrank>=0;labelrank<=9'
            }
        }, {
            "title": "block",
            "value": "block",
            "type": "point",
            "content": "One or more large immobile block(s) barring free access along a way.",
            url: 'https://wiki.thinkgeo.com/wiki/poi',
            "filter": {
                "zoom": "zoom>=14;zoom<=19",
                labelrank: 'labelrank>=0;labelrank<=9'
            }
        }, {
            "title": "pizza",
            "value": "pizza",
            "type": "point",
                "content": "Pizza",
            url: 'https://wiki.thinkgeo.com/wiki/poi',
            "filter": {
                "zoom": "zoom>=14;zoom<=19",
                labelrank: 'labelrank>=0;labelrank<=9'
            }
        }, {
            "title": "power",
            "value": "power",
            "type": "point",
                "content": "	A place/industrial facility where power is generated.",
            url: 'https://wiki.thinkgeo.com/wiki/poi',
            "filter": {
                "zoom": "zoom>=14;zoom<=19",
                labelrank: 'labelrank>=0;labelrank<=9'
            }
        }, {
            "title": "shop",
            "value": "shop",
            "type": "point",
                "content": "a mercantile establishment for the retail sale of goods or services",
            url: 'https://wiki.thinkgeo.com/wiki/poi',
            "filter": {
                "zoom": "zoom>=14;zoom<=19",
                labelrank: 'labelrank>=0;labelrank<=9'
            }
        }, {
            "title": "traffic_signals",
            "value": "traffic_signals",
            "type": "point",
                "content": "Lights that control the traffic",
            url: 'https://wiki.thinkgeo.com/wiki/poi',
            "filter": {
                "zoom": "zoom>=14;zoom<=19",
                labelrank: 'labelrank>=0;labelrank<=9'
            }
        }, {
            "title": "bus_stop",
            "value": "bus_stop",
            "type": "point",
                "content": "A small bus stop.",
            url: 'https://wiki.thinkgeo.com/wiki/poi',
            "filter": {
                "zoom": "zoom>=14;zoom<=19",
                labelrank: 'labelrank>=0;labelrank<=9'
            }
        }, {
            "title": "weir",
            "value": "weir",
            "type": "point",
                "content": "a low dam built across a stream to raise its level or divert its flow",
            url: 'https://wiki.thinkgeo.com/wiki/poi',
            "filter": {
                "zoom": "zoom>=14;zoom<=19",
                labelrank: 'labelrank>=0;labelrank<=9'
            }
        }, {
            "title": "clockwise",
            "value": "clockwise",
            "type": "point",
                "content": "running clockwise",
            url: 'https://wiki.thinkgeo.com/wiki/poi',
            "filter": {
                "zoom": "zoom>=14;zoom<=19",
                labelrank: 'labelrank>=0;labelrank<=9'
            }
        }, {
            "title": "anticlockwise",
            "value": "anticlockwise",
            "type": "point",
                "content": "running anticlockwise",
            url: 'https://wiki.thinkgeo.com/wiki/poi',
            "filter": {
                "zoom": "zoom>=14;zoom<=19",
                labelrank: 'labelrank>=0;labelrank<=9'
            }
        }, {
            "title": "guidepost",
            "value": "guidepost",
            "type": "point",
            "content": "a rule or principle that provides guidance to appropriate behavior",
            url: 'https://wiki.thinkgeo.com/wiki/poi',
            "filter": {
                "zoom": "zoom>=14;zoom<=19",
                labelrank: 'labelrank>=0;labelrank<=9'
            }
        }, {
            "title": "map",
            "value": "map",
            "type": "point",
                "content": "	A board with a map.",
            url: 'https://wiki.thinkgeo.com/wiki/poi',
            "filter": {
                "zoom": "zoom>=14;zoom<=19",
                labelrank: 'labelrank>=0;labelrank<=9'
            }
        }, {
            "title": "wayside_cross",
            "value": "wayside_cross",
            "type": "point",
                "content": "A historical cross, symbol of christian faith.",
            url: 'https://wiki.thinkgeo.com/wiki/poi',
            "filter": {
                "zoom": "zoom>=14;zoom<=19",
                labelrank: 'labelrank>=0;labelrank<=9'
            }
        }, {
            "title": "yes",
            "value": "yes",
            "type": "point",
                "content": "dispensing='yes'`",
            url: 'https://wiki.thinkgeo.com/wiki/poi',
            "filter": {
                "zoom": "zoom>=14;zoom<=19",
                labelrank: 'labelrank>=0;labelrank<=9'
            }
        }]
    }, {
        title: 'Road',
        layerName: 'road',
        content: 'The road(highway) is directly derived from the OSM highway hierarchy. ',
        url: 'https://wiki.thinkgeo.com/wiki/road',
        type: 'line',
        filter: {
            zoom: 'zoom>=5;zoom<=19',
            tunnel: 'tunnel>=0;tunnel<=1',
            bridge: 'bridge>=0;bridge<=1',
            indoor: 'indoor>=0;indoor<=1',
            oneway: 'oneway>=0;oneway<=1',
        },
        className: 'class',
        class: [{
            title: 'motorway',
            value: 'motorway',
            url: 'https://wiki.thinkgeo.com/wiki/road',
            type: 'line',
            filter: {
                zoom: 'zoom>=5;zoom<=19',
                tunnel: 'tunnel>=0;tunnel<=1',
                bridge: 'bridge>=0;bridge<=1',
                indoor: 'indoor>=0;indoor<=1',
                oneway: 'oneway>=0;oneway<=1',
            },
            content: 'a broad highway designed for high-speed traffic. '
        }, {
            title: 'trunk',
            value: 'trunk',
            url: 'https://wiki.thinkgeo.com/wiki/road',
            type: 'line',
            filter: {
                zoom: 'zoom>=7;zoom<=19',
                tunnel: 'tunnel>=0;tunnel<=1',
                bridge: 'bridge>=0;bridge<=1',
                indoor: 'indoor>=0;indoor<=1',
                oneway: 'oneway>=0;oneway<=1',
            },
            content: "A very important roads in a country's transportation system."
        }, {
            title: 'primary',
            value: 'primary',
            url: 'https://wiki.thinkgeo.com/wiki/road',
            type: 'line',
            filter: {
                zoom: 'zoom>=8;zoom<=19',
                tunnel: 'tunnel>=0;tunnel<=1',
                bridge: 'bridge>=0;bridge<=1',
                indoor: 'indoor>=0;indoor<=1',
                oneway: 'oneway>=0;oneway<=1',
            },
            content: "Less important than 'trunk', but also very important in a country's transportation system which is usually link larger towns."
        }, {
            title: 'secondary',
            value: 'secondary',
            url: 'https://wiki.thinkgeo.com/wiki/road',
            type: 'line',
            filter: {
                zoom: 'zoom>=8;zoom<=19',
                tunnel: 'tunnel>=0;tunnel<=1',
                bridge: 'bridge>=0;bridge<=1',
                indoor: 'indoor>=0;indoor<=1',
                oneway: 'oneway>=0;oneway<=1',
            },
            content: "The next important roads in a country's system which is usually link towns."
        }, {
            title: 'tertiary',
            value: 'tertiary',
            url: 'https://wiki.thinkgeo.com/wiki/road',
            type: 'line',
            filter: {
                zoom: 'zoom>=11;zoom<=19',
                tunnel: 'tunnel>=0;tunnel<=1',
                bridge: 'bridge>=0;bridge<=1',
                indoor: 'indoor>=0;indoor<=1',
                oneway: 'oneway>=0;oneway<=1',
            },
            content: "Usually link smaller towns and villages."
        }, {
            title: 'residential',
            value: 'residential',
            url: 'https://wiki.thinkgeo.com/wiki/road',
            type: 'line',
            filter: {
                zoom: 'zoom>=11;zoom<=19',
                tunnel: 'tunnel>=0;tunnel<=1',
                bridge: 'bridge>=0;bridge<=1',
                indoor: 'indoor>=0;indoor<=1',
                oneway: 'oneway>=0;oneway<=1',
            },
            content: 'Roads often lead to housing and lined with housing.'
        }, {
            title: 'living_street',
            value: 'living_street',
            url: 'https://wiki.thinkgeo.com/wiki/road',
            type: 'line',
            filter: {
                zoom: 'zoom>=11;zoom<=19',
                tunnel: 'tunnel>=0;tunnel<=1',
                bridge: 'bridge>=0;bridge<=1',
                indoor: 'indoor>=0;indoor<=1',
            },
            content: 'The residential streets that pedestrians have legal right over cars, low speed and children are allowed to play on the street.'
        }, {
            title: 'unclassified',
            value: 'unclassified',
            url: 'https://wiki.thinkgeo.com/wiki/road',
            type: 'line',
            filter: {
                zoom: 'zoom>=11;zoom<=19',
                tunnel: 'tunnel>=0;tunnel<=1',
                bridge: 'bridge>=0;bridge<=1',
                indoor: 'indoor>=0;indoor<=1',
                oneway: 'oneway>=0;oneway<=1',
            },
            content: 'Usually be used for roads used for local traffic, and for connecting others.'
        }, {
            title: 'motorway_link',
            value: 'motorway_link',
            url: 'https://wiki.thinkgeo.com/wiki/road',
            type: 'line',
            filter: {
                zoom: 'zoom>=13;zoom<=19',
                tunnel: 'tunnel>=0;tunnel<=1',
                bridge: 'bridge>=0;bridge<=1',
                indoor: 'indoor>=0;indoor<=1',
            },
            content: "A very important roads in a country's transportation system."
        }, {
            title: 'trunk_link',
            value: 'trunk_link',
            url: 'https://wiki.thinkgeo.com/wiki/road',
            type: 'line',
            filter: {
                zoom: 'zoom>=13;zoom<=19',
                tunnel: 'tunnel>=0;tunnel<=1',
                bridge: 'bridge>=0;bridge<=1',
                indoor: 'indoor>=0;indoor<=1',
            },
            content: 'The link roads (sliproads/ramps) leading to/from a trunk road from/to a trunk road or lower class highway.'
        }, {
            title: 'primary_link',
            value: 'primary_link',
            url: 'https://wiki.thinkgeo.com/wiki/road',
            type: 'line',
            filter: {
                zoom: 'zoom>=13;zoom<=19',
                tunnel: 'tunnel>=0;tunnel<=1',
                bridge: 'bridge>=0;bridge<=1',
                indoor: 'indoor>=0;indoor<=1',
            },
            content: 'The link roads (sliproads/ramps) leading to/from a primary road from/to a primary road or lower class highway.'
        }, {
            title: 'secondary_link',
            value: 'secondary_link',
            url: 'https://wiki.thinkgeo.com/wiki/road',
            type: 'line',
            filter: {
                zoom: 'zoom>=13;zoom<=19',
                tunnel: 'tunnel>=0;tunnel<=1',
                bridge: 'bridge>=0;bridge<=1',
                indoor: 'indoor>=0;indoor<=1',
            },
            content: "The link roads (sliproads/ramps) leading to/from a secondary road from/to a secondary road or lower class highway."
        }, {
            title: 'tertiary_link',
            value: 'tertiary_link',
            url: 'https://wiki.thinkgeo.com/wiki/road',
            type: 'line',
            filter: {
                zoom: 'zoom>=13;zoom<=19',
                tunnel: 'tunnel>=0;tunnel<=1',
                bridge: 'bridge>=0;bridge<=1',
                indoor: 'indoor>=0;indoor<=1',
            },
            content: "The link roads (sliproads/ramps) leading to/from a tertiary road from/to a tertiary road or lower class highway."

        }, {
            title: 'service',
            value: 'service',
            url: 'https://wiki.thinkgeo.com/wiki/road',
            type: 'line',
            filter: {
                zoom: 'zoom>=13;zoom<=19',
                tunnel: 'tunnel>=0;tunnel<=1',
                bridge: 'bridge>=0;bridge<=1',
                indoor: 'indoor>=0;indoor<=1',
            },
            content: 'Be used in conjunction to indicate the type of usage ,who can use it and in what circumstances.'
        }, {
            title: 'road',
            value: 'road',
            url: 'https://wiki.thinkgeo.com/wiki/road',
            type: 'line',
            filter: {
                zoom: 'zoom>=14;zoom<=19',
                tunnel: 'tunnel>=0;tunnel<=1',
                bridge: 'bridge>=0;bridge<=1',
                indoor: 'indoor>=0;indoor<=1',
            },
            content: 'An unknown type of road.'
        }, {
            title: 'raceway',
            value: 'raceway',
            url: 'https://wiki.thinkgeo.com/wiki/road',
            type: 'line',
            filter: {
                zoom: 'zoom>=14;zoom<=19',
                tunnel: 'tunnel>=0;tunnel<=1',
                bridge: 'bridge>=0;bridge<=1',
                indoor: 'indoor>=0;indoor<=1',
            },
            content: 'A course or track for (motor) racing'
        }, {
            title: 'pedestrian',
            value: 'pedestrian',
            url: 'https://wiki.thinkgeo.com/wiki/road',
            type: 'line',
            filter: {
                zoom: 'zoom>=14;zoom<=19',
                tunnel: 'tunnel>=0;tunnel<=1',
                bridge: 'bridge>=0;bridge<=1',
                indoor: 'indoor>=0;indoor<=1',
            },
            content: 'Mainly used for pedestrians in shopping and some of them allow access by motorised vehicles only for very limited periods of a day.'
        }, {
            title: 'cycleway',
            value: 'cycleway',
            url: 'https://wiki.thinkgeo.com/wiki/road',
            type: 'line',
            filter: {
                zoom: 'zoom>=14;zoom<=19',
                tunnel: 'tunnel>=0;tunnel<=1',
                bridge: 'bridge>=0;bridge<=1',
                indoor: 'indoor>=0;indoor<=1',
            },
            content: 'For designated cycleways.'
        }, {
            title: 'bridleway',
            value: 'bridleway',
            url: 'https://wiki.thinkgeo.com/wiki/road',
            type: 'line',
            filter: {
                zoom: 'zoom>=14;zoom<=19',
                tunnel: 'tunnel>=0;tunnel<=1',
                bridge: 'bridge>=0;bridge<=1',
                indoor: 'indoor>=0;indoor<=1',
            },
            content: '	For horses.'
        }, {
            title: 'construction',
            value: 'construction',
            url: 'https://wiki.thinkgeo.com/wiki/road',
            type: 'line',
            filter: {
                zoom: 'zoom>=14;zoom<=19',
                tunnel: 'tunnel>=0;tunnel<=1',
                bridge: 'bridge>=0;bridge<=1',
                indoor: 'indoor>=0;indoor<=1',
            },
            content: 'For roads under construction.'
        }, {
            title: 'path',
            value: 'path',
            url: 'https://wiki.thinkgeo.com/wiki/road',
            type: 'line',
            filter: {
                zoom: 'zoom>=14;zoom<=19',
                tunnel: 'tunnel>=0;tunnel<=1',
                bridge: 'bridge>=0;bridge<=1',
                indoor: 'indoor>=0;indoor<=1',
            },
            content: '	A non-specific path. '
        }, {
            title: 'track',
            value: 'track',
            url: 'https://wiki.thinkgeo.com/wiki/road',
            type: 'line',
            filter: {
                zoom: 'zoom>=14;zoom<=19',
                tunnel: 'tunnel>=0;tunnel<=1',
                bridge: 'bridge>=0;bridge<=1',
                indoor: 'indoor>=0;indoor<=1',
            },
            content: 'This tag represents roads for mostly agricultural use, forest tracks etc.; usually unpaved (unsealed) but may apply to paved tracks as well, that are suitable for two-track vehicles, such as tractors or jeeps.'
        }, {
            title: 'footway',
            value: 'footway',
            url: 'https://wiki.thinkgeo.com/wiki/road',
            type: 'line',
            filter: {
                zoom: 'zoom>=14;zoom<=19',
                tunnel: 'tunnel>=0;tunnel<=1',
                bridge: 'bridge>=0;bridge<=1',
                indoor: 'indoor>=0;indoor<=1',
                oneway: 'oneway>=0;oneway<=1',
            },
            content: 'For designated footpaths; i.e., mainly/exclusively for pedestrians. This includes walking tracks and gravel paths.'
        }, {
            title: 'steps',
            value: 'steps',
            url: 'https://wiki.thinkgeo.com/wiki/road',
            type: 'line',
            filter: {
                zoom: 'zoom>=14;zoom<=19',
                tunnel: 'tunnel>=0;tunnel<=1',
                bridge: 'bridge>=0;bridge<=1',
                indoor: 'indoor>=0;indoor<=1',
                oneway: 'oneway>=0;oneway<=1',
            },
            content: 'For flights of steps (stairs) on footways.'
        }]
    }, {
        title: 'Road_name',
        layerName: 'road_name',
        content: 'Road Name',
        className: 'class',
        url: 'https://wiki.thinkgeo.com/wiki/road_name',
        type: 'point',
        filter: {
            zoom: 'zoom>=6;zoom<=19'
        },
        class: [{
            title: 'motorway',
            value: 'motorway',
            url: 'https://wiki.thinkgeo.com/wiki/road_name',
            type: 'point',
            filter: {
                zoom: 'zoom>=6;zoom<=19',
                layer: 'layer>=-5;layer<=5',
                indoor: 'indoor>=0;indoor<=1',
            },
            content: 'A restricted access major divided highway, usually with 2 or more running path and plus a emergency hard shoulder. '
        }, {
            title: 'trunk',
            value: 'trunk',
            url: 'https://wiki.thinkgeo.com/wiki/road_name',
            type: 'point',
            filter: {
                zoom: 'zoom>=8;zoom<=19',
                layer: 'layer>=-5;layer<=5',
                indoor: 'indoor>=0;indoor<=1',
            },
            content: "A very important roads in a country's transportation system."
        }, {
            title: 'motorway_link',
            value: 'motorway_link',
            url: 'https://wiki.thinkgeo.com/wiki/road_name',
            type: 'point',
            filter: {
                zoom: 'zoom>=12;zoom<=19',
                layer: 'layer>=-5;layer<=5',
                indoor: 'indoor>=0;indoor<=1',
            },
            content: "A very important roads in a country's transportation system."
        }, {
            title: 'trunk_link',
            value: 'trunk_link',
            url: 'https://wiki.thinkgeo.com/wiki/road_name',
            type: 'point',
            filter: {
                zoom: 'zoom>=12;zoom<=19',
                layer: 'layer>=-5;layer<=5',
                indoor: 'indoor>=0;indoor<=1',
            },
            content: 'The link roads (sliproads/ramps) leading to/from a trunk road from/to a trunk road or lower class highway.'
        }, {
            title: 'primary',
            value: 'primary',
            url: 'https://wiki.thinkgeo.com/wiki/road_name',
            type: 'point',
            filter: {
                zoom: 'zoom>=12;zoom<=19',
                layer: 'layer>=-5;layer<=5',
                indoor: 'indoor>=0;indoor<=1',
            },
            content: "Less important than 'trunk', but also very important in a country's transportation system which is usually link larger towns."
        }, {
            title: 'primary_link',
            value: 'primary_link',
            url: 'https://wiki.thinkgeo.com/wiki/road_name',
            type: 'point',
            filter: {
                zoom: 'zoom>=12;zoom<=19',
                layer: 'layer>=-5;layer<=5',
                indoor: 'indoor>=0;indoor<=1',
            },
            content: 'The link roads (sliproads/ramps) leading to/from a primary road from/to a primary road or lower class highway.'
        }, {
            title: 'secondary',
            value: 'secondary',
            url: 'https://wiki.thinkgeo.com/wiki/road_name',
            type: 'point',
            filter: {
                zoom: 'zoom>=12;zoom<=19',
                layer: 'layer>=-5;layer<=5',
                indoor: 'indoor>=0;indoor<=1',
            },
            content: "The next important roads in a country's system which is usually link towns."
        }, {
            title: 'secondary_link',
            value: 'secondary_link',
            url: 'https://wiki.thinkgeo.com/wiki/road_name',
            type: 'point',
            filter: {
                zoom: 'zoom>=12;zoom<=19',
                layer: 'layer>=-5;layer<=5',
                indoor: 'indoor>=0;indoor<=1',
            },
            content: "The link roads (sliproads/ramps) leading to/from a secondary road from/to a secondary road or lower class highway."
        }, {
            title: 'tertiary',
            value: 'tertiary',
            url: 'https://wiki.thinkgeo.com/wiki/road_name',
            type: 'point',
            filter: {
                zoom: 'zoom>=12;zoom<=19',
                layer: 'layer>=-5;layer<=5',
                indoor: 'indoor>=0;indoor<=1',
            },
            content: "Usually link smaller towns and villages."
        }, {
            title: 'tertiary_link',
            value: 'tertiary_link',
            url: 'https://wiki.thinkgeo.com/wiki/road_name',
            type: 'point',
            filter: {
                zoom: 'zoom>=12;zoom<=19',
                layer: 'layer>=-5;layer<=5',
                indoor: 'indoor>=0;indoor<=1',
            },
            content: "The link roads (sliproads/ramps) leading to/from a tertiary road from/to a tertiary road or lower class highway."

        }, {
            title: 'living_street',
            value: 'living_street',
            url: 'https://wiki.thinkgeo.com/wiki/road_name',
            type: 'point',
            filter: {
                zoom: 'zoom>=12;zoom<=19',
                layer: 'layer>=-5;layer<=5',
                indoor: 'indoor>=0;indoor<=1',
            },
            content: 'The residential streets that pedestrians have legal right over cars, low speed and children are allowed to play on the street.'
        }, {
            title: 'pedestrian',
            value: 'pedestrian',
            url: 'https://wiki.thinkgeo.com/wiki/road_name',
            type: 'point',
            filter: {
                zoom: 'zoom>=12;zoom<=19',
                layer: 'layer>=-5;layer<=5',
                indoor: 'indoor>=0;indoor<=1',
            },
            content: 'Mainly used for pedestrians in shopping and some of them allow access by motorised vehicles only for very limited periods of a day.'
        }, {
            title: 'residential',
            value: 'residential',
            url: 'https://wiki.thinkgeo.com/wiki/road_name',
            type: 'point',
            filter: {
                zoom: 'zoom>=12;zoom<=19',
                layer: 'layer>=-5;layer<=5',
                indoor: 'indoor>=0;indoor<=1',
            },
            content: 'Roads which serve as an access to housing, without function of connecting settlements. Often pointd with housing.'
        }, {
            title: 'unclassified',
            value: 'unclassified',
            url: 'https://wiki.thinkgeo.com/wiki/road_name',
            type: 'point',
            filter: {
                zoom: 'zoom>=12;zoom<=19',
                layer: 'layer>=-5;layer<=5',
                indoor: 'indoor>=0;indoor<=1',
            },
                content: "Usually be used for roads used for local traffic, and for connecting others."
        }, {
            title: 'raceway',
            value: 'raceway',
            url: 'https://wiki.thinkgeo.com/wiki/road_name',
            type: 'point',
            filter: {
                zoom: 'zoom>=12;zoom<=19',
                layer: 'layer>=-5;layer<=5',
                indoor: 'indoor>=0;indoor<=1',
            },
            content: 'A course or track for (motor) racing.'
        }, {
            title: 'road',
            value: 'road',
            url: 'https://wiki.thinkgeo.com/wiki/road_name',
            type: 'point',
            filter: {
                zoom: 'zoom>=12;zoom<=19',
                layer: 'layer>=-5;layer<=5',
                indoor: 'indoor>=0;indoor<=1',
            },
            content: 'A road/way/street/motorway/etc. of unknown type. It can stand for anything ranging from a footpath to a motorway. This tag should only be used temporarily until the road/way/etc. has been properly surveyed.'
        }, {
            title: 'cycleway',
            value: 'cycleway',
            url: 'https://wiki.thinkgeo.com/wiki/road_name',
            type: 'point',
            filter: {
                zoom: 'zoom>=12;zoom<=19',
                layer: 'layer>=-5;layer<=5',
                indoor: 'indoor>=0;indoor<=1',
            },
            content: 'For designated cycleways.'
        },
        {
            title: 'construction',
            url: 'https://wiki.thinkgeo.com/wiki/road_name',
            type: 'point',
            filter: {
                zoom: 'zoom>=12;zoom<=19',
                layer: 'layer>=-5;layer<=5',
                indoor: 'indoor>=0;indoor<=1',
            },
            value: 'construction',
            content: 'For roads under construction '
        },
        {
            title: 'track',
            url: 'https://wiki.thinkgeo.com/wiki/road_name',
            type: 'point',
            filter: {
                zoom: 'zoom>=14;zoom<=19',
                layer: 'layer>=-5;layer<=5',
                indoor: 'indoor>=0;indoor<=1',
            },
            value: 'track',
            content: '	Roads for mostly agricultural or forestry uses. '
        },
        {
            title: 'footway',
            url: 'https://wiki.thinkgeo.com/wiki/road_name',
            type: 'point',
            filter: {
                zoom: 'zoom>=12;zoom<=19',
                layer: 'layer>=-5;layer<=5',
                indoor: 'indoor>=0;indoor<=1',
            },
            value: 'footway',
            content: 'For designated footpaths; i.e., mainly/exclusively for pedestrians. This includes walking tracks and gravel paths. '
        },
        {
            title: 'bridleway',
            url: 'https://wiki.thinkgeo.com/wiki/road_name',
            type: 'point',
            filter: {
                zoom: 'zoom>=12;zoom<=19',
                layer: 'layer>=-5;layer<=5',
                indoor: 'indoor>=0;indoor<=1',
            },
            value: 'bridleway',
            content: 'For horses. '
        },
        {
            title: 'steps',
            url: 'https://wiki.thinkgeo.com/wiki/road_name',
            type: 'point',
            filter: {
                zoom: 'zoom>=12;zoom<=19',
                layer: 'layer>=-5;layer<=5',
                indoor: 'indoor>=0;indoor<=1',
            },
            value: 'steps',
            content: 'For flights of steps (stairs) on footways.'
        },
        {
            title: 'path',
            url: 'https://wiki.thinkgeo.com/wiki/road_name',
            type: 'point',
            filter: {
                zoom: 'zoom>=14;zoom<=19',
                layer: 'layer>=-5;layer<=5',
                indoor: 'indoor>=0;indoor<=1',
            },
            value: 'path',
            content: 'A non-specific path.'
        },
        {
            title: 'services',
            url: 'https://wiki.thinkgeo.com/wiki/road_name',
            type: 'point',
            filter: {
                zoom: 'zoom>=12;zoom<=19',
                layer: 'layer>=-5;layer<=5',
                indoor: 'indoor>=0;indoor<=1',
            },
            value: 'services',
            content: 'A service station to get food and eat something, often found at motorways'
        }
        ]
    }, {
        title: 'Railway',
        layerName: 'railway',
        content: 'The railway is the lines which is used to represent the ways for many kinds of railways',
        className: 'class',
        url: 'https://wiki.thinkgeo.com/wiki/railway',
        type: 'line',
        filter: {
            zoom: 'zoom>=12;zoom<=19'
        },
        class: [{
            title: 'rail',
            value: 'rail',
            content: 'Full sized passenger or freight trains in the standard gauge for the country or state.',
            filter: {
                zoom: 'zoom>=12;zoom<=19'
            },
            url: 'https://wiki.thinkgeo.com/wiki/railway',
            type: 'line'
        }, {
            title: 'narrow_gauge',
            value: 'narrow_gauge',
            content: 'narrow-gauge passenger or freight or freight trains.',
            filter: {
                zoom: 'zoom>=12;zoom<=19'
            },
            url: 'https://wiki.thinkgeo.com/wiki/railway',
            type: 'line'
        }, {
            title: 'subway',
            value: 'subway',
            content: 'An underground rapid transit rail which is often used in cities.',
            filter: {
                zoom: 'zoom>=12;zoom<=19'
            },
            url: 'https://wiki.thinkgeo.com/wiki/railway',
            type: 'line'
        }, {
            title: 'light_rail',
            value: 'light_rail',
            content: 'It is neither tram or rail which is only a passenger traffic.',
            filter: {
                zoom: 'zoom>=12;zoom<=19'
            },
            url: 'https://wiki.thinkgeo.com/wiki/railway',
            type: 'line'
        }, {
            title: 'construction',
            value: 'construction',
            content: 'For the features which are currently under construction',
            filter: {
                zoom: 'zoom>=14;zoom<=19'
            },
            url: 'https://wiki.thinkgeo.com/wiki/railway',
            type: 'line'
        }],
    }, {
        title: 'Housenumber',
        layerName: 'housenumber',
        content: 'The housenumber layer contains points used for labeling the housenumbers at the centroid of the buildings.',
        url: 'https://wiki.thinkgeo.com/wiki/housenumber',
        type: 'point',
        filter: {
            zoom: 'zoom>=14;zoom<=19'
        }
    }, {
        title: 'Urban',
        layerName: 'urban',
        content: 'This is distinct from rural areas and that is means "related to cities".',
        url: 'https://wiki.thinkgeo.com/wiki/urban',
        type: 'polygon',
        filter: {
            zoom: 'zoom>=6;zoom<=19'
        }
    }, {
        title: 'Building_name',
        layerName: 'building_name',
        content: 'The name of buildings.',
        className: 'class',
        url: 'https://wiki.thinkgeo.com/wiki/building_name',
        type: 'point',
        filter: {
            zoom: 'zoom>=14;zoom<=19'
        },
        class: [{
            "title": "house",
            "value": "house",
            "type": "point",
            "content": "A single dwelling usually inhabited by a single household",
            url: "https://wiki.thinkgeo.com/wiki/building_name",
            "filter": {
                "zoom": "zoom>=14;zoom<=19"
            }
        }, {
            "title": "apartments",
            "value": "apartments",
            "type": "point",
            "content": "a suite of rooms usually on one floor of an apartment house",
            url: "https://wiki.thinkgeo.com/wiki/building_name",
            "filter": {
                "zoom": "zoom>=13;zoom<=19"
            }
        }, {
            "title": "farm",
            "value": "farm",
            "type": "point",
            "content": "an area the buildings on it, used for growing crops or keeping animals",
            url: "https://wiki.thinkgeo.com/wiki/building_name",
            "filter": {
                "zoom": "zoom>=13;zoom<=19"
            }
        }, {
            "title": "hotel",
            "value": "hotel",
            "type": "point",
            "content": "A building designed with separate rooms available for overnight accommodation.",
            url: "https://wiki.thinkgeo.com/wiki/building_name",
            "filter": {
                "zoom": "zoom>=13;zoom<=19"
            }
        }, {
            "title": "detached",
            "value": "detached",
            "type": "point",
            "content": "A free-standing residential building usually housing a single family",
            url: "https://wiki.thinkgeo.com/wiki/building_name",
            "filter": {
                "zoom": "zoom>=13;zoom<=19"
            }
        }, {
            "title": "residential",
            "value": "residential",
            "type": "point",
            "content": "A general tag for a building used primarily for residential purposes.",
            url: "https://wiki.thinkgeo.com/wiki/building_name",
            "filter": {
                "zoom": "zoom>=13;zoom<=19"
            }
        }, {
            "title": "dormitory",
            "value": "dormitory",
            "type": "point",
            "content": "For a shared building, as used by college/university students",
            url: "https://wiki.thinkgeo.com/wiki/building_name",
            "filter": {
                "zoom": "zoom>=13;zoom<=19"
            }
        }, {
            "title": "terrace",
            "value": "terrace",
            "type": "point",
            "content": "usually paved outdoor area adjoining a residence",
            url: "https://wiki.thinkgeo.com/wiki/building_name",
            "filter": {
                "zoom": "zoom>=13;zoom<=19"
            }
        }, {
            "title": "houseboat",
            "value": "houseboat",
            "type": "point",
            "content": "A boat used primarily as a home",
            url: "https://wiki.thinkgeo.com/wiki/building_name",
            "filter": {
                "zoom": "zoom>=13;zoom<=19"
            }
        }, {
            "title": "bungalow",
            "value": "bungalow",
            "type": "point",
            "content": "A single-storey detached small house, Dacha.",
            url: "https://wiki.thinkgeo.com/wiki/building_name",
            "filter": {
                "zoom": "zoom>=13;zoom<=19"
            }
        }, {
            "title": "static_caravan",
            "value": "static_caravan",
            "type": "point",
            "content": "A mobile home (semi)permanently left on a single site",
            url: "https://wiki.thinkgeo.com/wiki/building_name",
            "filter": {
                "zoom": "zoom>=13;zoom<=19"
            }
        }, {
            "title": "cabin",
            "value": "cabin",
            "type": "point",
            "content": "A cabin is a small, roughly built house usually with a wood exterior",
            url: "https://wiki.thinkgeo.com/wiki/building_name",
            "filter": {
                "zoom": "zoom>=13;zoom<=19"
            }
        }, {
            "title": "commercial",
            "value": "commercial",
            "type": "point",
            "content": "A building where non-specific commercial activities take place, not necessarily an office building_name",
            url: "https://wiki.thinkgeo.com/wiki/building_name",
            "filter": {
                "zoom": "zoom>=13;zoom<=19"
            }
        }, {
            "title": "office",
            "value": "office",
            "type": "point",
            "content": "An office building.",
            url: "https://wiki.thinkgeo.com/wiki/building_name",
            "filter": {
                "zoom": "zoom>=13;zoom<=19"
            }
        }, {
            "title": "industrial",
            "value": "industrial",
            "type": "point",
            "content": "A building where some industrial process takes place.",
            url: "https://wiki.thinkgeo.com/wiki/building_name",
            "filter": {
                "zoom": "zoom>=13;zoom<=19"
            }
        }, {
            "title": "retail",
            "value": "retail",
            "type": "point",
            "content": "A building primarily used for selling goods that are sold to the public",
            url: "https://wiki.thinkgeo.com/wiki/building_name",
            "filter": {
                "zoom": "zoom>=13;zoom<=19"
            }
        }, {
            "title": "supermarket",
            "value": "supermarket",
            "type": "point",
            "content": "Introduced by Mapnik. Self-service large-area store.",
            url: "https://wiki.thinkgeo.com/wiki/building_name",
            "filter": {
                "zoom": "zoom>=13;zoom<=19"
            }
        }, {
            "title": "warehouse",
            "value": "warehouse",
            "type": "point",
            "content": "A building primarily used for the storage or goods or as part of a distribution system.",
            url: "https://wiki.thinkgeo.com/wiki/building_name",
            "filter": {
                "zoom": "zoom>=13;zoom<=19"
            }
        }, {
            "title": "kiosk",
            "value": "kiosk",
            "type": "point",
            "content": "Small retail building.",
            url: "https://wiki.thinkgeo.com/wiki/building_name",
            "filter": {
                "zoom": "zoom>=13;zoom<=19"
            }
        }, {
            "title": "religious",
            "value": "religious",
            "type": "point",
            "content": "	Unspecific religious building. Prefer more specific values if possible.",
            url: "https://wiki.thinkgeo.com/wiki/building_name",
            "filter": {
                "zoom": "zoom>=13;zoom<=19"
            }
        }, {
            "title": "cathedral",
            "value": "cathedral",
            "type": "point",
            "content": "A building that was built as a cathedral.",
            url: "https://wiki.thinkgeo.com/wiki/building_name",
            "filter": {
                "zoom": "zoom>=13;zoom<=19",
                "title": "chapel",
                "value": "chapel",
                "type": "point",
                "content": "A building that was built as a chapel.",
                url: "https://wiki.thinkgeo.com/wiki/building_name",
                "filter": {
                    "zoom": "zoom>=13;zoom<=19"
                }
            }
        }, {
            "title": "church",
            "value": "church",
            "type": "point",
            "content": "A building that was built as a church.",
            url: "https://wiki.thinkgeo.com/wiki/building_name",
            "filter": {
                "zoom": "zoom>=13;zoom<=19"
            }
        }, {
            "title": "mosque",
            "value": "mosque",
            "type": "point",
            "content": "(Islam) a Muslim place of worship that usually has a minaret",
            url: "https://wiki.thinkgeo.com/wiki/building_name",
            "filter": {
                "zoom": "zoom>=13;zoom<=19"
            }
        }, {
            "title": "temple",
            "value": "temple",
            "type": "point",
            "content": "place of worship consisting of an edifice for the worship of a deity",
            url: "https://wiki.thinkgeo.com/wiki/building_name",
            "filter": {
                "zoom": "zoom>=13;zoom<=19"
            }
        }, {
            "title": "synagogue",
            "value": "synagogue",
            "type": "point",
            "content": "(Judaism) the place of worship for a Jewish congregation",
            url: "https://wiki.thinkgeo.com/wiki/building_name",
            "filter": {
                "zoom": "zoom>=13;zoom<=19"
            }
        }, {
            "title": "shrine",
            "value": "shrine",
            "type": "point",
            "content": "a place of worship hallowed by association with some sacred thing or person",
            url: "https://wiki.thinkgeo.com/wiki/building_name",
            "filter": {
                "zoom": "zoom>=13;zoom<=19"
            }
        }, {
            "title": "bakehouse",
            "value": "bakehouse",
            "type": "point",
            "content": "a workplace where baked goods are produced or sold",
            url: "https://wiki.thinkgeo.com/wiki/building_name",
            "filter": {
                "zoom": "zoom>=13;zoom<=19"
            }
        }, {
            "title": "kindergarten",
            "value": "kindergarten",
            "type": "point",
            "content": "a preschool for children age 4 to 6 to prepare them for primary school",
            url: "https://wiki.thinkgeo.com/wiki/building_name",
            "filter": {
                "zoom": "zoom>=13;zoom<=19"
            }
        }, {
            "title": "civic",
            "value": "civic",
            "type": "point",
            "content": "officially connected with a town or city",
            url: "https://wiki.thinkgeo.com/wiki/building_name",
            "filter": {
                "zoom": "zoom>=13;zoom<=19"
            }
        }, {
            "title": "government",
            "value": "government",
            "type": "point",
            "content": "For government buildings in general, including municipal, provincial...",
            url: "https://wiki.thinkgeo.com/wiki/building_name",
            "filter": {
                "zoom": "zoom>=13;zoom<=19"
            }
        }, {
            "title": "hospital",
            "value": "hospital",
            "type": "point",
            "content": "a health facility where patients receive treatment",
            url: "https://wiki.thinkgeo.com/wiki/building_name",
            "filter": {
                "zoom": "zoom>=13;zoom<=19"
            }
        }, {
            "title": "school",
            "value": "school",
            "type": "point",
            "content": "For any generic school buildings.",
            url: "https://wiki.thinkgeo.com/wiki/building_name",
            "filter": {
                "zoom": "zoom>=13;zoom<=19"
            }
        }, {
            "title": "stadium",
            "value": "stadium",
            "type": "point",
            "content": "a large structure for open-air sports or entertainments",
            url: "https://wiki.thinkgeo.com/wiki/building_name",
            "filter": {
                "zoom": "zoom>=13;zoom<=19"
            }
        }, {
            "title": "train_station",
            "value": "train_station",
            "type": "point",
            "content": "terminal where trains load or unload passengers or goods",
            url: "https://wiki.thinkgeo.com/wiki/building_name",
            "filter": {
                "zoom": "zoom>=13;zoom<=19"
            }
        }, {
            "title": "transportation",
            "value": "transportation",
            "type": "point",
            "content": "A building related to public transport.",
            url: "https://wiki.thinkgeo.com/wiki/building_name",
            "filter": {
                "zoom": "zoom>=13;zoom<=19"
            }
        }, {
            "title": "university",
            "value": "university",
            "type": "point",
            "content": "A university building.",
            url: "https://wiki.thinkgeo.com/wiki/building_name",
            "filter": {
                "zoom": "zoom>=13;zoom<=19"
            }
        }, {
            "title": "grandstand",
            "value": "grandstand",
            "type": "point",
            "content": "The main stand, usually roofed, commanding the best view sports grounds.",
            url: "https://wiki.thinkgeo.com/wiki/building_name",
            "filter": {
                "zoom": "zoom>=13;zoom<=19"
            }
        }, {
            "title": "public",
            "value": "public",
            "type": "point",
            "content": "The main stand, usually roofed, commanding the best view for spectators at racecourses or sports grounds.",
            url: "https://wiki.thinkgeo.com/wiki/building_name",
            "filter": {
                "zoom": "zoom>=13;zoom<=19"
            }
        }, {
            "title": "toilets",
            "value": "toilets",
            "type": "point",
            "content": "	A toilet block.",
            url: "https://wiki.thinkgeo.com/wiki/building_name",
            "filter": {
                "zoom": "zoom>=13;zoom<=19"
            }
        }, {
            "title": "barn",
            "value": "barn",
            "type": "point",
            "content": "An agricultural building used for storage and as a covered workplace.",
            url: "https://wiki.thinkgeo.com/wiki/building_name",
            "filter": {
                "zoom": "zoom>=13;zoom<=19"
            }
        }, {
            "title": "bridge",
            "value": "bridge",
            "type": "point",
            "content": "A building used as a bridge.",
            url: "https://wiki.thinkgeo.com/wiki/building_name",
            "filter": {
                "zoom": "zoom>=13;zoom<=19"
            }
        }, {
            "title": "bunker",
            "value": "bunker",
            "type": "point",
            "content": "A hardened military building_name",
            url: "https://wiki.thinkgeo.com/wiki/building_name",
            "filter": {
                "zoom": "zoom>=13;zoom<=19"
            }
        }, {
            "title": "carport",
            "value": "carport",
            "type": "point",
            "content": "garage for one or two cars consisting of a flat roof supported on poles",
            url: "https://wiki.thinkgeo.com/wiki/building_name",
            "filter": {
                "zoom": "zoom>=13;zoom<=19"
            }
        }, {
            "title": "conservatory",
            "value": "conservatory",
            "type": "point",
            "content": "a greenhouse in which plants are arranged in a pleasing manner",
            url: "https://wiki.thinkgeo.com/wiki/building_name",
            "filter": {
                "zoom": "zoom>=13;zoom<=19"
            }
        }, {
            "title": "construction",
            "value": "construction",
            "type": "point",
            "content": "Used for buildings under construction.",
            url: "https://wiki.thinkgeo.com/wiki/building_name",
            "filter": {
                "zoom": "zoom>=13;zoom<=19"
            }
        }, {
            "title": "cowshed",
            "value": "cowshed",
            "type": "point",
            "content": "cowshed is a building for housing cows, usually found on farms.",
            url: "https://wiki.thinkgeo.com/wiki/building_name",
            "filter": {
                "zoom": "zoom>=13;zoom<=19"
            }
        }, {
            "title": "digester",
            "value": "digester",
            "type": "point",
            "content": "A digester is a bioreactor for the production of inflatable biogas from biomass.",
            url: "https://wiki.thinkgeo.com/wiki/building_name",
            "filter": {
                "zoom": "zoom>=13;zoom<=19"
            }
        }, {
            "title": "farm_auxiliary",
            "value": "farm_auxiliary",
            "type": "point",
            "content": "A building on a farm that is not a dwelling",
            url: "https://wiki.thinkgeo.com/wiki/building_name",
            "filter": {
                "zoom": "zoom>=13;zoom<=19"
            }
        }, {
            "title": "garage",
            "value": "garage",
            "type": "point",
            "content": "A garage is a building suitable for the storage of one",
            url: "https://wiki.thinkgeo.com/wiki/building_name",
            "filter": {
                "zoom": "zoom>=13;zoom<=19"
            }
        }, {
            "title": "garages",
            "value": "garages",
            "type": "point",
            "content": "an outbuilding(or part of a building) for housing automobiles",
            url: "https://wiki.thinkgeo.com/wiki/building_name",
            "filter": {
                "zoom": "zoom>=13;zoom<=19"
            }
        }, {
            "title": "garbage_shed",
            "value": "garbage_shed",
            "type": "point",
            "content": "A garbage shed is a building used to store waste bins.",
            url: "https://wiki.thinkgeo.com/wiki/building_name",
            "filter": {
                "zoom": "zoom>=13;zoom<=19"
            }
        }, {
            "title": "greenhouse",
            "value": "greenhouse",
            "type": "point",
            "content": "A greenhouse is a glass or plastic covered building used to grow plants.",
            url: "https://wiki.thinkgeo.com/wiki/building_name",
            "filter": {
                "zoom": "zoom>=13;zoom<=19"
            }
        }, {
            "title": "hangar",
            "value": "hangar",
            "type": "point",
            "content": "hangar is a building used for the storage of airplanes, helicopters or space-craft.",
            url: "https://wiki.thinkgeo.com/wiki/building_name",
            "filter": {
                "zoom": "zoom>=13;zoom<=19"
            }
        }, {
            "title": "hut",
            "value": "hut",
            "type": "point",
            "content": "A hut is a small and crude shelter.",
            url: "https://wiki.thinkgeo.com/wiki/building_name",
            "filter": {
                "zoom": "zoom>=13;zoom<=19"
            }
        }, {
            "title": "pavilion",
            "value": "pavilion",
            "type": "point",
            "content": "",
            url: "https://wiki.thinkgeo.com/wiki/building_name",
            "filter": {
                "zoom": "zoom>=13;zoom<=19"
            }
        }, {
            "title": "parking",
            "value": "parking",
            "type": "point",
            "content": "",
            url: "https://wiki.thinkgeo.com/wiki/building_name",
            "filter": {
                "zoom": "zoom>=13;zoom<=19"
            }
        }, {
            "title": "riding_hall",
            "value": "riding_hall",
            "type": "point",
            "content": "A sports pavilion usually with changing rooms, storage areas",
            url: "https://wiki.thinkgeo.com/wiki/building_name",
            "filter": {
                "zoom": "zoom>=13;zoom<=19"
            }
        }, {
            "title": "roof",
            "value": "roof",
            "type": "point",
            "content": "A structure that consists of a roof with open sides",
            url: "https://wiki.thinkgeo.com/wiki/building_name",
            "filter": {
                "zoom": "zoom>=13;zoom<=19"
            }
        }, {
            "title": "shed",
            "value": "shed",
            "type": "point",
            "content": "A shed is a simple, single-storey structure in a back garden",
            url: "https://wiki.thinkgeo.com/wiki/building_name",
            "filter": {
                "zoom": "zoom>=13;zoom<=19"
            }
        }, {
            "title": "sports_hall",
            "value": "sports_hall",
            "type": "point",
            "content": "A building that was built as a sports hall.",
            url: "https://wiki.thinkgeo.com/wiki/building_name",
            "filter": {
                "zoom": "zoom>=13;zoom<=19"
            }
        }, {
            "title": "stable",
            "value": "stable",
            "type": "point",
            "content": "A stable is a building where horses are kep",
            url: "https://wiki.thinkgeo.com/wiki/building_name",
            "filter": {
                "zoom": "zoom>=13;zoom<=19"
            }
        }, {
            "title": "sty",
            "value": "sty",
            "type": "point",
            "content": "A sty is a building for raising domestic pigs, usually found on farms",
            url: "https://wiki.thinkgeo.com/wiki/building_name",
            "filter": {
                "zoom": "zoom>=13;zoom<=19"
            }
        }, {
            "title": "transformer_tower",
            "value": "transformer_tower",
            "type": "point",
            "content": "A characteristic tall building comprising a distribution transformer",
            url: "https://wiki.thinkgeo.com/wiki/building_name",
            "filter": {
                "zoom": "zoom>=13;zoom<=19"
            }
        }, {
            "title": "service",
            "value": "service",
            "type": "point",
            "content": "a small unmanned building with certain machinery",
            url: "https://wiki.thinkgeo.com/wiki/building_name",
            "filter": {
                "zoom": "zoom>=13;zoom<=19"
            }
        }, {
            "title": "ruins",
            "value": "ruins",
            "type": "point",
            "content": "a ruined building_name",
            url: "https://wiki.thinkgeo.com/wiki/building_name",
            "filter": {
                "zoom": "zoom>=13;zoom<=19"
            }
        }, {
            "title": "water_tower",
            "value": "water_tower",
            "type": "point",
            "content": "A water tower",
            url: "https://wiki.thinkgeo.com/wiki/building_name",
            "filter": {
                "zoom": "zoom>=13;zoom<=19"
            }
        }, {
            "title": "yes",
            "value": "yes",
            "type": "point",
            "content": "",
            url: "https://wiki.thinkgeo.com/wiki/building_name",
            "filter": {
                "zoom": "zoom>=13;zoom<=19"
            }
        }]
    }, {
        title: 'Ocean',
        layerName: 'ocean',
        content: 'Oceans.',
        url: 'https://wiki.thinkgeo.com/wiki',
        type: 'polygon',
        filter: {
            zoom: 'zoom>=0;zoom<=19'
        }
    }]

export default sidebarLayerData;