import React, { Component } from 'react'
import { Form, Icon, Tooltip, Select, Row, Col, InputNumber, Radio } from 'antd';
import ColorPicker from '../../../tool/colorPicker';
import IconFont from '../../../tool/iconFont';
import '../index.scss'
import './index.scss'
import _ from 'lodash'
const Option = Select.Option;

// const RadioButton = Radio.Button;
// const RadioGroup = Radio.Group;


class Mask extends Component {

    constructor(props) {
        super(props);
        this.state = {
            ...this.props.activeStyle
        }
    }

    componentWillReceiveProps(nextProps) {
        const state = _.cloneDeep(this.state)
        Object.keys(state).forEach((stateKey) => {
            state[stateKey] = undefined
        })

        this.setState({ ...state, ...nextProps.activeStyle })
    }

    handleTextMaskOutlineWidth = (value) => {
        this.setState({ 'text-mask-outline-width': value }, () => {
            this.props.updatedStyle(this.state)
        });
    }

    handleTextMaskType = (value) => {
        if (this.state['text-mask-outline-width'] === undefined && this.state['text-mask-outline-color'] === undefined && this.state['text-mask-type'] !== 'none') {
            this.setState({ 'text-mask-type': value, 'text-mask-outline-width': 1, 'text-mask-outline-color': '#000000' }, () => {
                this.props.updatedStyle(this.state)
            });
        } else if (this.state['text-mask-outline-width'] === undefined && this.state['text-mask-type'] !== 'none') {
            this.setState({ 'text-mask-type': value, 'text-mask-outline-width': 1 }, () => {
                this.props.updatedStyle(this.state)
            });
        } else if (this.state['text-mask-outline-color'] === undefined && this.state['text-mask-type'] !== 'none') {
            this.setState({ 'text-mask-type': value, 'text-mask-outline-color': '#000000' }, () => {
                this.props.updatedStyle(this.state)
            });
        }else {
            this.setState({ 'text-mask-type': value }, () => {
                this.props.updatedStyle(this.state)
            });
        }

    }

    handletextMaskColor = (color) => {

        this.setState({ 'text-mask-color': color }, () => {
            this.props.updatedStyle(this.state)
        })
    }

    handletextMaskOutlineColor = (color) => {
        this.setState({ 'text-mask-outline-color': color }, () => {
            this.props.updatedStyle(this.state)
        });
    }

    handletextMaskOutlineMargin = (value, index) => {
        let marginArr = [0, 0, 0, 0]
        if (this.state['text-mask-margin']) {
            let marginStr = this.state['text-mask-margin'];
            marginArr = marginStr.split(',')
        }
        marginArr[index] = value
        this.setState({ 'text-mask-margin': `${marginArr[0]},${marginArr[1]},${marginArr[2]},${marginArr[3]}` }, () => {
            this.props.updatedStyle(this.state)
        });
    }

    render() {
        return (
            <div>
                <Form>
                    <Form.Item>
                        <Row type="flex" justify="space-around" align="middle">
                            <Col span={3} className={this.state["text-mask-outline-color"] ? "" : 'opacity06'} >
                                <Tooltip title="Outline Color of Label’s Mask"><IconFont className="style-icon" type="icon-text-mask-color1" />

                                </Tooltip>
                            </Col>
                            <Col span={8}>
                                <ColorPicker color={this.state["text-mask-outline-color"] ? this.state["text-mask-outline-color"] : '#000000'} updateColor={this.handletextMaskOutlineColor} >
                                </ColorPicker>
                            </Col>
                            <Col span={3} offset={2} className={this.state["text-mask-outline-width"] ? "" : 'opacity06'}>
                                <Tooltip title="Outline Width of Label’s Mask"><IconFont className="style-icon " type="icon-text-mask-width" />
                                </Tooltip>
                            </Col>
                            <Col span={8} className={this.state["text-mask-outline-width"] ? "" : 'opacity06'}   >
                                <InputNumber size="small" className="style-input" min={0} max={200} value={this.state["text-mask-outline-width"] ? this.state["text-mask-outline-width"] : 0} onChange={this.handleTextMaskOutlineWidth} />
                            </Col>
                        </Row>
                    </Form.Item>

                    <Form.Item>
                        <Row type="flex" justify="space-around" align="middle">
                            <Col span={3} className={this.state["text-mask-color"] ? "" : 'opacity06'}>

                                <Tooltip title="Fill Color of Label’s Mask">
                                    <IconFont type="icon-text-mask-fill-color1" className="style-icon" />
                                </Tooltip>

                            </Col>
                            <Col span={8}    >
                                <ColorPicker color={this.state["text-mask-color"] ? this.state["text-mask-color"] : '#000000'} updateColor={this.handletextMaskColor}>
                                </ColorPicker>
                            </Col>

                            <Col span={3} offset={2} className={this.state["text-mask-type"] ? "" : 'opacity06'}>
                                <Tooltip title="Mask Type of Label"><IconFont className="style-icon" type="icon-text-mask-type" />

                                </Tooltip>
                            </Col>
                            <Col span={8} className={this.state["text-mask-type"] ? "" : 'opacity06'} >
                                <Select size="small" className="style-input style-xs-selelct" value={this.state["text-mask-type"] ? this.state["text-mask-type"] : "none"} dropdownMatchSelectWidth={false} onChange={this.handleTextMaskType}  >
                                    <Option value="rectangle">Rectangle</Option>
                                    <Option value="roundedCorners">RoundedCorners</Option>
                                    <Option value="roundedEnds">RoundedEnds</Option>
                                    <Option value="circle">Circle</Option>
                                    <Option value="none">None</Option>
                                </Select>


                            </Col>

                        </Row>
                    </Form.Item>


                    <Form.Item>
                        <Row type="flex" justify="space-around" align="middle">
                            <Col span={6} className={this.state["text-mask-margin"] ? "" : 'opacity06'}>
                                <Tooltip title="Mask Margin of Label"><IconFont className="style-icon" type="icon-margin" />

                                </Tooltip>

                            </Col>

                            <Col span={18} className={'mask-margin-wrap' + " " + (this.state["text-mask-margin"] ? "" : 'opacity06')}>
                                <div className="mask-margin">
                                    <div className="mask-margin-top">
                                        <InputNumber size="small" className="style-input" min={0} max={200} value={this.state["text-mask-margin"] ? (this.state["text-mask-margin"]).split(',')[0] : 0} onChange={(value) => this.handletextMaskOutlineMargin(value, 0)} />
                                    </div>
                                    <div className="mask-margin-bottom">
                                        <InputNumber size="small" className="style-input" min={0} max={200} value={this.state["text-mask-margin"] ? (this.state["text-mask-margin"]).split(',')[2] : 0} onChange={(value) => this.handletextMaskOutlineMargin(value, 2)} />
                                    </div>
                                    <div className="mask-margin-left">
                                        <InputNumber size="small" className="style-input" min={0} max={200} value={this.state["text-mask-margin"] ? (this.state["text-mask-margin"]).split(',')[3] : 0} onChange={(value) => this.handletextMaskOutlineMargin(value, 3)} />
                                    </div>
                                    <div className="mask-margin-right">
                                        <InputNumber size="small" className="style-input" min={0} max={200} value={this.state["text-mask-margin"] ? (this.state["text-mask-margin"]).split(',')[1] : 0} onChange={(value) => this.handletextMaskOutlineMargin(value, 1)} />
                                    </div>

                                </div>
                            </Col>


                        </Row>

                    </Form.Item>

                </Form>
            </div >
        );
    }
}

export default Mask;