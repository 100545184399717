const defaultState = {
  index: -1,
  layerDatas: [],
  maxLength: 8
}

const filter = (state = defaultState, action) => {
  const {type, data} = action;

  switch (type) {
    case 'SAVE_LAYERDATA':
      return Object.assign({}, state, data);
    case 'UPDATE_INDEX':
      return Object.assign({}, state, data)
    default:
      return state;
  }
}

export default filter