import LayoutWrap from '../../component/LayoutWrap';
import { connect } from 'react-redux';

const mapStateToProps = (state) => {
	const {rootReducer} = state;

	return {
		themestyle: rootReducer.themestyle
	}
};

export default connect(mapStateToProps)(LayoutWrap);
