import React, { Component } from 'react';
import { Form, Icon, Row, Col, InputNumber, Tooltip, Switch, Radio } from 'antd';

import '../index.scss'
import IconFont from '../../../tool/iconFont';
import _ from 'lodash'
class TextPlacement extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...this.props.activeStyle
        }
    }

    componentWillReceiveProps(nextProps) {
        const state = _.cloneDeep(this.state)
        Object.keys(state).map((stateKey) => {
            state[stateKey] = undefined
        })

        this.setState({ ...state, ...nextProps.activeStyle })
    }

    handleTextMaxCharAngle = (value) => {
        this.setState({ 'text-max-char-angle': value }, () => {
            this.props.updatedStyle(this.state)
        });

    }

    handleTextForceHorizontalForLine = (value) => {
        this.setState({ 'text-force-horizontal-for-line': value }, () => {
            this.props.updatedStyle(this.state)
        });
    }

    handleTextSpacing = (value) => {
        this.setState({ 'text-spacing': value }, () => {
            this.props.updatedStyle(this.state)
        });
    }

    render() {
        return (
            <Form>
                <Form.Item>
                    <Row type="flex" className={this.state['text-max-char-angle'] === undefined ? "opacity06" : ''} >
                        <Col span={3} className={this.state["text-max-char-angle"] ? "" : 'opacity06'}  >
                            <Tooltip title="Max Angle Allowed to Draw Labels Along Road "><IconFont className="style-icon" type="icon-text-max-angle" />
                            </Tooltip> </Col>
                        <Col span={8} className={this.state["text-max-char-angle"] ? "" : 'opacity06'}>
                            <InputNumber size="small" className="style-input" min={0} max={360} value={this.state["text-max-char-angle"] ? this.state["text-max-char-angle"] : 0} onChange={this.handleTextMaxCharAngle} />
                        </Col>
                        <Col span={3} className={this.state["text-spacing"] ? "" : 'opacity06'} offset={2} >
                            <Tooltip title="Text Spacing">
                                <IconFont className="style-icon" type="icon-text-spacing" />
                            </Tooltip>
                        </Col>
                        <Col span={8} className={this.state["text-spacing"] ? "" : 'opacity06'} >
                            <InputNumber
                                size="small"
                                className="style-input"
                                min={0}
                                max={100}
                                value={this.state["text-spacing"] ? this.state["text-spacing"] : 0}
                                onChange={this.handleTextSpacing}
                            />
                        </Col>
                    </Row>
                </Form.Item>
                <Form.Item>
                    <Row type="flex" ustify="space-around" align="middle">
                        <Col span={11} className="style-label">Placement Type</Col>
                        <Col span={11} className="style-label" >
                            <Switch checkedChildren='At Point' unCheckedChildren='Along Road' checked={this.state['text-force-horizontal-for-line'] === undefined ? false : this.state['text-force-horizontal-for-line']} onChange={this.handleTextForceHorizontalForLine} />
                        </Col>
                    </Row>
                </Form.Item>
                {/* <Form.Item>
                    <Row type="flex"    className={this.state['text-force-horizontal-for-line'] === undefined ? "opacity06" : ''} >
                      
                    </Row>
                </Form.Item>          */}
            </Form>
        );
    }
}

export default TextPlacement;