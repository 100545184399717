/**
 * @description get comparative data from layer data
 */

import sidebarLayerData from '../../../data/sidebarLayerData';

export const getSourceFilter = (styleJsonFilter) => {
  if(sidebarLayerData && styleJsonFilter){
    const filtersArray = styleJsonFilter.split(';');
    const filtersArrayLength = filtersArray.length;

    const filter_0 = filtersArray[0];
    const filterArr_0 = filter_0.split('=');
    const key_0 = filterArr_0[0];
    const value_0 = filterArr_0[1];
    
    for(let i = 0; i < sidebarLayerData.length; i++){
      const item = sidebarLayerData[i];
      
      if(`'${item[key_0]}'` === value_0){
        const class_ = item.class;

        for(let j = 1; j < filtersArrayLength; j++){
          const filter = filtersArray[j];
          const filterArr = filter.split('=');
          const value = filterArr[1];
          
          for(let k = 0; k < class_.length; k++){
            if(class_[k].value === +value){
              
              return class_[k].filter;
            }
          }
        }

        return item.filter;
      }      
    }  

    return [];
  }
}
