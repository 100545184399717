const defaultState = {
  themestyle: "dark",
  json: {
    id: 'default',
    styles: []
  },
  zoom: localStorage.getItem('zoom') ? localStorage.getItem('zoom'):3 ,
  webfontLoaded: false,
  centerPoint: localStorage.getItem('centerPoint') ? JSON.parse(localStorage.getItem('centerPoint')) :[-96.8474, 32.7492],
  showImagery: false,
  titleChange: false
}

const rootReducer = (state = defaultState, action) => {
  switch (action.type) {
    case 'TOGGLE_THEME':
      return {
        ...state,
        themestyle: action.themestyle
      };

    case 'UPDATE_ZOOM':
      return {
        ...state,
        zoom: action.zoom
      };

    case 'UPDATE_JSON':{
      return {
        ...state,
        json: action.json
      }};

    case 'UPDATE_LAYER':
      return {
        ...state,
        layer: action.layer
      };

    case 'UPDATE_WEBFONTLOADED':
      return {
        ...state,
        webfontLoaded: action.webfontLoaded
      };

    case 'UPDATE_CENTER_POINT':
      return {
        ...state,
        centerPoint: action.centerPoint
      };

    case 'UPDATE_IMAGERYLALER':
      return {
        ...state,
        showImagery: action.showImagery
      };

    case 'CHANGE_TITLE_STATE':
      return {
        ...state,
        titleChange: action.titleChange
      }

    case 'UPDATE_MAP':
      return {
        ...state,
        shouldMapUpdate: action.shouldMapUpdate
      }

    default:
      return state;
  }
}

export default rootReducer