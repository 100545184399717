import React from 'react';
import { Input } from 'antd';

import './index.scss';
const Search = Input.Search;
class SearchBox extends React.Component {
	search = (e) =>{
		const { search } = this.props;
		const value = e.target.value;
		search(value)
	}

	render() {
		return (
			<Search
				placeholder="Search Layer"
				size="small"
				onChange={this.search}
				value={this.props.value}
			/>
		);
	}
}

export default SearchBox;
