import React, { Component } from "react";
import { Row, Col, Button } from 'antd';
import Point from '../layerStyle/point';
import Line from '../layerStyle/line';
import Polygon from '../layerStyle/polygon';
import AppliedZoom from './appliedZoom';
import _ from 'lodash';
import IconFont from '../tool/iconFont';
class Styles extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...props,
      enableConfirm: false
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      ...nextProps
    })
  }

  getStyles = () => {
    const { zoom, selectedKeys, resultStyle } = this.state;
    for (let i = 0; i < resultStyle.length; i++) {
      const result = resultStyle[i];
      if (result.zoom.includes(`${zoom}`)) {
        const values = result.values;
        return { ...values[selectedKeys[0]].style };
      }
    }
  }

  replaceStyle = (updatedStyle) => {
    const { zoom, selectedKeys, resultStyle } = this.state;

    for (let i = 0; i < resultStyle.length; i++) {
      const result = resultStyle[i];
      if (result.zoom.includes(`${zoom}`)) {
        const values = result.values;
        values[selectedKeys[0]].style = updatedStyle;

        break;
      }
    }
  }

  renderStyleInput = () => {
    const { type, id, pointStyle } = this.state;
    const { handleJsonViewerChange } = this.props;
    const styleObj = this.getStyles();

    if (styleObj) {
      switch (type) {
        case 'point':
          return (
            <React.Fragment>
              <Point
                activeStyle={styleObj}
                updatedStyle={this.updatedStyle}
                id={id}
                handleJsonViewerChange={handleJsonViewerChange}
                pointStyle={pointStyle}
                activeStyleJson={this.props.activeStyle}
              />
            </React.Fragment>
          )
        case 'line':
          return (
            <React.Fragment>
              <Line
                activeStyle={styleObj}
                updatedStyle={this.updatedStyle}
                handleJsonViewerChange={handleJsonViewerChange}
                id={this.state.id}
                selectedKeys={this.state.selectedKeys}
              />
            </React.Fragment>
          )

        case 'polygon':
          return (
            <React.Fragment>
              <Polygon
                activeStyle={{ ...styleObj, id }}
                updatedStyle={this.updatedStyle}
                handleJsonViewerChange={handleJsonViewerChange}
              />
            </React.Fragment>
          )

        default:
      }
    }

  }

  //Updated 
  updatedStyle = (style) => {
    const updatedStyle = _.cloneDeep(style);
    this.replaceStyle(updatedStyle);
    if (this.props.autoRefresh) {
      this.comfimUpdatedStyle()
    } else {
      this.setState({
        enableConfirm: true
      })
    }
  }

  comfimUpdatedStyle = () => {
    this.props.updateStyleJson();
    this.setState({
      enableConfirm: false
    })
  }

  render() {
    const { enableConfirm } = this.state;
    const styles = this.renderStyleInput();
    const { resultStyle, filterStandard, updateStyleJson } = this.props;

    return (
      <React.Fragment>
        {styles}
        <AppliedZoom
          resultStyle={resultStyle}
          filterStandard={filterStandard}
          updateStyleJson={updateStyleJson}
        />

        {!this.props.autoRefresh ? <Row className='addfiterItem confirm-style-btn'>
          <Col span={14} offset={10} >
            <Button
              size="small"
              disabled={!enableConfirm}
              className='confirm-btn'
              block type="primary"

              onClick={this.comfimUpdatedStyle}
            >
              <IconFont type='icon-refresh'></IconFont>
              Refresh Map
            </Button>
          </Col>
        </Row> : null}
      </React.Fragment>
    )
  }
}

export default Styles;