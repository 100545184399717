import React, { Component } from 'react';
import ScrollBar from 'react-perfect-scrollbar';
import { Button, Menu, Icon, Row, Col, List, Tooltip, Select, InputNumber, Popover, message } from 'antd';
import './index.scss';
const { Option } = Select;
export default class Detail extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  initState = (props) => {
    if (props.detail.state === 'add') {
      this.setState({
        ...props,
        editItemIndex: 0,
        editItem: this.props.detail.filter[0],
        filterStandarFlag: false,
        addItemIndex: null
      })
    } else {
      this.setState({
        ...props,
        editItemIndex: null,
        editItem: null,
        filterStandarFlag: false,
        addItemIndex: null
      })
    }
  }


  componentDidMount() {
    this.initState(this.props);
  }

  selectEditItem = (index) => {
    const { detail } = this.state;
    delete detail.state
    this.setState({ editItemIndex: index, editItem: JSON.parse(JSON.stringify(detail.filter[index])), addItemIndex: null, filterStandarFlag: false, });

  };

  addEditItem = (index) => {
    this.setState({ addItemIndex: index, filterStandarFlag: false });
    this.state.detail.state = 'addAndCondition';
  }

  saveEditItem = (index) => {
    let { editItemIndex, detail, editItem } = this.state;
    let editItem_ = JSON.parse(JSON.stringify(editItem))
    editItem = editItem.filter((editSonItem, index) => {
      return editSonItem.equalArr.length > 0 | editSonItem.maxRange | editSonItem.minRange;
    });
    let flag = true
    editItem.forEach((innerFilter) => {
      if (innerFilter.type === 'population' && innerFilter.equalArr.length === 0) {
        if (Number(innerFilter.maxRange) < Number(innerFilter.minRange) || Number(innerFilter.maxRange) === Number(innerFilter.minRange)) {
          flag = false;
          message.error('The maximum must be greater than the minimum');
        } else if (Number(innerFilter.minRange) < 0 || Number(innerFilter.maxRange < 0)) {
          flag = false;
          message.error('The value must be greater than 0');
        } else if (!innerFilter.minRange || !innerFilter.maxRange) {

          flag = false;
          message.error('Must have a value');
        } else if (innerFilter.minRange === 'null' || innerFilter.maxRange === 'null') {
          flag = false;
          message.error('Must have a value');
        }
      } else if (innerFilter.equalArr.length === 0) {
        flag = false;
        message.error('Must have a value');
      }


    })
    if (editItem.length === 0) {
      flag = false;
      message.error('Must have a value');
    }


    if (flag) {
      if (detail.state === 'add') {
        if (editItem.length > 0) {
          this.props.updatedDetail({ supId: index, value: editItem, operation: 'addDataGroup' });
          this.setState({ editItemIndex: null, editItem: null });
        } else {
          this.props.closeDetail()
        }
      } else if (detail.state === 'addOrCondition') {
        this.setState({ editItemIndex: null, editItem: null });
        if (editItem.length === 0) {
          this.state.detail.filter.splice(this.state.detail.filter.length - 1, 1);
        }
        this.props.updatedDetail({})
      } else {
        detail.filter[editItemIndex] = editItem_;
        const emptyArr = [];
        editItem_.forEach((editSonItem) => {
          if (!(editSonItem.equalArr.length > 0 | editSonItem.maxRange | editSonItem.minRange)) {
            emptyArr.push(index);
          };
        });

        if (emptyArr.length > 0) {
          emptyArr.forEach((emptyIndex, index) => {
            this.props.updatedDetail({ supId: editItemIndex, index: emptyIndex, operation: 'delete' });
          });
        }
        this.setState({ editItemIndex: null, editItem: null, addItemIndex: null });
        this.props.updatedDetail({})
      }
      delete detail.state
    }
  };

  closeEdit = () => {
    if (this.state.detail.state === 'add') {
      this.props.closeDetail()
    } else if (this.state.detail.state === 'addOrCondition') {
      this.props.detail.filter.splice(this.props.detail.filter.length - 1, 1);
    };
    delete this.state.detail.state;
    this.setState({ editItemIndex: null, editItem: null, addItemIndex: null });
  };

  updatedData = (index, value) => {
    this.state.editItem[index].equalArr = value;
    let str = ''
    this.state.editItem.forEach((item) => {
      if (item.equalArr.length > 1) {
        str += `${item.type}='${item.equalArr.join(',')}';`;
      } else {
        str += `${item.type}=${item.equalArr.join(',')};`;
      }
    })

    this.state.editItem.forEach((item) => {
      item.source = str;
    })

  };

  updatePopulation = (type, index, value) => {
    if (type === 'minRange') {
      this.state.editItem[index][type] = `${value}`;
      this.state.editItem[index].source = `population>=${this.state.editItem[index]['minRange']};population<=${this.state.editItem[index]['maxRange']};`;
    } else if (type === 'maxRange') {
      this.state.editItem[index][type] = `${value}`;
      this.state.editItem[index].source = `population>=${this.state.editItem[index]['minRange']};population<=${this.state.editItem[index]['maxRange']};`;
    } else if (type === 'equalArr') {
      this.state.editItem[index][type][0] = `${value}`;
      this.state.editItem[index].source = `population=${this.state.editItem[index].equalArr[0]};`
      delete this.state.editItem[index]['maxRange'];
      delete this.state.editItem[index]['minRange']
    }
    this.setState({})
  }

  updatePopulationType = (type, index, value, standardArrange) => {
    if (value === '=' && !standardArrange) {
      this.state.editItem[index].equalArr[0] = this.state.editItem[index][type] || 0;
      this.state.editItem[index].source = `population=${this.state.editItem[index].equalArr[0]};`
      delete this.state.editItem[index]['minRange'];
      delete this.state.editItem[index]['maxRange'];
    } else if (standardArrange) {
      if (value === '>=') {
        this.state.editItem[index]['minRange'] = this.state.editItem[index].equalArr[0];
        this.state.editItem[index]['maxRange'] = standardArrange[1];
        this.state.editItem[index].equalArr = [];
      } else if (value === '<=') {
        this.state.editItem[index]['maxRange'] = this.state.editItem[index].equalArr[0];
        this.state.editItem[index]['minRange'] = standardArrange[1];
        this.state.editItem[index].equalArr = [];
      }
      this.state.editItem[index].source = `population>=${this.state.editItem[index]['minRange']};population<=${this.state.editItem[index]['maxRange']};`;
    };
    this.setState({})
  }

  closeDetail = () => {
    if (this.state.detail.state === 'addOrCondition') {
      this.props.detail.filter.splice(this.props.detail.filter.length - 1, 1);
    };
    this.setState({ editItemIndex: null, editItem: null, addItemIndex: null, filterStandarFlag: false });
    delete this.state.detail.state;
    this.props.closeDetail()
  }

  closeStander = () => {
    this.setState({
      filterStandarFlag: false,
      addItemIndex: null
    })
  }

  updateFilterStandarFlag = (event) => {
    event.nativeEvent.stopImmediatePropagation()
    this.setState({ filterStandarFlag: !this.state.filterStandarFlag, addItemIndex: null });
    if (this.state.filterStandarFlag) {
      delete this.state.detail.state
    } else {
      this.state.detail.state = 'addOrCondition'
    }

  }

  getChildren = (type) => {
    const { detail, filterStandard, editItemIndex } = this.state;
    let standardStr = this.props.filterStandard[type];
    const standardArr = [];
    let max, min;
    standardStr.split(';').forEach((item) => {
      if (item.includes('>=')) {
        min = Number(item.split('=')[1]);
      } else if (item.includes('<=')) {
        max = Number(item.split('=')[1]);
      };
    })
    if (type === 'population') {
      standardArr[0] = min;
      standardArr[1] = max;
    } else {
      const unuseArr = [];
      const { currentZoomFilter, detailIndex } = this.props
      currentZoomFilter.values.forEach((valueItem, valueIndex) => {
        if (valueIndex === detailIndex) {
          valueItem.filter.forEach((filterItem, sonItemIndex) => {
            if (editItemIndex !== sonItemIndex && filterItem.length === 1 && filterItem[0].type === type) {
              unuseArr.push(...filterItem[0].equalArr);
            }
          })
        } else {
          valueItem.filter.forEach((filterItem) => {
            if (filterItem.length === 1 && filterItem[0].type === type) {
              unuseArr.push(...filterItem[0].equalArr);
            }
          })
        }
      })

      for (let i = min; i < max + 1; i++) {
        if (unuseArr.includes(i.toString())) {
          standardArr.push(<Option disabled={true} key={`${i}`}>{`${i}`}</Option>);
        } else {
          standardArr.push(<Option key={`${i}`}>{`${i}`}</Option>);
        }

      };
    }
    return standardArr;
  };

  getPopulation = (sourceFilterAndItem, index) => {
    let standardArrange = this.getChildren(sourceFilterAndItem.type);

    return (
      <React.Fragment>
        {sourceFilterAndItem.equalArr.length === 0 &&

          <Col span={20} >
            <p className='population-label'>population</p>
            <div className='population-input'>

              <div>
                <Select defaultValue=">=" size="small" onChange={(value) => this.updatePopulationType('minRange', index, value)} >
                  <Option value=">=">>=</Option>
                  <Option value="=">=</Option>

                </Select>
              </div>
              <div>
                <InputNumber defaultValue={0} size="small" min={0} onChange={(value) => this.updatePopulation('minRange', index, value)} value={Number(sourceFilterAndItem.minRange) ? Number(sourceFilterAndItem.minRange) : ''} step={1000} />
              </div>
            </div>
            <div className='population-input'>
              <div>
                <Select defaultValue="<=" size="small" onChange={(value) => this.updatePopulationType('maxRange', index, value)}>
                  <Option value="<=">&lt;=</Option>
                  <Option value="=">=</Option>
                </Select>
              </div>
              <div>
                <InputNumber defaultValue={0} size="small" min={0} onChange={(value) => this.updatePopulation('maxRange', index, value)} value={Number(sourceFilterAndItem.maxRange) ? Number(sourceFilterAndItem.maxRange) : ''} step={1000} />
              </div>
            </div>
          </Col>
        }
        {sourceFilterAndItem.equalArr.length > 0 &&
          <Col span={20}  >
            <div className='population-input'>
              <div>
                <Select defaultValue="=" size="small" onChange={(value) => this.updatePopulationType('equalArr', index, value, standardArrange)}>
                  <Option value=">=">>=</Option>
                  <Option value="=">=</Option>
                  <Option value="<=">&lt;=</Option>
                </Select>
              </div>
              <div>
                <InputNumber size="small" onChange={(value) => this.updatePopulation('equalArr', index, value)} min={Number(standardArrange[0])} max={Number(standardArrange[1])} defaultValue={Number(sourceFilterAndItem.equalArr[0])} step={1000} />
              </div>
            </div>

          </Col>
        }
      </React.Fragment>
    )
  };

  renderFilterItem = (sourceFilterOrItem, supId) => sourceFilterOrItem.map((sourceFilterAndItem, index) => {
    let arr = sourceFilterAndItem.equalArr.map((equal) => {
      return equal.toString();
    });

    return (<List.Item key={supId + '_' + index}>
      <Row type="flex" align="middle" className='detail-list'>
        {sourceFilterAndItem.type !== 'population' && <Col span={8}>{sourceFilterAndItem.type}</Col>}
        {sourceFilterAndItem.type !== 'population' && <Col span={this.props.detail.state ? 16 : 13}  >
          <Select
            mode="multiple"
            style={{ width: '100%' }}
            placeholder={'Please select'}
            defaultValue={arr}
            onChange={(value) => this.updatedData(index, value)}
          >
            {this.getChildren(sourceFilterAndItem.type)}
          </Select>
        </Col>}
        {
          sourceFilterAndItem.type === 'population' && this.getPopulation(sourceFilterAndItem, index)
        }
        {
          !this.props.detail.state && <Col span={2} offset={1}>
            <Tooltip title='Delete'>
              <Icon type="delete" onClick={() => this.props.updatedDetail({ supId, index, operation: 'delete' })} />
            </Tooltip>
          </Col>
        }
      </Row>
    </List.Item>)
  })

  getFilter = (detail) => (detail).map((sourceFilterOrItem, index) => {
    const typeArr = []
    sourceFilterOrItem.forEach((sonItem) => {
      typeArr.push(sonItem.type)
    })
    let sonStr = sourceFilterOrItem[0].source;


    const filterAttributeList = this.renderFilterAttributeList(this.props.filterStandard, typeArr);

    return (
      <List key={index} className='sourceFilterOrItem' bordered={true}  >
        {
          index !== this.state.editItemIndex && <List.Item key={'item' + index} title={sonStr}>
            <Row type="flex" align="middle" className='detail-list'>
              <Col span={Object.keys(this.props.filterStandard).length > 2 ? 20 : 22}>
                <p className='filter-str'> {sonStr}</p>
              </Col>
              <Col span={2}>
                <Tooltip title='Edit'>
                  <Icon type="edit" onClick={() => this.selectEditItem(index)} />
                </Tooltip>
              </Col>
              {Object.keys(this.props.filterStandard).length > 2 && <Col span={2}>
                <Tooltip title='Add'>
                  <Popover overlayClassName='filterStandardList' placement="right" content={filterAttributeList} overlayStyle={{ padding: '7px' }} trigger="click"    >
                    <Icon type="plus" onClick={() => this.addEditItem(index)} />
                  </Popover>
                </Tooltip>
              </Col>}
            </Row>
          </List.Item>
        }

        {
          index === this.state.editItemIndex ? this.renderFilterItem(this.state.editItem, index) : null
        }
        {
          index === this.state.editItemIndex ? <List.Item key={'edit'}>  <Row type="flex" align="middle">
            <Col span={2} offset={19}>
              <Tooltip title='Save'>
                <Icon type="save" onClick={() => this.saveEditItem(index)} />
              </Tooltip>
            </Col>
            <Col span={2} offset={1}>
              <Tooltip title='close'>
                <Icon type="close" onClick={this.closeEdit} />
              </Tooltip>
            </Col>
          </Row> </List.Item> : null
        }
      </List>
    )
  })

  addFilter = (e) => {
    const { detail } = this.state;
    e.persist()
    let type = e.target.innerText
    if (!e.target.classList.contains('disable-add') && e.target.nodeName === 'A') {
      let obj;
      if (detail.state === 'addOrCondition') {
        if (type !== 'population') {
          obj = [{
            type: type,
            equalArr: [],
            source: null
          }]
        } else {
          obj = [{
            type: type,
            equalArr: [],
            source: null,
            minRange: '',
            maxRange: ''
          }]
        };
        this.state.detail.filter.push(obj)
        this.setState({ editItem: obj, editItemIndex: this.state.detail.filter.length - 1, filterStandarFlag: false });
      } else if (detail.state === 'addAndCondition') {
        if (type !== 'population') {
          obj = {
            type: type,
            equalArr: [],
            source: null
          }
        } else {
          obj = {
            equalArr: [],
            source: null,
            type: type,
            minRange: '',
            maxRange: ''
          }
        }
        const editItem = JSON.parse(JSON.stringify(this.state.detail.filter[this.state.addItemIndex]))
        editItem.push(obj)
        this.setState({ editItem: editItem, editItemIndex: this.state.addItemIndex, addEditItem: null });
      }
    }

  }

  //popover about add filter
  renderFilterAttributeItem = (filterStandard, type) => Object.keys(filterStandard).map((item, index) => {
    let flag = type.find((element) => {
      return element === item
    })
    if (item !== 'zoom') {
      return (
        <List.Item key={item}  >
          <a href="#" className={flag !== undefined ? 'disable-add' : ''}>{item}</a>
        </List.Item>
      )
    }
  });

  renderFilterAttributeList = (filterStandard, type) => {
    return (
      <List onClick={this.addFilter}
        header={<div>Choose a data column</div>}
        bordered
      >
        {this.renderFilterAttributeItem(filterStandard, type)}

      </List>
    );
  };
  render() {
    const { detail, editItem, addItemIndex } = this.state;
    const filterAttributeList = this.renderFilterAttributeList(this.props.filterStandard, []);
    return (
      <React.Fragment>
        <div className='detail' id='detail'>
          <a className='back-btn' onClick={this.closeDetail}><Icon type="arrow-left" /> Back</a>
          <div>
            <ScrollBar>
              {detail && detail.filter ? this.getFilter(detail.filter) : <div></div>}
            </ScrollBar>
          </div>
          {!editItem && !addItemIndex ? <Popover overlayClassName='filterStandardList' placement="right" content={filterAttributeList} overlayStyle={{ padding: '7px' }}>
            <Button className='add-or-condition' type="primary" size={"small"} onClick={this.updateFilterStandarFlag}>Add another filter</Button>
          </Popover> : null}

        </div>


      </React.Fragment>
    )
  }
}