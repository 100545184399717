import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import {configureStore} from './redux/stores';
import LayoutContainer from './redux/container/LayoutContainer';

// import 'antd/dist/antd.css'

const store = configureStore();

ReactDOM.render(
	<Provider store={store}>
		<LayoutContainer />
	</Provider>,
	document.getElementById('root')
);
