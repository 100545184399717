import React from 'react';
import './ZoomControl.scss';

class ZoomControl extends React.Component {
	render() {
		const { zoom } = this.props;
		return (
			<div className="zoom-wrap"> {zoom} </div>
		);
	}
}

export default ZoomControl;
