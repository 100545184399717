/* eslint-disable no-loop-func */
import React, {
    Component
} from 'react';
import _ from 'lodash';
import {
    connect
} from 'react-redux';
import {
    bindActionCreators
} from 'redux';
import {
    saveLayerData
} from '../../redux/actions/redoUndo';

import Lists from './lists';
import {
    Switch
} from 'antd';
import filter from './../../redux/reducers/filter';

const enRegular = /[^a-zA-Z]/g;
const numRegular = /[^\d]/g;
const speNumRegular = /[^(\d|,)]/g;
class FilterLayerWrap extends Component {
    constructor(props) {
        super(props);
        this.state = {
            resultStyle: [],
            shouldSaveInitialStyle: true,
            initialStyle: JSON.parse(JSON.stringify(props.activeStyle)),
            stylejsonIsHaveClass: false,
            ...props
        }
    }

    initialState = (props) => {
        this.getData(props);
    }

    componentDidMount() {
        this.initialState(this.props);
    }

    componentWillReceiveProps(nextProps) {
        const {
            activeStyle
        } = nextProps;
        if (this.props.id !== nextProps.id ||
            this.props.zoom !== nextProps.zoom ||
            activeStyle !== this.state.activeStyle ||
            (
                this.props.activeStyle.id === nextProps.eyeClickedParent &&
                this.props.eyeClicked !== nextProps.eyeClicked
            )
        ) {
            this.setState({
                ...nextProps,
                initialStyle: JSON.parse(JSON.stringify(nextProps.activeStyle)),
                shouldSaveInitialStyle: true,
            })
            this.initialState(nextProps);
        }
    }

    getStyleAttribute = (style) => {
        let styleArr = {};
        Object.keys(style).forEach((styleKey) => {
            if (styleKey !== 'filter' && styleKey !== 'id' && styleKey !== 'style' && styleKey !== 'visible' && styleKey !== 'z-index') {
                styleArr[styleKey] = style[styleKey];
            }
        })

        return styleArr;
    }

    // FIXME rewrite the method
    getfilterItem = (filter, sonItemStyle, nextProps) => {
        let arr = [];
        let types = [];
        let itemStyle = {
            filter: []
        }

        if (sonItemStyle) {
            itemStyle.style = sonItemStyle;
        }

        filter.split(";").forEach((item) => {
            if (item) {
                arr.push(item);
                types.push(item.replace(enRegular, ''));
            }
        })

        let sourceArr = [];
        filter.split(';').forEach(item => {
            if (item && !item.includes('zoom')) {
                sourceArr.push(item);
            }
        })

        types = Array.from(new Set(types));
        types.forEach((type) => {
            let obj = {
                type,
                equalArr: [],
                source: sourceArr.join(';')
            }
            let range = {};

            arr.forEach((item) => {
                if (item.replace(enRegular, '') === type) {
                    if (item.includes('<')) {
                        range.maxRange = item.replace(numRegular, '')
                    } else if (item.includes('>')) {
                        range.minRange = item.replace(numRegular, '')
                    } else if (item.includes('=')) {
                        obj.equalArr.push(...(item.replace(speNumRegular, '').split(',')))
                    }
                }
            });
            if (range.maxRange && !range.minRange) {
                range.minRange = nextProps.filterStandard[type].minRange
            } else if (!range.maxRange && range.minRange) {

                range.maxRange = nextProps.filterStandard[type].maxRange
            }
            if (range.maxRange && range.minRange && type !== 'population') {
                for (let index = Number(range.minRange); index < Number(range.maxRange) + 1; index++) {
                    obj.equalArr.push(index)
                }
                obj.equalArr.sort((m, n) => {
                    if (m < n) return -1
                    else if (m > n) return 1
                    else return 0
                });
            } else if (range.maxRange && range.minRange && type === 'population') {
                obj.maxRange = range.maxRange
                obj.minRange = range.minRange
            } else if (obj.equalArr.length > 0 && type === 'population') {
                range.equalArr = [...obj.equalArr]
            }

            itemStyle.filter.push(obj)
        })

        return itemStyle
    }

    getFilter = (filter, sonItemStyle, nextProps, pFilter) => {
        let arr = [];
        const filterArr = this.splitFilterByZoom(filter);
        const pFilter_ = _.cloneDeep(pFilter);

        filterArr.forEach((item) => {
            let zoomFilter = item.zoomFilter;
            let normalFilter = item.normalFilter;

            const filterStr = (zoomFilter && zoomFilter.includes('zoom')) ? zoomFilter : normalFilter;
            const filterItem = this.getfilterItem(filterStr, sonItemStyle, nextProps);

            if (pFilter_) {
                pFilter_.forEach(pItem => {
                    filterItem.filter.push(...pItem.filter);
                });
            }

            arr.push(filterItem);
        });

        return arr;
    }

    getSonStyle = (style, publicStyle, nextProps) => {
        let resultStyleArr = this.getFilterFromStyle(style, publicStyle, nextProps);
        let resultStyle = this.transformFilterByZoom(resultStyleArr);

        //update the select option that can not be selected
        const {
            zoom
        } = nextProps;
        this.setState({
            resultStyle,
            addSate: null,
            // confirmState: true,
            zoom
        });
    }

    getFilterFromStyle = (style, publicStyle, nextProps, pFilter) => {
        let resultStyleArr = [];

        style.forEach((item) => {
            if (item.filter && !item.filter.includes('class') && !item.filter.includes('admin_level')) {
                if (item.style) {
                    let publicStyle_ = {
                        ...publicStyle,
                        ...this.getStyleAttribute(item)
                    };
                    let pFilter_ = this.getFilter(item.filter, null, nextProps, pFilter);
                    const filterStyle = this.getFilterFromStyle(item.style, publicStyle_, nextProps, pFilter_);

                    resultStyleArr.push(...filterStyle);
                } else {
                    let sonItemStyle = {
                        ...publicStyle,
                        ...this.getStyleAttribute(item)
                    };
                    const filterStyle = this.getFilter(item.filter, sonItemStyle, nextProps, pFilter);
                    resultStyleArr.push(...filterStyle);
                }
            }
        });

        return resultStyleArr;
    }

    splitFilterByZoom = (filter) => {
        // if(!filter.includes('zoom')){
        //     return [{
        //         zoomFilter: null,
        //         normalFilter: filter
        //     }];
        // }

        const orFilter = filter.split('|');
        let zoomFilter = '';
        let normalFilter = '';
        let filterArr = [];

        orFilter.forEach((sonFilterItem, index) => {
            const andFilter = sonFilterItem.split(';');
            zoomFilter = '';
            normalFilter = '';

            andFilter.forEach(item => {

                if (sonFilterItem.includes('zoom')) {
                    zoomFilter += `${item};`;
                } else if (sonFilterItem) {
                    normalFilter += `${item};`;
                }
            });
            normalFilter = normalFilter.substring(0, normalFilter.length - 1);


            filterArr.push({
                zoomFilter,
                normalFilter
            })
        })

        return filterArr;
    }

    // restruct filters
    transformFilterByZoom = (resultStyleArr) => {
        let resultStyleObj = {};

        resultStyleArr.forEach((resultStyle) => {
            this.classifyFilterByZoom(_.cloneDeep(resultStyle), resultStyleObj);
        })

        // merge zoom to the needs
        return this.mergeFiter(resultStyleObj);
    }

    classifyFilterByZoom = (item, resultStyleObj) => {
        let zoomValues = [];
        let shouldBeDeleted = [];
        let filter = item.filter;

        filter.forEach((subFilter, index) => {
            const type = subFilter.type;
            if (type === 'zoom') {
                const zoomValue = subFilter.equalArr.join();
                if (!resultStyleObj[zoomValue]) {
                    resultStyleObj[zoomValue] = [];
                }

                if (!zoomValues.includes(zoomValue)) {
                    zoomValues.push(zoomValue);
                }

                shouldBeDeleted.push(index);
            }
        });

        // delete zoom
        for (let i = 0; i < shouldBeDeleted.length; i += 1) {
            let filter = item.filter;
            delete filter[shouldBeDeleted[i]];
        }

        item.filter = item.filter.filter(item_ => item_);
        zoomValues.forEach(zoomItem => {
            resultStyleObj[zoomItem].push(item);
        })
    }

    // merge zoom
    mergeFiter = (resultStyleObj) => {
        const zoomKeys = Object.keys(resultStyleObj);
        let tmpZoomKeys = [];

        for (let i = 0; i < zoomKeys.length; i++) {
            let zoomItem = zoomKeys[i].split(',');

            if (tmpZoomKeys.length === 0) {
                tmpZoomKeys.push(zoomKeys[i]);
            }

            let repeatedZoom = [];
            let tmpZoomItem = [];

            let tmpZoomKeysToSingleArray = tmpZoomKeys.join().split(',');
            zoomItem.forEach(item => {
                if (tmpZoomKeysToSingleArray.includes(item)) {
                    repeatedZoom.push(item);
                } else {
                    tmpZoomItem.push(item);
                }
            });

            // update tmpZoomKeys
            tmpZoomKeys.forEach((tmpItem, index) => {
                const tmpZoomArr = tmpItem.split(',');
                let splitRepeatedZoom = [];
                let splitUnRepeatedZoom = [];

                tmpZoomArr.forEach(item => {
                    if (repeatedZoom.includes(item)) {
                        splitRepeatedZoom.push(item);
                    } else {
                        splitUnRepeatedZoom.push(item);
                    }
                });

                if (splitUnRepeatedZoom.length && splitRepeatedZoom.length) {
                    delete tmpZoomKeys[index];
                    tmpZoomKeys.splice(index, 1, splitRepeatedZoom.join());
                    tmpZoomKeys.push(splitUnRepeatedZoom.join());
                }
            })

            tmpZoomItem.length && tmpZoomKeys.push(tmpZoomItem.join());
        }

        return this.setFilterObj(resultStyleObj, tmpZoomKeys);
    }

    setFilterObj = (resultStyleObj, zoomKeys) => {
        let restructedArr = [];
        let tmpRestructedArr = [];
        let restructedObj = {};
        let obj;
        let currentItem, nextItem;
        let currentStyle, nextStyle;
        let valueObj;

        zoomKeys.forEach((zoomKey) => {
            obj = {
                zoom: [...zoomKey.split(',')],
                values_: [],
                values: []
            }

            for (let key in resultStyleObj) {
                if (this.isSplitZoomInFilterZoom(zoomKey.split(','), key.split(','))) {
                    const resultStyleObjItem = resultStyleObj[key];
                    obj.values_.push(...resultStyleObjItem);
                }
            }
            tmpRestructedArr.push(obj);
        });

        // classify filter by style
        tmpRestructedArr.forEach(item => {
            const values_ = item.values_;
            restructedObj = {
                zoom: item.zoom,
                values: []
            }

            for (let i = 0; i < values_.length; i++) {
                currentItem = values_[i];
                if (currentItem) {
                    currentStyle = currentItem.style;
                    valueObj = {
                        filter: [currentItem.filter],
                        style: currentItem.style
                    };

                    for (let j = i + 1; j < values_.length; j++) {
                        nextItem = values_[j];
                        if (nextItem) {
                            nextStyle = nextItem.style;

                            if (this.isSameStyle(currentStyle, nextStyle)) {
                                valueObj.filter.push(nextItem.filter);
                                delete values_[j];
                            }
                        }
                    }
                    restructedObj.values.push(valueObj);
                }
            }

            restructedArr.push(restructedObj);
        })

        return restructedArr;
    }

    isSplitZoomInFilterZoom = (splitZooms, filterZooms) => {
        for (let i = 0; i < splitZooms.length; i++) {
            if (filterZooms.includes(splitZooms[i])) {
                return true;
            }
        }

        return false;
    }

    isSameStyle = (current, next) => {
        let currentLength = Object.keys(current).length;
        let nextLength = Object.keys(next).length;

        if (currentLength !== nextLength) {
            return false;
        }

        for (let key in current) {
            if (key === 'children') {
                for (let i = 0; i < current[key].length; i++) {
                    for (let j = 0; j < next[key].length; j++) {
                        if (!this.isSameStyle(current[key][i], next[key][j])) {
                            return false;
                        }
                    }
                }
            } else if (current[key] !== next[key]) {
                return false;
            }
        }

        return true;
    }

    findStyle = (value, style, publicStyle, nextProps) => {
        if (style.filter) {
            const valueTmp = style.filter.split("=")[1];
            const filterValues = valueTmp.replace(/\'/g, "");
            // const splitValues = valueTmp.split(',');
            // if (style.filter.split("=")[1] && (filterValues == value || (filterValues !== undefined && splitValues.includes(value))) && 
            if (style.filter.split("=")[1] && filterValues == value && !style.filter.includes('layerName')) {
                if (style.style) {
                    let publicStyleAdd = {
                        ...publicStyle,
                        ...this.getStyleAttribute(style)
                    };
                    this.getSonStyle(style.style, publicStyleAdd, nextProps)
                    this.setState({
                        stylejsonIsHaveClass: true
                    })
                }
            } else {
                if (style.style) {
                    style.style.forEach((item) => {
                        this.findStyle(value, item, publicStyle, nextProps)
                    })
                }
            }
        }
    }

    /**
     * Split multiple classes
     * eg:
     * @param{object,object}
     */
    splitClass = (style, value) => {
        if (style.style) {
            let styleArr = [...style.style];
            const styleSplitClass = []
            style.style.forEach((styleItem, index) => {
                if (styleItem.filter) {
                    styleItem.filter.split(';').forEach((filterItem) => {
                        if (filterItem.includes('class') || filterItem.includes('admin_level')) {
                            let classStr = filterItem.split("=")[1].replace(/\'/g, "")

                            if (classStr.split(',').length > 1 && classStr.includes(value)) {
                                styleArr = styleArr.filter(styleArrItem => styleArrItem.filter !== styleItem.filter);
                                classStr.split(',').forEach((classItem) => {
                                    let afterSplit = _.cloneDeep(styleItem)
                                    let arr = [];
                                    let classStr = null;
                                    afterSplit.filter.split(';').forEach((updatedfilter) => {
                                        if (filterItem.includes('class') || filterItem.includes('admin_level')) {
                                            classStr = filterItem.includes('class') ? `class='${classItem}'` : `admin_level='${classItem}'`
                                        } else {
                                            arr.push(updatedfilter)
                                        }
                                    })

                                    if (arr.length > 1) {
                                        afterSplit.filter = arr.join(';') + classStr
                                    } else if (arr.length === 1) {
                                        afterSplit.filter = arr.join(';') + ';' + classStr
                                    } else {
                                        afterSplit.filter = classStr
                                    }

                                    styleSplitClass.push(afterSplit)
                                })
                            }
                        }
                    })
                }
            })
            style.style = [...styleArr, ...styleSplitClass]
        }
    }

    /**
     * Convert stylejson to the new data format
     * @param{object,boolean} 
     */
    getData = (nextProps) => {
        this.setState({
            stylejsonIsHaveClass: false
        })
        let filter = nextProps.activeStyle.filter
        let sonClass = null
        let splitClassData = JSON.parse(JSON.stringify(nextProps.activeStyle))
        this.splitClass(splitClassData, nextProps.value)
        let publicStyle = this.getStyleAttribute(splitClassData)
        this.setState({
            stylejsonData: splitClassData
        });

        filter.split(';').map((item) => {
            if (item && !item.includes('layerName')) {
                sonClass = item
            }
        })

        if (nextProps.id === splitClassData.id || (sonClass && sonClass.includes(nextProps.value))) {
            if (splitClassData.style) {
                this.getSonStyle(splitClassData.style, publicStyle, nextProps)
                this.setState({
                    stylejsonIsHaveClass: true
                })
            }
        } else {
            this.findStyle(nextProps.value, splitClassData, publicStyle, nextProps)
        }
    }

    findClass = (value, style, styleArr) => {
        if (style.filter) {
            if (style.filter.split("=")[1].replace(/\'/g, "") == value && !style.filter.includes('layerName')) {
                Object.keys(style).forEach((styleKeyItem) => {
                    if (styleKeyItem != "filter" && styleKeyItem != "style" && styleKeyItem != "visible") {
                        delete style[styleKeyItem]
                    }
                })
                style.style = [...styleArr]
            } else {
                if (style.style) {
                    style.style.forEach((item) => {
                        this.findClass(value, item, styleArr)
                    })
                }
            }
        }
    }

    switchResultStyleToJsonStyle = () => {

        const {
            resultStyle
        } = this.state;

        let styles = [];
        let obj, subObj;
        resultStyle.forEach(item => {
            obj = {};
            // obj.filter = `zoom>=${item.zoom[0]};zoom<=${item.zoom[item.zoom.length-1]}`;
            obj.filter = `zoom=${item.zoom.join()}`;
            obj.style = [];
            item.values.forEach(value => {
                subObj = {
                    ...value.style
                };
                subObj.filter = this.getCombinedFilter(value.filter);
                if (!subObj.filter) {
                    obj = {
                        ...subObj,
                        ...obj
                    };
                    delete obj.style;
                } else {
                    obj.style.push(subObj);
                }
            });

            styles.push(obj);
        });

        return styles;
    }

    getCombinedFilter = (filter) => {
        let item, current, currentType;
        let filterStr = '';
        let subFilterStr = '';
        // let tmpObj;

        for (let i = 0; i < filter.length; i++) {
            item = filter[i];
            if (item && item[0]) {
                // tmpObj = {};
                subFilterStr = item[0].source;

                // and filter
                for (let j = 0; j < item.length; j++) {
                    current = item[j]
                    currentType = current.type;
                    // if (!tmpObj[currentType]) {
                    //     tmpObj[currentType] = [];
                    // }
                    // if (currentType === 'population') {
                    // tmpObj[currentType].push(current.source);
                    // } else {
                    // tmpObj[currentType].push(...current.equalArr);
                    // }
                }

                // for (let key in tmpObj) {
                //     subFilterStr += `${subFilterStr ? ';' : ''}${key}=${tmpObj[key].join()}`;
                // }

                filterStr += `${filterStr ? '|' : ''}${subFilterStr}`;
            }

        }

        return filterStr;
    }

    updateStyleJson = (operatType=undefined) => {

        const {
            id,
            stylejsonData,
            value,
            selectedKeys,
            menuTitle,
            stylejsonIsHaveClass,
            type,
            pointStyle
        } = this.state;

        const style = this.switchResultStyleToJsonStyle();
        const stylejsonDataCopy = _.cloneDeep(stylejsonData);


        if (id === stylejsonData.id && !value) {
            stylejsonDataCopy.style = [...style];
        } else if ((value && stylejsonData.filter.includes('class')) || stylejsonData.filter.includes('admin_level')) {
            stylejsonDataCopy.style = [...style];
        } else if (value || value === 0) {
            if (stylejsonIsHaveClass) {
                stylejsonData.style.forEach((styleItem) => {
                    this.findClass(value, styleItem, style);
                })
            } else {
                let className = stylejsonDataCopy.id === 'country_boundary' || stylejsonDataCopy.id === 'province_boundary' ? 'admin_level' : 'class';
                const obj = {
                    filter: `${className}='${value}'`,
                    style: style
                }
                if (type === 'point') {
                    switch (stylejsonData.id) {
                        case ' POI_icon':
                            break;
                        case 'place_name':
                            stylejsonDataCopy["shield-name"] = "name_en";
                            break;
                        default:
                            stylejsonDataCopy["text-name"] = "name_en";
                            break;
                    }
                }

                stylejsonData.style.push(obj);
            }


            stylejsonDataCopy.style = [...stylejsonData.style];
        }

        const {
            initialZoom,
            centerPoint
        } = this.props;
        const {
            shouldSaveInitialStyle,
            initialStyle
        } = this.state;
        const cloneDeepStyleJsonData = {
            type: 'layerStyle',
            data: _.cloneDeep(stylejsonDataCopy),
            mapState: {
                zoom: initialZoom,
                center: centerPoint
            },
            sidebarState: {
                selectedKeys,
                menuTitle,
                type,
                value,
                pointStyle
            }
        };

        const dataArr = shouldSaveInitialStyle ? [{
            type: 'initialLayerStyle',
            data: initialStyle,
            mapState: {
                zoom: initialZoom,
                center: centerPoint
            },
            sidebarState: {
                selectedKeys,
                menuTitle,
                type,
                value,
                pointStyle
            }
        }, cloneDeepStyleJsonData] : [cloneDeepStyleJsonData];
        this.saveLayerData(dataArr);
        // this.props.handleCurrentZoom(isCurrentZoomFlag)

        this.props.UpdateActiveStyle(stylejsonDataCopy)
        this.props.updatedStyleJson(stylejsonDataCopy, operatType);
    }

    // For redo undo
    saveLayerData = (layerData) => {
        const {
            index,
            maxLength,
            layerDatas
        } = this.props;

        this.props.saveLayerData(layerData, index, maxLength, layerDatas);

        this.setState({
            shouldSaveInitialStyle: false
        })
    }

    getSelectedStyles = () => {
        const {
            selectedKeys,
            resultStyle
        } = this.state;
        let styles_ = {};

        for (let i = 0; i < resultStyle.length; i++) {
            const style = resultStyle[i];
            const resultArr = style.filter;
            for (let j = 0; j < resultArr.length; j++) {
                const result = resultArr[j];
                if (selectedKeys.includes(result.id)) {
                    const styles = result.style;
                    styles_ = styles.reduce((accumulator, currentValue) => {
                        return {
                            ...accumulator,
                            ...currentValue
                        }
                    }, {});
                    break;
                }
            }
        }
        return styles_;
    }

    getPointStyle = (resultStyle) => {
        let pointStyle = 'text';
        if (Array.isArray(resultStyle) && resultStyle.length > 0) {
            Object.keys(resultStyle[0].values[0].style).forEach((styleKey) => {
                if (styleKey.includes('text')) {
                    pointStyle = 'text';
                    return
                } else if (styleKey.includes('point')) {
                    pointStyle = 'point';
                    return
                } else if (styleKey.includes('shield')) {
                    pointStyle = 'shield';
                    return
                }
            })
        }
        return pointStyle
    };

    getPointStyleWithoutClass = (activeStyle) => {
        let pointStyle = 'text';
        switch (activeStyle.id) {
            case ' POI_icon':
                pointStyle = 'point';
                break;
            case 'place_name':
                pointStyle = 'shield';
                break;
            default:
                pointStyle = 'text';
                break;
        }
        return pointStyle
    }


    updatedWithOutStyle = (e) => {
        const {
            type,
            resultStyle,
            id,
            stylejsonIsHaveClass,
            activeStyle
        } = this.state;
        let resultStyle_ = JSON.parse(JSON.stringify(resultStyle));
        let styleObj = {};
        let {
            zoom
        } = this.props;
        let obj = null;
        let pointStyle
        if (e) {
            if (!stylejsonIsHaveClass) {
                resultStyle_ = []
            }
            if (localStorage.getItem('initializationStyle')) {
                let initializationStyle = JSON.parse(localStorage.getItem('initializationStyle'));
                if (initializationStyle[id]) {
                    if (type === 'point') {

                        pointStyle = this.getPointStyle(initializationStyle[id]);

                    }
                    initializationStyle[id].forEach((initItem) => {

                        if (initItem.zoom.includes(String(zoom))) {
                            obj = {
                                values: JSON.parse(JSON.stringify(initItem.values)),
                                zoom: [String(zoom)]
                            }

                        }
                    })
                }
            }

            if (!obj) {
                if (type === 'point') {
                    if (stylejsonIsHaveClass) {
                        pointStyle = this.getPointStyle(resultStyle);
                    } else {
                        pointStyle = this.getPointStyleWithoutClass(activeStyle);
                    }


                    switch (pointStyle) {
                        case 'text':
                            styleObj = {
                                "text-font": "500 14px Arial, Helvetica, sans-serif",
                                "text-fill": "#cccccc"
                            }
                            break;
                        case 'point':
                            styleObj = {
                                "point-fill": "#cccccc"
                            }
                            break;
                        case 'shield':
                            styleObj = {
                                "shield-font": "500 14px Arial, Helvetica, sans-serif",
                                "shield-fill": "#cccccc"
                            }
                            break;
                        default:
                            break;
                    }
                } else {
                    switch (type) {
                        case 'line':
                            styleObj = {
                                "line-width": 1,
                                "line-color": "#cccccc"
                            }
                            break;
                        case 'polygon':
                            styleObj = {
                                "polygon-fill": "#cccccc"
                            }
                            break;

                        default:
                            styleObj = {
                                "text-font": "500 14px Arial, Helvetica, sans-serif"
                            }
                            break;
                    }
                }
                obj = {
                    zoom: [String(zoom)],
                    values: [{
                        filter: [
                            []
                        ],
                        style: {
                            ...styleObj
                        }
                    }]
                };
            }

            resultStyle_.push(obj);
        } else {
            let zoomIndex = resultStyle_.findIndex((element) => {
                return element.zoom.includes(String(zoom));
            });

            if (localStorage.getItem('initializationStyle')) {
                let initializationStyle = JSON.parse(localStorage.getItem('initializationStyle'));
                if (!initializationStyle[id]) {
                    initializationStyle[id] = resultStyle;
                    localStorage.setItem('initializationStyle', JSON.stringify(initializationStyle));
                }
            } else {
                let initializationStyle = {};
                initializationStyle[id] = resultStyle
                localStorage.setItem('initializationStyle', JSON.stringify(initializationStyle));
            }

            if (resultStyle_.length > 0) {
                resultStyle_.splice(zoomIndex, 1)
            }
        }

        this.setState({
            resultStyle: resultStyle_,
            pointType: pointStyle
        }, () => {

            this.updateStyleJson('enable')
        })

    }



    render() {
        let {
            activeStyle,
            filterStandard,
            resultStyle,
            type,
            id,
            pointType,
            stylejsonIsHaveClass
        } = this.state;

        let {
            zoom
        } = this.props;
        let flag = -1;
        if (resultStyle.length > 0) {
            flag = resultStyle.findIndex((element) => {
                return element.zoom.includes(String(zoom))
            })
        }

        pointType = this.getPointStyle(resultStyle);
 
        return (
            <React.Fragment >
                <div className={flag > -1 && stylejsonIsHaveClass ? 'switch-style' : 'switch-style-open'}>
                    <Switch checked={flag > -1 && stylejsonIsHaveClass ? true : false} onClick={this.updatedWithOutStyle} checkedChildren="Enabled" unCheckedChildren="Disable" />
                </div>
                {flag > -1 && stylejsonIsHaveClass ? <Lists type={type}
                    activeStyle={activeStyle}
                    resultStyle={resultStyle}
                    pointStyle={pointType}
                    filterStandard={filterStandard}
                    updateStyleJson={this.updateStyleJson}
                    autoRefresh={this.props.autoRefresh}
                    handleJsonViewerChange={this.props.handleJsonViewerChange}
                    selectLayerInfor={this.props.selectLayerInfor}
                    id={this.props.id}> </Lists> : null}

            </React.Fragment>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    const {
        rootReducer,
        filter
    } = state;


    return ({
        zoom: Math.floor(rootReducer.zoom),
        initialZoom: rootReducer.zoom,
        centerPoint: rootReducer.centerPoint,
        index: filter.index,
        layerDatas: filter.layerDatas,
        maxLength: filter.maxLength,
        json: rootReducer.json
    })
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        saveLayerData
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(FilterLayerWrap);