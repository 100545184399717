import React from 'react';
import { Avatar, Typography, message } from 'antd';
import { updateJson, changeTitleState } from '../../../redux/actions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import './index.scss';
const { Title } = Typography;

const avatarImageSrc = require('../../../images/thinkgeo-logo@2x.png');
const error = () => {
	message.error('Title could not be empty!');
};
class AvatarTitle extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			title: this.props.json.id
		};
	}

	shouldComponentUpdate(nextProps, nextState) {
		if (nextProps.json.id !== this.props.json.id || nextState.title !== this.state.title) {
			this.setState({
				title: nextProps.json.id
			});
			return true;
		} 
		return false;
	}

	changeTitle = (str) => {
		if (str === '') {
			error();
		} else {
			const { json, changeTitleState } = this.props;
			const json_ = JSON.parse(JSON.stringify(json));
			if (str !== json_.id) {
				const { handleJsonChange } = this.props;
				json_.id = str;
				changeTitleState(true);
				handleJsonChange(json_);
			}
		}
	};

	render() {
		const { title } = this.state;
		let titleStr = '';
		title.split('-').forEach(element => {
			titleStr += element.substring(0, 1).toUpperCase() + element.substring(1) +' '
		});
		return (
			<div className="avatar-title">
				<Avatar size={48} src={avatarImageSrc} />
				<Title level={3} editable={{onChange: this.changeTitle }} ellipsis={true}>
					{titleStr}
				</Title>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({ json: state.rootReducer.json });

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			handleJsonChange: updateJson,
			changeTitleState: changeTitleState
		},
		dispatch
	);
};

export default connect(mapStateToProps, mapDispatchToProps)(AvatarTitle);
