import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { Icon, Tooltip, Switch  } from 'antd';
import ImportStyle from './importStyle';
import IconFont from '../tool/iconFont';
import { handleRedoUndo } from '../../redux/actions/redoUndo';
import './index.scss';
import { bindActionCreators } from 'redux';
import SaveStyle from './saveStyle';
import FindPlace from './findPlace';

import './index.scss';
class TopMenuItems extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			index: -1,
			layerDatas: [],
			json: {},
			searching: false
		 
		};
	}

	componentDidMount(){
		document.onkeydown = (e) => {
			if ( e.ctrlKey && e.keyCode === 90 ) {
				const undoDisabled = this.isUndoDisabled();
				this.handleUndo(undoDisabled);
			} 
			else if( e.ctrlKey && e.keyCode === 89 ) {
				const redoDisabled = this.isRedoDisabled();
				this.handleRedo(redoDisabled);
			}
		}
	}

	componentWillReceiveProps(nextProps) {
		const { index, json, layerDatas, maxLength } = nextProps;

		this.setState({
			index,
			json,
			layerDatas,
			maxLength
		});
	}

	updateJson = (layerData, json) => {
		if (!layerData) {
			return false;
		}

		// The initial styleJson
		if (layerData.type === 'initStyleJson') {
			return _.cloneDeep(layerData.data);
		} else if (layerData.type === 'layerOrder') {
			// drag layers
			const json_ = _.cloneDeep(json);

			json_.layers[0].styles = [ ...layerData.data ];

			return json_;
		} else if (
			layerData.type === 'layerStyle' ||
			layerData.type === 'initialLayerStyle' ||
			layerData.type === 'visibleStyle' ||
			layerData.type === 'initialVisibleStyle'
		) {
			// change the layer style
			const json_ = _.cloneDeep(json);
			const data = _.cloneDeep(layerData.data);
			const id = data.id;
			const styles = json_.styles;
			let style;

			for (let i = 0; i < styles.length; i++) {
				style = styles[i];
				if (style.id === id) {
					styles[i] = data;

					return json_;
				}
			}
		}

		return false;
	};

	handleRedoUndo = (num) => {
		const { index, json, layerDatas } = this.state;
		let index_ = index + num;
		const layerData = layerDatas[index_];
		const json_ = this.updateJson(layerData, json);

		this.setState(
			{
				index: index_,
				json: json_
			},
			() => {
				if (this.timeoutId) {
					clearTimeout(this.timeoutId);
				}
				this.timeoutId = setTimeout(() => {
					this.props.handleRedoUndo(index_, json_);
				}, 100);
			}
		);
	};

	handleRedo = (disabled) => {
		if (!disabled) {
			const { layerDatas, index } = this.state;
			const layerData = layerDatas[index + 1];
			let num = 1;
			if (layerData && layerData.type === 'initialVisibleStyle') {
				num += 1;
			}
			this.handleRedoUndo(num);
		}
	};

	handleUndo = (disabled) => {
		if (!disabled) {
			const { layerDatas, index } = this.state;

			const layerData = layerDatas[index];
			let num = -1;
			if (layerData && layerData.type === 'initialVisibleStyle') {
				num -= 1;
			}
			this.handleRedoUndo(num);
		}
	};

	isUndoDisabled = () => {
		const { index } = this.state;

		return index <= 0;
	};

	isRedoDisabled = () => {
		const { index, maxLength, layerDatas } = this.state;

		return index === maxLength - 1 || index === layerDatas.length - 1;
	};

	toggleSearch = (bool) => {
		if (bool) {
			this.input.focus();
		}
		this.setState({
			searching: bool
		});
	};

	updatedAutoRefresh=(e)=>{
		this.props.updatedAutoRefresh(e)
	}

	render() {
		const { handleGeocodingPlaceChange, handleRefreshViewChange } = this.props;
		const { searching } = this.state;
		const undoDisabled = this.isUndoDisabled();
		const redoDisabled = this.isRedoDisabled();
		const inputing = searching ? 'inputing' : '';

		return (
			<div className="top-menu-item-group">
				<div className={`top-menu-item search-place ${inputing}`}>
					<FindPlace
						handleGeocodingPlaceChange={handleGeocodingPlaceChange}
						handleRefreshViewChange={handleRefreshViewChange}
						toggleSearch={this.toggleSearch}
						inputRef={(input) => {
							this.input = input;
						}}
					/>
					<Tooltip
						placement="bottom"
						onClick={() => {
							this.toggleSearch(true);
						}}
					>
						<Icon type="search" />
						<span>Search</span>
					</Tooltip>
				</div>
				<div className={`top-menu-item auto-refresh`}>
					<Tooltip placement="bottom">
						<div className='input-check' >
							<Switch size="small" checked={this.props.autoRefresh} onClick={this.updatedAutoRefresh}  />
						</div>
						<span>Auto-Refresh</span>
					</Tooltip>
				</div>
				<div
					className={`top-menu-item undo ${undoDisabled ? 'disabled' : ''}`}
					onClick={() => this.handleUndo(undoDisabled)}
				>
					<Tooltip placement="bottom">
						<IconFont type="icon-undo" />
						<span>Undo</span>
					</Tooltip>
				</div>
				<div
					className={`top-menu-item redo ${redoDisabled ? 'disabled' : ''}`}
					onClick={() => this.handleRedo(redoDisabled)}
				>
					<Tooltip placement="bottom">
						<IconFont type="icon-redo" />
						<span>Redo</span>
					</Tooltip>
				</div>
				<ImportStyle />
				<SaveStyle />
				<div className="top-menu-item">
					<Tooltip placement="bottom">
						<a href="https://wiki.thinkgeo.com/wiki/thinkgeo_stylejson" target="_blank" rel="noopener">
							<Icon type="question" />
							<span>Help</span>
						</a>
					</Tooltip>
				</div>
				{/* <ShareStyle /> */}
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	const { rootReducer, filter } = state;

	return {
		json: rootReducer.json,
		index: filter.index,
		maxLength: filter.maxLength,
		layerDatas: filter.layerDatas
	};
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			handleRedoUndo
		},
		dispatch
	);
};

export default connect(mapStateToProps, mapDispatchToProps)(TopMenuItems);
