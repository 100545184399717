import React, { Component } from 'react';
// import { SketchPicker } from 'react-color'
import './index.scss'
import ColorPicker from '../../../tool/colorPicker';
import { Form, Tooltip, Row, Col, InputNumber } from 'antd'
import IconFont from '../../../tool/iconFont';
import _ from 'lodash'
// const RadioButton = Radio.Button;
// const RadioGroup = Radio.Group;

// const props = {
//     action: '//jsonplaceholder.typicode.com/posts/',
//     listType: 'picture',
// }
class PolygonContent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...this.props.activeStyle,
        }
    }

    componentWillReceiveProps(nextProps) {
        const state = _.cloneDeep(this.state)
        Object.keys(state).forEach((stateKey) => {
            state[stateKey] = undefined
        })

        this.setState({
            ...state,
            ...nextProps.activeStyle
        })
    }

    handlePolygonFill = (color) => {
        this.setState({ 'polygon-fill': color }, () => {
            this.props.updatedStyle(this.state)
        });
    }

    handlePolygonOpacity = (value) => {
        this.setState({ 'polygon-opacity': value }, () => {
            this.props.updatedStyle(this.state)
        });

    }

    // handlePolygonOutlineWidth = (value) => {
    //     this.setState({ 'polygon-outline-width': value },()=>{
    //         this.props.updatedStyle(this.state)
    //     });

    // }

    // handlePolygonOutlineColor = (color) => {
    //     this.setState({ 'polygon-outline-color': color },()=>{
    //         this.props.updatedStyle(this.state)
    //     });
    //     const state_ = _.cloneDeep(this.state)
    //     state_['polygon-outline-color'] = color
    //     this.props.updatedStyle(state_)
    // }

    handlePolygonShadowColor = (color) => {
        this.setState({ 'polygon-shadow-color': color }, () => {
            this.props.updatedStyle(this.state)
        });

    }

    handlePolygonShadowDx = (value) => {
        this.setState({ 'polygon-shadow-dx': value }, () => {
            this.props.updatedStyle(this.state)
        });

    }

    handlePolygonShadowDy = (value) => {
        this.setState({ 'polygon-shadow-dy': value }, () => {
            this.props.updatedStyle(this.state)
        });

    }

    // handlePolygonDx = (value) => {
    //     this.setState({ 'polygon-dx': value },()=>{
    //         this.props.updatedStyle(this.state)
    //     });

    // }

    // handlePolygonDy = (value) => {
    //     this.setState({ 'polygon-dy': value },()=>{
    //         this.props.updatedStyle(this.state)
    //     });

    // }

    render() {
        const { id } = this.state;
        if (id === 'ocean') {
            return (
                <Form>
                    <Form.Item>
                        <Row type="flex" justify="space-around" align="middle" >
                            <Col span={3} className={this.state['polygon-fill'] ? this.state['polygon-texture-file'] ? 'opacity06' : '' : ''} ><Tooltip title="Fill Color of Polygon "><IconFont className="style-icon" type="icon-color" /></Tooltip> </Col>
                            <Col span={8}>
                                <ColorPicker color={this.state["polygon-fill"] ? this.state["polygon-fill"] : '#bbbbbb'} updateColor={this.handlePolygonFill}>
                                </ColorPicker>
                            </Col>
                            <Col span={3} offset={2} className={this.state['polygon-opacity'] ? '' : 'opacity06'} ><Tooltip title="Opacity "><IconFont className="style-icon" type="icon-opacity" /></Tooltip> </Col>
                            <Col span={8} className={this.state['polygon-opacity'] ? '' : 'opacity06'} >
                                <InputNumber size="small" className="style-input" min={0} max={1} value={this.state["polygon-opacity"] ? this.state["polygon-opacity"] : 1} step={0.1} onChange={this.handlePolygonOpacity} />
                            </Col>
                        </Row>
                    </Form.Item>
                </Form>
            )
        }

        return (
            <div>
                <Form>
                    <Form.Item>
                        <Row type="flex" justify="space-around" align="middle" >
                            <Col span={3} className={this.state['polygon-fill'] ? this.state['polygon-texture-file'] ? 'opacity06' : '' : ''} ><Tooltip title="Fill Color of Polygon "><IconFont className="style-icon" type="icon-color" /></Tooltip> </Col>
                            <Col span={8}>
                                <ColorPicker color={this.state["polygon-fill"] ? this.state["polygon-fill"] : '#bbbbbb'} updateColor={this.handlePolygonFill}>
                                </ColorPicker>
                            </Col>
                            <Col span={3} offset={2} className={this.state['polygon-opacity'] ? '' : 'opacity06'} ><Tooltip title="Opacity "><IconFont className="style-icon" type="icon-opacity" /></Tooltip> </Col>
                            <Col span={8} className={this.state['polygon-opacity'] ? '' : 'opacity06'} >
                                <InputNumber size="small" className="style-input" min={0} max={1} value={this.state["polygon-opacity"] ? this.state["polygon-opacity"] : 1} step={0.1} onChange={this.handlePolygonOpacity} />
                            </Col>
                        </Row>
                    </Form.Item>
                    {/* 
                    <Form.Item>
                        <Row type="flex" justify="space-around" align="middle" >

                            <Col span={3} className={this.state['polygon-texture-file'] ? this.state['polygon-texture-file'] ? 'opacity06' : '' : 'opacity06'} ><Tooltip title="Polygon Texture Fill "><Icon className="style-icon" type="upload" /></Tooltip> </Col>
                            <Col span={21} className={this.state['polygon-texture-file'] ? this.state['polygon-texture-file'] ? 'opacity06' : '' : 'opacity06'} >
                                <Upload {...props} className="polygon-upload" >
                                    <Button className="style-input" size="small">
                                        Upload
                                </Button>
                                </Upload>
                            </Col>
                        </Row>
                    </Form.Item> */}

                    {/* <Form.Item>
                        <Row type="flex" justify="space-around" align="middle">
                            <Col span={3} className={this.state['polygon-dx'] ? "" : 'opacity06'} ><Tooltip title="Offset-X "><IconFont className="style-icon" type="icon-offset_x" /></Tooltip> </Col>
                            <Col span={8} className={this.state['polygon-dx'] ? "" : 'opacity06'} >
                                <InputNumber size="small" className="style-input" min={0} max={200} value={this.state['polygon-dx'] ? this.state["polygon-dx"] : 0} onChange={this.handlePolygonDx} />
                            </Col>
                            <Col span={3} offset={2} className={this.state["polygon-dy"] ? "" : 'opacity06'}><Tooltip title="Offset-Y "><IconFont className="style-icon" type="icon-offset_y" /></Tooltip> </Col>
                            <Col span={8} className={this.state["polygon-dy"] ? "" : 'opacity06'} >
                                <InputNumber size="small" className="style-input" min={0} max={200} value={this.state["polygon-dy"] ? this.state["polygon-dy"] : 0} onChange={this.handlePolygonDy} />
                            </Col>
                        </Row>
                    </Form.Item> */}

                    {/* <Form.Item>
                        <Row type="flex" justify="space-around" align="middle">
                            <Col span={3} className={this.state["polygon-outline-width"] ? "" : 'opacity06'}   >
                                <Tooltip title="Polygon Outline Width"><IconFont className="style-icon " type="icon--quanbubiankuang" />
                                </Tooltip>
                            </Col>
                            <Col span={8} className={this.state["polygon-outline-width"] ? "" : 'opacity06'}  >
                                <InputNumber size="small" className="style-input" min={0} max={100} value={this.state["polygon-outline-width"] ? this.state["polygon-outline-width"] : 0} onChange={this.handlePolygonOutlineWidth} />
                            </Col>
                            <Col span={3} offset={2} className={this.state["polygon-outline-color"] ? "" : 'opacity06'}  >
                                <Tooltip title="Polygon Outline Color"><IconFont className="style-icon" type="icon-bordercolor" />

                                </Tooltip>
                            </Col>
                            <Col span={8}  >
                                <ColorPicker color={this.state["polygon-outline-color"] ? this.state["polygon-outline-color"] : 0} updateColor={this.handlePolygonOutlineColor}  >
                                </ColorPicker>
                            </Col>
                        </Row>
                    </Form.Item> */}

                    <Form.Item>
                        <Row type="flex" justify="space-around" align="middle">
                            <Col className={this.state["polygon-shadow-dx"] ? "" : 'opacity06'} span={3} >
                                <Tooltip title="Shadow Offset in X Direction"><IconFont className="style-icon" type="icon-box-shadow-offsetXSVG1" />
                                </Tooltip>
                            </Col>
                            <Col className={this.state["polygon-shadow-dx"] ? "" : 'opacity06'} span={8} >
                                <InputNumber size="small" className="style-input" min={-200} max={200} value={this.state["polygon-shadow-dx"] ? this.state["polygon-shadow-dx"] : 0} onChange={this.handlePolygonShadowDx} />
                            </Col>
                            <Col className={this.state["polygon-shadow-dy"] ? "" : 'opacity06'} span={3} offset={2} >
                                <Tooltip title="Shadow Offset  in Y Direction"><IconFont className="style-icon" type="icon-box-shadow-offsetY" />
                                </Tooltip>
                            </Col>
                            <Col className={this.state["polygon-shadow-dy"] ? "" : 'opacity06'} span={8} >
                                <InputNumber size="small" className="style-input" min={-200} max={200} value={this.state["polygon-shadow-dy"] ? this.state["polygon-shadow-dy"] : 0} onChange={this.handlePolygonShadowDy} />
                            </Col>
                        </Row>
                    </Form.Item>

                    <Form.Item>
                        <Row type="flex" justify="space-around" align="middle" >
                            <Col span={3} className={this.state["polygon-shadow-color"] ? "" : 'opacity06'}>
                                <Tooltip title="Shadow Color of Polygon"><IconFont className="style-icon" type="icon-box-shadow" />
                                </Tooltip>
                            </Col>
                            <Col span={8} >
                                <ColorPicker color={this.state["polygon-shadow-color"] ? this.state["polygon-shadow-color"] : '#bbbbbb'} updateColor={this.handlePolygonShadowColor}>
                                </ColorPicker>
                            </Col>
                            <Col span={13} >
                            </Col>
                        </Row>
                    </Form.Item>
                </Form>
            </div>
        )
    }
}


export default PolygonContent;