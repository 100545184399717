import React from 'react';
import { Spin, Icon, message } from 'antd';
import ol from '../../lib/vectormap';
import _ from 'lodash'
import '../../../node_modules/vectormap-js/vectormap.css';
import './index.scss';
import sidebarLayerData from '../../data/sidebarLayerData';
import IconFont from '../tool/iconFont';
import ZoomControl from './ZoomControl';
import apiKey from '../../data/apiKey';
// import LineItem from './../layerStyle/line/item/index';
// import filter from './../../redux/reducers/filter';


const mapAttributionHTML =
    '<a href="https://openlayers.org/"><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAMAAABEpIrGAAAAA3NCSVQICAjb4U/gAAAACXBIWXMAAAHGAAABxgEXwfpGAAAAGXRFWHRTb2Z0d2FyZQB3d3cuaW5rc2NhcGUub3Jnm+48GgAAAhNQTFRF////AP//AICAgP//AFVVQECA////K1VVSbbbYL/fJ05idsTYJFtbbcjbJllmZszWWMTOIFhoHlNiZszTa9DdUcHNHlNlV8XRIVdiasrUHlZjIVZjaMnVH1RlIFRkH1RkH1ZlasvYasvXVsPQH1VkacnVa8vWIVZjIFRjVMPQa8rXIVVkXsXRsNveIFVkIFZlIVVj3eDeh6GmbMvXH1ZkIFRka8rWbMvXIFVkIFVjIFVkbMvWH1VjbMvWIFVlbcvWIFVla8vVIFVkbMvWbMvVH1VkbMvWIFVlbcvWIFVkbcvVbMvWjNPbIFVkU8LPwMzNIFVkbczWIFVkbsvWbMvXIFVkRnB8bcvW2+TkW8XRIFVkIlZlJVloJlpoKlxrLl9tMmJwOWd0Omh1RXF8TneCT3iDUHiDU8LPVMLPVcLPVcPQVsPPVsPQV8PQWMTQWsTQW8TQXMXSXsXRX4SNX8bSYMfTYcfTYsfTY8jUZcfSZsnUaIqTacrVasrVa8jTa8rWbI2VbMvWbcvWdJObdcvUdszUd8vVeJaee87Yfc3WgJyjhqGnitDYjaarldPZnrK2oNbborW5o9bbo9fbpLa6q9ndrL3ArtndscDDutzfu8fJwN7gwt7gxc/QyuHhy+HizeHi0NfX0+Pj19zb1+Tj2uXk29/e3uLg3+Lh3+bl4uXj4ufl4+fl5Ofl5ufl5ujm5+jmySDnBAAAAFp0Uk5TAAECAgMEBAYHCA0NDg4UGRogIiMmKSssLzU7PkJJT1JTVFliY2hrdHZ3foSFhYeJjY2QkpugqbG1tre5w8zQ09XY3uXn6+zx8vT09vf4+Pj5+fr6/P39/f3+gz7SsAAAAVVJREFUOMtjYKA7EBDnwCPLrObS1BRiLoJLnte6CQy8FLHLCzs2QUG4FjZ5GbcmBDDjxJBXDWxCBrb8aM4zbkIDzpLYnAcE9VXlJSWlZRU13koIeW57mGx5XjoMZEUqwxWYQaQbSzLSkYGfKFSe0QMsX5WbjgY0YS4MBplemI4BdGBW+DQ11eZiymfqQuXZIjqwyadPNoSZ4L+0FVM6e+oGI6g8a9iKNT3o8kVzNkzRg5lgl7p4wyRUL9Yt2jAxVh6mQCogae6GmflI8p0r13VFWTHBQ0rWPW7ahgWVcPm+9cuLoyy4kCJDzCm6d8PSFoh0zvQNC5OjDJhQopPPJqph1doJBUD5tnkbZiUEqaCnB3bTqLTFG1bPn71kw4b+GFdpLElKIzRxxgYgWNYc5SCENVHKeUaltHdXx0dZ8uBI1hJ2UUDgq82CM2MwKeibqAvSO7MCABq0wXEPiqWEAAAAAElFTkSuQmCC"></a>' +
    '  © <a href="https://thinkgeo.com/">ThinkGeo</a>  contributors';

class MapContent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            stylesArr: [],
            featureArr: [],
            loading: true,
            decimalZoom: this.props.initialZoom,
            localLayerDatasLength: props.layerDatas.length
        }
    }

    componentDidMount() {
        const { json, initialZoom } = this.props;
        this.initializeMap(json, initialZoom);
        this.updateStylesArray(json);
        this.baseLayer = new ol.mapsuite.VectorTileLayer(json, {
            zIndex: 1,
            apiKey: apiKey,
            renderBuffer: 30
        });
        this.map.addLayer(this.baseLayer);

    }

    componentWillReceiveProps(nextProps) {
        if (this.props.titleChange || nextProps.titleChange) {
            // If the title has been changed, do not refresh the map.
            const { changeTitleState } = this.props;
            changeTitleState(false);
        } else {
            const currentGeocodingPlace = nextProps.currentGeocodingPlace;
            // Update geocoding layer.
            if (this.props.currentGeocodingPlace !== currentGeocodingPlace) {
                this.updateGeocodingLayer(currentGeocodingPlace);
            };

            if (nextProps.json !== this.state.json) {
                const initLayer = this.baseLayer;
                const { currentZoomFlag, zoom } = nextProps;
                const showImagery = localStorage.hasOwnProperty('showImagery') ? (localStorage.getItem('showImagery') === 'true') : this.props.showImagery;
                if (showImagery && !this.imageryLayer) {
                    this.imageryLayer = new ol.layer.Tile({
                        source: new ol.source.XYZ({
                            url: `https://cloud.thinkgeo.com/api/v1/maps/raster/aerial/x1/3857/512/{z}/{x}/{y}.jpeg?apiKey=${apiKey}`,
                            tileSize: 512,
                        }),
                        zIndex: 0,
                        layerName: 'imagery'
                    });
                    this.map.addLayer(this.imageryLayer);
                } else if (!!this.imageryLayer) {
                    this.map.removeLayer(this.imageryLayer);
                    this.imageryLayer = null;
                }

                if ((Array.isArray(currentZoomFlag) && currentZoomFlag.includes(zoom)) || currentZoomFlag === undefined) {
                    this.updateMap(nextProps, initLayer);
                }
            }
        }
    }

    updateMap = (nextProps, initLayer) => {
        const { json, layerDatas, index, zoom, shouldMapUpdate } = nextProps;
        const { localLayerDatasLength, localBackgroundColor } = this.state;
        const layerData = layerDatas[index];
        let mapState;
        const backgroundColor = json.background;
        const callback = () => {
            // if(shouldMapUpdate){
            this.setState({
                loading: true
            }, () => {
                // about 800ms to update map 
                initLayer.update(json);
                // FIXME: shouldn't refresh sidebar at here for efficiency.
                this.updateStylesArray(json);
            })
            // }
        }
        let centerPoint = ol.proj.toLonLat(this.view.getCenter());
        centerPoint = [+centerPoint[0].toFixed(6), +centerPoint[1].toFixed(6)]

        if (layerData) {
            mapState = layerData.mapState;
        };

        if (nextProps.json.id !== this.state.json.id) {
           
            const intervalZoom = Math.abs(zoom - 3) + 1;
         
            localStorage.removeItem('zoom');
            localStorage.removeItem('centerPoint');
            this.view.animate({
                center: ol.proj.fromLonLat([-96.8474, 32.7492]),
                zoom: 3,
                duration: intervalZoom * 350
            }, callback);
        } else if ((index !== layerDatas.length - 1 || localLayerDatasLength === layerDatas.length) &&
            mapState && (!_.isEqual(mapState.center, centerPoint) || !_.isEqual(mapState.zoom, zoom)) &&
            localBackgroundColor === backgroundColor) {
            const intervalZoom = Math.abs(mapState.zoom - zoom);
            this.animate = true;
            this.view.animate({
                center: ol.proj.fromLonLat(mapState.center),
                zoom: mapState.zoom,
                duration: intervalZoom * 350
            }, callback);
        } else {
            callback();
            this.setState({
                localLayerDatasLength: layerDatas.length,
                localBackgroundColor: backgroundColor
            })
        }
    }

    componentWillUpdate() {
        const { handleRefreshViewChange } = this.props;
        handleRefreshViewChange(false);
    }

    renderPopContent = (featureArr) => {
        this.setState({
            layerList: []
        })
        const layerArr = []
        let oceanFlag = false
        const { json } = this.props
        featureArr.map((feature, index) => {
            if (feature.values_.layerName) {
                const obj = {

                }
                if (feature.values_.layerName === 'marine_name') {
                    oceanFlag = true
                }
                let values_ = { ...feature.values_ }
                delete values_.geometry
                obj.layerName = feature.values_.layerName
                obj.layerInfor = values_
                obj.showInforState = false
                if (feature.styleId) {
                    obj.styleItemId = feature.styleId.split('#')[0]
                }
                // obj.id = 'layer' + index
                if (feature.values_.admin_level !== undefined || feature.values_.class !== undefined) {
                    obj.value = feature.values_.admin_level || feature.values_.class
                }

                const sameLayerName = this.state.newSidebarData.filter((item) => {
                    return item.layerName === feature.values_.layerName
                })

                sameLayerName.forEach((item) => {

                    if (obj.value === undefined) {
                        obj.filter = item.filter
                        obj.content = item.content
                        obj.url = item.url
                    }

                    if (item.class) {
                        item.class.forEach((classItem) => {
                            if (obj.value !== undefined && classItem.value === obj.value) {
                                obj.title = classItem.title
                                obj.filter = classItem.filter
                                obj.content = classItem.content
                                obj.url = classItem.url
                            }
                        })
                    }
                })
                json.styles.forEach((styleItem) => {
                    if (!obj.styleItemId) {
                        if (styleItem.id === obj.layerName) {
                            obj.styleItemId = styleItem.id
                        } else if (styleItem.filter) {
                            styleItem.filter.split(';').forEach((filterItem) => {
                                if (filterItem.includes('class') || filterItem.includes('admin_level')) {
                                    let classStr = filterItem.split("=")[1].replace(/\'/g, "")
                                    classStr.split(',').forEach((classItem) => {
                                        if (classItem == obj.value) {
                                            obj.styleItemId = styleItem.id
                                        }
                                    })
                                }
                            })
                        } else {
                            obj.styleItemId = obj.layerName
                        }
                    }
                })
                if (obj.title) {
                    obj.unique = obj.styleItemId + '_' + obj.title
                } else {
                    obj.unique = obj.styleItemId
                }

                this.state.newSidebarData.forEach((item) => {
                    if (item.layerName === feature.values_.layerName) {
                        if (obj.styleItemId === item.id) {
                            obj.type = item.type;
                        }
                        if (!obj.title) {
                            if (obj.unique === item.id) {
                                obj.pointStyle = item.pointStyle;
                            }
                        }
                        if (item.class) {
                            item.class.forEach((classItem) => {
                                // console.log(classItem)
                                if (obj.unique == classItem.id) {
                                    obj.pointStyle = classItem.pointStyle;

                                }
                            })
                        }
                    }
                })

                layerArr.push(obj)
            }
        })

        let layerArr_ = []

        if (layerArr.length > 1) {
            layerArr.forEach((item) => {
                if (!oceanFlag && item.layerName !== 'ocean') {
                    layerArr_.push(item)
                }
            })
        }
        if (layerArr_.length === 0) {
            layerArr_ = [...layerArr];
        };
        layerArr_ = [..._.uniqBy(layerArr_, 'unique')];
        layerArr_.forEach((item, index) => {
            item.id = 'layer' + index;
            delete item.unique;
        })

        this.setState({
            layerList: layerArr_
        })

    }

    initializeMap = (json, zoom) => {
        if (json.styles.length === 0) {
            return;
        }
        const { handleZoomChange, updateCenterPoint, centerPoint } = this.props;

        this.view = new ol.View({
            center: ol.proj.fromLonLat(centerPoint),
            maxZoom: 19,
            maxResolution: 40075016.68557849 / 512,
            zoom: zoom,
            progressiveZoom: false,
            minZoom: 2
        });

        const content = document.getElementById("popup-content")

        if (content) {
            content.addEventListener('click', (e) => {
                this.selectFeature(e)
            })
        }

        const formateCoodinate = (coord) => {
            let template = '{y},{x}';
            let out = ol.coordinate.format(coord, template, 4)

            return out
        }

        const mousePositionControl = new ol.control.MousePosition({
            projection: 'EPSG:4326',
            // comment the following two lines to have the mouse position
            // be placed within the map.
            coordinateFormat: formateCoodinate,
            target: document.getElementById('mouse-position')

        })

        this.map = new ol.Map({
            renderer: 'webgl',
            controls: new ol.control.defaults({
                attributionOptions: {
                    collapsible: false,
                    collapsed: false
                }
            }),

            loadTilesWhileAnimating: true,
            loadTilesWhileInteracting: true,
            layers: [],
            target: 'map',
            view: this.view
        });

        const showImagery = localStorage.hasOwnProperty('showImagery') ? (localStorage.getItem('showImagery') === 'true') : this.props.showImagery;
        if (showImagery) {
            this.imageryLayer = new ol.layer.Tile({
                source: new ol.source.XYZ({
                    url: `https://cloud.thinkgeo.com/api/v1/maps/raster/aerial/x1/3857/512/{z}/{x}/{y}.jpeg?apiKey=${apiKey}`,
                    tileSize: 512,
                }),
                zIndex: 0,
                layerName: 'imagery'
            });
            this.map.addLayer(this.imageryLayer);
        }

        this.map.addControl(mousePositionControl);
        this.overlay = new ol.Overlay({
            element: document.getElementById("popup"),
            autoPan: false
        });

        const olAttributionLi = document.querySelector('.ol-attribution>ul>li');
        olAttributionLi.innerHTML = mapAttributionHTML;

        this.view.on('change:resolution', (e) => {
            let zoom = this.view.getZoom();
            const fixedZoom = Math.floor(zoom * 10) / 10;
            this.changeDecimalZoom(fixedZoom);
            localStorage.setItem('zoom', fixedZoom);
            if (!this.animate) {
                if (this.resolutionKey) {
                    clearTimeout(this.resolutionKey);
                }

                this.resolutionKey = setTimeout(() => {
                    handleZoomChange(fixedZoom);
                    this.refreshSidebar();

                    if (this.animate) {
                        this.animate = undefined;
                    }

                    if (this.overlay) {
                        this.overlay.setPosition(undefined);
                    }
                }, 400);
            }
        });

        //Map interactions
        this.map.on('singleclick', (e) => {
            if (e.originalEvent.target.nodeName === 'CANVAS') {
                const pixel = this.map.getEventPixel(e.originalEvent);
                const map = e.map;
                const featureArr = []
                map.forEachFeatureAtPixel(pixel, function (feature, layer) {
                    featureArr.push(feature)
                });
                if (featureArr.length === 0) {
                    this.overlay.setPosition(undefined);
                } else {
                    this.overlay.setPosition(undefined);
                    this.overlay.setPosition(e.coordinate);
                    this.map.addOverlay(this.overlay);
                }

                this.renderPopContent(featureArr)
            }
        });

        this.map.on('pointerdrag', (e) => {
            if (e.dragging) {
                if (this.dragKey) {
                    clearTimeout(this.dragKey);
                }
                this.dragKey = setTimeout(() => {
                    const centerPoint = ol.proj.toLonLat(this.view.getCenter());
                    localStorage.setItem('centerPoint', JSON.stringify([+centerPoint[0].toFixed(6), +centerPoint[1].toFixed(6)]));
                    updateCenterPoint([+centerPoint[0].toFixed(6), +centerPoint[1].toFixed(6)]);
                }, 1000);
            }
        });

        this.map.on('postcompose', () => {
            if (this.renderedkey) {
                clearTimeout(this.renderedkey);
            }

            this.renderedkey = setTimeout(() => {
                if (this.state.loading) {
                    this.setState({
                        loading: false
                    })
                }
            }, 800)
        });

        this.map.getViewport().addEventListener('contextmenu', (e) => {
            e.preventDefault()
            this.overlay.setPosition(undefined);
        });
    };

    changeDecimalZoom = (decimalZoom) => {
        this.setState({
            decimalZoom
        })
    }

    /**
     * @func
     * @desc Judge a the zoom filter if is in the current zoom level by passing a filter string. 
     * @param {string} str - eg: "zoom>=4" or "zoom>=4;zoom<=9" or "zoom=4|zoom<=2" or "zoom=4;zoom<=2;class='motorway'"
     */
    hasCurrentStyle = (str) => {
        const { zoom } = this.props;// Do not delete this zoom, it will be used in the eval expression later.
        if (str.includes('zoom') && str.includes(',')) {
            let zoomArr = str.replace(/[^(\d|,)]/g, '').split(',');
            if (zoomArr.includes(`${zoom}`)) {
                return true;
            }
        } else {
            let flag;
            const regex = /(zoom>=[0-9]+(\||;)zoom<=[0-9]+)|(zoom<=[0-9]+(\||;)zoom>=[0-9]+)|(zoom(>=|<=|<|=|>)[0-9]+)/g;
            const zoomMath = str.match(regex);
            if (zoomMath !== null) {
                for (let i = 0, l = zoomMath.length; i < l; i++) {
                    let str = zoomMath[i];

                    if (str.match(/zoom<[0-9]+/g)) {
                        str = str.replace('<', '<=');
                    }
                    if (str.match(/zoom=[0-9]+/)) {

                        str = str.replace('=', '===');
                    }
                    let evalStr = str.replace('|', '||');
                    evalStr = evalStr.replace(';', '&&');

                    flag = eval(evalStr);
                    if (flag) {
                        return flag;
                    }
                }
            }
        }

        return false;
    }

    updateStylesArray = (json) => {
        let jsonIds = json.layers[0].styles;
        let jsonStyles = json.styles;
        let stylesArr = [];

        // Get the style objs and make sure the layer order is right.
        for (let i = 0, l = jsonIds.length; i < l; i++) {
            let id = jsonIds[i];
            jsonStyles.some((obj) => {
                // let newObj = {};
                // Object.assign(newObj, obj);
                if (obj.id === id) {
                    stylesArr.push(obj);

                    return true;
                }
            })
        }

        this.setState({
            stylesArr,
            json
        }, this.refreshSidebar)
    }

    getZoomArr = (item) => {
        let maxZoom = null, minZoom = null, zoomArr = [];
        item.filter.zoom.split(';').forEach((zoomItem) => {
            if (zoomItem.includes('>=')) {
                minZoom = Number(zoomItem.split('=')[1]);
            } else {
                maxZoom = Number(zoomItem.split('=')[1]);
            }
        });
        for (let i = minZoom; i < maxZoom + 1; i++) {
            zoomArr.push(i)
        }
        return zoomArr
    }

    getLayersByZoom = () => {
        let zoom = Math.floor(this.state.decimalZoom);
        const allLayerData = JSON.parse(JSON.stringify(sidebarLayerData));
        const layerDataByZoom = [];
        allLayerData.forEach((item) => {
            let zoomArr = this.getZoomArr(item);
            if (zoomArr.includes(zoom)) {
                let obj = {};
                obj = JSON.parse(JSON.stringify(item));
                if (item.class) {
                    obj.class = [];
                    item.class.forEach((classItem) => {
                        let classZoomArr = this.getZoomArr(classItem);
                        if (classZoomArr.includes(zoom)) {
                            obj.class.push(classItem)
                        }
                    })
                }
                layerDataByZoom.push(obj)
            };

        });
        return layerDataByZoom
    };

    insertItem = (item, newSidebarData, layerOrder) => {

        newSidebarData.push(item);

    }

    getLayerNameArr = (item) => {
        let layerArr = this.state.json.styles.filter((stylejsonItem) => {
            let layerName;
            stylejsonItem.filter.split(';').forEach((filterItem) => {
                if (filterItem.includes('layerName')) {
                    layerName = filterItem.split("=")[1].replace(/\'/g, "");
                };
            });
            return layerName === item.layerName;
        });
        return layerArr
    }

    getIndex = (item, newSidebarData, layerOrder) => {
        let layerArr = this.getLayerNameArr(item);
        layerArr.forEach((layerItem) => {
            const item_ = JSON.parse(JSON.stringify(item))
            if (layerItem.filter.includes('class') || layerItem.filter.includes('admin_level')) {
                layerItem.filter.split(';').forEach((filterItem) => {
                    if (!filterItem.includes('layerName') && filterItem && filterItem.includes('=')) {
                        let classStrArr = filterItem.split("=")[1].replace(/\'/g, "").split(",");
                        item_.id = layerItem.id;
                        item_.color = undefined;
                        item_.visible = true;
                        item_.isHaveStyle = false;
                        item_.searched = true;
                        const classArr = [];
                        if (item_.class) {
                            item_.class.forEach((classItem) => {
                                classItem.color = undefined;
                                classItem.visible = true;
                                classItem.isHaveStyle = false;
                                classItem.searched = true;
                                classItem.id = layerItem.id + "_" + classItem.title
                                if (classStrArr.includes(classItem.value)) {
                                    classArr.push(classItem);
                                } else if (typeof (classItem.value) === 'number' && classStrArr.includes(String(classItem.value))) {
                                    classArr.push(classItem);
                                }
                            })
                            item_.class = classArr
                            if (classArr.length > 0) {

                                this.insertItem(item_, newSidebarData, layerOrder);
                            }

                        }

                    }
                })
            } else if (!(layerItem.filter.includes('class') || layerItem.filter.includes('admin_level')) && item.layerName !== 'road_name') {
                item_.color = undefined;
                item_.visible = true;
                item_.isHaveStyle = false;
                item_.searched = true;
                item_.id = layerItem.id;
                if (item_.class) {
                    item_.class.forEach((classItem) => {
                        classItem.color = undefined;
                        classItem.visible = true;
                        classItem.isHaveStyle = false;
                        classItem.searched = true;
                        classItem.id = layerItem.id + "_" + classItem.title
                    })
                };
                this.insertItem(item_, newSidebarData, layerOrder);
            }
        })
    }

    getClassWithoutStyle = (item, newSidebarData, layerOrder) => {
        let layerArr = this.getLayerNameArr(item);
        let flag = false;

        layerArr.forEach((layerItem) => {
            if (layerItem.filter.includes('class') || layerItem.filter.includes('admin_level')) {
                flag = true;
            };
        });
        if (flag) {
            const newSidebarDataArr = newSidebarData.filter((stylejsonItem) => {
                return stylejsonItem.layerName === item.layerName;
            });
            const sidebarDataClassArr = [];
            newSidebarDataArr.forEach((arrItem) => {
                sidebarDataClassArr.push(...arrItem.class);
            });
            // .log(sidebarDataClassArr, item.class)
            const otherClassArr = [];

            item.class.forEach((dataItemClass) => {
                let classFlag = sidebarDataClassArr.find((sidebarDataClassItem) => {
                    // console.log(dataItemClass, sidebarDataClassItem)
                    return dataItemClass.value == sidebarDataClassItem.value;
                })
                if (!classFlag) {
                    // console.log(dataItemClass)
                    otherClassArr.push(dataItemClass);
                };
            });
            layerArr.forEach((layerItem) => {
                // let styleClassFilter = layerItem;
                // if (layerItem.filter.includes('class') || layerItem.filter.includes('admin_level')) {
                //     styleClassFilter= layerItem
                // } else if (layerItem.style && layerItem.style[0] && layerItem.style[0].filter) {
                //     styleClassFilter= layerItem.style[0]
                // }
                layerItem.filter.split(';').forEach((filterItem) => {

                    if (!filterItem.includes('layerName') && !filterItem.includes('zoom') && filterItem) {
                        let classStrArr = filterItem.split("=")[1].replace(/\'/g, "").split(",");
                        const classInStyleJson = [];
                        classStrArr.forEach((classStrItem) => {
                            let deleteItemIndex = null
                            let findItem = otherClassArr.find((otherClassItem, index) => {
                                if (otherClassItem.value == classStrItem) {
                                    deleteItemIndex = index;
                                }
                                return otherClassItem.value == classStrItem;
                            });
                            if (findItem && deleteItemIndex !== null) {
                                findItem.color = undefined;
                                findItem.visible = true;
                                findItem.isHaveStyle = false;
                                findItem.searched = true;
                                findItem.id = layerItem.id + '_' + findItem.title;
                                classInStyleJson.push(findItem);
                                otherClassArr.splice(deleteItemIndex, 1)
                            }
                        })


                        if (classInStyleJson.length > 0) {
                            const item_ = JSON.parse(JSON.stringify(item))
                            item_.color = undefined;
                            item_.visible = true;
                            item_.isHaveStyle = false;
                            item_.searched = true;
                            item_.id = layerItem.id;
                            item_.class = classInStyleJson;

                            this.insertItem(item_, newSidebarData, layerOrder);
                        }
                    }
                })
            })
            if (otherClassArr.length > 0) {
                const item_ = JSON.parse(JSON.stringify(item));
                item_.color = undefined;
                item_.visible = true;
                item_.isHaveStyle = false;
                item_.searched = true;
                item_.id = item_.layerName + '_' + 'other';
                otherClassArr.forEach((otherClassItem) => {
                    otherClassItem.color = undefined;
                    otherClassItem.visible = true;
                    otherClassItem.isHaveStyle = false;
                    otherClassItem.searched = true;
                    otherClassItem.id = item_.layerName + '_' + 'other' + '_' + otherClassItem.title;
                });
                item_.class = otherClassArr;

                newSidebarData.push(item_);
            }

        } else {
            newSidebarData.forEach((sidebarDataItem) => {
                if (item.layerName === sidebarDataItem.layerName) {
                    if (sidebarDataItem.class.length !== item.class.length) {
                        item.class.forEach((dataItem) => {
                            let flagClass = sidebarDataItem.class.find((sidebarDataItemClass) => {
                                return dataItem.value === sidebarDataItemClass.value;
                            });
                            if (!flagClass) {
                                dataItem.color = undefined;
                                dataItem.visible = true;
                                dataItem.isHaveStyle = false;
                                dataItem.searched = true;
                                dataItem.id = sidebarDataItem.id + "_" + dataItem.title;
                                if (sidebarDataItem.class[0] && sidebarDataItem.class[0].type) {
                                    dataItem.type = sidebarDataItem.class[0].type;
                                }

                                sidebarDataItem.class.push(dataItem);
                            }
                        })

                    }
                }
            });
        }
    }

    /**
     * @func
     * @desc Once the stylejson has been changed, we need to refresh the sidebar layer list.
     */
    refreshSidebar = () => {
        const { stylesArr, json } = this.state;
        let ids = [];
        let layerName;
        let newSidebarData = [];
        let color = undefined;
        let colors = {};
        let layerOrder = JSON.parse(JSON.stringify(json.layers[0].styles)).reverse();

        // console.log(json.layers[0].styles)

        // this.styleLoop() :  {'ids': ids, 'colors': colors}
        ids = this.styleLoop(stylesArr, undefined, ids, layerName, newSidebarData, color, colors, undefined, json).ids;
        // const test = JSON.parse(JSON.stringify(newSidebarData));

        let layerDataByZoom = this.getLayersByZoom()


        newSidebarData.forEach((item) => {
            item.isHaveStyle = true
            if (item.class) {
                item.class.forEach((classItem) => {
                    classItem.isHaveStyle = true
                })
            }
        })


        layerDataByZoom.forEach((item) => {
            let flag = newSidebarData.find((stylejsonItem) => {
                return stylejsonItem.layerName === item.layerName
            })
            if (flag && item.class) {
                this.getClassWithoutStyle(item, newSidebarData, layerOrder);
            } else if (!flag) {
                this.getIndex(item, newSidebarData, layerOrder);
            }
        })

        let newSidebarData_ = [];
        let extraData = []
        newSidebarData.forEach((newSidebarItem) => {
            let itemIndex = layerOrder.findIndex((element) => {
                return element === newSidebarItem.id;
            })
            if (itemIndex > -1) {
                newSidebarData_[itemIndex] = newSidebarItem;
            } else {
                extraData.push(newSidebarItem)
            }
        });
        newSidebarData = newSidebarData_.filter((item_) => {
            return item_
        })
        // newSidebarData = newSidebarData.concat(extraData);
        // console.log(newSidebarData)
        this.setState({ color: colors, newSidebarData: newSidebarData })
        this.props.handleSidebarDataChange(newSidebarData, colors);
        return ids;
    }

    /**
     * @func
     * @desc Get a object's type(It will only search in the top level of the object, 
     * which means it will not search in the inner level). The type could be: point, 
     * line and polygon. Also, the point type divided to three subtype: text, shield and point.
     * @param {object} obj - 
     */
    getTypeInShallow = (obj) => {
        let type = {};
        Object.keys(obj).some(key => {
            if (key.includes('line')) {
                type.layerType = 'line';
                return true;
            } else if (key.includes('polygon')) {
                type.layerType = 'polygon';
                return true;
            } else if (key.includes('text')) {
                type.layerType = 'point';
                type.pointStyle = 'text';
                return true;
            } else if (key.includes('shield')) {
                type.layerType = 'point';
                type.pointStyle = 'shield';
                return true;
            } else if (key.includes('point')) {
                type.layerType = 'point';
                type.pointStyle = 'point';
                return true;
            }
        })
        return type;
    }

    styleLoop = (stylesArr, currentId, ids, layerName, newSidebarData, color, colors, visible, json, type) => {
        for (let i = 0; i < stylesArr.length; i++) {
            let styleObj = stylesArr[i];
            let styleFilter = styleObj.filter;
            if (styleObj.id) {
                currentId = styleObj.id;
                visible = this.isVisible(styleObj);
            }

            let formerColor = color;
            color = this.getColor(styleObj, formerColor);

            if (ids.includes(currentId)) {
                break;
            }

            if (styleFilter && styleFilter.match(/layerName/)) {
                layerName = styleFilter.match(/layerName='(\w+)'/)[1];
                type = undefined;
            }

            if (!type || Object.keys(type).length === 0) {
                type = this.getTypeInShallow(styleObj);
            }

            if (styleFilter && styleFilter.match(/zoom/)) {
                if (this.hasCurrentStyle(styleFilter)) {
                    if (this.idInDataZoom(currentId, layerName, newSidebarData, colors, color, formerColor, visible, json, type)) {
                        ids.push(currentId);
                        colors[currentId] = color;
                    }
                    break;
                }
                continue;
            } else {
                if (styleObj.style) {
                    let newStylesArr = styleObj.style.concat();
                    ids = this.styleLoop(newStylesArr, currentId, ids, layerName, newSidebarData, color, colors, visible, json, type).ids;
                } else {
                    continue;
                }
            }
        }
        return {
            ids: ids,
            colors: colors
        };
    };

    idInDataZoom = (id, layerName, newSidebarData, colors, color, formerColor, visible, json, type) => {
        let newClasses = [];
        const layerData = sidebarLayerData.concat();
        let flag = false;
        layerData.some((obj) => {
            if (obj.layerName === layerName) {
                let newObj = {};
                Object.assign(newObj, obj);
                if (this.hasCurrentStyle(newObj.filter.zoom)) {
                    let curClassValue = this.getCurClassById(id, colors, color, formerColor, json, type);
                    let classKeys = Object.keys(curClassValue);
                    if (classKeys.length > 0) {
                        let allClasses = obj.class.concat();
                        allClasses.filter((classObj) => {
                            classKeys.some((value) => {
                                if (classObj.value.toString() === value) {
                                    if (this.hasCurrentStyle(classObj.filter.zoom)) {
                                        let newClassObj = {};
                                        Object.assign(newClassObj, classObj);
                                        newClassObj.id = id + '_' + classObj.title; // country_boundary_country
                                        const classId = id + '_' + value; // country_boundary_2
                                        newClassObj.color = colors[classId];
                                        newClassObj.visible = curClassValue[value];
                                        if (type.layerType) {
                                            const layerType = type.layerType;
                                            if (layerName === 'aeroway') {
                                                if (value === 'aerodrome' || value === 'helipad' || value === 'apron') {
                                                    newClassObj.type = 'polygon';
                                                } else {
                                                    newClassObj.type = 'line';
                                                }
                                            } else {
                                                newClassObj.type = layerType;
                                            }
                                            if (layerType === 'point') {
                                                newClassObj.pointStyle = type.pointStyle;
                                            }
                                        }
                                        newClasses.push(newClassObj);
                                    }
                                    return true;
                                }
                            });
                        });
                        newObj.class = newClasses;
                    }
                    newObj.id = id;
                    newObj.color = colors[id];
                    newObj.visible = visible;
                    if (type.layerType) {
                        const layerType = type.layerType;
                        newObj.type = layerType;
                        if (layerType === 'point') {
                            newObj.pointStyle = type.pointStyle;
                        }
                    }
                    newSidebarData.push(newObj);
                    flag = true;
                    return true;
                }
            }
        });
        return flag;
    }

    getColor = (obj, formerColor, deep) => {
        let color = obj['text-fill'] || obj['shield-fill'] || obj['point-fill'] || obj['line-color'] || obj['polygon-fill'];

        // Get the color in children. If the obj has children and has line-color property, then the returned color will be the color in children.
        if (obj["children"]) {
            let childrenColor = obj["children"][0]["line-color"];
            if (childrenColor) {
                color = childrenColor;
            }
        }

        if (color && color.match(/\@/g)) {
            const { json } = this.props;
            let variables = json.variables;
            color = variables[color];
        }

        if (color === undefined && !!deep && !!obj['style']) {
            const styles = obj['style'];
            styles.some(styleObj => {
                color = this.getColor(styleObj);
                if (!!color) {
                    return true
                }
            })
        } else if (color === undefined && formerColor !== undefined) {
            color = formerColor;
        }

        return color;
    }

    isVisible = (obj) => {
        if (obj.visible === undefined || obj.visible === true) {
            return true;
        } else {
            return false;
        }
    }

    currentStyleInZoom = (styleObj) => {
        let flag = false;

        let filter = styleObj.filter;
        if (!filter) {
            return false;
        }

        if (this.hasCurrentStyle(filter)) {
            flag = true;
        } else {
            flag = false;
        }
        return flag;
    };

    getCurClassById = (id, colors, color, formerColor, nextJson) => {
        let json;
        if (nextJson) {
            json = nextJson;
        } else {
            json = this.props.json;
        }
        let stylesObj = json.styles;
        let curStyleObj;
        let layerName;
        let curClass = {};
        stylesObj.some((obj) => {
            if (obj.id === id) {
                curStyleObj = obj;
                layerName = obj.filter.match(/layerName='(\w+)'/)[1];
                return true;
            }
        });

        let { classArr, className } = this.getAllClassNameAndClassByLayerName(layerName);
        if (classArr.length === 0) {
            return [];
        } else {
            let classValue;
            let styleInZoom = false;
            let visible = true;
            // let color;
            let result = this.run(
                curStyleObj,
                className,
                curClass,
                classValue,
                classArr,
                styleInZoom,
                colors,
                id,
                color,
                formerColor, visible
            );
            return result;
        }
    };

    run = (curStyleObj, className, curClass, classValue, classArr, styleInZoom, colors, id, color, formerColor, visible) => {
        if (!curStyleObj.filter) {
            if (curStyleObj.style) {
                let styleArr = curStyleObj.style;
                styleArr.forEach((obj) => {
                    this.run(
                        obj,
                        className,
                        curClass,
                        classValue,
                        classArr,
                        styleInZoom,
                        colors,
                        id,
                        color,
                        formerColor, visible
                    );
                });
                return curClass;
            } else {
                if (styleInZoom && Object.keys(curClass) && Object.keys(curClass).length > 0) {
                    let newClassValue = classArr.filter((value) => {
                        if (!Object.keys(curClass).includes(value)) {
                            return value;
                        }
                    });
                    color = this.getColor(curStyleObj, formerColor);
                    if (classValue) {
                        curClass = Object.assign(curClass, ...(classValue.map(item => ({ [item]: visible }))));
                    }
                    const classId = id + '_' + newClassValue;
                    colors[classId] = color;
                    return curClass;
                } else {
                    return [];
                }
            }
        }

        if (curStyleObj.filter.includes('zoom')) {
            styleInZoom = this.currentStyleInZoom(curStyleObj);
            if (!styleInZoom) {
                return curClass;
            }
        }

        // let re = new RegExp('((?<!sub)' + className + "(=|!=)'?(\\w+((\\s)*,(\\s)*\\w+)*)'?)", 'g');
        let re = new RegExp('((^|;)(' + className + "(=|!=)'?(\\w+((\\s)*,(\\s)*\\w+)*)'?))", 'g');
        let objFilter = curStyleObj.filter;
        let matchClass = objFilter.match(re);
        matchClass = Array.from(new Set(matchClass)); //de-duplicate
        if (matchClass.length > 0) {
            visible = this.isVisible(curStyleObj);
            matchClass.forEach((item) => {
                item = item.slice(1);
                if (item.includes('!=')) {
                    classValue = item.split('!=')[1]; //"'1,2,3,4'" or "1"
                    classValue = classValue.replace(/\s+/g, '');
                    let matchValue = classValue.match(/\'((\S)+)\'/);
                    if (matchValue) {
                        classValue = matchValue[1]; // "1,2,3,4"
                        let popClassValue = classValue.split(','); // [1,2,3,4] or [1]

                        let classArrCopy = Array.from(classArr);
                        popClassValue.forEach((value) => {
                            let index = classArrCopy.indexOf(value);
                            classArrCopy.splice(index, 1);
                            classValue = classArrCopy;
                        });
                    }
                } else {
                    classValue = item.split('=')[1]; //"'1,2,3,4'" or "1"
                    classValue = classValue.replace(/\s+/g, '');
                    let matchValue = classValue.match(/\'((\S)+)\'/);
                    if (matchValue) {
                        classValue = matchValue[1]; // "1,2,3,4"
                    }
                    classValue = classValue.split(','); // [1,2,3,4] or [1]
                }

                if (styleInZoom === true) {
                    color = this.getColor(curStyleObj, formerColor);
                    if (classValue) {
                        curClass = Object.assign(curClass, ...(classValue.map(item => ({ [item]: visible }))));
                    }
                    classValue.forEach((value) => {
                        const classId = id + '_' + value;
                        colors[classId] = color;
                    });
                } else {
                    if (!curStyleObj.style) {
                        return [];
                    }
                    color = this.getColor(curStyleObj, formerColor);
                    curStyleObj.style.some((obj) => {
                        styleInZoom = this.currentStyleInZoom(obj);
                        if (styleInZoom) {
                            formerColor = color ? color : formerColor;
                            if (classValue) {
                                const innerColor = this.getColor(obj, formerColor, true);
                                color = innerColor ? innerColor : color;
                                curClass = Object.assign(curClass, ...(classValue.map(item => ({ [item]: visible }))));
                            } else {
                                const innerColor = this.getColor(obj, formerColor);
                                color = innerColor ? innerColor : color;
                            }
                            classValue.forEach((value) => {
                                const classId = id + '_' + value;
                                colors[classId] = color;
                            });
                            return true;
                        } else {
                            let objStyle = obj.style;
                            if (objStyle) {
                                objStyle.some((obj) => {
                                    curStyleObj = obj;
                                    // if there is already a classValue in curClass
                                    let flag = false;
                                    classValue.some((value) => {
                                        if (Object.keys(curClass).includes(value)) {
                                            flag = true;
                                            return true;
                                        }
                                    });
                                    if (!flag) {
                                        let res = this.run(
                                            curStyleObj,
                                            className,
                                            curClass,
                                            classValue,
                                            classArr,
                                            styleInZoom,
                                            colors,
                                            id,
                                            color,
                                            formerColor, visible
                                        );
                                        if (res.length > 0) {
                                            // curClass = Array.from(new Set(curClass)); //de-duplicate
                                            curClass = this.deDuplicate(curClass);
                                        }
                                    }
                                });
                            }
                        }
                    });
                }
            });
        } else {
            if (styleInZoom && classValue) {
                color = this.getColor(curStyleObj, formerColor);
                if (classValue) {
                    curClass = Object.assign(curClass, ...(classValue.map(item => ({ [item]: visible }))));
                }
                classValue.forEach((value) => {
                    const classId = id + '_' + value;
                    colors[classId] = color;
                });
            } else if (curStyleObj.style && curStyleObj.filter) {
                curStyleObj.style.forEach((obj) => {
                    curStyleObj = obj;
                    curClass = this.run(
                        curStyleObj,
                        className,
                        curClass,
                        classValue,
                        classArr,
                        styleInZoom,
                        colors,
                        id,
                        color,
                        formerColor, visible
                    );
                });
            } else if (styleInZoom && !curStyleObj.style && !classValue) {
                curClass = Object.assign({}, ...(classArr.map(item => {
                    const classId = id + '_' + item;
                    colors[classId] = color;
                    return ({ [item]: visible })
                })));
            }
        }
        return curClass; // ["0", "1", "2", "3", "4"]
    };

    deDuplicate = (obj) => {
        const keys = Object.keys(obj);
        keys = Array.from(new Set(keys)); //de-duplicate
        let obj_ = {};
        keys.forEach((key) => {
            obj_[key] = obj[key];
        });
        return obj_;
    }

    getAllClassNameAndClassByLayerName = (layerName) => {
        let classArr = [];
        let className;
        sidebarLayerData.some((layer) => {
            if (layer.class && layer.layerName === layerName) {
                let classContentArr = layer.class;
                classContentArr.forEach((obj) => {
                    classArr.push(obj.value);
                });

                className = layer.className;
                return true;
            }
        });
        return {
            classArr,
            className
        };
    };

    selectFeature = (e) => {
        let id;
        if (e.target.classList.contains('layer-name')) {
            id = e.target.getAttribute('itemid')
            this.state.layerList.forEach((item) => {
                if (item.id === id) {
                    this.props.selectFeature(item)
                    this.overlay.setPosition(undefined);
                }
            })
        } else if (e.target.getAttribute('data-icon') === 'info-circle' || e.target.nodeName === 'path') {
            if (e.target.getAttribute('data-icon') === 'info-circle') {
                id = e.target.parentNode.getAttribute('itemid')
            } else {
                id = e.target.parentNode.parentNode.getAttribute('itemid')
            }
            this.state.layerList.forEach((item) => {
                if (item.id !== id) {
                    item.showInforState = false
                } else {
                    item.showInforState = !item.showInforState
                }
            })
            this.setState({})
        }
        // const layerList = [...this.state.layerList]
    }

    updateGeocodingLayer = (currentGeocodingPlace) => {
        const format = new ol.format.WKT();
        const feature = format.readFeature(currentGeocodingPlace.geometry, {
            dataProjection: 'EPSG:4326',
            featureProjection: 'EPSG:3857'
        });
        const geom = feature.getGeometry();
        if (geom instanceof ol.geom.Point) {
            const xyCoord = ol.proj.fromLonLat(currentGeocodingPlace.coordinate);
            this.view.animate({
                center: xyCoord,
                zoom: 17,
                duration: 0
            });
        } else {
            this.view.fit(geom, {
                padding: [20, 20, 20, 20]
            })
        }
    }

    render() {
        const { centerPoint, refreshView } = this.props;
        const { loading, decimalZoom } = this.state;
        const renderIcon = (item) => {
            // let color ='#f0eee8'
            let str = null
            if (item.value) {
                str = item.styleItemId + '_' + item.value
            } else {
                str = item.styleItemId
            }

            let color = this.state.color[str] || '#f0eee8'

            switch (item.type) {
                case 'point':
                    return (<IconFont type="icon-font" style={{ backgroundColor: color }}></IconFont>)
                case 'line':
                    return (<IconFont type="icon-line" style={{ backgroundColor: color }}></IconFont>)
                case 'polygon':
                    return (<IconFont type="icon-vector-polygon" style={{ backgroundColor: color }}></IconFont>)
                default:
                    return undefined;
            }
        }

        const loopPopItemInfor = (layerInfor) => Object.keys(layerInfor).map((key, index) => {
            return (<li key={index}><span className='infor-key' >{key}</span>:<span className='infor-value'>{String(layerInfor[key])}</span> </li>)
        })

        const loopPopContent = (layerArr) => layerArr.map((item, index) => {
            let classStr = ''
            if (item.title) {
                classStr = `: ${item.title}`
            }

            return (<li key={item.id} className="popup-item" ><span className="title-icon">{renderIcon(item)}</span><span itemID={item.id} className='layer-name'>{item.styleItemId}{classStr}</span> <Icon type="info-circle" className='infor-icon' itemID={item.id} /> <div className={'collapse' + ' ' + (item.showInforState ? 'collapse-dispaly' : 'collapse-hide')} ><ul>{loopPopItemInfor(item.layerInfor)}</ul></div>   </li>)
        })

        if (refreshView) {
            this.view.setCenter(ol.proj.fromLonLat(centerPoint));
        }

        const antIcon = <Icon type="loading" style={{ fontSize: 30 }} spin></Icon>;
        return (

            <React.Fragment>
                <Spin indicator={antIcon} spinning={loading} delay={50}>
                    <div id="map">
                        <div id="mouse-position" className={this.props.drawerVisible ? 'ol-mouse-right' : 'ol-mouse-left'} ></div>
                    </div>
                    <ZoomControl zoom={decimalZoom} />
                </Spin>
                <div id="popup" className="ol-popup" >
                    <div id="popup-content">
                        <ul  >
                            {loopPopContent(this.state.layerList || [])}
                        </ul>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default MapContent;
