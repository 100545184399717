

function getAnalogousColor (color, count){
    let hsl = rgbToHsl(color.r, color.g, color.b, color.a);
    return analogous(hsl, count, 200);
}

function analogous(hsl, results, slices) {
    results = results || 6;
    slices = slices || 30;
    const res = [];
    var part = 360 / slices;
    for (hsl.h = ((hsl.h - (part * results >> 1)) + 720) % 360; --results;) {
        hsl.h = (hsl.h + part) % 360;
        hsl = JSON.parse(JSON.stringify(hsl));
        res.push(hslToRgb(hsl.h, hsl.s, hsl.l, hsl.a));
    }
    return res;
}

function rgbToHsl(r, g, b, a) {
    r = bound01(r, 255);
    g = bound01(g, 255);
    b = bound01(b, 255);

    var max = Math.max(r, g, b),
        min = Math.min(r, g, b);
    var h, s, l = (max + min) / 2;

    if (max == min) {
        h = s = 0; // achromatic
    } else {
        var d = max - min;
        s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
        switch (max) {
            case r:
                h = (g - b) / d + (g < b ? 6 : 0);
                break;
            case g:
                h = (b - r) / d + 2;
                break;
            case b:
                h = (r - g) / d + 4;
                break;
        }

        h /= 6;
    }
    h = h * 360;
    l = l * 100 + "%";
    s = s * 100 + "%";

    return {
        h: h,
        s: s,
        l: l,
        a: a
    };
}

function hslToRgb(h, s, l, a) {
    var r, g, b;
    h = bound01(h, 360);
    s = bound01(s, 100);
    l = bound01(l, 100);

    function hue2rgb(p, q, t) {
        if (t < 0) t += 1;
        if (t > 1) t -= 1;
        if (t < 1 / 6) return p + (q - p) * 6 * t;
        if (t < 1 / 2) return q;
        if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
        return p;
    }

    if (s === 0) {
        r = g = b = l; // achromatic
    } else {
        var q = l < 0.5 ? l * (1 + s) : l + s - l * s;
        var p = 2 * l - q;
        r = hue2rgb(p, q, h + 1 / 3);
        g = hue2rgb(p, q, h);
        b = hue2rgb(p, q, h - 1 / 3);
    }

    return {
        r: Math.round(r * 255),
        g: Math.round(g * 255),
        b: Math.round(b * 255),
        a: a
    };
}

function isOnePointZero(n) {
    return typeof n == "string" && n.indexOf('.') != -1 && parseFloat(n) === 1;
}
// Check to see if string passed in is a percentage
function isPercentage(n) {
    return typeof n === "string" && n.indexOf('%') != -1;
}

function bound01(n, max) {
    if (isOnePointZero(n)) {
        n = "100%";
    }

    var processPercent = isPercentage(n);
    n = Math.min(max, Math.max(0, parseFloat(n)));

    // Automatically convert percentage into number
    if (processPercent) {
        n = parseInt(n * max, 10) / 100;
    }

    // Handle floating point rounding errors
    if ((Math.abs(n - max) < 0.000001)) {
        return 1;
    }

    // Convert into [0, 1] range if it isn't already
    return (n % max) / parseFloat(max);
}

export default getAnalogousColor;