import React, { Component } from 'react';

import { Form, Icon, Tooltip, Select, Row, Col, InputNumber, Radio, Popover, Button, Input } from 'antd';
import 'vectormap-icons/vectormap-icons.css';
import ScrollBar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';
import '../index.scss';
import './index.scss';
import ColorPicker from '../../../tool/colorPicker';
import IconFont from '../../../tool/iconFont';
import _ from 'lodash'
import iconData from '../../../../data/iconListData'
const Option = Select.Option;
const props = {
    action: '//jsonplaceholder.typicode.com/posts/',
    listType: 'picture',
}

class PointIcon extends Component {

    constructor(props) {
        super(props);
        this.state = {
            ...this.props.activeStyle,
            iconData: iconData,
            selectItemContent: this.getSelectIcon(this.props)
        }
    }

    componentWillReceiveProps(nextProps) {
        const state = _.cloneDeep(this.state)
        Object.keys(state).map((stateKey) => {
            state[stateKey] = undefined
        })

        this.setState({ ...state, ...nextProps.activeStyle, iconData: iconData, selectItemContent: this.getSelectIcon(nextProps) })
    }

    getSelectIcon = (props) => {
        let selectItemContent = null;
        if (props.activeStyle['point-glyph-name']) {
            iconData.forEach((item) => {
                if (escape(props.activeStyle['point-glyph-name']) === '%u' + escape(item.code).toUpperCase()) {
                    selectItemContent = item.content;
                };
            })
        }

        return selectItemContent
    }


    handlePointFill = (color) => {
        this.setState({ 'point-fill': color }, () => {
            this.props.updatedStyle(this.state)
        });
    }

    handlePointOutlineColor = (color) => {
        this.setState({ 'point-outline-color': color }, () => {
            this.props.updatedStyle(this.state)
        });
    }

    handlePointSymbolTypel = (e) => {
        this.setState({ 'point-symbol-type': e.target.value }, () => {
            this.props.updatedStyle(this.state)
        });

    }

    handlePointType = (value) => {
        this.setState({ 'point-type': value }, () => {
            this.props.updatedStyle(this.state)
        });
    }

    handlePointSize = (value) => {
        this.setState({ 'point-size': value }, () => {
            this.props.updatedStyle(this.state)
        });

    }

    handlePointOpacity = (value) => {
        this.setState({ 'point-opacity': value },() => {
            this.props.updatedStyle(this.state)
        });
    }

    handlePointRotateAngle = (value) => {
        this.setState({ 'point-rotate-angle': value }, () => {
            this.props.updatedStyle(this.state)
        });
    }

    handlePointOutlineWidth = (value) => {
        this.setState({ 'point-outline-width': value }, () => {
            if (value === 0) {
                delete this.state['point-outline-color'];
            }
            this.props.updatedStyle(this.state);
        });
    }

    handlePointDx = (value) => {
        this.setState({ 'point-dx': value }, () => {
            this.props.updatedStyle(this.state)
        });
    }

    handlePointDy = (value) => {
        this.setState({ 'point-dy': value }, () => {
            this.props.updatedStyle(this.state)
        });
    }

    handlePointFile = (value) => {
        this.setState({ 'point-file': value }, () => {
            this.props.updatedStyle(this.state)
        });
    }

    handlePointGlyphName = (value) => {
        this.setState({ 'point-glyph-name': unescape('%u' + value) }, () => {
            this.props.updatedStyle(this.state);
        });
        iconData.map((item) => {
            if (value === item.code) {
                this.setState({ selectItemContent: item.content, iconData: [...iconData] });
            }
        })
    };



    searchIcon = (e) => {
        e.persist();
        let seachList = []
        if (e.target.value) {
            iconData.map((item) => {
                if (e.target.value === item.content) {
                    this.setState({ 'point-glyph-name': unescape('%u' + item.code) }, () => {
                        this.props.updatedStyle(this.state);
                    });
                } else {
                    this.setState({ 'point-glyph-name': null })
                }
                if (item.content.includes(e.target.value) || e.target.value.includes(item.content)) {
                    seachList.push(item)
                }
            })
        } else {
            seachList = [...iconData]
        }
        this.setState({ selectItemContent: e.target.value, iconData: seachList });

    }

    selectIcon = (e) => {
        e.persist();
        let target = e.target;
        if (target.nodeName === 'SPAN') {
            this.handlePointGlyphName(target.getAttribute('code'));
        };
    };



    render() {

        let findFlag = false

        const renderIconItem = (iconData) => iconData.map((item) => {
            if (item.content === this.state.selectItemContent) {
                findFlag = true
            }
            return (<Tooltip key={item.code} placement="leftTop" title={item.content}><span code={item.code} className={escape(this.state['point-glyph-name']) === '%u' + escape(item.code).toUpperCase() ? `icon icon-tg-${item.content} select-icon` : `icon icon-tg-${item.content}`}></span></Tooltip>)
        })

        const IconList = (
            <div className='iconlist-popover' onClick={this.selectIcon}>
                <ScrollBar>
                    {
                        renderIconItem(iconData)
                    }
                </ScrollBar>
            </div>
        );


        const seacherList = (
            <div className='iconlist-popover' onClick={this.selectIcon}>
                <ScrollBar>
                    {
                        renderIconItem(this.state.iconData)
                    }
                </ScrollBar>
            </div>
        );

        const renderIconBtn = (
            <Popover placement="bottomRight" content={IconList} trigger="click" overlayClassName='iconlist-wrap'>
                <Button>{findFlag ? <span className={`icon icon-tg-${this.state.selectItemContent}`}></span> : <Icon type="warning" theme="filled" />}</Button>
            </Popover>
        )

        const renderIconInput = () => {
            return (<Popover overlayClassName='iconlist-wrap' mouseLeaveDelay={0} placement="bottomRight" content={seacherList} trigger="focus"><Input className={findFlag ? 'style-input' : 'style-input illegal-content'} size="small" allowClear value={this.state.selectItemContent} onChange={this.searchIcon}
                addonAfter={renderIconBtn} /></Popover>)
        };

        return (
            <Form>
                {/* Point Type */}
                < Form.Item >
                    <Row type="flex" className={this.state["point-type"] ? "" : 'opacity06'}>
                        <Col span={11} className="style-label">Point Type</Col>
                        <Col span={11} offset={2}>
                            <Select size="small" className="style-input" onChange={this.handlePointType} value={this.state["point-type"] ? this.state["point-type"] : 'none'}  >
                                <Option value="none">None</Option>
                                <Option value="symbol">Symbol</Option>
                                <Option value="glyph">Glyph</Option>
                            </Select>
                        </Col>
                    </Row>
                </Form.Item >

                {/* Point Glyph */}
                < Form.Item className={this.state['point-type'] === 'glyph' ? "" : "dispaly-none"} >
                    <Row type="flex" justify="space-around" align="middle" className='icon-list-wrap'>
                        {
                            renderIconInput()
                        }
                    </Row>

                </Form.Item >

                {/* Point Symbol Type */}
                < Form.Item className={this.state['point-type'] === 'symbol' ? "" : "dispaly-none"} >
                    <Row type="flex" justify="space-around" align="middle">
                        <Col span={3} className={this.state["point-symbol-type"] ? "" : 'opacity06'}   >
                            <Tooltip title="Symbol Type"><IconFont className="style-icon" type="icon-type" /></Tooltip>
                        </Col>
                        <Col span={21} className={this.state["point-symbol-type"] ? "" : 'opacity06'} >
                            <Radio.Group size="small" onChange={this.handlePointSymbolTypel} className='inputRadio symbol-type' value={this.state["point-symbol-type"] ? this.state["point-symbol-type"] : 'square'}   >
                                <Radio.Button value="circle">
                                    <Tooltip title="Circle">
                                        <IconFont type="icon-yuan" />
                                    </Tooltip>
                                </Radio.Button>
                                <Radio.Button value="square">
                                    <Tooltip title="Square">
                                        <IconFont type="icon-juxing" />
                                    </Tooltip>
                                </Radio.Button>
                                <Radio.Button value="triangle">
                                    <Tooltip title="Triangle">
                                        <IconFont type="icon-sanjiaoxing-copy" />
                                    </Tooltip>
                                </Radio.Button>

                                <Radio.Button value="star">
                                    <Tooltip title="Star">
                                        <IconFont type="icon-xing" />
                                    </Tooltip>
                                </Radio.Button>
                            </Radio.Group>
                        </Col>
                    </Row>
                </Form.Item >

                {/* Point File */}
                {/* <Form.Item className={this.state['point-type'] === 'image' ? "" : "dispaly-none"}>
                    <Row type="flex" justify="space-around" align="middle" className={this.state["point-file"] ? "" : 'opacity06'}    >
                        <Col span={10} className="style-label">Icon Img</Col>
                        <Col span={14}>
                            <Upload {...props}>
                                <Button size="small" className="style-input">
                                    <Icon type="upload" /> Upload
                                </Button>
                            </Upload>
                        </Col>
                    </Row>
                </Form.Item> */}

                {/* Point Size&Point Color */}
                <Form.Item className={this.state.pointType === 'image' ? "dispaly-none" : ""}>
                    <Row type="flex" justify="space-around" align="middle">
                        <Col span={3} className={this.state["point-fill"] ? "" : 'opacity06'}><Tooltip title="Icon Color "><Icon className="style-icon" type="bg-colors" /></Tooltip> </Col>
                        <Col span={8}>
                            <ColorPicker color={this.state["point-fill"] ? this.state["point-fill"] : '#000000'} updateColor={this.handlePointFill}>
                            </ColorPicker>
                        </Col>
                        <Col span={3} offset={2} className={this.state["point-size"] ? "" : 'opacity06'} ><Tooltip title="Icon Size"><IconFont className="style-icon" type="icon-size" /></Tooltip> </Col>
                        <Col span={8} className={this.state["point-size"] ? "" : 'opacity06'} >
                            <InputNumber size="small" className="style-input" min={0} max={100} value={this.state["point-size"] ? this.state["point-size"] : 0} onChange={this.handlePointSize} />
                        </Col>
                    </Row>
                </Form.Item>

                {/* Point Opacity&Point Rotate Angle */}
                <Form.Item>
                    <Row type="flex" justify="space-around" align="middle">
                        <Col className={this.state["point-opacity"] !== undefined ? "" : 'opacity06'} span={3} ><Tooltip title="Icon Opacity "><IconFont className="style-icon" type="icon-opacity" /></Tooltip> </Col>
                        <Col className={this.state["point-opacity"] !== undefined ? "" : 'opacity06'} span={8}>
                            <InputNumber size="small" className="style-input" min={0} max={1} value={this.state["point-opacity"] ? this.state["point-opacity"] : 1} step={0.1} onChange={this.handlePointOpacity} />
                        </Col>
                        <Col className={this.state["point-rotate-angle"] ? "" : 'opacity06'} span={3} offset={2}><Tooltip title="Icon Rotation 0 ~ 360 "><IconFont className="style-icon" type="icon-rotate" /></Tooltip> </Col>
                        <Col className={this.state["point-rotate-angle"] ? "" : 'opacity06'} span={8}>
                            <InputNumber size="small" className="style-input" min={0} max={360} value={this.state["point-rotate-angle"] ? this.state["point-rotate-angle"] : 0} onChange={this.handlePointRotateAngle} />
                        </Col>
                    </Row>
                </Form.Item>


                <Form.Item>
                    <Row type="flex" justify="space-around" align="middle">
                        <Col className={this.state["point-dx"] ? "" : 'opacity06'} span={3}><Tooltip title="Offset-X "><IconFont className="style-icon" type="icon-offset_x" /></Tooltip> </Col>
                        <Col className={this.state["point-dx"] ? "" : 'opacity06'} span={8}  >
                            <InputNumber size="small" className="style-input" min={-200} max={200} onChange={this.handlePointDx} value={this.state["point-dx"] ? this.state["point-dx"] : 0} />
                        </Col>
                        <Col className={this.state["point-dy"] ? "" : 'opacity06'} span={3} offset={2}><Tooltip title="Offset-Y "><IconFont className="style-icon" type="icon-offset_y" /></Tooltip> </Col>
                        <Col className={this.state["point-dy"] ? "" : 'opacity06'} span={8} >
                            <InputNumber size="small" className="style-input" min={-200} max={200} onChange={this.handlePointDy} value={this.state["point-dy"] ? this.state["point-dy"] : 0} />
                        </Col>
                    </Row>
                </Form.Item>


                <Form.Item>
                    <Row type="flex" justify="space-around" align="middle">
                        <Col className={this.state["point-outline-color"] ? "" : 'opacity06'} span={3}  >
                            <Tooltip title="Outline Color"><IconFont className="style-icon" type="icon-icon-outline-color" />

                            </Tooltip>
                        </Col>
                        <Col className={this.state["point-outline-color"] ? "" : 'opacity06'} span={8}    >
                            <ColorPicker color={this.state["point-outline-color"] ? this.state["point-outline-color"] : '#000000'} updateColor={this.handlePointOutlineColor}>
                            </ColorPicker>
                        </Col>
                        <Col className={this.state["point-outline-width"] ? "" : 'opacity06'} span={3} offset={2}  >
                            <Tooltip title="Outline Width"><IconFont className="style-icon " type="icon-icon-outline-width" />

                            </Tooltip>
                        </Col>
                        <Col className={this.state["point-outline-width"] ? "" : 'opacity06'} span={8}    >
                            <InputNumber size="small" className="style-input" min={0} max={100} value={this.state["point-outline-width"] ? this.state["point-outline-width"] : 0} onChange={this.handlePointOutlineWidth} />
                        </Col>


                    </Row>
                </Form.Item>
            </Form >
        );
    }
}

export default PointIcon;