import { createStore, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk';
import DevTools from '../devTools'
import reducers from '../reducers'

import { persistStore } from 'redux-persist';

const enhancer = compose(
  applyMiddleware(thunk),
  //monitors
  DevTools.instrument()
)

export const configureStore = (initialState) => {
  const store = createStore(reducers, initialState, enhancer);
  persistStore(store);
  
  return store;
}