import React from 'react';

import './index.scss';

class LatLon extends React.Component {
	render() {
    const { centerPoint } = this.props;
		const latLonCoord = centerPoint.concat().reverse();
	 
		return <div className="latLon"> {latLonCoord[0].toFixed(6) + ',' + latLonCoord[1].toFixed(6)} </div>;
	}
}

export default LatLon;
