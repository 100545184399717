import { connect } from 'react-redux';
import './index.scss';
import React from 'react';
import { Modal, Icon, Row, Col, Tooltip, Tabs } from 'antd';
import JSZip from 'jszip'
import axios from 'axios'
const { TabPane } = Tabs;

const html = ['<!DOCTYPE html>',
	'<html lang="en">',
	'<head>',
	'    <meta charset="UTF-8">',
	'    <meta name="viewport" content="width=device-width, initial-scale=1.0">',
	'    <meta http-equiv="X-UA-Compatible" content="ie=edge">',
	'    <title> Vector Tiles </title>',
	'</head>',
	'<body>',
	'    <!-- This <div> is the container into which our map control will be loaded. -->',
	'    <div id="map">',
	'        <!-- Set up error message panel. -->',
	'        <div id="error-modal" class="hide">',
	'            <div class="modal-content">',
	'                <p>We\'re having trouble communicating with the ThinkGeo Cloud. Please check the API key being used in',
	'                    this',
	'                    sample\'s JavaScript source code, and ensure it has access to the ThinkGeo Cloud services you are',
	'                    requesting. You can create and manage your API keys at <a href="https://cloud.thinkgeo.com"',
	'                        target="_blank" rel="noopener">https://cloud.thinkgeo.com</a>.</p>',
	'                <button>OK</button>',
	'            </div>',
	'        </div>',
	'    </div>',
	'</body>',
	'</html>'].join("");

const js = `/*===========================================================================*/
// Vector Tiles
// Sample map by ThinkGeo
// 
//   1. ThinkGeo Cloud API Key
//   2. Map Control Setup
//   3. Changing the Map Style
//   4. ThinkGeo Map Icon Fonts
//   5. Tile Loading Event Handlers
/*===========================================================================*/


/*---------------------------------------------*/
// 1. ThinkGeo Cloud API Key
/*---------------------------------------------*/

// First, let's define our ThinkGeo Cloud API key, which we'll use to
// authenticate our requests to the ThinkGeo Cloud API.  Each API key can be
// restricted for use only from a given web domain or IP address.  To create your
// own API key, you'll need to sign up for a ThinkGeo Cloud account at
// https://cloud.thinkgeo.com.
const apiKey = 'WPLmkj3P39OPectosnM1jRgDixwlti71l8KYxyfP2P0~';


/*---------------------------------------------*/
// 2. Map Control Setup
/*---------------------------------------------*/

// Now, we'll create the two different styles of layers for our map. 
// The two styles of layers use ThinkGeo Cloud Maps Vector Tile service to 
// display the detailed light style street map and dark style street map.
// For more info, see our wiki: 
// https://wiki.thinkgeo.com/wiki/thinkgeo_cloud_maps_vector_tiles
let mapstyle = new ol.mapsuite.VectorTileLayer('./thinkgeo-world-streets.json', {
    apiKey: apiKey,
    layerName: 'mapstyle'
});
 
// This function will create and initialize our interactive map.
// We'll call it later when our POI icon font has been fully downloaded,
// which ensures that the POI icons display as intended.
let map;
let initializeMap = () => {
    map = new ol.Map({
        renderer:'webgl',
        loadTilesWhileAnimating: true,
        loadTilesWhileInteracting: true,

        // Add our two previously-defined ThinkGeo Cloud Vector Tile layers to the map.
        layers: [mapstyle],
        // States that the HTML tag with id="map" should serve as the container for our map.
        target: 'map',
        // Create a default view for the map when it starts up.
        view: new ol.View({

            // Center the map on the United States and start at zoom level 3.
            center: ol.proj.fromLonLat([-96.79620, 32.79423]),
            maxResolution: 40075016.68557849 / 512,
            zoom: 3,
            minZoom: 2,
            maxZoom: 19
        })
    });

    // Add a button to the map that lets us toggle full-screen display mode.
    map.addControl(new ol.control.FullScreen());
}

/*---------------------------------------------*/
// 3. ThinkGeo Map Icon Fonts
/*---------------------------------------------*/

// Finally, we'll load the Map Icon Fonts using ThinkGeo's WebFont loader. 
// The loaded Icon Fonts will be used to render POI icons on top of the map's 
// background layer.  We'll initalize the map only once the font has been 
// downloaded.  For more info, see our wiki: 
// https://wiki.thinkgeo.com/wiki/thinkgeo_iconfonts 
WebFont.load({
    custom: {
        families: ["vectormap-icons"],
        urls: ["https://cdn.thinkgeo.com/vectormap-icons/2.0.0/vectormap-icons.css"],
        testStrings: {
          'vectormap-icons': '\ue001'
        }
    },
    // The "active" property defines a function to call when the font has
    // finished downloading.  Here, we'll call our initializeMap method.
    active: initializeMap
});


/*---------------------------------------------*/
// 4. Tile Loading Event Handlers
/*---------------------------------------------*/

// These events allow you to perform custom actions when 
// a map tile encounters an error while loading.
const errorLoadingTile = () => {
    const errorModal = document.querySelector('#error-modal');
    if (errorModal.classList.contains('hide')) {
        // Show the error tips when Tile loaded error.
        errorModal.classList.remove('hide');
    }
}

const setLayerSourceEventHandlers = (layer) => {
    let layerSource = layer.getSource();
    layerSource.on('tileloaderror', function () {
        errorLoadingTile();
    });
}

setLayerSourceEventHandlers(light);
setLayerSourceEventHandlers(dark);

document.querySelector('#error-modal button').addEventListener('click', () => {
    document.querySelector('#error-modal').classList.add('hide');
})`

const css = `body {
  background-color: #f0f0f0;
}

#map {
  position: relative;
  width: 100%;
  height: 100%;
}

#wrap {
  position: absolute;
  top: 1em;
  right: 0.5em;
  z-index: 1000;

}

.ol-control button {
  height: 1.2em !important;
  width: 1.2em !important;
  border: solid 1px #ccc;
  font-size: 1.5em !important;
  background-color: #fff !important;
  color: #2d3c4d;
  cursor: pointer;
  border-radius: 2px;
}

.ol-control button:focus {
  outline: unset;
}

.ol-full-screen {
  right: unset;
  left: 0.5em;
  top: 5.5em;
}

#error-modal {
  position: fixed;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 99;
  background-color: #0000006e;
}

.modal-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 600px;
  height: auto;
  text-align: right;
  padding: 10px;
  border-radius: 5px;
  font-size: 1.1rem;
  line-height: 1.5;
  background-color: #f0f0f0;
  border: 1px solid #b8b8b8;
}

.modal-content p {
  text-align: left;
}

.modal-content button {
  color: #fff;
  background-color: #3d3d3e;
  border-color: #3d3d3e;
  border-radius: 4px;
  padding: 0 7px;
  height: 30px;
  width: 60px;
  cursor: pointer;
  margin-right: 20px;
}

.hide {
  display: none;
}

@media (max-width: 767px) {
  .modal-content {
    width: 80vw;
  }
}`

class SaveStyle extends React.Component {
	state = { visible: false };
	showModal = () => {
		this.setState({
			visible: true,
			loading: true
		});
	};

	onChange = (checked) => {
		this.setState({ loading: !checked });
	};

	handleOk = () => {
		this.setState({
			visible: false
		});
	};

	handleCancel = () => {
		this.setState({
			visible: false
		});
	};

	saveStyleJson = () => {
		this.setState({
			visible: false
		});
		let aTag = document.createElement('a');
		let json = localStorage.getItem('styleJson');
		if (!json) {
			json = this.props.json;
			aTag.download = `${json.id}.json`;
			json = JSON.stringify(json);
		} else {
			aTag.download = `${JSON.parse(json).id}.json`;
		}
		let blob = new Blob([json]);
		aTag.href = URL.createObjectURL(blob);
		aTag.click();
		URL.revokeObjectURL(blob);
	};

	downloadWebSamole = () => {
		let zip = new JSZip();
		zip.file("index.html", html);
		zip.file("script.js", js);
		zip.file("style.css", css);
		let json = localStorage.getItem('styleJson');
		if (!json) {
			json = this.props.json;
			json = JSON.stringify(json);
			zip.file("thinkgeo-world-streets.json", json);
		} else {
			zip.file("thinkgeo-world-streets.json", json);
		}
		zip.generateAsync({ type: "blob" }).then(function (content) {
			let filename = "example.zip";
			let eleLink = document.createElement('a');
			eleLink.download = filename;
			eleLink.style.display = 'none';
			eleLink.href = URL.createObjectURL(content);
			document.body.appendChild(eleLink);
			eleLink.click();
			document.body.removeChild(eleLink);
		});
	}

	render() {
		return (
			<React.Fragment>
				<div className="top-menu-item" onClick={this.showModal}>
					<Tooltip placement="bottom">
						<Icon type="download" /> <span>Download</span>
					</Tooltip>
				</div>
				<Modal
					className="savestyle topMenu"
					visible={this.state.visible}
					onOk={this.handleOk}
					onCancel={this.handleCancel}
					footer={null}
				>
					<Tabs defaultActiveKey="Style" animated={false}>
						<TabPane tab="Style" key="Style">
							<div className="link-margin">
								<h4>Download Style </h4>

								<p>Download a JSON style to your computer.</p>

								<a href="javascript: void(0)" onClick={this.saveStyleJson}>
									<Icon type="download" /> Download StyleJson
								</a>
							</div>

							<div className="link-margin disabled ">
								<h4>
									Save Style <span style={{ fontSize: '12px' }}>(Coming Soon)</span>
								</h4>

								<p>Save style under your ThinkGeo account.</p>

								<a >
									<Icon type="login" /> Sign in to ThinkGeo Cloud
								</a>
							</div>
						</TabPane>
						<TabPane tab="Samples" key="Samples">
							<Row className="link-margin   ">
								<h4>
									Download Multi-platform Sample{' '}
									<span style={{ fontSize: '12px' }}> </span>{' '}
								</h4>

								<p>Download the Sample for multi-platform use styles .</p>

							</Row>
							<Row className="  ">
								<Col span={4} className="link-margin-min">
									<span>Web:</span>
								</Col>

								<Col span={10} className="link-margin-min">
									<a href="javascript: void(0)" onClick={this.downloadWebSamole}>
										<Icon type="download" /> Download JavaScript Sample
									</a>
								</Col>
							</Row>
							<h4 className="link-margin ">Coming Soon</h4>
							<Row className="link-margin disabled">
								<Col span={4} >
									<span>Mobile:</span>
								</Col>

								<Col span={10}>
									<a>
										<Icon type="download" /> Download Android Sample
									</a>
								</Col>
								<Col span={10}>
									<a>
										<Icon type="download" /> Download iOS Sample
									</a>
								</Col>

							</Row>

							<Row className="link-margin disabled">

								<Col span={4}>
									<span>Desktop:</span>
								</Col>
								<Col span={10}>
									<a>
										<Icon type="download" /> Download Winforms Sample
									</a>
								</Col>
								<Col span={10}>
									<a>
										<Icon type="download" /> Download WPF Sample
									</a>
								</Col>
							</Row>
						</TabPane>
					</Tabs>
				</Modal>
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state) => ({
	json: state.rootReducer.json
});

export default connect(mapStateToProps)(SaveStyle);
