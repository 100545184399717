import React, { Component } from 'react';
import PolygonContent from './content';
import './index.scss';
import _ from 'lodash'
import { Tabs } from 'antd';
const TabPane = Tabs.TabPane;

class Polygon extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...this.props.activeStyle
        }

        // Update Json code viewer.
        const activeStyle = JSON.stringify(this.props.activeStyle) === "{}" ? {} : this.state;
        const currentStyle = JSON.parse(JSON.stringify(activeStyle));
        delete currentStyle['id'];
        this.props.handleJsonViewerChange(currentStyle);
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            ...nextProps.activeStyle
        })
    }

    componentWillUpdate = (nextProps, nextState) => {
        // Update Json code viewer.
        const activeStyle = JSON.stringify(nextProps.activeStyle) === "{}" ? {} : nextState;
        const currentStyle = JSON.parse(JSON.stringify(activeStyle));
        delete currentStyle['id'];
        this.props.handleJsonViewerChange(currentStyle);
    }

    updatedStyle = (style) => {
        const style_ = _.cloneDeep(style)
        delete style_['id'];
        Object.keys(style_).map((style_Key) => {
            if (style_[style_Key] == undefined || style_[style_Key] == 'ocean') {
                delete style_[style_Key]
            }
        })
        this.props.updatedStyle(style_)
    }

    render() {
        const activeStyle = JSON.stringify(this.props.activeStyle) === "{}" ? {} : this.state;
        
        return (
            <React.Fragment>
                <PolygonContent activeStyle={activeStyle} updatedStyle={this.updatedStyle} ></PolygonContent>
            </React.Fragment>
        );
    }
}

export default Polygon;