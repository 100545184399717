import React, { Component } from 'react'
import { Form, Icon, Tooltip, Select, Row, Col, InputNumber, Radio, Switch, TreeSelect } from 'antd';
import '../index.scss'
import ColorPicker from '../../../tool/colorPicker';
import IconFont from '../../../tool/iconFont';
import _ from 'lodash'
const { TreeNode } = TreeSelect;
const Option = Select.Option;
const fontFamily = ['Arial', 'Helvetica', 'Verdana', 'Trebuchet MS', 'Arial Narrow', 'sans-serif', 'Georgia', 'Times', 'Times New Roman', 'serif', 'Courier', 'OCR A Std', 'Andale Mono', 'monospace', 'Comic Sans MS', 'Brush Script MT', 'Brush Script Std', 'cursive', 'Stencil Std'];
const OpenSans = ['Open Sans', 'Lato', 'Oswald', 'Montserrat', 'Raleway', 'Roboto ']
class Font extends Component {

    constructor(props) {
        super(props);
        this.state = {
            ...this.props.activeStyle, ...this.processingData(this.props)
        }

    }
    componentWillReceiveProps(nextProps) {
        const state = _.cloneDeep(this.state)
        Object.keys(state).map((stateKey) => {
            state[stateKey] = undefined
        })

        this.setState({ ...state, ...nextProps.activeStyle, ...this.processingData(nextProps) })
    }
    processingData = (props) => {
        let obj = {

        }

        let textFont;
        if (props.activeStyle['text-font']) {
            textFont = props.activeStyle['text-font'];
        } else {
            textFont = "normal 14px Arial, Helvetica, sans-serif";
        }

        obj = {
            "text-font-family": []
        }

        const fontFamilyAll = fontFamily.concat(OpenSans)

        textFont.split(',').forEach((item, index) => {
            if (index === 0) {
                let arr = item.split(' ');
                let str = arr[arr.length - 2] + ' ' + arr[arr.length - 1]
                let strWithWorld = arr[arr.length - 3] + ' ' + arr[arr.length - 2] + ' ' + arr[arr.length - 1]
                if (fontFamilyAll.includes(strWithWorld.replace(/\'/g, "").replace(/\"/g, ""))) {
                    obj["text-font-family"][0] = strWithWorld

                } else if (fontFamilyAll.includes(str.replace(/\'/g, "").replace(/\"/g, ""))) {
                    obj["text-font-family"][0] = str
                } else {
                    str = null
                }
                item.split(' ').forEach((element) => {
                    if (element == 100 || element == 200 || element == 300 || element == 400 || element == 500 || element == 600 || element == 700 || element == 800 || element == 900 || element == 'bold' || element == 'normal') {
                        obj["text-font-weight"] = element
                    } else if (element.indexOf('px') !== -1) {
                        obj["text-font-size"] = parseInt(element)
                    } else if (element == 'italic' || element == 'oblique') {
                        obj["text-font-style"] = element
                    } else if (element == 'small-caps') {
                        obj["text-font-variant"] = element
                    } else if (fontFamilyAll.includes(element.replace(/\'/g, "").replace(/\"/g, "")) && !str) {
                        obj["text-font-family"].push(element)
                    }
                })
            } else {
                item = item.replace(/^\s+|\s+$/g, "")
                if (fontFamilyAll.includes(item)) {
                    obj["text-font-family"].push(item)
                }
            }
        });

        return obj
    }

    handleFontWeight = (value) => {
        this.setState({ "text-font-weight": value }, () => {
            this.props.updatedStyle(this.state)
        });


    }
    handleFontFamily = (value) => {
        const fontFamily_ = JSON.parse(JSON.stringify(this.state["text-font-family"]));
        fontFamily_[0] = OpenSans.includes(value) ? `'${value}'` : value;
        this.setState({ "text-font-family": fontFamily_ }, () => {
            this.props.updatedStyle(this.state)
        });

    }
    handleFontSize = (value) => {
        if (this.state['text-fill'] === undefined) {
            this.setState({ "text-font-size": value, "text-fill": '#000000' }, () => {
                this.props.updatedStyle(this.state)
            });
        } else {
            this.setState({ "text-font-size": value }, () => {
                this.props.updatedStyle(this.state)
            });
        }

    }

    handleFontStyle = (checked) => {
        if (checked) {
            this.setState({ 'text-font-style': 'oblique' }, () => {
                this.props.updatedStyle(this.state)
            });
        } else {
            delete this.state['text-font-style']
            this.setState({}, () => {
                this.props.updatedStyle(this.state)
            })
        }


    }
    handleFontVariant = (checked) => {
        if (checked) {
            this.setState({ 'text-font-variant': 'small-caps' }, () => {
                this.props.updatedStyle(this.state)
            });
        } else {
            delete this.state['text-font-variant']
            this.setState({}, () => {
                this.props.updatedStyle(this.state)
            });
        }
    }

    handleTextAlignChange = (e) => {
        this.setState({ "text-align": e.target.value }, () => {
            this.props.updatedStyle(this.state)
        });
    }
    handleTextPlacement = (e) => {
        this.setState({ "text-placements": e.target.value }, () => {
            this.props.updatedStyle(this.state)
        });
    }

    handleTextBaseline = (e) => {
        this.setState({ "text-baseline": e.target.value }, () => {
            this.props.updatedStyle(this.state)
        });

    }

    handleTextname = (value) => {
        this.setState({ 'text-name': value }, () => {
            this.props.updatedStyle(this.state)
        });

    }
    handleTextrotateangle = (value) => {
        let hd = (value / 180) * Math.PI
        this.setState({ 'text-rotate-angle': hd }, () => {
            this.props.updatedStyle(this.state)
        });
    }
    handleTextopacity = (value) => {
        this.setState({ 'text-opacity': value }, () => {
            this.props.updatedStyle(this.state)
        });

    }
    handleTexthalofill = (color) => {
        this.setState({ 'text-halo-fill': color }, () => {
            this.props.updatedStyle(this.state)
        });

    }
    handleTextwrapwidth = (value) => {
        this.setState({ 'text-wrap-width': value }, () => {
            this.props.updatedStyle(this.state)
        });

    }
    handleTextfill = (color) => {
        this.setState({ 'text-fill': color }, () => this.props.updatedStyle(this.state));
    }
    handleTexthaloradius = (value) => {
        this.setState({ 'text-halo-radius': value }, () => {
            this.props.updatedStyle(this.state)
        });

    }
    handleTextlettercase = (value) => {
        this.setState({ 'text-letter-case': value }, () => {
            this.props.updatedStyle(this.state)
        });

    }
    handleTextDx = (value) => {
        this.setState({ 'text-dx': value }, () => {
            this.props.updatedStyle(this.state)
        });

    }
    handleTextDy = (value) => {
        this.setState({ 'text-dy': value }, () => {
            this.props.updatedStyle(this.state)
        });

    }
    handleTextSpacing = (value) => {
        this.setState({ 'text-letter-spacing': value }, () => {
            this.props.updatedStyle(this.state)
        });
    }


    render() {

        const renderFontFamily = () => fontFamily.map((item, index) => {
            return (<TreeNode key={item} value={item} title={item} style={{ fontFamily: item }} />)
        })
        const renderOpenSans = () => OpenSans.map((item, index) => {
            return (<TreeNode key={item} value={item} title={item} style={{ fontFamily: `'${item}'` }} />)
        })


        return (
            <Form>
                <Form.Item   >
                    <Row type="flex" justify="space-around" align="middle"   >

                        <Col span={3} className={this.state["text-font-family"] ? "" : 'opacity06'} >  <Tooltip title="Font Family Name"><IconFont className="style-icon  " type="icon-bx-font-family" /></Tooltip>   </Col>
                        <Col span={21} className={this.state["text-font-family"] ? "" : 'opacity06'} >
                            {/* <Select size="small" className="style-input" mode="multiple"
                                style={{ width: '100%' }}
                                placeholder="Please select" value={this.state["text-font-family"] ? this.state["text-font-family"] : []} onChange={this.handleFontFamily}>
                                {renderFontFamily()}
                            </Select> */}
                            <TreeSelect
                                
                                style={{ width: '100%' }}
                                dropdownStyle={{ maxHeight: 300, overflow: 'auto' }}
                                placeholder="Please select"

                                treeDefaultExpandAll
                                onChange={this.handleFontFamily}
                                className="style-input"
                                value={this.state["text-font-family"] ? this.state["text-font-family"][0].replace(/\'/g, "").replace(/\"/g, "") : 'Arial'}
                            >


                                {renderFontFamily()}
                                {renderOpenSans()}

                            </TreeSelect>

                        </Col>

                    </Row>
                </Form.Item>
                <Form.Item>
                    <Row type="flex" justify="space-around" align="middle">
                        <Col span={3} className={this.state["text-fill"] ? "" : 'opacity06'}  ><Tooltip title="Font Color "><Icon className="style-icon" type="font-colors" /></Tooltip> </Col>
                        <Col span={8}    >
                            <ColorPicker color={this.state["text-fill"] ? this.state["text-fill"] : '#000000'} updateColor={this.handleTextfill}   >
                            </ColorPicker>
                        </Col>

                        <Col span={3} className={this.state["text-font-size"] ? "" : 'opacity06'} offset={2}><Tooltip title="Font Size"><Icon className="style-icon" type="font-size" /></Tooltip> </Col>
                        <Col span={8} className={this.state["text-font-size"] ? "" : 'opacity06'} >
                            <InputNumber size="small" className="style-input" min={8} max={56} value={this.state["text-font-size"] ? this.state["text-font-size"] : 14} onChange={this.handleFontSize} />
                        </Col>
                    </Row>
                </Form.Item>
                <Form.Item>
                    <Row type="flex" justify="space-around" align="middle">
                        {/* <Col span={3} className={this.state["text-name"] ? "" : 'opacity06'} >
                            <Tooltip title="Language"><Icon className="style-icon" type="global" /></Tooltip>
                        </Col>
                        <Col span={8} className={this.state["text-name"] ? "" : 'opacity06'}>
                            <Select size="small" className="style-input" value={this.state["text-name"] ? this.state["text-name"] : "name_en"} onChange={this.handleTextname}  >
                                <Option value="name_en">English</Option>
                                <Option value="name_zh">Chinese</Option>
                                <Option value="name_ar">Aribic</Option>
                                <Option value="name_de">Geman</Option>
                            </Select>
                        </Col> */}
                        <Col span={3} className={this.state["text-font-weight"] ? "" : 'opacity06'} ><Tooltip title="Font Weight"><IconFont type="icon-bold" className="style-icon" /></Tooltip> </Col>
                        <Col span={8} className={this.state["text-font-weight"] ? "" : 'opacity06'} >
                            <Select size="small" className="style-input" value={this.state["text-font-weight"] ? this.state["text-font-weight"] : 'normal'} onChange={this.handleFontWeight} >
                                <Option value="normal">normal</Option>
                                <Option value="bold">bold</Option>
                                <Option value="100">100</Option>
                                <Option value="200">200</Option>
                                <Option value="300">300</Option>
                                <Option value="400">400</Option>
                                <Option value="500">500</Option>
                                <Option value="600">600</Option>
                                <Option value="700">700</Option>
                                <Option value="800">800</Option>
                                <Option value="900">900</Option>

                            </Select>
                        </Col>
                        <Col span={3} offset={2} className={this.state['text-font-style'] ? "" : 'opacity06'}><Tooltip title="Font Italic of Label "><Icon className="style-icon" type="italic" /></Tooltip> </Col>
                        <Col span={8} className={(this.state['text-font-style'] ? "" : 'opacity06') + ' ' + ('input-check')}>
                            <Switch checkedChildren={'Italic'} unCheckedChildren={'Normal'} checked={this.state['text-font-style'] ? true : false} onChange={this.handleFontStyle} />
                        </Col>

                    </Row>
                </Form.Item>
                <Form.Item>
                    <Row type="flex" justify="space-around" align="middle">
                        <Col span={3} className={this.state["text-rotate-angle"] ? "" : 'opacity06'}><Tooltip title="Label Rotation in Degree (0~360) "><IconFont className="style-icon" type="icon-rotate-left" /></Tooltip> </Col>
                        <Col span={8} className={this.state["text-rotate-angle"] ? "" : 'opacity06'}>
                            <InputNumber size="small" className="style-input" min={0} max={360} value={this.state["text-rotate-angle"] ? Math.round((this.state["text-rotate-angle"] / Math.PI) * 180) : 0} onChange={this.handleTextrotateangle} />
                        </Col>
                        <Col span={3} className={this.state["text-opacity"] !== undefined ? "" : 'opacity06'} offset={2}><Tooltip title=" Opacity"><IconFont className="style-icon" type="icon-opacity" /></Tooltip> </Col>
                        <Col span={8} className={this.state["text-opacity"] !== undefined ? "" : 'opacity06'} >
                            <InputNumber size="small" className="style-input" min={0} step={0.1} max={1} value={this.state["text-opacity"] !== undefined ? this.state["text-opacity"] : 1} onChange={this.handleTextopacity} />
                        </Col>
                    </Row>
                </Form.Item>
                <Form.Item>
                    <Row type="flex" justify="space-around" align="middle">
                        <Col span={3} className={this.state["text-letter-case"] ? "" : 'opacity06'}  ><Tooltip title="Letter Case of Label"><IconFont className="style-icon" type="icon-case" /></Tooltip> </Col>
                        <Col span={8} className={this.state["text-letter-case"] ? "" : 'opacity06'} >
                            <Select size="small" className="style-input" value={this.state["text-letter-case"] ? this.state["text-letter-case"] : 'default'} onChange={this.handleTextlettercase}>
                                <Option value="default">Default</Option>
                                <Option value="uppercase">Uppercase</Option>
                                <Option value="lowercase">Lowercase</Option>
                                {/* <Option value="small-caps">Small caps</Option> */}

                            </Select>
                        </Col>
                        <Col span={3} offset={2} className={this.state["text-font-variant"] ? "" : 'opacity06'}><Tooltip title="variant of Label "><IconFont className="style-icon" type="icon-font-variant" /></Tooltip> </Col>
                        <Col span={8} className={(this.state['text-font-variant'] ? "" : 'opacity06') + ' ' + ('input-check')}>
                            <Switch checkedChildren={'Small-caps'} unCheckedChildren={'Normal'} checked={this.state['text-font-variant'] ? true : false} onChange={this.handleFontVariant} />
                        </Col>
                    </Row>
                </Form.Item>

                <Form.Item>
                    <Row type="flex" justify="space-around" align="middle">
                        <Col span={3} className={this.state["text-halo-fill"] ? "" : 'opacity06'} ><Tooltip title="Outline Color of Label "><IconFont className="style-icon" type="icon-halo-color" /></Tooltip> </Col>
                        <Col span={8}  >
                            <ColorPicker color={this.state["text-halo-fill"] ? this.state["text-halo-fill"] : '#000000'} updateColor={this.handleTexthalofill} >
                            </ColorPicker>
                        </Col>
                        <Col span={3} className={this.state["text-halo-radius"] ? "" : 'opacity06'} offset={2}><Tooltip title="Outline Width of Label"><IconFont className="style-icon" type="icon-halo-font-size" /></Tooltip> </Col>
                        <Col span={8} className={this.state["text-halo-radius"] ? "" : 'opacity06'} >
                            <InputNumber size="small" className="style-input" min={0} max={100} value={this.state["text-halo-radius"] ? this.state["text-halo-radius"] : 0} onChange={this.handleTexthaloradius} />
                        </Col>
                    </Row>
                </Form.Item>
                <Form.Item>
                    <Row type="flex" justify="space-around" align="middle">
                        <Col span={3} className={this.state['text-dx'] ? "" : 'opacity06'} ><Tooltip title="Offset X of Label "><IconFont className="style-icon" type="icon-offset_x" /></Tooltip> </Col>
                        <Col span={8} className={this.state['text-dx'] ? "" : 'opacity06'} >
                            <InputNumber size="small" className="style-input" min={-200} max={200} value={this.state['text-dx'] ? this.state["text-dx"] : 0} onChange={this.handleTextDx} />
                        </Col>
                        <Col span={3} offset={2} className={this.state["text-dy"] ? "" : 'opacity06'}><Tooltip title="Offset Y of Label "><IconFont className="style-icon" type="icon-offset_y" /></Tooltip> </Col>
                        <Col span={8} className={this.state["text-dy"] ? "" : 'opacity06'} >
                            <InputNumber size="small" className="style-input" min={-200} max={200} value={this.state["text-dy"] ? this.state["text-dy"] : 0} onChange={this.handleTextDy} />
                        </Col>
                    </Row>
                </Form.Item>

                <Form.Item>
                    <Row type="flex" ustify="space-around" align="middle">
                        <Col span={3} className={this.state["text-letter-spacing"] ? "" : 'opacity06'}  >
                            <Tooltip title="Spacing Between Charactors"><IconFont className="style-icon" type="icon-text-glyph-spacing1" />

                            </Tooltip>
                        </Col>
                        <Col span={8} className={this.state["text-letter-spacing"] ? "" : 'opacity06'} >
                            <InputNumber size="small" className="style-input" min={0} max={100} value={this.state["text-letter-spacing"] ? this.state["text-letter-spacing"] : 0} onChange={this.handleTextSpacing} />
                        </Col>
                        <Col span={3} className={this.state["text-wrap-width"] ? "" : 'opacity06'} offset={2}><Tooltip title="Wrap Width of Label"><IconFont className="style-icon" type="icon-text-wrap-width" /></Tooltip> </Col>
                        <Col span={8} className={this.state["text-wrap-width"] ? "" : 'opacity06'} >
                            <InputNumber size="small" className="style-input" min={0} max={200} value={this.state["text-wrap-width"] ? this.state["text-wrap-width"] : 0} onChange={this.handleTextwrapwidth} />
                        </Col>
                    </Row>
                </Form.Item>
                <Form.Item>
                    <Row type="flex"  >
                        <Col span={3} className={('style-label') + ' ' + (this.state['text-placements'] ? "" : 'opacity06')} >
                            <Tooltip title=" Text Placement "><IconFont className="style-icon" type="icon-Objectposition" /></Tooltip>
                        </Col>
                        <Col span={21} className={this.state['text-placements'] ? "" : 'opacity06'} >
                            <Radio.Group size="small" className='inputRadio inputRadio-long' value={this.state["text-placements"] ? this.state["text-placements"] : 'C'} onChange={this.handleTextPlacement} >
                                <Radio.Button value="C"> <Tooltip title="text-placements:center "><IconFont type="icon-position-center" /></Tooltip></Radio.Button>
                                <Radio.Button value="U"> <Tooltip title="text-placements:top "><IconFont type="icon-position-top" /></Tooltip></Radio.Button>
                                <Radio.Button value="B"> <Tooltip title="text-placements:bottom "><IconFont type="icon-position-bottom-copy" /></Tooltip></Radio.Button>
                                <Radio.Button value="L"> <Tooltip title="text-placements:left "><IconFont type="icon-position-left" /></Tooltip></Radio.Button>
                                <Radio.Button value="R"> <Tooltip title="text-placements:right "><IconFont type="icon-position-right" /></Tooltip></Radio.Button>
                            </Radio.Group>
                        </Col>
                    </Row>
                </Form.Item>
                <Form.Item>
                    <Row type="flex" justify="space-around" align="middle">
                        <Col span={11} className={('style-label') + ' ' + (this.state['text-align'] ? "" : 'opacity06')} >
                            Text Alignment
                        </Col>
                        <Col span={11} offset={2} className={this.state['text-align'] ? "" : 'opacity06'} >
                            <Radio.Group size="small" className='inputRadio' value={this.state["text-align"] ? this.state["text-align"] : 'center'} onChange={this.handleTextAlignChange} >
                                <Radio.Button value="left"> <Tooltip title="text-align:left "><Icon type="align-left" /></Tooltip></Radio.Button>
                                <Radio.Button value="center"> <Tooltip title="text-align:center "><Icon type="align-center" /></Tooltip></Radio.Button>
                                <Radio.Button value="right"> <Tooltip title="text-align:right "><Icon type="align-right" /></Tooltip></Radio.Button>
                            </Radio.Group>
                        </Col>
                    </Row>
                </Form.Item>
              
                <Form.Item>
                    <Row type="flex" justify="space-around" align="middle">
                        <Col span={11} className={('style-label') + ' ' + (this.state['text-baseline'] ? "" : 'opacity06')} >
                            Text Baseline
                        </Col>
                        <Col span={11} offset={2} className={this.state['text-baseline'] ? "" : 'opacity06'}>
                            <Radio.Group className='inputRadio' size="small" onChange={this.handleTextBaseline} value={this.state["text-baseline"] ? this.state["text-baseline"] : 'middle'}  >
                                <Radio.Button value="top">  <Tooltip title="text-baseline:top "><IconFont type="icon-icverticalalignto" /></Tooltip></Radio.Button>
                                <Radio.Button value="middle"> <Tooltip title="text-baseline:middle "><IconFont type="icon-icverticalalignce" /></Tooltip></Radio.Button>
                                <Radio.Button value="bottom"> <Tooltip title="text-baseline:bottom "><IconFont type="icon-icverticalalignbo" /></Tooltip></Radio.Button>
                            </Radio.Group>
                        </Col>
                    </Row>
                </Form.Item>

                {/* <Form.Item>
                    <Row type="flex" justify="space-around" align="middle">
                        <Col span={3} className={this.state['text-font-style'] ? "" : 'opacity06'}><Tooltip title="Font Italic of Label "><Icon className="style-icon" type="italic" /></Tooltip> </Col>
                        <Col span={8} className={this.state['text-font-style'] ? "" : 'opacity06'}>
                            <Switch checkedChildren={<Icon type="check" />} unCheckedChildren={<Icon type="close" />} checked={this.state['text-font-style'] ? true : false} onChange={this.handleFontStyle} />
                        </Col>
                        <Col span={13}></Col>
                    </Row>
                </Form.Item> */}


            </Form>
        );
    }
}

export default Font