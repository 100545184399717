import React from 'react';
import { connect } from 'react-redux';
import { SketchPicker } from 'react-color';
import { Input } from 'antd';
import './index.scss';

class ColorPicker extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			displayColorPicker: false,
			color: '#000000',
			opacity: false,
			isColorChanged: false
		};
	}

	getColor_ = (color) => {
		const { json } = this.props;
		if (color && color.match(/\@/)) {
			const variables = json.variables;

			return variables[color];
		}
		return color || this.state.color;
	}

	componentWillReceiveProps(nextProps) {
		const { color } = nextProps;

		if (color !== this.state.color) {
			const transformedColor = this.getColor_(color);
			this.setState({
				color: transformedColor,
				initColor: transformedColor,
				opacity: !!color,
				isColorChanged: false
			});
		}
	}

	componentDidMount() {
		const { color } = this.props;
		this.setState({
			color: this.getColor_(color),
			opacity: !!color
		});
	}

	handleClick = () => {
		this.setState({
			displayColorPicker: !this.state.displayColorPicker,
			isColorChanged: false
		});
	};

	handleClose = () => {
		const { color, isColorChanged } = this.state;
		this.setState({
			displayColorPicker: false,
			initColor: color
		});
		isColorChanged && this.props.updateColor(color);
	};

	handleChange = (color) => {
		const { initColor } = this.state;
		const color_ = this.getInputColor(color);

		this.setState({
			color: color_,
			isColorChanged: color_ !== initColor,
			opacity: true
		});
	}

	getInputColor = (color) => {
		const rgba = color.rgb;
		const hex = color.hex;
		return rgba.a !== 1 ? `rgba(${rgba.r},${rgba.g},${rgba.b},${rgba.a})` : hex;
	}

	getSketchPickerColor = (color) => {
		return color.includes('#') ? this.hexToRgba(color) : color;
	}

	hexToRgba = (hex) => {
		let hexColor = /^#/.test(hex) ? hex.slice(1) : hex, r, g, b;
		hexColor = /^[0-9a-f]{3}|[0-9a-f]{6}$/i.test(hexColor) ? hexColor : 'fffff';

		if (hexColor.length === 3) {
			hexColor = hexColor.replace(/(\w)(\w)(\w)/gi, '$1$1$2$2$3$3');
		}
		r = hexColor.slice(0, 2);
		g = hexColor.slice(2, 4);
		b = hexColor.slice(4, 6);
		r = parseInt(r, 16);
		g = parseInt(g, 16);
		b = parseInt(b, 16);

		return {
			hex: '#' + hexColor,
			rgba: 'rgba(' + r + ', ' + g + ', ' + b + ', ' + 1 + ')'
		};
	}

	inputChange = (e) => {
		let value = e.target.value;
		const { initColor } = this.state;

		this.setState({
			color: value,
			isColorChanged: value !== initColor,
			opacity: true
		});
	}

	onBlur = () => {
		this.handleClose();
	}

	rgb2hex = (rgba) => {
		let aColor = rgba.replace(/(?:\(|\)|rgba|RGBA)*/g, "").split(",");
		let strHex = "#"
		if (aColor && aColor.length === 4) {

			for (var i = 0; i < 3; i++) {
				let hex = Number(aColor[i]).toString(16);
				if (hex.length < 2) {
					hex = '0' + hex;
				}
				strHex += hex;
			}

			let a_10 = Number(aColor[3].replace(/\s+/g, ""))

			if (a_10 !== 1) {
				strHex = strHex + Math.ceil(a_10 * 255).toString(16)
			}
			return strHex

		}
	}

	render() {
		const { color, displayColorPicker, opacity } = this.state;
		const styles = {
			color: {
				borderRadius: '2px',
				background: color,
				width: '10px',
				height: '10px'
			},

			swatch: {
				position: 'absolute',
				left: '6px',
				top: '5px',
				borderRadius: '2px',
				padding: '2px',
				background: '#fff',
				boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
				display: 'inline-block',
				cursor: 'pointer'
			},

			popover: {
				position: 'absolute',
				zIndex: '998'
			},
			cover: {
				position: 'fixed',
				top: '0px',
				right: '0px',
				bottom: '0px',
				left: '0px'
			}
		};

		let inputcolor = color;
		if (color && (color.includes('rgba') || color.includes('RGBA'))) {
			inputcolor = this.rgb2hex(color)
		}

		return (
			<div className="color-picker">
				<Input
					size="small"
					value={inputcolor}
					className={'style-input' + ' ' + (opacity ? '' : ' opacity06 ')}
					// onClick={this.handleClick}
					onChange={this.inputChange}
					onBlur={this.onBlur}
				/>
				<div
					style={styles.swatch}
					onClick={this.handleClick}
					className={opacity ? '  ' : 'opacity06'}
				>
					<div style={styles.color} />
				</div>

				{displayColorPicker ? (
					<div style={styles.popover}>
						<div style={styles.cover} onClick={this.handleClose} />
						<SketchPicker color={color} onChange={this.handleChange} />
					</div>
				) : null}
			</div>
		);
	}
}

const mapStateToProps = (state) => ({ json: state.rootReducer.json });

export default connect(mapStateToProps)(ColorPicker);
