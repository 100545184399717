import React from 'react';
import { Tree, Icon, Drawer, Tooltip, Switch, Select, Row, Col } from 'antd';
import ReactJson from 'react-json-view';
import ColorPicker from '../../tool/colorPicker';
import FilterLayerWrap from '../../filter';
import IconFont from '../../tool/iconFont';
import ScrollBar from 'react-perfect-scrollbar';
import { connect } from 'react-redux';
import { updateJson } from '../../../redux/actions';
import { saveLayerData } from '../../../redux/actions/redoUndo';

import 'react-perfect-scrollbar/dist/css/styles.css';
import './index.scss';
import _ from 'lodash';
import { bindActionCreators } from 'redux';

const { TreeNode } = Tree;
const numRegular = /[^\d]/g;

class LayerList extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			visible: false,
			closed: true,
			menuType: '',
			menuTitle: [],
			menuContent: '',
			selected: false,
			canBeDrop: true,
			subid: '',
			filter: {},
			mapstyleArr: [],
			filterIndex: null,
			activeStyle: {},
			selectedKeys: [],
			color: undefined,
			expandedKeys: [],
			viewCode: false,
			shouldLayerOrderUpdate: true,
			eyeClicked: false,
			eyeClickedParent: '',
			isSelectOperation: false,
			localStyleJson: this.props.json,

		};
	}

	arr = [];

	// shouldComponentUpdate = (nextProps, nextState) => {
	// 	if (nextProps.newSidebarData.length === 0 && this.props.newSidebarData.length === 0) {
	// 		return false;
	// 	}
	// 	return true;
	// };

	initState = () => {
		this.setState({
			isSelectOperation: false,
			selectedKeys: [],
			visible: false,
			closed: true,
			expandedKeys: []
		})
	}

	componentDidMount = () => {
		this.props.onRef(this);
	}

	componentWillReceiveProps = (nextProps) => {
		this.handleRedoUndo(nextProps);
		// this.shouldCloseSidebarPanel(nextProps);
		// this.initState();	
		// If there are less than 4 layers in the sidebar list, expand all the layers.
		if (nextProps.expandedKeys.length < 4) {
			this.setState({
				expandedKeys: nextProps.expandedKeys
			})
		}
		// initial panel state
		const { localStyleJson } = this.state;
		const { json } = nextProps;
		if (!localStyleJson.id || json.id !== localStyleJson.id) {
			this.initState();
		}
		this.setState({
			localStyleJson: _.cloneDeep(json),

		})

		// save initial order
		// const {shouldLayerOrderUpdate} = this.state;
		// if(shouldLayerOrderUpdate && nextProps.newSidebarData.length){
		// 	this.saveOrderedLayerData(nextProps.newSidebarData);
		// 	this.setState({
		// 		shouldLayerOrderUpdate: false
		// 	})
		// }		
	};

	updateScrollTop = (selectLayerInfor, newSidebarData) => {
		// const {selectLayerInfor, newSidebarData} = nextProps;

		if (selectLayerInfor) {
			const layerInfor = selectLayerInfor.layerInfor;
			const styleItemId = selectLayerInfor.styleItemId;
			const subclass = layerInfor.class;
			let index;
			let classIndex = 0;
			for (let i = 0; i < newSidebarData.length; i++) {
				const sidebarData = newSidebarData[i];
				if (sidebarData.id === styleItemId) {
					index = i;
					const class_ = sidebarData.class;
					if (class_) {
						classIndex = 1;
						for (let j = 0; j < class_.length; j++) {
							if (class_[j].value === subclass) {
								classIndex += j;
								break;
							}
						}
					} else {
						break;
					}
				}
			}



			if (index > 0) {
				// this._scrollBarRef.updateScroll();
				// (this._container) && (this._container.scrollTop = index * 34);		
				this._scrollBarRef.scrollTop = index * 34 + classIndex * 24;
			}
		}
	}

	handleRedoUndo = (nextProps) => {
		const { localIndex } = this.state;
		const { index, layerDatas } = nextProps;
		const layerData = layerDatas[index];

		// it's a redo undo operation
		if (layerData && (layerData.type === 'initialLayerStyle' || layerData.type === 'layerStyle') &&
			((localIndex !== index))) {
			const data = layerData.data;
			const sidebarState = layerData.sidebarState;

			if (sidebarState) {
				this.setState({
					activeStyle: data,
					selectedKeys: sidebarState.selectedKeys,
					menuTitle: sidebarState.menuTitle,
					type: sidebarState.type,
					value: sidebarState.value,
					pointStyle: sidebarState.pointStyle,
					id: data.id,
					localIndex: index
				});
			}
		}
	}

	handleLayerSelectedOnMap = (selectLayerInfor) => {
		const filterStandard = {};
		Object.keys(selectLayerInfor.filter).forEach((filterKeyItem) => {
			filterStandard[filterKeyItem] = {};
			selectLayerInfor.filter[filterKeyItem].split(';').forEach((item) => {
				if (item.indexOf('<') !== -1) {
					filterStandard[filterKeyItem].maxRange = item.replace(numRegular, '');
				} else if (item.indexOf('>=') !== -1) {
					filterStandard[filterKeyItem].minRange = item.replace(numRegular, '');
				}
			});
		});
		const { colors } = this.props;
		const json = this.state.localStyleJson;
		const style = json.styles;
		let id, menuTitle, selectedKeys, color, activeStyle;
		let value = selectLayerInfor.value;
		const styleItemId = selectLayerInfor.styleItemId;
		if (selectLayerInfor.title) {
			id = styleItemId + '_' + selectLayerInfor.title
			menuTitle = id
			selectedKeys = [id]
		} else {
			value = null;
			id = styleItemId;
			menuTitle = styleItemId;
			selectedKeys = [styleItemId]
		}

		Object.keys(colors).forEach((item) => {
			color = '#f0eee8'
			if (value && styleItemId + '_' + value === item) {
				color = colors[item];
			} else if (styleItemId === item) {
				color = colors[item]
			}
		});

		style.forEach((item) => {
			if (item.id === styleItemId) {
				activeStyle = item
			}
		})

		this.setState({
			menuType: selectLayerInfor.type,
			menuContent: selectLayerInfor.content,
			filter: selectLayerInfor.filter,
			subid: selectLayerInfor.styleItemId,
			pointStyle: selectLayerInfor.pointStyle,
			title: selectLayerInfor.title,
			filterIndex: null,
			filterStandard: filterStandard,
			value: value,
			id: id,
			url: filterStandard.url,
			closed: false,
			activeStyle: activeStyle,
			color: color,
			selectedKeys: selectedKeys,
			menuTitle: [menuTitle],
			expandedKeys: [styleItemId],
			selectLayerInfor: selectLayerInfor,

		});
		const { newSidebarData } = this.props;

		setTimeout(() => { this.updateScrollTop(selectLayerInfor, newSidebarData) }, 1000)
		const { visible, closed } = this.state;
		if (!visible || closed) {
			this.setState({
				visible: true,
				closed: false
			});
		}

	}

	onDragStart = (info) => {
		let startPos = info.node.props.pos;
		let l = startPos.split('-').length;

		this.setState({
			canBeDrop: l < 3
		});
	}

	onDrop = (info) => {
		const { canBeDrop } = this.state;
		if (!canBeDrop) {
			// Drop start in children
			return;
		}

		const nodeProps = info.node.props;
		const { newSidebarData, colors } = this.props;
		const dropKey = nodeProps.eventKey;
		const dragKey = info.dragNode.props.eventKey;
		const dropPos = nodeProps.pos.split('-');
		const length = dropPos.length;

		if (length >= 3) {
			// Drop to the children
			return;
		}

		const dropPosition = info.dropPosition - Number(dropPos[length - 1]);

		const loop = (data, key, callback) => {
			data.forEach((item, index, arr) => {
				if (item.id === key) {
					return callback(item, index, arr);
				}
				if (item.class) {
					return loop(item.class, key, callback);
				}
			});
		};
		const data = [...newSidebarData];

		// Find dragObject
		let dragObj;
		loop(data, dragKey, (item, index, arr) => {
			arr.splice(index, 1);
			dragObj = item;
		});

		if (!info.dropToGap) {
			// Drop on the content
			return;
			// loop(data, dropKey, (item) => {
			// 	item.class = item.class || [];
			// 	// where to insert
			// 	item.class.push(dragObj);
			// });
		} else if (
			(info.node.props.class || []).length > 0 && // Has children
			info.node.props.expanded && // Is expanded
			dropPosition === 1 // On the bottom gap
		) {
			loop(data, dropKey, (item) => {
				item.class = item.class || [];
				// where to insert
				item.class.unshift(dragObj);
			});
		} else {
			let ar;
			let i;
			loop(data, dropKey, (item, index, arr) => {
				ar = arr;
				i = index;
			});
			if (dropPosition === -1) {
				ar.splice(i, 0, dragObj);
			} else {
				ar.splice(i + 1, 0, dragObj);
			}
		}

		this.props.handleSidebarDataChange(data, colors);
		// this.saveOrderedLayerData(data);
	};

	// saveOrderedLayerData = (layersOrder) => {
	// 	const {index, maxLength, layerDatas} = this.props;
	// 	const dataArr = [{
	// 		type: 'layerOrder',
	// 		data: this.getLayerOrder(layersOrder)
	// 	}];

	// 	this.props.saveLayerData(dataArr, index, maxLength, layerDatas);
	// }

	getLayerOrder = (layersOrder) => {
		return layersOrder.map(item => {
			return item.layerName
		})
	}

	onSelect = (selectedKeys, e) => {
		if (selectedKeys[0] === "background") {
			const { visible, closed } = this.state;
			if (!visible || closed) {
				this.setState({
					visible: true,
					closed: false
				});
			}
			const props = e.node.props;
			this.setState({
				menuType: props.menuType,
				menuContent: props.menuContent,
				id: props.id,
				menuTitle: ['background'],
				color: props.menuType,
				selectedKeys: selectedKeys
			});


		} else {
			const { handleSelected, updateSelectedNodes } = this.props;
			const json = this.state.localStyleJson;
			const style = json.styles;
			const props = e.node.props;
			const selectedNodes = e.selectedNodes;

			if (!props.isLeaf) {
				let { expandedKeys } = this.state;
				let expandedKeysTmp = [];
				const eventKey = props.eventKey;

				if (expandedKeys.includes(eventKey)) {
					expandedKeysTmp = expandedKeys.filter((item) => {
						return item !== eventKey;
					});
				} else {
					expandedKeysTmp.push(...expandedKeys, eventKey);
				}

				this.setState({
					expandedKeys: expandedKeysTmp
				});

				return;
			}

			this.setState({ selectedKeys: selectedKeys }, () => {
				this.setActiveStyle(selectedNodes, props, selectedKeys);
			});
			updateSelectedNodes(selectedNodes);
			handleSelected(selectedNodes.length > 0);

			let filterStandard = {};

			Object.keys(props.menuFilter).map((filterKeyItem) => {
				filterStandard[filterKeyItem] = {};
				props.menuFilter[filterKeyItem].split(';').map((item) => {
					if (item.indexOf('<') !== -1) {
						filterStandard[filterKeyItem].maxRange = item.replace(numRegular, '');
					} else if (item.indexOf('>=') !== -1) {
						filterStandard[filterKeyItem].minRange = item.replace(numRegular, '');
					}
				});
			});

			this.setState({
				menuType: props.menuType,
				menuContent: props.menuContent,
				filter: props.menuFilter,
				subid: props.subid,
				mapstyleArr: this.arr,
				filterIndex: null,
				filterStandard: filterStandard,
				value: props.value,
				id: props.id,
				url: props.url,
				title: props.classTitle,
				closed: selectedNodes.length === 0,
				pointStyle: props.pointStyle,
				selectLayerInfor: undefined

			});

			style.map((item) => {
				if ((item.id == props.menuTitle && !props.subid) || item.id == props.subid) {
					this.setState({
						activeStyle: item
					});
				}
			});
		}

	};

	toggleFilterLayerVisible = (newVisible) => {
		const { visible, closed } = this.state;

		if (newVisible !== visible && !closed) {
			this.setState({
				visible: newVisible
			});
		}
	};

	setActiveStyle = (selectedNodes, props, keys) => {
		const json = this.state.localStyleJson;
		const style = json.styles;
		const selectedNodes_ = selectedNodes.filter((node) => {
			if (keys.includes(node.key)) {
				return node;
			}
		});
		let menuTitles = [];
		style.forEach((item) => {
			if (((item.id == props.id && !props.class) || item.id == props.subid) && selectedNodes_.length > 0) {
				selectedNodes_.forEach((node) => {
					menuTitles.push(node.props.menuTitle);
				});

				this.setState({
					color: selectedNodes_[0].props.color,
					menuTitle: menuTitles
				});
			}
		});

		this.setState({
			visible: selectedNodes.length !== 0
		});
	};

	getDrawerContainer() {
		// Specify the HTML nodes which the Drawer are mounted on.
		// So that the Drawer component will under themetype="dark" Ele node and to chenge its style.
		return document.querySelector('.ant-layout');
	}

	closeLayerStyleBox = (e) => {
		this.setState({
			closed: true,
			selectedKeys: []
		});
	};

	addIconByType = (itemType, color) => {
		const iconType =
			itemType === 'polygon' ? 'icon-vector-polygon' : itemType === 'line' ? 'icon-line' : 'icon-font';
		return <IconFont type={iconType} className="type" style={{ backgroundColor: color }} />;
	};

	handleExpand = (expandedKeys, e) => {
		this.setState({
			expandedKeys
		});
	};

	isVisible = (obj) => {
		if (obj.visible === undefined || obj.visible === true) {
			return true;
		} else {
			return false;
		}
	};

	setVisibleForChildren = (obj, visible) => {
		const children = obj['children'];
		if (children) {
			children.forEach(child => {
				visible ? delete child['visible'] : child['visible'] = false;
			})
		} else if (obj['style']) {
			const styles = obj['style'];
			styles.forEach(styleObj => {
				this.setVisibleForChildren(styleObj, visible);
			})
		}
	}

	setVisible = (rootObj, obj, subclassid, subclassname, stylesArr, type) => {
		if (!rootObj) {
			rootObj = obj;
		}
		const classNameRegexp = new RegExp(subclassname, 'g');
		let filter = obj['filter'];
		// Remove the space between letters.
		filter = filter.replace(/\s/g, '');
		if (filter) {
			if (filter.match(classNameRegexp)) {
				// There are classes in the filter

				const filterItems = filter.split(';'); // "zoom=10;class=0" ==> ["zoom=10", "class=0"]  or  "zoom=10;class='0,1,2'" ==> ["zoom=10", "class='0,1,2'"]
				let classFilter;
				filterItems.some((item) => {
					if (item.match(classNameRegexp)) {
						classFilter = item;
						return true;
					}
				}); // classFilter= "class=0"   or   classFilter= "class='0,1,2'"

				const equalRegExp = new RegExp(`${subclassname}=`);
				const unequalRegExp = new RegExp(`${subclassname}!=`);
				if (filter.match(equalRegExp)) {
					// class='0,1,2'
					let classItems = classFilter.split('='); // '0' or "'0,1,2'"
					let classStr = classItems[1].replace(/'/g, ''); // "0,1,2"
					let classItemsArr = classStr.split(','); // ['0']  or  ['0','1','2']
					let classItemsLength = classItemsArr.length;
					if (classItemsArr.indexOf(subclassid) > -1 || classItemsArr.indexOf(`'${subclassid}'`) > -1) {
						// id in class
						if (classItemsLength > 1) {
							// ['0','1','2']
							// Add a new obj to stylesArr, this obj only has subclassid
							const replaceRegExp = new RegExp(`${subclassname}='(\\w*,*)*'`);
							const obj_ = _.cloneDeep(obj);
							let filter_ = filter.replace(replaceRegExp, `${subclassname}='${subclassid}'`); // The original filter will not change.
							obj_['filter'] = filter_;
							const lastVisiblity = this.isVisible(obj_);
							lastVisiblity ? (obj_['visible'] = false) : delete obj_['visible'];
							if (type === 'line') {
								this.setVisibleForChildren(obj_, !lastVisiblity)
							}
							if (!this.isVisible(rootObj) && !lastVisiblity) {
								// Set visible true
								delete rootObj['visible'];
							}
							stylesArr.push(obj_);

							// Move the subclassid away from the ['0','1','2'] former filter.
							let replaceAnotherRegExp;
							let filterAnother;

							if (
								classItemsArr.indexOf(subclassid) === 0 ||
								classItemsArr.indexOf(`'${subclassid}'`) === 0
							) {
								// The calssid is the first one: '0',
								replaceAnotherRegExp = new RegExp(`${subclassid},`);
								filterAnother = filter.replace(replaceAnotherRegExp, '');
							} else if (
								classItemsArr.indexOf(subclassid) === classItemsLength - 1 ||
								classItemsArr.indexOf(`'${subclassid}'`) === classItemsLength - 1
							) {
								// The classid is the last one: ,'2'
								replaceAnotherRegExp = new RegExp(`,${subclassid}`);
								filterAnother = filter.replace(replaceAnotherRegExp, '');
							} else {
								// The classid is not the first one and last one: ,'1',
								replaceAnotherRegExp = new RegExp(`,${subclassid},`);
								filterAnother = filter.replace(replaceAnotherRegExp, ',');
							}
							obj['filter'] = filterAnother;
						} else {
							// ['0']
							const lastVisiblity = this.isVisible(obj);
							lastVisiblity ? (obj['visible'] = false) : delete obj['visible'];
							if (type === 'line') {
								this.setVisibleForChildren(obj, !lastVisiblity)
							}
							if (!this.isVisible(rootObj) && !lastVisiblity) {
								// Set visible true
								delete rootObj['visible'];
							}
						}
					}
				} else {
					// class!='0,1,2'
					const classItems = classFilter.split('!=');
					let classStr = classItems[1].replace(/'/g, ''); // "0,1,2"
					let classItemsArr = classStr.split(','); // ['0']  or  ['0','1','2']
					if (classItemsArr.indexOf(`'${subclassid}'`) === -1) {
						// '3' is not in '0,1,2', so '3' will in this style obj.
						if (classItemsArr.length === 1) {
							// There are only on classid in the class filter.
							let filter_ = filter.replace(unequalRegExp, `${subclassname}=`);
							obj['filter'] = filter_;
							const lastVisiblity = this.isVisible(obj);
							!lastVisiblity ? delete obj['visible'] : (obj['visible'] = false);
							if (type === 'line') {
								this.setVisibleForChildren(obj, !lastVisiblity)
							}

							// When there is at least one sub class visible, set parent visible.
							// Parent is false, children is true, set parent visible.
							if (!this.isVisible(rootObj) && this.isVisible(obj)) {
								if (rootObj['visible']) {
									delete rootObj['visible'];
								}
							}

						} else {
							// There are more than one class ids in the class filter.

							// Add a new obj to stylesArr, this obj only has subclassid.
							const replaceRegExp = new RegExp(`${subclassname}!='(\\w*,*)*'`);
							let obj_ = _.cloneDeep(obj)
							let filter_ = filter.replace(replaceRegExp, `${subclassname}='${subclassid}'`); // The original filter will not change.
							obj_['filter'] = filter_;

							const lastVisiblity = this.isVisible(obj_);
							lastVisiblity ? (obj_['visible'] = false) : delete obj_['visible'];

							if (type === 'line') {
								this.setVisibleForChildren(obj_, !lastVisiblity);
							}
							if (!this.isVisible(rootObj) && this.isVisible(obj_)) {
								// Set visible true
								if (rootObj['visible']) {
									delete rootObj['visible'];
								}
							}
							stylesArr.push(obj_);

							// Add subclassid to the != filter.
							const replaceRegExp_ = new RegExp(`${subclassname}!='(\\w*,*)*`);
							let str = filter.match(replaceRegExp_)[0]; // "class!='0,1,2"
							// The original filter will not change.
							let filterAnother = filter.replace(replaceRegExp_, `${str},${subclassid}'`); // "class!='0,1,2,3"
							obj['filter'] = filterAnother;
						}
					}
				}
			} else {
				// There is no class in the filter
				let stylesArr = obj['style'];
				if (stylesArr && stylesArr.length > 0) {
					// There are some inner style objs. Find the one who has class filter.
					stylesArr.some((obj) => {
						this.setVisible(rootObj, obj, subclassid, subclassname, stylesArr, type);
					});
				}
			}
		}
	};

	handleEyeClick = (e, type, isHaveStyle) => {
		e.stopPropagation();
		const { handleJsonChange, zoom, centerPoint } = this.props;
		const json = this.state.localStyleJson;
		let json_ = _.cloneDeep(json);
		let styles = json_.styles;
		let target = e.currentTarget;
		let id = target.id;
		let subclasstitle = target.getAttribute('subclasstitle');
		if (isHaveStyle) {
			if (subclasstitle) {
				id = id.split(`_${subclasstitle}`)[0];
			};
			this.setState({
				eyeClickedParent: id
			});
			for (let i = 0, l = styles.length; i < l; i++) {
				if (styles[i].id === id) {
					this.setState({
						eyeClicked: !this.state.eyeClicked
					})
					let subclassname = target.getAttribute('subclassname');
					const styleTmp = JSON.parse(JSON.stringify(styles[i]));
					if (!subclasstitle) {
						// Parent node:
						const lastVisiblity = !target.classList.contains('anticon-eye-invisible');
						lastVisiblity
							? this.setAllFalse(styles[i], subclassname)
							: this.setAllTrue(styles[i]);
						this.setVisibleForChildren(styles[i], !lastVisiblity);
					} else {
						// Children node:
						let subclassid = target.getAttribute('subclassid');
						let rootObj;
						this.setVisible(rootObj, styles[i], subclassid, subclassname, undefined, type);
					}

					const styleTmpChanged = JSON.parse(JSON.stringify(styles[i]));
					const dataArr = [{
						type: 'initialVisibleStyle',
						data: styleTmp,
						mapState: {
							zoom,
							center: centerPoint
						}
					}, {
						type: 'visibleStyle',
						data: styleTmpChanged,
						mapState: {
							zoom,
							center: centerPoint
						}
					}];
					this.saveVisibleLayerData(dataArr);

					break;
				}
			}

			handleJsonChange(json_);
		}

	};

	saveVisibleLayerData = (layerData) => {
		const { index, maxLength, layerDatas } = this.props;

		this.props.saveLayerData(layerData, index, maxLength, layerDatas);
	}

	setAllFalse = (obj, subclassname) => {
		if (obj['filter'] && obj['filter'].match(regexp)) {
			obj['visible'] = false;
		}
		const regexp = new RegExp(subclassname, 'g');
		const styles = obj['style'];
		if (styles && styles.length > 0) {
			styles.forEach((styleObj) => {
				if (styleObj['filter'] && styleObj['filter'].match(regexp)) {
					styleObj['visible'] = false;
					this.setAllFalse(styleObj, subclassname);
				}
			});
		}
	};

	setAllTrue = (obj) => {
		delete obj['visible'];
		Object.keys(obj).some((key) => {
			if (key === 'visible') {
				delete obj['visible'];
			}
		});
		const styles = obj['style'];
		if (styles && styles.length > 0) {
			styles.forEach((styleObj) => {
				this.setAllTrue(styleObj);
			});
		}
	};

	updatedStyleJson = (stylejson, operatType) => {
		const { handleJsonChange } = this.props;
		const json_ = _.cloneDeep(this.state.localStyleJson);
		const stylejson_ = _.cloneDeep(stylejson);

		json_.styles = json_.styles.map((item) => {
			if (item.id === stylejson.id) {
				if (operatType === 'enable') {
					if (item.visible == false) {
						stylejson_.visible = true;
					}

					if (stylejson_.style && item.style) {
						stylejson_.style.forEach((classItmNew) => {
							item.style.forEach((classItmOld) => {
								if (classItmNew.filter === classItmOld.filter && classItmOld.visible === false) {
									classItmNew.visible = true
								}
							})
						})
					}
				} else {
					if (item.visible !== undefined) {
						stylejson_.visible = item.visible;
					}
					if (stylejson_.style && item.style) {
						stylejson_.style.forEach((classItmNew) => {
							item.style.forEach((classItmOld) => {
								if (classItmNew.filter === classItmOld.filter && classItmOld.filter !== undefined) {
									classItmNew.visible = classItmOld.visible
								}
							})
						})
					}
				}
			 
				return stylejson_;
			}

			return item
		});

		handleJsonChange(json_);
	};

	updateBackground = (color) => {
		const json_ = _.cloneDeep(this.state.localStyleJson);
		const { handleJsonChange } = this.props;
		if (color !== json_.background) {
			json_.background = color;
			handleJsonChange(json_);
		}
	}

	handleCurrentZoom = (isCurrentZoomFlag) => {
		this.props.handleCurrentZoom(isCurrentZoomFlag)
	}

	changeView = () => {
		this.setState({
			viewCode: !this.state.viewCode
		});
	};

	UpdateActiveStyle = (activeStyle) => {
		this.setState({
			activeStyle
		})
	}

	allSubitemInvisible = (item) => {
		const passed = item.class.filter(classItem => {
			return classItem['visible'] === true;
		})
		if (passed.length === 0) {
			// All the subitems : visible === false
			return true;
		} else {
			// There is at least one subitem : visible === true
			return false;
		}
	}

	loop = (data, subid = '', classname, sublayername) => {
		const { colors } = this.props;

		return data.map((item, index) => {
			let title = item.title;
			let id = item.id;
			if (!item.layerName) {
				// children items, like landcover's children: ice, land, grass, treess...
				if (item['searched'] === undefined || item['searched'] === true) {
					return (
						<TreeNode
							key={id}
							title={
								<React.Fragment>
									{this.addIconByType(item.type, item.color)}
									<span className="text">{title}</span>
									<Icon
										type={item.visible ? 'eye' : 'eye-invisible'}
										id={id}
										subclassid={item.value}
										subclasstitle={title}
										subclassname={classname}
										onClick={(e) => { this.handleEyeClick(e, item.type, item.isHaveStyle) }}
									/>
								</React.Fragment>
							}
							id={id}
							value={item.value}
							classTitle={item.title}
							menuType={item.type}
							menuTitle={id}
							menuContent={item.content}
							menuFilter={item.filter}
							subid={subid}
							color={item.color}
							isLeaf={true}
							classname={classname}
							sublayername={sublayername}
							url={item.url}
							className={(item.visible ? '' : 'invisible') + '' + (item.isHaveStyle ? '' : 'without-style')}
							pointStyle={item.pointStyle}
						// checkable={subid == this.state.subid ? true : false}
						/>
					);
				}
			}
			if (item.class) {
				// Parent items who have children, like landcover, country_boundary...
				classname = item.className;
				sublayername = item.layerName;
				if (this.allSubitemInvisible(item)) {
					item.visible = false;
				}
				let id_ = id.replace('_', ' ')
				if (item.class.length > 0) {
					if (item['searched'] === undefined || item['searched'] === true) {
						return (
							<TreeNode
								key={id}
								title={
									<React.Fragment>
										<span className="text">{id_}</span>
										<span className="count">({item.class.length})</span>
										<Icon
											type={item.visible ? 'eye' : 'eye-invisible'}
											id={id}
											onClick={(e) => { this.handleEyeClick(e, item.type, item.isHaveStyle) }}
											subclassname={classname}
										/>
									</React.Fragment>
								}
								id={id}
								menuType={item.type}
								menuTitle={id}
								menuContent={item.content}
								menuFilter={item.filter}
								value={item.value}
								classTitle={item.title}
								color={colors[id]}
								isLeaf={false}
								pointStyle={item.pointStyle}
								url={item.url}
								className={(item.visible ? '' : 'invisible') + '' + (item.isHaveStyle ? '' : 'without-style')}
							>
								{this.loop(item.class, id, classname, sublayername).filter((node) => node !== undefined)}
							</TreeNode>
						);
					}
				} else {
					return;
				}
			}
			// Parent items who don't have child, like: water, waterway...
			if (item['searched'] === undefined || item['searched'] === true) {

				return (
					<TreeNode
						key={id}
						title={
							<React.Fragment>
								{/* <IconFont type="icon-dot" className='no-classlist' ></IconFont> */}
								{this.addIconByType(item.type, colors[id])}
								<span className="text">{id}</span>
								<Icon
									type={item.visible ? 'eye' : 'eye-invisible'}
									id={id}
									onClick={(e) => { this.handleEyeClick(e, item.type, item.isHaveStyle) }}
								/>
							</React.Fragment>
						}
						id={id}
						menuType={item.type}
						menuTitle={id}
						color={colors[id]}
						menuContent={item.content}
						menuFilter={item.filter}
						value={item.value}
						classTitle={item.title}
						isLeaf={true}
						pointStyle={item.pointStyle}
						url={item.url}
						className={(item.visible ? '' : 'invisible') + '' + (item.isHaveStyle ? '' : 'without-style') + ' ' + ('list-no-class')}
					/>
				);
			}
		});
	}

	drawerTitle = () => {
		let { menuTitle, menuType, color, menuContent, url, subid, value, title, id } = this.state;
		if (id === 'background') {
			color = this.state.localStyleJson.background;
		}

		let str = ''
		if (menuTitle[0]) {
			menuTitle[0].split('_').forEach((item, index) => {
				if (index === 0) {
					str += item + ' ';
				} else if (index === 1) {
					str += item;
				} else if (index === 2) {
					str += '/' + item;
				}

			})
		}
		let title_ = str;
		return (
			<React.Fragment>
				<h1>
					<span className="title-icon">
						{menuType === 'polygon' ? (
							<IconFont type="icon-vector-polygon" style={{ backgroundColor: color }} />
						) : menuType === 'line' ? (
							<IconFont type="icon-line" style={{ backgroundColor: color }} />
						) : (
									<IconFont type="icon-font" style={{ backgroundColor: color }} />
								)}
					</span>
					{title_}	<span className="title-question" ><Tooltip title={(<p className='detailLink'> {menuContent} <a
						href={url}
						target="_blank"
						rel="noopener noreferrer"
					>
						DETAIL
					</a> </p>)}>
						<Icon type="question" /></Tooltip>
					</span>
				</h1>
				{/* <p className={menuTitle.length > 1 ? 'hide' : 'show'}>
					<Tooltip placement="topLeft" titile={menuContent}>
						
					</Tooltip>
					
				</p> */}
			</React.Fragment>
		)
	}

	shouldCloseSidebarPanel = (nextProps) => {
		const { newSidebarData } = nextProps;
		const { selectedKeys } = this.state;

		if (selectedKeys.length && newSidebarData) {
			if (!this.IsSelectedKeysInSidebarData(newSidebarData, selectedKeys)) {
				this.setState({
					closed: true
				})
			}
		}
	}

	IsSelectedKeysInSidebarData = (data, selectedKeys) => {
		// search in parent
		for (let i = 0; i < data.length; i++) {
			const item = data[i];

			if (selectedKeys.includes(item.id)) {
				return true;
			}
		}

		// search in child
		for (let i = 0; i < data.length; i++) {
			const item = data[i];

			if (item.class) {
				return this.IsSelectedKeysInSidebarData(item.class, selectedKeys);
			}
		}

		return false;
	}

	render() {
		const { newSidebarData, styleJsonCode } = this.props;
		const { menuTitle, expandedKeys, closed, visible, eyeClicked, eyeClickedParent, activeStyle, selectedKeys, selectLayerInfor, id } = this.state;
		let selectItem;
		newSidebarData.forEach((item) => {
			if (item.id === selectedKeys[0]) {
				selectItem = item
			} else if (item.class) {
				item.class.forEach((itemClass) => {
					if (itemClass.id === selectedKeys[0]) {
						selectItem = itemClass
					}
				})
			}
		})

		return (
			<React.Fragment>
				<ScrollBar
					// ref = {(ref) => {this._scrollBarRef = ref;}}
					containerRef={(ref) => { this._scrollBarRef = ref; }}
				>
					<div className="tree-list-wrap">
						<Tree
							className="draggable-tree"
							onDrop={this.onDrop}
							onDragStart={this.onDragStart}
							onSelect={this.onSelect}
							filterTreeNode={this.filterTreeNode}
							selectedKeys={selectedKeys}
							onExpand={this.handleExpand}
							expandedKeys={expandedKeys}
						// draggable
						>
							{
								this.loop(newSidebarData).filter((node) => node !== undefined)
							}

							<TreeNode
								key={'background'}
								title={
									<React.Fragment>
										{/* <IconFont type="icon-dot" className='no-classlist' ></IconFont> */}
										<span className="title-icon">
											<IconFont type="icon-vector-polygon" style={{ backgroundColor: this.state.localStyleJson.background }} />
										</span>
										<span className="text">background</span>

									</React.Fragment>
								}
								id={'background'}
								menuType={'polygon'}
								menuTitle={'background'}
								color={this.state.localStyleJson.background}
								menuContent={'Map background'}
								isLeaf={true}
								className={'list-no-class'}
							/>
						</Tree>
					</div>
				</ScrollBar>
				<Drawer
					title={this.drawerTitle()}
					width="280"
					placement="left"
					closable={true}
					mask={false}
					maskClosable={false}
					visible={!closed && visible}
					getContainer={this.getDrawerContainer}
					zIndex={998}
					onClose={this.closeLayerStyleBox}
					className={this.state.viewCode ? 'menu-drawer view-code-drawer' : 'menu-drawer'}
					afterVisibleChange={this.props.afterVisibleChange}

				// drag
				>
					<div id="muneScrollBar">
						<ScrollBar>
							<div className="layerWrap">
								<div className="code-preview">
									<ReactJson
										src={styleJsonCode}
										theme="google"
										enableClipboard={true}
										displayObjectSize={false}
										displayDataTypes={false}
										name={false}
									/>
								</div>
								{id !== 'background' && id !== undefined ? <div className="editing-panel">
									<FilterLayerWrap
										id={menuTitle[0]}
										activeStyle={activeStyle}
										filterStandard={this.state.filterStandard}
										value={this.state.value}
										type={this.state.menuType}
										updatedStyleJson={this.updatedStyleJson}
										toggleFilterLayerVisible={this.toggleFilterLayerVisible}
										handleJsonViewerChange={this.props.handleJsonViewerChange}
										UpdateActiveStyle={this.UpdateActiveStyle}
										pointStyle={this.state.pointStyle}
										handleCurrentZoom={this.handleCurrentZoom}
										eyeClicked={eyeClicked}
										eyeClickedParent={eyeClickedParent}
										selectedKeys={selectedKeys}
										menuTitle={menuTitle}
										selectLayerInfor={selectLayerInfor}
										autoRefresh={this.props.autoRefresh}
									/>
								</div> : <Row >
										<Col span={3} className='backgrandlabel'  >
											<Tooltip title="Map background color  "><IconFont className="style-icon" type="icon-color" /></Tooltip>
										</Col>
										<Col span={8}  >
											<ColorPicker color={this.state.localStyleJson.background} updateColor={this.updateBackground} />
										</Col>
									</Row>}
							</div>
						</ScrollBar>
						{selectItem && selectItem.isHaveStyle ? <div className='segmentation' /> : null}
						{selectItem && selectItem.isHaveStyle ? <span id="change-view" className={this.state.viewCode ? 'showcode' : 'hidecode'} onClick={this.changeView}>
							<Tooltip placement="topRight" title={this.state.viewCode ? 'Code in StyleJson' : 'Style'}>
								<IconFont type="icon-code" />
							</Tooltip>
						</span> : null}
					</div>
				</Drawer>
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state) => {
	const { rootReducer, filter } = state;

	return {
		index: filter.index,
		layerDatas: filter.layerDatas,
		maxLength: filter.maxLength,
		json: rootReducer.json,
		zoom: rootReducer.zoom,
		centerPoint: rootReducer.centerPoint
	}
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators({
		handleJsonChange: updateJson,
		saveLayerData
	}, dispatch)
};

export default connect(mapStateToProps, mapDispatchToProps)(LayerList);
