import React, { Component } from 'react';

import { Form, Icon, Tooltip, Select, Row, Col, InputNumber, Radio, Upload, Button } from 'antd';
import '../index.scss'
import './index.scss'
import ColorPicker from '../../../tool/colorPicker';
import IconFont from '../../../tool/iconFont';
import _ from 'lodash'
const Option = Select.Option;



const props = {
    action: '//jsonplaceholder.typicode.com/posts/',
    listType: 'picture',
}


class ShieldIcon extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...this.props.activeStyle
        }
    }

    componentWillReceiveProps(nextProps) {
        const state = _.cloneDeep(this.state)
        Object.keys(state).map((stateKey) => {
            state[stateKey] = undefined
        })

        this.setState({ ...state, ...nextProps.activeStyle })
    }


    handlePointFill = (color) => {
        this.setState({ 'shield-icon-color': color }, () => {
            this.props.updatedStyle(this.state)
        })
    }

    handlePointOutlineColor = (color) => {
        this.setState({ 'shield-icon-outline-color': color }, () => {
            this.props.updatedStyle(this.state)
        });

    }
    handlePointSymbolTypel = (e) => {

        this.setState({ 'shield-icon-symbol-type': e.target.value }, () => {
            this.props.updatedStyle(this.state)
        });

    }

    handleTextPointType = (value) => {
        if (value !== null) {
            this.setState({ 
            'shield-icon-type': value,
            'shield-icon-symbol-type': this.state['shield-icon-symbol-type'] !== undefined ? this.state['shield-icon-symbol-type'] : 'circle' ,
            'shield-icon-size': this.state['shield-icon-size'] !== undefined ? this.state['shield-icon-size'] : 10,
            'shield-icon-color': this.state['shield-icon-color'] !== undefined ? this.state['shield-icon-color'] : '#000000'
        }, () => {
                this.props.updatedStyle(this.state)
            });
        }else{
            this.setState({ 'shield-icon-type': value }, () => {
                this.props.updatedStyle(this.state)
            });
        }
    }

    handlePointSize = (value) => {
        if (this.state['shield-icon-color'] === undefined) {
            this.setState({ 'shield-icon-size': value, 'shield-icon-color': '#000000' }, () => {
                this.props.updatedStyle(this.state)
            });
        } else {
            this.setState({ 'shield-icon-size': value }, () => {
                this.props.updatedStyle(this.state)
            });
        }


    }
    handlePointOutlineWidth = (value) => {
        if (this.state['shield-icon-outline-color'] === undefined) {
            this.setState({ 'shield-icon-outline-width': value, 'shield-icon-outline-color': '#000000' }, () => {
                this.props.updatedStyle(this.state)
            });
        } else if (value === 0) {
            this.setState({ 'shield-icon-outline-width': value }, () => {
                delete this.state['shield-icon-outline-color'];
                this.props.updatedStyle(this.state)
            });
        } else {
            this.setState({ 'shield-icon-outline-width': value }, () => {
                this.props.updatedStyle(this.state)
            });
        }
    }

    handlePointFile = (value) => {
        this.setState({ 'shield-icon-file': value }, () => {
            this.props.updatedStyle(this.state)
        });
    }



    render() {

        return (
            <Form>

                <Form.Item>
                    <Row type="flex" className={this.state["shield-icon-type"] ? "" : 'opacity06'}>
                        <Col span={11} className="style-label">Point Type</Col>
                        <Col span={11} offset={2} >
                            <Select size="small" className="style-input" onChange={this.handleTextPointType} value={this.state["shield-icon-type"] ? this.state["shield-icon-type"] : 'none'}  >
                                <Option value="none">None</Option>
                                <Option value="symbol">Symbol</Option>
                            </Select>
                        </Col>
                    </Row>
                </Form.Item>

                {/* Point Symbol Type */}
                <Form.Item className={this.state['shield-icon-type'] === 'symbol' ? "" : "dispaly-none"}>
                    <Row type="flex" justify="space-around" align="middle">
                        <Col span={3} className={this.state["shield-icon-symbol-type"] ? "" : 'opacity06'}   >
                            <Tooltip title="Symbol Type of Coordinate"><IconFont className="style-icon" type="icon-type" /></Tooltip>
                        </Col>
                        <Col span={21} className={this.state["shield-icon-symbol-type"] ? "" : 'opacity06'} >
                            <Radio.Group size="small" onChange={this.handlePointSymbolTypel} className='inputRadio symbol-type' value={this.state["shield-icon-symbol-type"] ? this.state["shield-icon-symbol-type"] : 'circle'}   >
                                <Radio.Button value="circle">
                                    <Tooltip title="Circle">
                                        <IconFont type="icon-yuan" />
                                    </Tooltip>
                                </Radio.Button>
                                <Radio.Button value="square">
                                    <Tooltip title="Square">
                                        <IconFont type="icon-juxing" />
                                    </Tooltip>
                                </Radio.Button>
                                <Radio.Button value="triangle">
                                    <Tooltip title="Triangle">

                                        <IconFont type="icon-sanjiaoxing-copy" />
                                    </Tooltip>
                                </Radio.Button>

                                <Radio.Button value="star">
                                    <Tooltip title="Star">
                                        <IconFont type="icon-xing" />
                                    </Tooltip>
                                </Radio.Button>
                            </Radio.Group>
                        </Col>
                    </Row>
                </Form.Item>

                {/* Point File */}
                {/* <Form.Item className={this.state['shield-icon-type'] === 'image' ? "" : "dispaly-none"}>
                    <Row type="flex" justify="space-around" align="middle" className={this.state["shield-icon-file"] ? "" : 'opacity06'}    >
                        <Col span={10} className="style-label">Icon Img</Col>
                        <Col span={14}>
                            <Upload {...props}>
                                <Button size="small" className="style-input">
                                    <Icon type="upload" /> Upload
                                </Button>
                            </Upload>
                        </Col>
                    </Row>
                </Form.Item> */}

                {/* Point Size&Point Color */}
                <Form.Item className={this.state['shield-icon-type'] === 'symbol' ? "" : "dispaly-none"}>
                    <Row type="flex" justify="space-around" align="middle">
                        <Col span={3} className={this.state["shield-icon-color"] ? "" : 'opacity06'}><Tooltip title="Symbol Color of Coordinate "><IconFont className="style-icon" type="icon-icon-color1" /></Tooltip> </Col>
                        <Col span={8}>
                            <ColorPicker color={this.state["shield-icon-color"] ? this.state["shield-icon-color"] : '#000000'} updateColor={this.handlePointFill}>
                            </ColorPicker>
                        </Col>

                        <Col span={3} offset={2} className={this.state["shield-icon-size"] ? "" : 'opacity06'} ><Tooltip title="Symbol Size of Coordinate"><IconFont className="style-icon" type="icon-icon-size" /></Tooltip> </Col>
                        <Col span={8} className={this.state["shield-icon-size"] ? "" : 'opacity06'} >
                            <InputNumber size="small" className="style-input" min={0} max={100} value={this.state["shield-icon-size"] ? this.state["shield-icon-size"] : 0} onChange={this.handlePointSize} />
                        </Col>
                    </Row>
                </Form.Item>


                <Form.Item className={this.state['shield-icon-type'] === 'symbol' ? "" : "dispaly-none"}>
                    <Row type="flex" justify="space-around" align="middle" >
                        <Col className={this.state["shield-icon-outline-color"] ? "" : 'opacity06'} span={3}  >
                            <Tooltip title="Symbol’s Outline Color of Coordinate"><IconFont className="style-icon" type="icon-icon-outline-color1" />

                            </Tooltip>
                        </Col>
                        <Col span={8}    >
                            <ColorPicker color={this.state["shield-icon-outline-color"] ? this.state["shield-icon-outline-color"] : '#000000'} updateColor={this.handlePointOutlineColor}>
                            </ColorPicker>
                        </Col>
                        <Col className={this.state["shield-icon-outline-width"] ? "" : 'opacity06'} span={3} offset={2}  >
                            <Tooltip title="Symbol’s Outline Width of Coordinate"><IconFont className="style-icon " type="icon-icon-outline-width1" />

                            </Tooltip>
                        </Col>
                        <Col className={this.state["shield-icon-outline-width"] ? "" : 'opacity06'} span={8}    >
                            <InputNumber size="small" className="style-input" min={0} max={100} value={this.state["shield-icon-outline-width"] ? this.state["shield-icon-outline-width"] : 0} onChange={this.handlePointOutlineWidth} />
                        </Col>


                    </Row>
                </Form.Item>


            </Form>
        )
    }

}


export default ShieldIcon;