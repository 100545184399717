import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Select, message } from 'antd';
import {updateMap} from '../../redux/actions/index';
const { Option } = Select;

class AppliedZoom extends Component {
  constructor(props){
    super(props);
    this.state = {
      sourceFilter: [],
      filterStandard: [],
      selectedItems: [],
      value: []
    }
  }

  initState = (props) => {
    this.initSelectedItems(props);
    this.setState({
      ...props
    })
  }

  componentDidMount(){
    this.initState(this.props);
  }

  componentWillReceiveProps(nextProps){
    this.initState(nextProps);
  }

  handleChange = (value) => {
    const {zoom} = this.props;

    if(!value.includes(`${zoom}`)){
      message.error(`To remove this style from the current zoom level, set the “Enabled” slider to off.`, 5);
    }else{
      this.setState({
        value: value.sort((a, b) => {
          if(+a > +b){
            return 1;
          }else if(+a <= +b){
            return -1;
          }
        })
      }, this.udpateResultStyle)
    }    
  }

  udpateResultStyle = () => {
    const {value, zoom, resultStyle} = this.state;

    resultStyle.forEach(item => {
      const equalArr = item.zoom;
      
      if(equalArr.includes(`${zoom}`) || equalArr.includes(zoom)){
        item.zoom = [...value];
      }
    });

    this.props.updateMap(false);
    this.props.updateStyleJson();
  }

  initSelectedItems = (props) => {
    const {resultStyle, zoom} = props;
    const selectedItems = [];
    let currentValues = [];

    resultStyle.forEach(item => {
      const equalArr = item.zoom;
      
      if(equalArr.includes(`${zoom}`) || equalArr.includes(zoom)){
        currentValues.push(...equalArr);
      }else{
        selectedItems.push(...equalArr);
      }
    });

    this.setState({
      selectedItems,
      value: currentValues
    })
  }
  
  renderSelectItem = () => {
    const {filterStandard, selectedItems} = this.state;
    const {zoom} = this.props;
    const type = 'zoom';
    
    let standerMax = Number(filterStandard[type] ? filterStandard[type].maxRange : 19);
    let standerMin = Number(filterStandard[type] ? filterStandard[type].minRange : 0);
    let children = [];
    
    if (standerMax > standerMin && standerMax < 30) {
        for (let i = standerMin; i <= standerMax; i++) {
            const disabled = selectedItems.includes('' + i) && i !== zoom;
            children.push(<Option key={i} title={disabled ? 'Another style is already applied to this zoom level' : ''} disabled={disabled}>{i}</Option>);
        }
    }

    return children;
  }

  render(){
    const {value} = this.state;
    
    return (
      <React.Fragment>
        <label 
          htmlFor=""
          style={{ 
            color: '#ffffff',
            fontSize: '13px' ,
            margin: '0.2rem 0',
            display: 'inline-block'
          }}
        >
          Styles applied to zoom levels:
        </label>
        <Select
          mode='multiple'
          placeholder='Please select'        
          value={value}
          onChange={this.handleChange}
          className='multiple-zoom-select'
          style={{ width: '100%' }}
        >
          { this.renderSelectItem() }
        </Select>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => {
  const {rootReducer} = state;

  return ({
    zoom: Math.floor(rootReducer.zoom)
  })
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    updateMap
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(AppliedZoom);

